import { ArrayParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';

export const useFilterQueryParameters = () => {
    const [locationsRaw, setLocations] = useQueryParam('location', ArrayParam);
    const [dateRaw, setDate] = useQueryParam('date', StringParam);
    const [timeRaw, setTime] = useQueryParam('time', StringParam);
    const [durationRaw, setDuration] = useQueryParam('duration', NumberParam);

    const locations = locationsRaw?.filter<string>((location): location is string =>
        Boolean(location)
    );

    const date = dateRaw ? (dateRaw as string) : undefined;

    const time = timeRaw ? (timeRaw as string) : undefined;

    const duration = durationRaw ?? undefined;

    return {
        locations,
        setLocations,
        date,
        setDate,
        time,
        setTime,
        duration,
        setDuration,
    };
};
