import { FC } from 'react';
import { format } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Stack, Avatar, Typography } from '@mui/material';

import { UserData, UserDataFields } from 'src/features/user/api';

import type { MaintenanceChatMessage } from '../../../api';

const OPERATIONS_TEAM_USER = {
    [UserDataFields.AvatarUrl]: null,
    [UserDataFields.FirstName]: 'Operations',
    [UserDataFields.LastName]: 'Team',
};

export const Message: FC<{
    currentUser: UserData;
    message: MaintenanceChatMessage;
}> = ({ message, currentUser }) => {
    const { palette } = useTheme();

    const isFromMyUser = !message.inbox;

    const user = isFromMyUser ? currentUser : OPERATIONS_TEAM_USER;
    const username = `${user[UserDataFields.FirstName]} ${user[UserDataFields.LastName]}`;

    return (
        <Stack direction={isFromMyUser ? 'row-reverse' : 'row'} gap="12px">
            <Avatar
                src={user.avatar_url!}
                alt="user avatar"
                css={{
                    width: '36px',
                    height: '36px',
                    background: isFromMyUser ? palette.secondary.main : undefined,

                    svg: {
                        width: '24px',
                        height: '24px',
                    },
                }}
            />

            <div
                css={{
                    padding: '16px',
                    flex: 1,
                    borderRadius: isFromMyUser ? '4px 0px 4px 4px' : '0px 4px 4px 4px',
                    background: isFromMyUser ? palette.secondary.main : palette.background.paper,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="16px"
                >
                    {!isFromMyUser && <Typography variant="subtitle2">{username}</Typography>}

                    <Typography variant="body2" color="text.secondary">
                        {format(new Date(message.created_datetime), 'HH:mm')}
                    </Typography>
                </Stack>

                <Typography variant="body2" css={{ wordBreak: 'break-word' }}>
                    {message.message}
                </Typography>
            </div>
        </Stack>
    );
};
