import { FC } from 'react';
import { Stack } from '@mui/material';

import { GA4 } from 'src/lib/ga4';

import { MoveOutInnerComplete } from './move-out-inner-complete';
import { MoveOutInnerIncomplete } from './move-out-inner-incomplete';

export const MoveOut: FC<{
    requestedDateCheckOut: string | null;
    checkOutFormURL: string;
}> = ({ requestedDateCheckOut, checkOutFormURL }) => {
    const handleClick = () => GA4.outboundLink('Check-Out Click', checkOutFormURL);

    return (
        <Stack
            component="a"
            href={checkOutFormURL}
            onClick={handleClick}
            target="_blank"
            css={{
                padding: 0,
                width: '100%',
                borderRadius: 0,
                textDecoration: 'inherit',
                color: 'inherit',
                ':hover': {
                    '[aria-label="link title"]': {
                        ':after': {
                            width: '100%',
                        },
                    },
                },
            }}
        >
            {requestedDateCheckOut ? (
                <MoveOutInnerComplete requestedDateCheckOut={requestedDateCheckOut} />
            ) : (
                <MoveOutInnerIncomplete />
            )}
        </Stack>
    );
};
