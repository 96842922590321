import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { ErrorState } from 'src/components/error-state';
import { AppPreloader } from 'src/components/app-preloader';
import { EmptyList } from 'src/components/empty-list/empty-list';
import { Button } from 'src/components/button';
import { routes } from 'src/constants/routes';

import { SocialSpaceBookingCard } from '../social-space-booking-card/social-space-booking-card';
import { useGetMySocialSpaceBookings } from '../../hooks/use-get-my-social-space-bookings';

export const SocialSpaceBookingsList = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { breakpoints } = useTheme();

    const { data: spaceBookings, isLoading, isError, isFetching } = useGetMySocialSpaceBookings();

    if (isError) {
        return <ErrorState direction="column" title={t('social_space_my_bookings_error')} />;
    }

    if (isLoading || isFetching) {
        return <AppPreloader />;
    }

    const handleBackToSpaces = () => navigate(routes.socialSpaces.index);

    return (
        <Box
            padding={{
                xs: '16px 16px 40px 16px',
                md: '40px 40px 60px 40px',
                lg: '40px 60px 60px 60px',
                xl: '40px 100px 60px 100px',
            }}
            gap={{ xs: '16px' }}
            flex={1}
        >
            {spaceBookings.length ? (
                <Box
                    display="grid"
                    gridTemplateColumns={{ xs: '1fr ', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }}
                    gap={{ xs: '16px', lg: '24px' }}
                    marginBottom="auto"
                >
                    {spaceBookings.map((spaceBooking) => (
                        <SocialSpaceBookingCard
                            socialSpaceBooking={spaceBooking}
                            key={spaceBooking.id}
                        />
                    ))}
                </Box>
            ) : (
                <EmptyList
                    mainText={<Trans i18nKey="social_space_my_bookings_no_bookings_title" />}
                    secondaryText={t('social_space_my_bookings_no_bookings_subtitle')}
                    actionRow={
                        <Button
                            onClick={handleBackToSpaces}
                            design="primary"
                            css={{ width: '100%' }}
                        >
                            {t('social_space_my_bookings_no_bookings_cta')}
                        </Button>
                    }
                    css={{
                        [breakpoints.up('md')]: {
                            width: '280px',
                            margin: 'auto',
                        },
                    }}
                />
            )}
        </Box>
    );
};
