import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import findLast from 'lodash/findLast';
import { useTheme } from '@emotion/react';
import { Stack, Typography } from '@mui/material';

import { LanguagesCode } from 'src/i18n/constants';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { trimText } from 'src/utils/trim-text';
import { routes } from 'src/constants/routes';
import { EventType, GA4 } from 'src/lib/ga4';

import { TicketChip } from '../../ticket-cheap';
import { MaintenanceTicket } from '../../../api';

import { TicketNotification } from './ticket-notification';

const TicketRow: FC<{ label: string; value: string }> = ({ label, value }) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        paddingTop="20px"
        gap="1em"
        css={{
            ':not(:last-of-type)': {
                borderBottom: '1px solid #ECEDEE',
                paddingBottom: '20px',
            },
        }}
    >
        <Typography variant="body2" color="text.secondary" flex={1}>
            {label}
        </Typography>

        <Typography variant="subtitle2" textAlign="right" flex={1}>
            {value && trimText(value, 50)}
        </Typography>
    </Stack>
);

export const TicketItem: FC<{
    ticket: MaintenanceTicket;
}> = ({ ticket }) => {
    const { shadows } = useTheme();

    const { t, i18n } = useTranslation();
    const lngCode = i18n.language as LanguagesCode;

    const messages = ticket.comments || [];

    const lastMessage = findLast(messages, ({ inbox }) => inbox); // WARN: we need to have 'unread' field

    const handleClick = () =>
        GA4.event('Maintenance Ticket Click', EventType.Cta, {
            ticketStatus: ticket.status,
        });

    return (
        <Link
            onClick={handleClick}
            to={routes.maintenance.id.replace(':id', ticket.id)}
            css={{
                padding: '24px',
                boxShadow: shadows[1],
                textDecoration: 'inherit',
                color: 'inherit',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="8px"
            >
                <TicketChip status={ticket.status} />

                <ArrowIcon
                    css={{
                        transform: 'rotate(270deg)',
                    }}
                />
            </Stack>

            <div>
                <TicketRow
                    label={t('maintenance_reported-issues_card_issue-id')}
                    value={ticket.case_number}
                />

                <TicketRow
                    label={t('maintenance_reported-issues_card_issue')}
                    value={ticket.issue_display_name[lngCode]}
                />

                <TicketRow
                    label={t('maintenance_reported-issues_card_issue-details')}
                    value={ticket.description}
                />
            </div>

            {lastMessage && (
                <Link
                    css={{ textDecoration: 'inherit', color: 'inherit' }}
                    to={{
                        pathname: routes.maintenance.id.replace(':id', ticket.id),
                        search: new URLSearchParams({ 'is-chat-open': '1' }).toString(),
                    }}
                >
                    <TicketNotification lastMessage={lastMessage} />
                </Link>
            )}
        </Link>
    );
};
