import { FC, Fragment, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Skeleton, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { range } from 'lodash';
import { useTheme } from '@emotion/react';

import { PrivateHat } from 'src/layout/private-hat';
import { EventsList, Subject, SUBJECT_TITLE_TRANSLATION_KEY } from 'src/features/feedback';
import { useGetMyPastEvents } from 'src/features/events';
import { ErrorState } from 'src/components/error-state';
import { Button } from 'src/components/button';
import { routes } from 'src/constants/routes';
import { ReactComponent as RetryIcon } from 'src/assets/svg/retry.svg';
import { EmptyList } from 'src/components/empty-list/empty-list';

export const FeedbackEventsPage: FC = () => {
    const { t } = useTranslation();

    const { palette, custom, breakpoints } = useTheme();

    const { data, isLoading, isSuccess, isError, refetch } = useGetMyPastEvents();

    // Sort events in descending order (most recent events on top)
    const eventsDsc = data
        ?.slice()
        .sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());

    const [eventId, setEventId] = useState<string | null>(null);

    const handleEventChange = (id: string) => setEventId(id);

    const handleRetry = () => refetch;

    const isEmptyList = isSuccess && eventsDsc?.length === 0;

    const hasContent = isSuccess && !isEmptyList;

    return (
        <Fragment>
            <PrivateHat
                title={t(SUBJECT_TITLE_TRANSLATION_KEY[Subject.Events])}
                backIconText={t('back_cta')}
            />

            <Stack
                padding={{ xs: '16px 16px 85px 16px', md: '40px' }}
                flexGrow={1}
                gap={{ xs: '26px', md: '60px' }}
                justifyContent={{ md: 'center' }}
                alignItems={{ md: 'center' }}
            >
                <Stack width="100%" alignItems={{ md: 'center' }}>
                    {!isEmptyList && (
                        <Typography
                            variant="subtitle1"
                            color="text.primary"
                            textAlign={{ md: 'center' }}
                            maxWidth={{ md: '350px' }}
                        >
                            {t('feedback_page_select-event_description')}
                        </Typography>
                    )}

                    {isError && (
                        <Stack
                            minHeight={{ md: '400px' }}
                            flexGrow={1}
                            justifyContent="center"
                            alignItems="center"
                            gap="10px"
                        >
                            <ErrorState
                                title={<Trans i18nKey="feedback_page_select-event_error_api" />}
                                css={{ flex: 0, paddingBottom: '20px' }}
                            />

                            <Button
                                design="primary"
                                onClick={handleRetry}
                                endIcon={<RetryIcon />}
                                css={{ width: '250px' }}
                            >
                                {t('feedback_page_select-event_error_cta')}
                            </Button>
                        </Stack>
                    )}

                    {isLoading && (
                        <Stack
                            padding="32px 0"
                            gap="12px"
                            marginX={{ xs: '-16px', md: '0' }}
                            width={{ md: '100%' }}
                            maxWidth={{ md: '600px' }}
                        >
                            {range(3).map((index) => (
                                <Stack direction="row" gap={{ xs: '16px', md: '24px' }} key={index}>
                                    <Skeleton variant="rectangular" width="33.33%" height="104px" />

                                    <Stack
                                        gap="12px"
                                        marginRight={{ xs: '16px', md: '24px' }}
                                        flexGrow={1}
                                        justifyContent="center"
                                    >
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="40px"
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            width="132px"
                                            height="20px"
                                        />
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    )}

                    {hasContent && (
                        <EventsList
                            events={eventsDsc!}
                            onEventChange={handleEventChange}
                            selectedEventId={eventId}
                        />
                    )}

                    {isEmptyList && (
                        <EmptyList
                            mainText={<Trans i18nKey="feedback_events_empty-state_title" />}
                            secondaryText={t('feedback_events_empty-state_subtitle')}
                            actionRow={
                                <Button
                                    component={Link}
                                    to={routes.feedback.index}
                                    design="light"
                                    bordered
                                    css={{
                                        width: '100%',
                                    }}
                                >
                                    {t('feedback_events_empty-state_secondary-cta')}
                                </Button>
                            }
                            css={{
                                [breakpoints.up('md')]: {
                                    minWidth: '260px',
                                    margin: 'auto',
                                },
                            }}
                        />
                    )}
                </Stack>

                <Stack
                    alignItems="center"
                    position={{ xs: 'fixed', md: 'static' }}
                    width="100%"
                    left={0}
                    bottom={{ xs: custom.footerHeight, md: 0 }}
                    css={{
                        [breakpoints.down('md')]: {
                            backgroundColor: palette.background.default,
                        },
                    }}
                    padding={{
                        xs: '20px 16px',
                        md: '0',
                    }}
                >
                    {isLoading && (
                        <Skeleton
                            height="45px"
                            variant="rounded"
                            css={{
                                width: '100%',
                                [breakpoints.up('md')]: {
                                    width: '260px',
                                },
                            }}
                        />
                    )}

                    {hasContent && (
                        <Button
                            design="primary"
                            disabled={eventId === null}
                            component={Link}
                            fullWidth
                            to={routes.feedback.events.id.replace(':id', eventId ?? '')}
                            css={{
                                [breakpoints.up('md')]: {
                                    width: '260px',
                                },
                            }}
                        >
                            {t('feedback_page_select-event_cta')}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Fragment>
    );
};
