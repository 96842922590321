import { FC, memo, useRef } from 'react';
import { Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { format, parseISO } from 'date-fns';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { getLocalIso } from 'src/utils/get-local-iso';

import { DateOption } from '../../api';
import {
    CAROUSEL_SWIPE_ENABLED_LIMIT,
    DATE_SELECTOR_SWIPER_BREAKPOINTS,
} from '../../constants/date-selector-swiper-breakpoints';

const DateSelectorUnwrapped: FC<{
    label?: string;
    selectedDate?: string;
    onDateChange: (newDate: string) => void;
    options: DateOption[];
    isShowAllDates?: boolean;
    disabled?: boolean;
}> = ({ label, selectedDate, onDateChange, options, isShowAllDates, disabled }) => {
    const { palette } = useTheme();

    const swiperRef = useRef<SwiperClass | null>(null);

    const setSwiperRef: SwiperProps['onSwiper'] = (swiper) => {
        swiperRef.current = swiper;
    };

    const isCarouselScrollable = useMediaQuery(`(max-width:${CAROUSEL_SWIPE_ENABLED_LIMIT}px)`);

    const handleSlideToPrevious = () => swiperRef.current?.slidePrev();

    const handleSlideToNext = () => swiperRef.current?.slideNext();

    const initialSlide = options.findIndex((selectorDate) => selectedDate === selectorDate.date);

    return (
        <Stack gap="12px" overflow="hidden" css={{ pointerEvents: disabled ? 'none' : undefined }}>
            {label && (
                <Typography variant="body2" color={palette.text.secondary}>
                    {label}
                </Typography>
            )}

            <Stack direction="row" alignItems="center">
                {isCarouselScrollable && (
                    <IconButton
                        disabled={swiperRef.current?.isBeginning}
                        onClick={handleSlideToPrevious}
                        css={{ padding: '2px' }}
                    >
                        <ArrowIcon
                            width="18px"
                            height="18px"
                            css={{ transform: 'rotate(90deg)' }}
                        />
                    </IconButton>
                )}

                <Swiper
                    slidesPerView={4}
                    breakpoints={DATE_SELECTOR_SWIPER_BREAKPOINTS}
                    onSwiper={setSwiperRef}
                    initialSlide={initialSlide}
                    css={{
                        width: '100%',
                    }}
                >
                    {options.map((option) => {
                        const date = parseISO(option.date);
                        const isoDate = getLocalIso(date);
                        const dayOfMonth = format(date, 'dd');
                        const weekday = format(date, 'iii');
                        const month = format(date, 'MMM');

                        const isSelected = selectedDate && selectedDate === isoDate;

                        const isDateHidden = !isShowAllDates && option.daily_limit_remaining === 0;

                        const handleDateChange = () => !disabled && onDateChange(isoDate);

                        const getCssPropValues = () => {
                            if (disabled || isDateHidden) {
                                return {
                                    color: '#D8D8D8 !important',
                                    background: 'none',
                                };
                            }
                            if (isSelected) {
                                return {
                                    color: `${palette.common.white} !important`,
                                    background: palette.common.black,
                                    '&:hover': {
                                        background: palette.common.black,
                                    },
                                };
                            }

                            return {
                                color: palette.common.black,
                            };
                        };

                        return (
                            <SwiperSlide key={isoDate}>
                                <Button
                                    onClick={handleDateChange}
                                    key={isoDate}
                                    css={{
                                        padding: '5px !important',
                                        width: '100%',
                                        borderRadius: '0',
                                        ...getCssPropValues(),
                                    }}
                                    disabled={isDateHidden}
                                >
                                    <Stack alignItems="center" gap="4px">
                                        <Typography
                                            variant="body2"
                                            css={{
                                                textDecoration: isDateHidden
                                                    ? 'strike-through'
                                                    : undefined,
                                            }}
                                        >
                                            {month}
                                        </Typography>

                                        <Typography
                                            variant="h4"
                                            css={{
                                                textDecoration: isDateHidden
                                                    ? 'strike-through'
                                                    : undefined,
                                            }}
                                        >
                                            {dayOfMonth}
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            css={{
                                                textDecoration: isDateHidden
                                                    ? 'strike-through'
                                                    : undefined,
                                            }}
                                        >
                                            {weekday}
                                        </Typography>
                                    </Stack>
                                </Button>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                {isCarouselScrollable && (
                    <IconButton
                        disabled={swiperRef.current?.isEnd}
                        onClick={handleSlideToNext}
                        css={{ padding: '2px' }}
                    >
                        <ArrowIcon
                            width="18px"
                            height="18px"
                            css={{ transform: 'rotate(-90deg)' }}
                        />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
};

export const DateSelector = memo(DateSelectorUnwrapped);
