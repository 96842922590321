import { Locale } from 'date-fns';
import { es, enGB as en } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { LanguagesCode } from 'src/i18n/constants';

export const useDateLocale = (): Locale => {
    const {
        i18n: { language },
    } = useTranslation();

    const locales = { en, es };

    return locales[language as LanguagesCode];
};
