export enum NotificationsEndpoints {
    GetNotifications = 'v1/users/{id}/notifications',
    PostNotificationToken = 'v1/users/{id}/token',
    PatchNotificationStatus = 'v1/notifications/{id}/status',
}

export enum NotificationType {
    Payment = 'PAYMENT',
    Event = 'EVENT',
    Maintenance = 'MAINTENANCE',
    BuildingUpdate = 'BUILDING_UPDATE',
    SocialBooking = 'SOCIAL_BOOKING',
    Update = 'UPDATE',
}

export enum NotificationStatus {
    Unseen = 'UNSEEN',
    Read = 'READ',
    Actioned = 'ACTIONED',
}

export enum NotificationMessageId {
    // MAINTENANCE
    NewComment = 'NEW_COMMENT', // INCIDENTS also
    StatusUpdate = 'STATUS_UPDATE',
    NewTicket = 'NEW_TICKET',

    // EVENTS
    LocationUpdate = 'LOCATION_UPDATE',
    WaitingListAttendance = 'WAITING-LIST_ATTENDANCE',
    NewAttendee = 'NEW_ATTENDEE',
    Cancellation = 'CANCELLATION', // SOCIAL_SPACES also

    // INCIDENTS
    Resolved = 'RESOLVED',
    Created = 'CREATED',

    // SOCIAL_SPACES
    NewBooking = 'NEW_BOOKING',
}
