import { FC, Fragment, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Button, IconButton, Popover, Stack, Typography, Box } from '@mui/material';

import { ReactComponent as CrossIcon } from 'src/assets/svg/cross.svg';

import { ReactComponent as QuestionIcon } from '../../assets/question.svg';
import { ReactComponent as BestPriceIcon } from '../../assets/best-price.svg';

export const RebookingDiscountTooltip: FC = () => {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();

    const [anchorEl, setAnchorElement] = useState<HTMLButtonElement>();
    const isOpen = Boolean(anchorEl);

    const handleClose = () => setAnchorElement(undefined);

    const handleOpen: MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
        setAnchorElement(currentTarget as HTMLButtonElement);
        window.addEventListener('scroll', handleClose, { once: true });
    };

    return (
        <Fragment>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        elevation: 1,
                        square: true,
                    },
                }}
                onClose={handleClose}
                css={{
                    '.MuiPaper-root': {
                        background: 'white',
                    },
                }}
            >
                <Stack
                    direction="row"
                    gap="16px"
                    alignItems="flex-start"
                    padding="30px"
                    maxWidth="327px"
                >
                    <Typography variant="body2" color="primary.main">
                        {t('swap-your-room_room-card_discount-notice_description')}
                    </Typography>

                    <IconButton
                        disableRipple
                        onClick={handleClose}
                        css={{ padding: 0, flex: '0 0 auto' }}
                    >
                        <CrossIcon width="24px" height="24px" />
                    </IconButton>
                </Stack>
            </Popover>

            <Button
                onClick={handleOpen}
                endIcon={<QuestionIcon />}
                css={{
                    padding: 0,
                    color: palette.primary.main,
                    width: 'fit-content',

                    ':hover > h6': {
                        background: palette.primary.main,
                        color: palette.background.default,
                    },

                    [breakpoints.down('lg')]: {
                        display: 'none',
                    },
                }}
            >
                <Typography variant="subtitle2" css={{ borderBottom: '1px solid' }}>
                    {t('swap-your-room_room-card_discount-notice_best-price')}
                </Typography>
            </Button>

            <Stack direction="row" display={{ lg: 'none' }} gridArea="tooltip">
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    padding="8px 12px"
                    css={{ background: palette.primary.main, color: palette.common.white }}
                >
                    <BestPriceIcon />

                    <Typography variant="body2" align="center">
                        {t('swap-your-room_room-card_discount-notice_best-price')}
                    </Typography>
                </Stack>

                <Box flex={1} padding="8px 12px" css={{ background: palette.error.light }}>
                    <Typography variant="body2" color="primary.main">
                        {t('swap-your-room_room-card_discount-notice_description')}
                    </Typography>
                </Box>
            </Stack>
        </Fragment>
    );
};
