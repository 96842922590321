import { StaffCategory, Subject } from './form-options';

export const SUBJECT_TRANSLATION_KEY: Record<Subject, string> = {
    [Subject.Events]: 'feedback_page_events',
    [Subject.Breakfast]: 'feedback_page_breakfast',
    [Subject.HouseKeeping]: 'feedback_page_housekeeping',
    [Subject.Staff]: 'feedback_page_staff',
    [Subject.HealthAndSafety]: 'feedback_page_health-safety',
    [Subject.Wifi]: 'feedback_page_wifi',
    [Subject.EverythingElse]: 'feedback_page_everything-else',
};

export const SUBJECT_TITLE_TRANSLATION_KEY: Record<Subject, string> = {
    [Subject.Events]: 'feedback_page_events_title',
    [Subject.Breakfast]: 'feedback_page_breakfast_title',
    [Subject.HouseKeeping]: 'feedback_page_housekeeping_title',
    [Subject.Staff]: 'feedback_page_staff_title',
    [Subject.HealthAndSafety]: 'feedback_page_health-safety_title',
    [Subject.Wifi]: 'feedback_page_wifi_title',
    [Subject.EverythingElse]: 'feedback_page_everything-else_title',
};

export const SUBJECT_RATING_TITLE_TRANSLATION_KEY: Record<Subject, string> = {
    [Subject.Events]: 'feedback_page_events_rating_title',
    [Subject.Breakfast]: 'feedback_page_breakfast_rating_title',
    [Subject.HouseKeeping]: 'feedback_page_housekeeping_rating_title',
    [Subject.Staff]: 'feedback_page_staff_rating_title',
    [Subject.HealthAndSafety]: 'feedback_page_health-safety_rating_title',
    [Subject.Wifi]: 'feedback_page_wifi_rating_title',
    [Subject.EverythingElse]: 'feedback_page_everything-else_rating_title',
};

export const STAFF_CATEGORY_TRANSLATION_KEY: Record<StaffCategory, string> = {
    [StaffCategory.DayTeam]: 'feedback_page_select-staff_day-team',
    [StaffCategory.NightTeam]: 'feedback_page_select-staff_night-team',
    [StaffCategory.Housekeepers]: 'feedback_page_select-staff_housekeepers',
    [StaffCategory.Management]: 'feedback_page_select-staff_management',
    [StaffCategory.Maintenance]: 'feedback_page_select-staff_maintenance',
};
