import { FC, Fragment, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ConciergeResponseCode } from 'src/api/concierge';
import { PrivateHat } from 'src/layout/private-hat';
import { StateView } from 'src/components/state-view';

export const SocialSpaceError: FC<{
    onResetBooking: VoidFunction;
    errorCode?: ConciergeResponseCode;
}> = ({ onResetBooking, errorCode }) => {
    const { t } = useTranslation();

    const errorContent = new Map<
        ConciergeResponseCode,
        { title: ReactNode; text: string; cta: string }
    >([
        [
            ConciergeResponseCode.SocialSpaceUnavailable,
            {
                title: <Trans i18nKey="social_space_booking_error_slot_unavailable_title" />,
                text: t('social_space_booking_error_slot_unavailable_text'),
                cta: t('social_space_booking_error_slot_unavailable_cta'),
            },
        ],
        [
            ConciergeResponseCode.SocialSpaceInsufficientDailyLimit,
            {
                title: <Trans i18nKey="social_space_booking_error_daily_limit_reached_title" />,
                text: t('social_space_booking_error_daily_limit_reached_text'),
                cta: t('social_space_booking_error_daily_limit_reached_cta'),
            },
        ],
        [
            ConciergeResponseCode.SocialSpaceInsufficientTwoWeekLimit,
            {
                title: <Trans i18nKey="social_space_booking_error_two_week_limit_reached_title" />,
                text: t('social_space_booking_error_two_week_limit_reached_text'),
                cta: t('social_space_booking_error_two_week_limit_reached_cta'),
            },
        ],
    ]);

    const currentErrorContent = errorCode && errorContent.get(errorCode);

    return (
        <Fragment>
            <PrivateHat
                title={t('social_space_page_title')}
                backIconText={t('back_cta')}
                onBack={onResetBooking}
            />
            <StateView
                state="fail"
                title={
                    currentErrorContent?.title ?? (
                        <Trans i18nKey="social_space_booking_error_title" />
                    )
                }
                actions={{
                    main: {
                        text: currentErrorContent?.cta ?? t('social_space_booking_error_back_cta'),
                        callback: onResetBooking,
                    },
                }}
            >
                {currentErrorContent?.text}
            </StateView>
        </Fragment>
    );
};
