import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { getTicketFormDataTree, MaintenanceEndpoints, MaintenanceTicketFormDataTree } from '../api';

type Config = UseQueryOptions<MaintenanceTicketFormDataTree, AxiosError<ConciergeErrorResponse>>;

export const useGetTicketFormDataTree = (config: Config = {}) => {
    const query = useQuery({
        queryKey: [MaintenanceEndpoints.getTicketFormDataTree],
        queryFn: getTicketFormDataTree,
        ...config,
    });

    return query;
};
