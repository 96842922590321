import { FC } from 'react';
import { Stack, Typography, Avatar } from '@mui/material';

import { AppPreloader } from 'src/components/app-preloader';
import { UserData, UserDataFields } from 'src/features/user/api';

import { ProfileAvatarBadge } from './profile-avatar-badge';

export const ProfileAvatarVertical: FC<{
    user: UserData;
    errorElement: JSX.Element | undefined;
    inputElement: JSX.Element;
    isLoading: boolean;
}> = ({ user, inputElement, errorElement, isLoading }) => {
    const fullName = `${user[UserDataFields.FirstName]} ${user[UserDataFields.LastName]}`;

    return (
        <Stack gap="16px" alignItems="center" css={{ textDecoration: 'none' }}>
            {inputElement}

            {isLoading ? (
                <AppPreloader
                    background="paper"
                    css={{ width: '72px', height: '72px', borderRadius: '50%' }}
                />
            ) : (
                <ProfileAvatarBadge htmlFor={inputElement.props.id}>
                    <Avatar
                        src={user[UserDataFields.AvatarUrl]!}
                        css={{
                            width: '72px',
                            height: '72px',

                            'svg[aria-label="avatar placeholder"]': {
                                width: '40px',
                                height: '40px',
                            },
                        }}
                    />
                </ProfileAvatarBadge>
            )}

            <Typography color="text.primary" variant="subtitle1" textTransform="uppercase">
                {fullName}
            </Typography>

            {errorElement}
        </Stack>
    );
};
