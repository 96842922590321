import { FC, Fragment, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { max, parseISO, isWithinInterval } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MoveInDay, usePostMoveInBooking } from 'src/features/move-in';
import { useGetUpcomingBooking } from 'src/features/bookings';
import { appConfig } from 'src/app/app-config';
import { Button } from 'src/components/button';
import { PrivateHat } from 'src/layout/private-hat';
import { StateView } from 'src/components/state-view';
import { routes } from 'src/constants/routes';
import { generateWeeks } from 'src/constants/weeks';

import { Page404 } from './page404';

export const MoveInDayPage: FC = () => {
    const { palette, breakpoints } = useTheme();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { data: upcomingBooking } = useGetUpcomingBooking();

    const { mutate: mutateBooking, isError, reset, isLoading, isSuccess } = usePostMoveInBooking();

    const [selectedDate, setSelectedDate] = useState<string | null>(upcomingBooking!.date_movein);
    const [week, setWeek] = useState<string>('');

    const currentDateObject = new Date();

    const dateFrom = max([parseISO(upcomingBooking!.date_from), currentDateObject]);
    const dateTo = max([parseISO(upcomingBooking!.date_to), currentDateObject]);
    const weeks = generateWeeks(dateFrom, dateTo);

    const isSaveButtonDisabled = selectedDate === upcomingBooking?.date_movein;

    const handleBackToHome = () => navigate(routes.home);

    const setSelectedDateAndDeselectTime = (date: string) => {
        setSelectedDate(date);
        // Find the week that the selected date falls into
        const matchingWeek = weeks.find((weekRange) =>
            isWithinInterval(parseISO(date), {
                start: new Date(weekRange.split(' - ')[0]),
                end: new Date(weekRange.split(' - ')[1]),
            })
        );
        if (matchingWeek) {
            setWeek(matchingWeek);
        }
    };

    const onSubmit = () => {
        if (upcomingBooking?.id && selectedDate && week) {
            mutateBooking({
                booking_id: upcomingBooking?.id,
                date: selectedDate,
                week,
            });
        }
    };

    if (!upcomingBooking || !upcomingBooking.is_main_occupant) {
        return <Page404 />;
    }

    if (isSuccess) {
        handleBackToHome();
    }

    if (isError) {
        return (
            <Fragment>
                <PrivateHat
                    title={t('move_in_time_slot_title')}
                    backIconText={t('back_cta')}
                    onBack={reset}
                />
                <StateView
                    state="fail"
                    actions={{
                        main: {
                            text: t('move_in_time_slot_submission_general_error_try_again_cta'),
                            callback: onSubmit,
                        },
                        secondary: {
                            text: t('move_in_time_slot_submission_general_error_back_to_home_cta'),
                            callback: handleBackToHome,
                        },
                    }}
                    title={<Trans i18nKey="move_in_time_slot_submission_general_error_message" />}
                />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <PrivateHat title={t('move_in_time_slot_title')} backIconText={t('back_cta')} />
            <Stack direction="row" justifyContent="center">
                <Stack
                    direction="column"
                    alignItems="stretch"
                    justifyContent={{ md: 'flex-start' }}
                    gap="32px"
                    width="100%"
                    padding={{ xs: '40px 16px', md: '48px 60px' }}
                    css={{
                        maxWidth: '760px',
                    }}
                >
                    <Typography
                        paddingBottom={{ xs: '28px', md: '48px' }}
                        variant="body1"
                        color={palette.text.secondary}
                    >
                        <Trans i18nKey="book_your_move_in_slot_step_1_subtitle" />
                    </Typography>

                    <MoveInDay
                        date={selectedDate}
                        setDate={setSelectedDateAndDeselectTime}
                        booking={upcomingBooking}
                    />

                    <Typography
                        paddingBottom="28px"
                        variant="body1"
                        css={{
                            maxWidth: '640px',
                            width: '100%',
                            color: palette.common.black,
                            a: {
                                color: palette.common.black,
                            },
                        }}
                    >
                        <Trans
                            i18nKey="book_your_move_in_slot_step_1_help_message"
                            components={{
                                a: (
                                    // @ts-ignore
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                                    <a
                                        href={appConfig.contacts.callbackForm}
                                        rel="noreferrer"
                                        target="_blank"
                                    />
                                ),
                            }}
                        />
                    </Typography>

                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        alignItems="center"
                        justifyContent="start"
                        gap="10px"
                    >
                        <Button
                            onClick={() => onSubmit()}
                            design="primary"
                            disabled={isSaveButtonDisabled}
                            loading={isLoading}
                            css={{
                                minWidth: '100%',
                                [breakpoints.up('sm')]: {
                                    minWidth: '260px',
                                },
                            }}
                        >
                            <Typography variant="h6">{t('book_your_move_in_slot_cta')}</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Fragment>
    );
};
