import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Paper, Typography } from '@mui/material';

import { ReactComponent as Success } from 'src/assets/svg/success-in-circle.svg';

export const UpToDate: FC = () => {
    const { t } = useTranslation();
    const { palette, breakpoints } = useTheme();

    return (
        <Paper
            data-testid="MakePayment.UpToDate"
            square
            elevation={1}
            css={{
                padding: '40px',
                background: palette.background.default,
                [breakpoints.up('md')]: { padding: '60px' },
                [breakpoints.up('lg')]: { padding: '80px 60px 60px 60px' },
            }}
        >
            <Success width="48px" height="48px" color={palette.success.main} />

            <div
                css={{
                    margin: '26px 0 28px 0',
                    height: '1px',
                    width: '68px',
                    background: palette.common.black,
                }}
            />

            <Typography variant="h2">{t('gocardless_instalment_paid')}</Typography>
        </Paper>
    );
};
