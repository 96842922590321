import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { useGetActiveBooking } from 'src/features/bookings';
import { queryClient } from 'src/lib/query-client';

import { MaintenanceEndpoints, MaintenanceTicket, postTicket, postTicketAttachment } from '../api';
import { mapCreateTicketForm } from '../utils/map-create-ticket-form';
import { CreateTicketFormFields } from '../constants/create-ticket-form';

import type { CreateTicketFormData } from '../types/create-ticket-form';

type Config = UseMutationOptions<
    MaintenanceTicket,
    AxiosError<ConciergeErrorResponse>,
    CreateTicketFormData
>;

export const usePostTicket = (config: Config = {}) => {
    const { data: activeBooking } = useGetActiveBooking();

    return useMutation<MaintenanceTicket, AxiosError<ConciergeErrorResponse>, CreateTicketFormData>(
        {
            async mutationFn(formData: CreateTicketFormData) {
                const attachments = await Promise.all(
                    formData[CreateTicketFormFields.DescriptionAttachments].map((file) =>
                        postTicketAttachment(file)
                    )
                );

                const mappedData = mapCreateTicketForm(
                    formData,
                    activeBooking!.id,
                    activeBooking!.room_id,
                    attachments
                );

                return postTicket(mappedData);
            },

            onSuccess: () => queryClient.invalidateQueries([MaintenanceEndpoints.getTickets]),

            ...config,
        }
    );
};
