import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * This hook handles anchor links.
 * react-router-dom can't solve hash links and scroll to the specific element
 */
export const useHashAnchor = (element?: HTMLElement) => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const sourceElement = document.getElementById(hash.substring(1));

            if (sourceElement) {
                sourceElement.scrollIntoView({ behavior: 'smooth' });
                return;
            }
        }

        (element ?? window).scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
};
