import { ConciergeResponseCode } from 'src/api/concierge';

export const MAINTENANCE_ATTACHMENT_ERROR_KEYS = new Map<ConciergeResponseCode, string>([
    [
        ConciergeResponseCode.MaximumAttachmentsReached,
        'maintenance_report_issue-details_attached-files_error_files-amount',
    ],
    [
        ConciergeResponseCode.InvalidUploadFileType,
        'maintenance_report_issue-details_attached-files_error_file-type',
    ],
    [
        ConciergeResponseCode.InvalidUploadFileSize,
        'maintenance_report_issue-details_attached-files_error_file-size',
    ],
]);
