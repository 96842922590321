import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import { routes } from 'src/constants/routes';
import { ButtonWithIcon } from 'src/components/button-with-icon';
import { ReactComponent as VideoIcon } from 'src/assets/svg/video.svg';

export const BuildingVideosFooter: FC = () => {
    const { t } = useTranslation();

    const { palette } = useTheme();

    return (
        <footer
            css={{
                background: palette.background.paper,
                color: palette.text.primary,
            }}
        >
            <div
                css={{
                    margin: '0 auto',
                    textAlign: 'center',
                    padding: '36px 16px',
                    boxSizing: 'content-box',
                    maxWidth: '340px',
                }}
            >
                <Typography variant="h4" marginBottom="32px">
                    {t('my-building_video-guides-banner_title')}
                </Typography>

                <Typography variant="body1" marginBottom="24px">
                    {t('my-building_video-guides-banner_description')}
                </Typography>

                <ButtonWithIcon
                    component={Link}
                    to={routes.guides.index}
                    design="light"
                    rightIcon={<VideoIcon color={palette.common.white} />}
                    bordered
                >
                    <Typography variant="h6">{t('my-building_video-guides-banner_cta')}</Typography>
                </ButtonWithIcon>
            </div>
        </footer>
    );
};
