import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'src/constants/routes';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { IconLabel } from 'src/components/icon-label';

import { SocialSpace } from '../../api';
import { useFilterQueryParameters } from '../../hooks/use-filter-query-parameters';
import { ReactComponent as LocationIcon } from '../../assets/location.svg';
import { ReactComponent as CapacityIcon } from '../../assets/capacity.svg';

export const SocialSpaceCard: FC<{ space: SocialSpace }> = ({ space }) => {
    const { palette, shadows } = useTheme();

    const { t } = useTranslation();

    const queryParameters = useFilterQueryParameters();

    const filterParameters = new URLSearchParams();

    if (queryParameters.date) {
        filterParameters.set('date', queryParameters.date);
    }

    if (queryParameters.time) {
        filterParameters.set('time', queryParameters.time);
    }

    if (queryParameters.duration) {
        filterParameters.set('duration', queryParameters.duration.toString());
    }

    const filterString = `?${filterParameters.toString()}`;

    return (
        <Stack
            component={Link}
            to={{ pathname: routes.socialSpaces.id.replace(':id', space.id), search: filterString }}
            padding={{ xs: '16px', lg: '25px 28px' }}
            justifyContent="space-between"
            direction="row"
            css={{
                position: 'relative',
                textDecoration: 'none',
                color: palette.common.black,
                boxShadow: shadows[1],
            }}
        >
            {space.out_of_order && (
                <Typography
                    variant="overlineMedium"
                    color={palette.common.white}
                    padding="9.25px 16px"
                    position="absolute"
                    top={0}
                    right={0}
                    css={{
                        backgroundColor: palette.primary.main,
                    }}
                >
                    {t('social_space_out_of_order')}
                </Typography>
            )}

            <Stack gap="18px">
                <img
                    src={space.image_url}
                    alt="icon"
                    width="24px"
                    height="24px"
                    css={{ objectFit: 'contain' }}
                />

                <Typography variant="h3" css={{ textTransform: 'uppercase' }}>
                    {space.title}
                </Typography>

                <Stack
                    direction={{ md: 'row' }}
                    gap={{ xs: '10px', md: '25px' }}
                    marginTop={{ xs: '6px', md: '24px' }}
                >
                    <IconLabel icon={<LocationIcon />}>{space.location}</IconLabel>

                    <IconLabel icon={<CapacityIcon />}>
                        <Trans
                            i18nKey="social_space_occupancy"
                            values={{ count: space.occupancy }}
                        />
                    </IconLabel>
                </Stack>
            </Stack>

            <ArrowIcon
                width="24px"
                height="24px"
                css={{ transform: 'rotate(-90deg)', alignSelf: 'flex-end' }}
                color={palette.common.black}
            />
        </Stack>
    );
};
