import { appConfig } from 'src/app/app-config';

import type { FirebaseOptions } from 'firebase/app';
import type { GetTokenOptions } from 'firebase/messaging';

export const firebaseOptions: FirebaseOptions = appConfig.firebase.core;

export const getTokenOptions: GetTokenOptions = {
    vapidKey: appConfig.firebase.messaging.webPushCertificateKey,
};
