import { ForwardRefRenderFunction, MouseEventHandler, forwardRef } from 'react';
import { useTheme } from '@emotion/react';
import { IconButton, InputAdornment, TextField, OutlinedTextFieldProps } from '@mui/material';

import { ReactComponent as CrossIcon } from 'src/assets/svg/cross.svg';
import { ReactComponent as SearchIcon } from 'src/assets/svg/search.svg';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
    onSearch?: MouseEventHandler;
    onClear?: MouseEventHandler;
}

const SearchInputUnwrapped: ForwardRefRenderFunction<HTMLInputElement, Props> = (
    { value, onSearch, onClear, ...muiProps },
    ref
) => {
    const { shadows, palette } = useTheme();

    return (
        <TextField
            {...muiProps}
            ref={ref}
            value={value}
            fullWidth
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={onSearch}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: value ? (
                    <InputAdornment position="end">
                        <IconButton onClick={onClear} css={{ padding: 0 }}>
                            <CrossIcon />
                        </IconButton>
                    </InputAdornment>
                ) : undefined,
            }}
            css={{
                '.MuiInputBase-root': {
                    boxShadow: shadows[1],
                    borderRadius: '100px',
                    background: palette.background.default,
                },
            }}
        />
    );
};

export const SearchInput = forwardRef(SearchInputUnwrapped);
