import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { queryClient } from 'src/lib/query-client';

import { MaintenanceEndpoints, PostMaintenanceCommentBody, postTicketComment } from '../api';

type Config = UseMutationOptions<unknown, ConciergeErrorResponse, PostMaintenanceCommentBody>;

export const usePostTicketComment = (id: string, config: Config = {}) => {
    const { onSuccess, ...restConfig } = config;

    const mutation = useMutation<unknown, ConciergeErrorResponse, PostMaintenanceCommentBody>({
        mutationFn: (comment) => postTicketComment(id, comment),

        onSuccess(data, variables, context) {
            onSuccess?.(data, variables, context);
            return queryClient.invalidateQueries([MaintenanceEndpoints.getTicket, id]);
        },

        ...restConfig,
    });

    return mutation;
};
