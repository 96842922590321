import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { PaymentsEndpoints, getMakePayment, MakePayment } from '../api';

interface Payload {
    bookingId: string;
}

type Config = UseQueryOptions<MakePayment, AxiosError<ConciergeErrorResponse>>;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const useGetMakePayment = (config: Config = {}, payload: Payload) => {
    const query = useQuery<MakePayment, AxiosError<ConciergeErrorResponse>>({
        queryKey: [PaymentsEndpoints.getMakePayment, payload.bookingId],
        queryFn: () => getMakePayment(payload.bookingId),
        ...config,
    });

    return query;
};
