import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { BuildingEndpoints, getActiveBuilding, Buildings, Building } from '../api';

type QueryConfig = UseQueryOptions<Buildings, AxiosError<ConciergeErrorResponse>, Building>;

const selectActiveBuilding = (buildings: Buildings) => {
    const [activeBuilding] = buildings;

    if (!activeBuilding) {
        const message = 'User has no active building';
        const exception = new Error(message);

        captureException(exception);

        throw exception;
    }

    return activeBuilding;
};

export const useGetActiveBuilding = (config: QueryConfig = {}) =>
    useQuery<Buildings, AxiosError<ConciergeErrorResponse>, Building>({
        queryKey: [BuildingEndpoints.getBuildings, { active: true }],
        queryFn: getActiveBuilding,
        select: selectActiveBuilding,
        ...config,
    });
