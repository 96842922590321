import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import { AppPreloader } from 'src/components/app-preloader';
import { ErrorState } from 'src/components/error-state';

import { useGetSocialSpaceSlots } from '../../hooks/use-get-social-space-slots';

import { SlotOption } from './parts/slot-option';

export const SlotSelector: FC<{
    socialSpaceId: string;
    selectedTime?: string;
    onTimeChange: (newTime: string) => void;
    selectedDate: string;
    selectedDuration: number;
}> = ({ socialSpaceId, selectedTime, onTimeChange, selectedDate, selectedDuration }) => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    const {
        data: slots,
        isError,
        isLoading,
    } = useGetSocialSpaceSlots(socialSpaceId, { date: selectedDate, duration: selectedDuration });

    if (isLoading) {
        return <AppPreloader size="small" css={{ margin: '80px auto', height: 'auto' }} />;
    }

    if (isError) {
        return (
            <ErrorState
                direction="column"
                title={<Trans i18nKey="social_bookings_error_loading_slots" />}
                css={{ margin: '80px auto' }}
            />
        );
    }

    return (
        <Stack gap="20px">
            <Typography variant="body2" color={palette.text.secondary}>
                {t('specific_social_space_select_time')}
            </Typography>
            <Stack
                display="grid"
                gap="8px"
                gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))"
            >
                {slots.map((slot) => {
                    const isSelected = Boolean(selectedTime && slot.start_time === selectedTime);

                    return slot.is_available ? (
                        <SlotOption
                            key={slot.start_time}
                            startTime={slot.start_time}
                            isAvailable
                            isSelected={isSelected}
                            onTimeChange={onTimeChange}
                        >
                            {`${slot.start_time} - ${slot.end_time}`}
                        </SlotOption>
                    ) : (
                        <SlotOption
                            key={slot.start_time}
                            startTime={slot.start_time}
                            isAvailable={false}
                        >
                            {`${slot.start_time} - ${slot.end_time}`}
                        </SlotOption>
                    );
                })}
            </Stack>
        </Stack>
    );
};
