import { PostTypes } from '../api';
import * as PostsIcons from '../components/icons';
import { NullFilter, FilterKeys } from '../types/filter';

export const NULL_FILTER: NullFilter = 'All';

export const FILTER_OPTIONS: FilterKeys[] = [NULL_FILTER, ...Object.values(PostTypes)];

export const OPTIONS_MAP = {
    [NULL_FILTER]: { icon: PostsIcons.PostFilterAll },
    [PostTypes.Event]: { icon: PostsIcons.PostFilterAll },
    [PostTypes.Trend]: { icon: PostsIcons.PostFilterTrend },
    [PostTypes.Poll]: { icon: PostsIcons.PostFilterPolls },
    [PostTypes.Marketing]: { icon: PostsIcons.PostFilterMarketing },
};
