import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import type { ConciergeErrorResponse } from 'src/api/concierge';

import { BuildingIncidents } from '../api/types';
import { getBuildingIncidents } from '../api/requests';
import { BuildingUpdatesEndpoints } from '../api/constants';

type Config = UseQueryOptions<BuildingIncidents, ConciergeErrorResponse>;

export const useGetBuildingIncidents = (id: string, config: Config = {}) =>
    useQuery<BuildingIncidents, ConciergeErrorResponse>({
        queryKey: [BuildingUpdatesEndpoints.getIncidents, id],
        queryFn: () => getBuildingIncidents(id),
        ...config,
    });
