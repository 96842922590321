import { Alert } from '@mui/material';
import { Trans } from 'react-i18next';
import { FC } from 'react';
import { useTheme } from '@emotion/react';

import { appConfig } from 'src/app/app-config';
import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';

const getLimitMessageKey = (
    dailyLimitRemaining: number | undefined,
    twoWeekLimitRemaining: number
) => {
    // date is not selected yet
    const dateSet = typeof dailyLimitRemaining !== 'undefined';

    if (twoWeekLimitRemaining === 0) {
        // no limit left for 2 weeks
        return dateSet && dailyLimitRemaining === 0
            ? 'social_space_limit_reached_message'
            : 'social_space_two_week_limit_reached_message';
    }

    if (twoWeekLimitRemaining !== appConfig.socialSpaces.twoWeekLimit) {
        // only display daily limit remaining if it is less than the two week limit remaining
        return dateSet && dailyLimitRemaining <= twoWeekLimitRemaining
            ? 'social_space_daily_limit_upcoming_message'
            : 'social_space_two_week_limit_upcoming_message';
    }

    if (dateSet && dailyLimitRemaining === 0) {
        // no limit left on selected date
        return 'social_space_daily_limit_reached_message';
    }

    if (dateSet && dailyLimitRemaining !== appConfig.socialSpaces.dailyLimit) {
        // only display daily limit remaining if it is less than the two week limit remaining
        return dailyLimitRemaining <= twoWeekLimitRemaining
            ? 'social_space_daily_limit_upcoming_message'
            : 'social_space_two_week_limit_upcoming_message';
    }

    return null;
};

export const LimitAlert: FC<{
    dailyLimitRemaining?: number;
    twoWeekLimitRemaining: number;
    twoWeekLimitResetDate: string | null;
}> = ({ dailyLimitRemaining, twoWeekLimitRemaining, twoWeekLimitResetDate }) => {
    const { palette, breakpoints } = useTheme();

    const limitMessageKey = getLimitMessageKey(dailyLimitRemaining, twoWeekLimitRemaining);

    if (!limitMessageKey) {
        return null;
    }

    if (
        (typeof dailyLimitRemaining === 'undefined' ||
            dailyLimitRemaining >= appConfig.socialSpaces.durationLimit) &&
        twoWeekLimitRemaining >= appConfig.socialSpaces.durationLimit
    ) {
        return null;
    }

    const severity = dailyLimitRemaining === 0 || twoWeekLimitRemaining === 0 ? 'error' : 'warning';

    return (
        <Alert
            severity={severity}
            css={{
                [breakpoints.down('md')]: {
                    margin: '0 -16px',
                },
                padding: '16px',
                borderRadius: '0',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
                columnSpan: 'all',
            }}
            icon={
                <WarningIcon
                    width="24px"
                    height="24px"
                    color={severity === 'warning' ? palette.warning.dark : palette.error.dark}
                />
            }
        >
            <Trans
                i18nKey={limitMessageKey}
                components={{ b: <b css={{ fontWeight: 400 }} /> }}
                values={{
                    daily_limit_remaining: dailyLimitRemaining,
                    two_week_limit_remaining: twoWeekLimitRemaining,
                    two_week_limit_reset_date: twoWeekLimitResetDate,
                }}
            />
        </Alert>
    );
};
