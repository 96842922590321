import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { ReactComponent as SuccessIcon } from 'src/assets/svg/success-in-circle.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';

export const CheckInInnerComplete = () => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    return (
        <Stack
            padding="20px"
            gap="16px"
            width="100%"
            direction="row"
            css={{
                borderBottom: `1px solid ${palette.divider}`,
                color: palette.common.black,
                position: 'relative',
                pointerEvents: 'none',
            }}
        >
            <SuccessIcon
                width="32px"
                height="32px"
                color={palette.success.main}
                css={{ flexShrink: 0 }}
            />

            <Stack direction="column" gap="6px">
                <Typography variant="h4">{t('check_in_form_title')}</Typography>
                <Typography variant="body2" color={palette.text.secondary}>
                    {t('check_in_form_subtitle_complete')}
                </Typography>
            </Stack>

            <ArrowIcon
                css={{
                    color: palette.text.disabled,
                    width: '24px',
                    height: '24px',
                    flexShrink: 0,
                    alignSelf: 'center',
                    marginLeft: 'auto',
                    transform: 'rotate(-90deg)',
                }}
            />
        </Stack>
    );
};
