import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { PrivateHat } from 'src/layout/private-hat';
import { StateView } from 'src/components/state-view';
import { routes } from 'src/constants/routes';

export const FeedbackFormFail: FC<{ title: string; onRetry: VoidFunction }> = ({
    title,
    onRetry,
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleBackToFeedback = () => navigate(routes.feedback.index);

    return (
        <Fragment>
            <PrivateHat title={title} backIconText={t('back_cta')} />
            <StateView
                state="fail"
                title={<Trans i18nKey="feedback_page_confirmation_error" />}
                actions={{
                    main: {
                        text: t('feedback_page_confirmation_cta_try-again'),
                        callback: onRetry,
                    },
                    secondary: {
                        text: t('feedback_page_confirmation_cta_back'),
                        callback: handleBackToFeedback,
                    },
                }}
            />
        </Fragment>
    );
};
