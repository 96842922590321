import {
    ControllerRenderProps,
    FieldValues,
    Path,
    PathValue,
    UseFormClearErrors,
    UseFormSetError,
} from 'react-hook-form';

import { attachmentsSchema } from 'src/components/attachment-gallery/validation/attachment-schema';

const setAttachments = <TFormData extends FieldValues, TField extends Path<TFormData>>(
    field: ControllerRenderProps<TFormData, TField>,
    files: File[],
    clearErrors: UseFormClearErrors<TFormData>,
    setError: UseFormSetError<TFormData>
) => {
    try {
        field.onChange(files as PathValue<TFormData, TField>);
        attachmentsSchema.validateSync(files);
        clearErrors(field.name);
    } catch (err) {
        setError(field.name, {
            type: 'validation',
            message: err.message,
        });
    }
};

export const deleteAttachment =
    <TFormData extends FieldValues, TField extends Path<TFormData>>(
        field: ControllerRenderProps<TFormData, TField>,
        clearErrors: UseFormClearErrors<TFormData>,
        setError: UseFormSetError<TFormData>
    ) =>
    (file: File) => {
        const filtered = field.value?.filter((item: File) => item !== file);

        setAttachments(field, filtered, clearErrors, setError);
    };

export const addAttachments =
    <TFormData extends FieldValues, TField extends Path<TFormData>>(
        field: ControllerRenderProps<TFormData, TField>,
        clearErrors: UseFormClearErrors<TFormData>,
        setError: UseFormSetError<TFormData>
    ) =>
    (files: File[]) => {
        setAttachments(field, files, clearErrors, setError);
    };
