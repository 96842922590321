import { FC, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Button as MuiButton } from '@mui/material';

import { EditProfile, ProfileAvatar } from 'src/features/user';
import { PrivateHat } from 'src/layout/private-hat';

export const EditProfilePage: FC = () => {
    const formRef = useRef<{ onSubmit: VoidFunction } | null>(null);

    const { t } = useTranslation();
    const { breakpoints } = useTheme();

    const handleClick = () => formRef.current?.onSubmit();

    return (
        <Fragment>
            <PrivateHat
                title={t('edit_profile_page_title').toLocaleUpperCase()}
                actionLabel={
                    <MuiButton
                        onClick={handleClick}
                        css={{
                            [breakpoints.up('md')]: {
                                display: 'none',
                            },
                        }}
                    >
                        {t('profile_edit_details_save')}
                    </MuiButton>
                }
                backIconText={t('profile_navigate_back')}
            />

            <ProfileAvatar variant="expanded" />

            <EditProfile ref={formRef} />
        </Fragment>
    );
};
