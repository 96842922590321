import { FC } from 'react';
import { Link as MuiLink, LinkProps, useTheme } from '@mui/material';

type Variant = 'primary' | 'light' | 'dark';
type Props = Omit<LinkProps, 'variant'> & { variant: Variant };

export const Link: FC<Props> = ({ variant = 'light', ...restProps }) => {
    const { palette, transitions } = useTheme();

    const transition = transitions.create('all');

    const colorsSchemes = {
        light: {
            color: palette.text.primary,
            borderBottomColor: palette.text.primary,

            '::before': {
                background: palette.common.black,
            },

            ':hover': {
                color: palette.common.white,
                borderBottomColor: palette.common.white,
            },
        },

        primary: {
            color: palette.primary.main,
            borderBottomColor: palette.primary.main,

            '::before': {
                background: palette.primary.main,
            },

            ':hover': {
                color: palette.common.white,
            },
        },

        dark: {
            color: palette.common.white,
            borderBottomColor: palette.common.white,

            '::before': {
                background: palette.common.white,
            },

            ':hover': {
                color: palette.text.primary,
            },
        },
    };

    return (
        <MuiLink
            {...restProps}
            underline="none"
            css={[
                {
                    transition,
                    position: 'relative',
                    display: 'inline-block',
                    zIndex: 1,
                    borderBottom: '1px solid',

                    '::before': {
                        content: '""',
                        position: 'absolute',
                        zIndex: -1,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: 0,

                        transition,
                    },

                    ':hover': {
                        '::before': {
                            width: '100%',
                        },
                    },
                },
                colorsSchemes[variant],
            ]}
        />
    );
};
