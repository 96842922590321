import { conciergeClient } from 'src/api/concierge';

import { MaintenanceEndpoints } from './constants';

import type {
    GetTicketFormDataTreeResponse,
    GetTicketResponse,
    GetTicketsListResponse,
    PatchTicketAttachmentsBody,
    PatchTicketAttachmentsResponse,
    PostCloseTicketResponse,
    PostMaintenanceCommentBody,
    PostMaintenanceCommentResponse,
    PostTicketAttachmentBody,
    PostTicketAttachmentResponse,
    PostTicketBody,
    PostTicketResponse,
} from './types';

export const postTicket = async (body: PostTicketBody) => {
    const { data: response } = await conciergeClient.post<PostTicketResponse>(
        MaintenanceEndpoints.postTicket,
        body
    );

    return response.data;
};

export const getTicketsList = async (booking_id: string) => {
    const { data: response } = await conciergeClient.get<GetTicketsListResponse>(
        MaintenanceEndpoints.getTickets,
        { params: { booking_id } }
    );

    return response.data;
};

export const getTicket = async (id: string) => {
    const { data: response } = await conciergeClient.get<GetTicketResponse>(
        MaintenanceEndpoints.getTicket.replace('{id}', id)
    );

    return response.data;
};

export const getTicketFormDataTree = async () => {
    const { data: response } = await conciergeClient.get<GetTicketFormDataTreeResponse>(
        MaintenanceEndpoints.getTicketFormDataTree
    );

    return response.data;
};

export const postTicketComment = async (id: string, comment: PostMaintenanceCommentBody) => {
    const { data: response } = await conciergeClient.post<PostMaintenanceCommentResponse>(
        MaintenanceEndpoints.postTicketComment.replace('{id}', id),
        comment
    );

    return response.data;
};

export const postCloseTicket = async (id: string) => {
    const { data: response } = await conciergeClient.post<PostCloseTicketResponse>(
        MaintenanceEndpoints.postCloseTicket.replace('{id}', id)
    );

    return response.data;
};

export const patchTicketAttachments = async (
    id: string,
    attachments: PatchTicketAttachmentsBody
) => {
    const { data: response } = await conciergeClient.patch<PatchTicketAttachmentsResponse>(
        MaintenanceEndpoints.patchAttachmentsUrls.replace('{id}', id),
        attachments
    );

    return response.data;
};

export const postTicketAttachment = async (attachment: PostTicketAttachmentBody) => {
    const body = new FormData();
    body.append('attachment', attachment);

    const { data: response } = await conciergeClient.post<PostTicketAttachmentResponse>(
        MaintenanceEndpoints.postAttachmentFile,
        body
    );

    return response.data;
};
