import { NotificationType } from '../../../api/constants';
import { ReactComponent as CategoryPaymentsIcon } from '../../../assets/svg/category-payments.svg';
import { ReactComponent as CategoryProfileIcon } from '../../../assets/svg/category-profile.svg';
import { ReactComponent as CategoryBuildingIcon } from '../../../assets/svg/category-building.svg';
import { ReactComponent as CategoryBookingsIcon } from '../../../assets/svg/category-bookings.svg';
import { ReactComponent as CategoryMaintenanceIcon } from '../../../assets/svg/category-maintenance.svg';
import { ReactComponent as CategoryEventsIcon } from '../../../assets/svg/category-events.svg';

export const CATEGORY_ICONS = new Map<NotificationType, JSX.Element>([
    [NotificationType.Payment, <CategoryPaymentsIcon key={NotificationType.Payment} />],
    [NotificationType.Update, <CategoryProfileIcon key={NotificationType.Update} />],
    [
        NotificationType.BuildingUpdate,
        <CategoryBuildingIcon key={NotificationType.BuildingUpdate} />,
    ],
    [NotificationType.SocialBooking, <CategoryBookingsIcon key={NotificationType.SocialBooking} />],
    [NotificationType.Maintenance, <CategoryMaintenanceIcon key={NotificationType.Maintenance} />],
    [NotificationType.Event, <CategoryEventsIcon key={NotificationType.Event} />],
]);
