export const ASSETS_BASE_URL = 'https://assets.vitastudent.com/concierge';

export const BRANDFOLDER_BASE_URL = 'https://cdn.brandfolder.io';

export enum ConciergeResponseCode {
    Ok = 'OK',
    BadRequest = 'ERROR_BAD_REQUEST',
    TokenProblem = 'ERROR_AUTH_TOKEN_PROBLEM',
    MoveInSlotFullyBooked = 'ERROR_MOVE_IN_SLOT_FULLY_BOOKED',
    SocialSpaceUnavailable = 'ERROR_SOCIAL_SPACE_UNAVAILABLE',
    SocialSpaceInsufficientDailyLimit = 'ERROR_SOCIAL_SPACE_INSUFFICIENT_DAILY_LIMIT',
    SocialSpaceInsufficientTwoWeekLimit = 'ERROR_SOCIAL_SPACE_INSUFFICIENT_TWO_WEEK_LIMIT',
    InvalidUploadFileType = 'ERROR_UPLOAD_FILE_TYPE',
    InvalidUploadFileSize = 'ERROR_UPLOAD_FILE_SIZE',
    MaximumAttachmentsReached = 'ERROR_MAXIMUM_ATTACHMENTS_REACHED',
}

export const attachmentResponseCodes = [
    ConciergeResponseCode.InvalidUploadFileType,
    ConciergeResponseCode.InvalidUploadFileSize,
    ConciergeResponseCode.MaximumAttachmentsReached,
];

export enum SfCountryCode {
    EN = 'en',
    ES = 'es',
}

export enum ShowForCountry {
    ALL = 'all',
    EN = 'en',
    ES = 'es',
}
