import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { UserEndpoints, getCurrentUser, UserData } from '../api';

type QueryConfig = UseQueryOptions<UserData, AxiosError<ConciergeErrorResponse>>;

export const useGetCurrentUser = (config: QueryConfig = {}) => {
    const userQuery = useQuery<UserData, AxiosError<ConciergeErrorResponse>>({
        queryKey: [UserEndpoints.getCurrentUser],
        queryFn: getCurrentUser,
        ...config,
    });

    return userQuery;
};
