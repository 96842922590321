import { FC, MouseEventHandler, ReactNode } from 'react';
import styled from '@emotion/styled';
import {
    ListItemButton,
    ListItemIcon,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
    useTheme,
} from '@mui/material';

const StyledButton = styled(ListItemButton)(({ theme, ...props }) => ({
    gap: '1em',
    flex: '0 0 auto',
    background: 'none !important',
    justifyContent: props['aria-expanded'] ? 'flex-start' : 'center',
    color: props.selected ? theme.palette.background.default : theme.palette.grey[600],
    borderRadius: '4px',
    padding: '12px 20px',
    transition: theme.transitions.create('all'),

    '&:hover': {
        background: 'rgba(255, 255, 255, 0.05) !important',
    },

    '& svg': {
        color: props.selected ? theme.palette.background.default : theme.palette.grey[600],
    },
})) as typeof ListItemButton;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        borderRadius: 0,
        margin: '0 0 -32px -16px !important',
        padding: '12px 16px',
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
    },
}));

export const SidebarNavButton: FC<{
    expanded: boolean;
    selected?: boolean;
    label: string;
    icon: ReactNode;
    onClick: MouseEventHandler;
}> = ({ expanded, selected, label, icon, onClick }) => {
    const { palette } = useTheme();

    return (
        <li
            css={{
                padding: '0 28px 0 20px',
                borderLeft: `2px solid ${selected ? palette.primary.main : 'transparent'}`,
            }}
        >
            <StyledTooltip
                title={
                    <Typography variant="overlineMedium" color="text.primary">
                        {label}
                    </Typography>
                }
                placement="right-end"
            >
                <StyledButton selected={selected} aria-expanded={expanded} onClick={onClick}>
                    <ListItemIcon css={{ minWidth: 'auto' }}>{icon}</ListItemIcon>

                    {expanded && (
                        <Typography
                            variant={selected ? 'overlineMedium' : 'overlineRegular'}
                            whiteSpace="nowrap"
                            flex={1}
                        >
                            {label}
                        </Typography>
                    )}
                </StyledButton>
            </StyledTooltip>
        </li>
    );
};
