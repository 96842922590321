import { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { ReactComponent as LinkIcon } from 'src/assets/svg/link.svg';
import { useGetActiveBuilding } from 'src/features/building';
import { ErrorState } from 'src/components/error-state';
import { AppPreloader } from 'src/components/app-preloader';

export const FaqVideoLinks: FC = () => {
    const { t } = useTranslation();

    const { palette } = useTheme();

    const { data: building, isError, isLoading, isSuccess } = useGetActiveBuilding();

    if (isError) {
        return (
            <ErrorState
                direction="row"
                title={<Trans i18nKey="faq_video-links_error" />}
                css={{ flex: 0, padding: '35px', backgroundColor: palette.background.paper }}
            />
        );
    }

    if (isLoading) {
        return <AppPreloader css={{ padding: '44px' }} />;
    }

    if (isSuccess) {
        return (
            <Stack gap="16px">
                <Link
                    target="_blank"
                    href={building.building_guide_video.video_url}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                >
                    <Typography variant="subtitle2">
                        {t('faq_video-links_welcome-to-your-building')}
                    </Typography>
                    <LinkIcon />
                </Link>

                <Link
                    target="_blank"
                    href={building.utilities_guide_video.video_url}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                >
                    <Typography variant="subtitle2">
                        {t('faq_video-links_utilities-guide')}
                    </Typography>
                    <LinkIcon />
                </Link>

                <Link
                    target="_blank"
                    href={building.your_studio_video.video_url}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                >
                    <Typography variant="subtitle2">{t('faq_video-links_your-studio')}</Typography>
                    <LinkIcon />
                </Link>

                <Link
                    target="_blank"
                    href={building.safety_at_vita_student_video.video_url}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                >
                    <Typography variant="subtitle2">{t('faq_video-links_safety')}</Typography>
                    <LinkIcon />
                </Link>
            </Stack>
        );
    }

    return null;
};
