import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { IconButton, Stack, Typography } from '@mui/material';

import { PrivateHat } from 'src/layout/private-hat';
import { useGetEvents, EventsList } from 'src/features/events';
import { Button } from 'src/components/button';
import { ReactComponent as CalendarEmptyIcon } from 'src/assets/svg/calendar-empty.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/calendar.svg';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

export const EventsPage: FC = () => {
    const { t } = useTranslation();
    const { breakpoints } = useTheme();

    const { data, isLoading, isError } = useGetEvents();

    // Sort events in ascending order (earliest first)
    const eventsAsc = data
        ?.slice()
        .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

    const handleMyBookingsClick = () =>
        GA4.ctaEvent({
            name: 'Events My Bookings Click',
            location: 'Private Hat',
            label: t('events_tab_my-events', { lng: LanguagesCode.English }),
        });

    return (
        <Fragment>
            <PrivateHat
                title={t('events_title')}
                backIconText={t('back_cta')}
                actionLabel={
                    <Fragment>
                        <Button
                            design="primary"
                            component={Link}
                            to="my"
                            onClick={handleMyBookingsClick}
                            endIcon={<CalendarEmptyIcon />}
                            css={{
                                margin: '-12px 0',
                                [breakpoints.down('md')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            {t('events_tab_my-events')}
                        </Button>

                        <IconButton
                            component={Link}
                            to="my"
                            disableRipple
                            color="primary"
                            css={{
                                padding: 0,
                                margin: '-2px 0',

                                [breakpoints.up('md')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <CalendarIcon />
                        </IconButton>
                    </Fragment>
                }
            />

            <Stack
                flex={1}
                padding={{
                    xs: '40px 16px 20px 16px',
                    md: '40px 40px 20px 40px',
                    lg: '40px 60px 20px 60px',
                    xl: '40px 100px 20px 100px',
                }}
            >
                <Typography
                    variant="h4"
                    marginBottom="22px"
                    paddingLeft={{ xs: '16px', md: '42px', lg: 0 }}
                >
                    {t('events_tab_upcoming', {
                        count: eventsAsc?.length,
                    })}
                </Typography>

                <EventsList events={eventsAsc} isLoading={isLoading} isError={isError} />
            </Stack>
        </Fragment>
    );
};
