import { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { useTheme } from '@emotion/react';
import { TableRow, TableCell, IconButton, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { downloadFile } from 'src/utils/download-file';
import { formatAmount } from 'src/utils/format-amount';
import { ReactComponent as DownloadFileIcon } from 'src/assets/svg/download-file.svg';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { PaymentReceipt as IPaymentReceipt } from '../../../api';
import { useGetPaymentsReceiptPdf } from '../../../hooks/use-get-payment-receipt-pdf';

export const PaymentReceipt: FC<{ receipt: IPaymentReceipt }> = ({ receipt }) => {
    const { t } = useTranslation();

    const { palette } = useTheme();

    const { refetch, isFetching } = useGetPaymentsReceiptPdf(receipt.id);

    const handlePdfLoad = async () => {
        const { data: file } = await refetch();

        if (file) {
            downloadFile(file, `receipt-${receipt.id}.pdf`);

            GA4.downloadEvent({
                name: 'Payment Receipt Download',
                label: t('my_bookings_payment_receipts_title', { lng: LanguagesCode.English }),
                location: 'Payment Receipts Table',
            });
        }
    };

    return (
        <TableRow>
            <TableCell>{format(parseISO(receipt.date), 'dd/MM/yyyy')}</TableCell>
            <TableCell>{receipt.category}</TableCell>
            <TableCell>{formatAmount(receipt.amount, receipt.amount_currency)}</TableCell>
            <TableCell>
                {isFetching ? (
                    <CircularProgress color="info" css={{ padding: '8px' }} />
                ) : (
                    <IconButton data-testid="PaymentReceipt.Download" onClick={handlePdfLoad}>
                        <DownloadFileIcon color={palette.common.black} />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
};
