import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { SocialSpacesEndpoints, Filters, getFiltersTree, GetFiltersTreeParams } from '../api';

type QueryConfig = UseQueryOptions<Filters, AxiosError<ConciergeErrorResponse>>;

let placeholderData: Filters;

export const useGetFiltersTree = (params: GetFiltersTreeParams, config: QueryConfig = {}) => {
    const { onSuccess, ...restConfig } = config;

    const query = useQuery<Filters, AxiosError<ConciergeErrorResponse>>({
        queryKey: [SocialSpacesEndpoints.GetFiltersTree, params],
        cacheTime: 0,
        placeholderData,
        queryFn: () => getFiltersTree(params),
        onSuccess(data) {
            placeholderData = data;

            onSuccess?.(data);
        },
        ...restConfig,
    });

    return query;
};
