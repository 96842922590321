import { FC, Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Box, Stack } from '@mui/material';

import { StateView } from 'src/components/state-view';
import { Spinner } from 'src/components/spinner';
import { Dialog } from 'src/components/dialog';
import { routes } from 'src/constants/routes';
import { EmptyList } from 'src/components/empty-list/empty-list';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { useGetBuildingOffers } from '../../hooks/use-get-building-offers';

import { BuildingOffersItem } from './building-offer-item';

export const BuildingOffersList: FC = () => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    const { data: offers, isError, isLoading, refetch } = useGetBuildingOffers();

    const [modalState, setModalState] = useState({
        content: '',
        visible: false,
    });

    const handleCloseDialog = () => setModalState({ ...modalState, visible: false });

    const handleOpenDialog = (content: string) => {
        setModalState({ content, visible: true });

        GA4.modalEvent({
            name: 'Offer Card See More Click',
            open: true,
            location: 'Offers & Discounts Page',
            label: t('offers_description_see-more', { lng: LanguagesCode.English }),
        });
    };

    if (isError) {
        return (
            <StateView
                state="fail"
                title={<Trans i18nKey="offers_error_title" />}
                actions={{
                    main: {
                        text: t('offers_error_retry_cta'),
                        callback: () => refetch(),
                    },

                    secondary: {
                        text: t('offers_error_back_cta'),
                        to: routes.myBuilding.index,
                    },
                }}
            />
        );
    }

    if (isLoading) {
        return (
            <Stack flex={1} justifyContent="center" alignItems="center">
                <Spinner size="big" color={palette.primary.main} />
            </Stack>
        );
    }

    return offers.length ? (
        <Fragment>
            <Box
                flex={1}
                display="grid"
                gridAutoRows="min-content"
                columnGap="24px"
                paddingY="60px"
                gridTemplateColumns={{ lg: '1fr 1fr 1fr' }}
                rowGap={{ xs: '24px', lg: '38px' }}
                paddingX={{ md: '60px', xl: '100px' }}
            >
                {offers.map((offer) => (
                    <BuildingOffersItem
                        key={offer.id}
                        offer={offer}
                        onShowMore={handleOpenDialog}
                    />
                ))}
            </Box>

            <Dialog open={modalState.visible} onClose={handleCloseDialog}>
                {modalState.content}
            </Dialog>
        </Fragment>
    ) : (
        <EmptyList mainText={<Trans i18nKey="offers_empty_state_title" />} />
    );
};
