import { EditProfileFormData } from '../types/edit-profile';
import { PatchUserBody, UserDataFields } from '../api';

export const getEditProfileFormDataAsPatchBody = (formData: EditProfileFormData): PatchUserBody =>
    ({
        ...formData,
        [UserDataFields.PhoneNumber]: [
            formData.phone_number?.callingCode,
            formData.phone_number?.number,
        ].join(''),
        [UserDataFields.EmergencyContactPhone]: [
            formData.emergency_contact_phone?.callingCode,
            formData.emergency_contact_phone?.number,
        ].join(''),
    }) as PatchUserBody;
