import { Box, Stack, Typography, useTheme, Button as MuiButton } from '@mui/material';
import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { range } from 'lodash';

import { PrivateHat } from 'src/layout/private-hat';
import { ReactComponent as GoogleIcon } from 'src/assets/svg/google.svg';
import { ReactComponent as StarIcon } from 'src/assets/svg/star.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { routes } from 'src/constants/routes';
import { Button } from 'src/components/button';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { SUBJECT_TITLE_TRANSLATION_KEY } from '../constants/translation-keys';
import { Subject } from '../constants/form-options';
import { PostFeedback } from '../api/types';

export const FeedbackFormSuccess: FC<{
    subject: Subject;
    feedback: PostFeedback;
    reset: VoidFunction;
}> = ({ subject, feedback, reset }) => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    const handleBackOnSuccess = () => {
        reset();
    };

    const showRating = feedback.rating >= 4;

    const handleReviewClick = () =>
        GA4.outboundLink('Feedback Google Review Link', feedback.google_review_link);

    const handleCTAClick = () =>
        GA4.ctaEvent({
            name: 'Feedback Success CTA Click',
            location: 'Feedback Form Success',
            label: t('feedback_page_confirmation_cta_back', { lng: LanguagesCode.English }),
        });

    return (
        <Fragment>
            <PrivateHat
                title={t(SUBJECT_TITLE_TRANSLATION_KEY[subject])}
                backIconText={t('back_cta')}
                onBack={handleBackOnSuccess}
            />

            <Box
                flexGrow={1}
                display="grid"
                justifyContent="center"
                gridTemplateRows={showRating ? '1fr auto auto auto 1fr' : '1fr auto auto 1fr'}
                width="100%"
                gridTemplateColumns="100%"
            >
                <Stack
                    width="280px"
                    gridRow={2}
                    justifySelf="center"
                    gap="40px"
                    textAlign="center"
                    marginBottom="48px"
                    className="state-view__descriptor"
                >
                    <Typography
                        variant="h2"
                        borderTop={`6px solid ${palette.primary.main}`}
                        paddingTop="20px"
                        marginX="auto"
                    >
                        <Trans i18nKey="feedback_page_confirmation_page_title" />
                    </Typography>

                    <Typography variant="body1" color="text.secondary">
                        {t('feedback_page_confirmation_description')}
                    </Typography>
                </Stack>

                {showRating && (
                    <Stack
                        width="100%"
                        padding="24px"
                        gap="12px"
                        alignItems="center"
                        gridRow={3}
                        css={{
                            background: palette.background.paper,
                        }}
                    >
                        <GoogleIcon />

                        <Stack direction="row" gap="6px">
                            {range(feedback.rating).map((index) => (
                                <StarIcon key={index} width="16px" height="16px" />
                            ))}
                        </Stack>

                        <MuiButton
                            css={{ color: palette.primary.main, marginTop: '8px' }}
                            component="a"
                            target="_blank"
                            onClick={handleReviewClick}
                            href={feedback.google_review_link}
                        >
                            {t('feedback_page_confirmation_cta_google_rate')}
                            <ArrowIcon
                                width="16px"
                                height="16px"
                                className="PrivateHat__back-icon"
                                css={{
                                    marginLeft: '16px',
                                    transform: 'rotate(-90deg)',
                                }}
                            />
                        </MuiButton>
                    </Stack>
                )}

                <Stack
                    width={{ md: '280px' }}
                    justifySelf="center"
                    bottom="16px"
                    right="16px"
                    left="16px"
                    gap="10px"
                    position={{ xs: 'absolute', md: 'static' }}
                    gridRow={showRating ? 4 : 3}
                    marginTop={showRating ? '80px' : '32px'}
                >
                    <Button
                        component={Link}
                        to={routes.feedback.index}
                        onClick={handleCTAClick}
                        design="light"
                        bordered
                        fullWidth
                    >
                        {t('feedback_page_confirmation_cta_back')}
                    </Button>
                </Stack>
            </Box>
        </Fragment>
    );
};
