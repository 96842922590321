import { areIntervalsOverlapping, endOfYear, startOfYear, Interval } from 'date-fns';

export const isYearOutsideRange = (sourceDate: Date, calendarRange: Interval) => {
    const sourceYearRange: Interval = {
        start: startOfYear(sourceDate),
        end: endOfYear(sourceDate),
    };

    return !areIntervalsOverlapping(sourceYearRange, calendarRange);
};
