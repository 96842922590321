import { conciergeClient } from 'src/api/concierge';

import { BookingsEndpoints } from './constants';

import type {
    GetBookingsSuccessResponse,
    GetBookingsSummarySuccessResponse,
    PatchBookingBody,
    PatchBookingSuccessResponse,
} from './types';

export const getBookingsList = async () => {
    const { data: response } = await conciergeClient.get<GetBookingsSuccessResponse>(
        BookingsEndpoints.getMyBookings
    );

    return response.data;
};

export const patchBooking = async (id: string, payload: PatchBookingBody) => {
    const { data: response } = await conciergeClient.patch<PatchBookingSuccessResponse>(
        BookingsEndpoints.patchMyBookings.replace('{id}', id),
        payload
    );

    return response.data;
};

export const getBookingsSummary = async () => {
    const { data: response } = await conciergeClient.get<GetBookingsSummarySuccessResponse>(
        BookingsEndpoints.getBookingsSummary
    );

    return response.data;
};
