import { FC } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LangSwitcher } from 'src/components/lang-switcher';

import { UserDataRow } from '../user-data-row';

export const UserSettings: FC = () => {
    const { t } = useTranslation();

    return (
        <Box display={{ md: 'none' }} marginTop="48px">
            <Typography variant="h3" gutterBottom>
                {t('settings_title')}
            </Typography>

            <UserDataRow label={t('language_switcher_label')} content={<LangSwitcher />} />
        </Box>
    );
};
