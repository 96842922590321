import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { useGetActiveBooking } from 'src/features/bookings';

import { getTicketsList, MaintenanceEndpoints, MaintenanceTickets } from '../api';

type Config = UseQueryOptions<MaintenanceTickets, AxiosError<ConciergeErrorResponse>>;

export const useGetTickets = (config: Config = {}) => {
    const { data: activeBooking } = useGetActiveBooking();

    return useQuery({
        queryKey: [MaintenanceEndpoints.getTickets],
        queryFn: () => getTicketsList(activeBooking!.id),
        ...config,
    });
};
