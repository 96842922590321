import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

export const IconLabel: FC<{ icon: ReactNode; children: ReactNode; className?: string }> = ({
    icon,
    children,
    className,
}) => (
    <Stack direction="row" gap="16px" alignItems="center" className={className}>
        {icon}
        <Typography variant="subtitle2" textAlign="left" flex={1}>
            {children}
        </Typography>
    </Stack>
);
