import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Stack, Typography } from '@mui/material';

import { Link } from 'src/components/link';
import { EmptyList } from 'src/components/empty-list/empty-list';
import { LogoutDialog } from 'src/features/auth';
import { Button } from 'src/components/button';
import { ReactComponent as VitaLogo } from 'src/assets/svg/vita-logo-opened.svg';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { LangSwitcher } from '../components/lang-switcher';

export const NoBookingsPage: FC = () => {
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const { t } = useTranslation();
    const { palette } = useTheme();

    const handleToggleLogoutDialog = () => setShowLogoutDialog(!showLogoutDialog);

    const handleLogoutTracking = () =>
        GA4.ctaEvent({
            name: 'Logout',
            location: 'No Bookings Page',
            label: t('empty_booking_error_logout', { lng: LanguagesCode.English }),
        });

    return (
        <Stack flex={1} data-testid="NoBookingPage.Container">
            <Stack
                flexDirection="row"
                justifyContent="space-between"
                css={{ padding: '30px 40px' }}
            >
                <VitaLogo />
                <LangSwitcher />
            </Stack>

            <Stack flex={1} gap="16px" alignItems="center" padding={{ xs: '16px', md: 0 }}>
                <Stack flex={1} justifyContent="center" alignItems="center">
                    <EmptyList
                        mainText={<Trans i18nKey="empty_booking_error_message" />}
                        secondaryText={<Trans i18nKey="empty_booking_error_message_footer" />}
                        css={{ margin: '0 0 60px 0', flex: 0 }}
                    />

                    <Typography
                        variant="body2"
                        fontWeight={400}
                        textAlign="center"
                        css={{
                            borderTop: `5px solid ${palette.primary.main}`,
                            paddingTop: '24px',
                        }}
                    >
                        <Trans i18nKey="empty_booking_error_contact_message">
                            If you have any issues or questions, please contact
                            <Link
                                variant="light"
                                href="mailto:hello@vitastudent.com"
                                css={{
                                    textDecoration: 'none',
                                    color: palette.primary.main,
                                }}
                            >
                                hello@vitastudent.com
                            </Link>
                        </Trans>
                    </Typography>
                </Stack>

                <Button
                    onClick={handleToggleLogoutDialog}
                    bordered
                    design="light"
                    css={{
                        color: palette.text.primary,
                        marginBottom: '48px',
                        width: '360px',
                        maxWidth: '100%',
                    }}
                >
                    <Typography variant="body1" lineHeight="24px">
                        {t('empty_booking_error_logout')}
                    </Typography>
                </Button>
            </Stack>

            <LogoutDialog
                isOpen={showLogoutDialog}
                onClose={handleToggleLogoutDialog}
                onLogout={handleLogoutTracking}
            />
        </Stack>
    );
};
