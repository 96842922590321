import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { routes } from 'src/constants/routes';
import { Spinner } from 'src/components/spinner';
import { ErrorState } from 'src/components/error-state';
import { LanguagesCode } from 'src/i18n/constants';
import { trimText } from 'src/utils/trim-text';
import { GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';

import { ReactComponent as OffersIcon } from '../../assets/offers.svg';
import { BuildingCard } from '../building-card';
import { BuildingNavLink } from '../building-nav-link';
import { useGetBuildingOffers } from '../../hooks/use-get-building-offers';
import { BuildingOffers } from '../../api';

interface Props {
    checkedIn: boolean;
    isFeatureEnabled: boolean;
    className?: string;
}

const selectFeaturedOffer = (offers: BuildingOffers) => offers.find(({ featured }) => featured);

export const BuildingFeaturedOffer: FC<Props> = ({ checkedIn, isFeatureEnabled, className }) => {
    const {
        data: offer,
        isError,
        isLoading,
        isSuccess,
    } = useGetBuildingOffers({
        select: selectFeaturedOffer,
        enabled: isFeatureEnabled,
    });

    const { t, i18n } = useTranslation();
    const lngCode = i18n.language as LanguagesCode;

    const handleTitleClick = () =>
        GA4.ctaEvent({
            name: 'Offers Title Click',
            label: t('offers_title'),
            location: 'Offers Card',
        });

    const handleOfferClick = () => {
        if (offer) {
            if (offer.external_url) {
                GA4.outboundLink('Featured Offer Click', offer.external_url);
            } else {
                GA4.ctaEvent({
                    name: 'Offer Click',
                    label: t(offer.title[lngCode]),
                    location: 'Offers Card',
                });
            }
        }
    };

    const handleCTAClick = () =>
        GA4.ctaEvent({
            name: 'Offers View More Click',
            label: t('offers_see_all_offers'),
            location: 'Offers Card',
        });

    return (
        <BuildingNavLink
            Icon={OffersIcon}
            title={t('offers_title')}
            link={{
                url: routes.myBuilding.offers,
                text: t('offers_see_all_offers'),
            }}
            checkedIn={checkedIn}
            isFeatureEnabled={isFeatureEnabled}
            onTitleClick={handleTitleClick}
            onCTAClick={handleCTAClick}
            className={className}
        >
            {/* Use function because we need pass a node to BuildingNavLink not array. */}
            {(() => {
                if (isError) return <ErrorState title={<Trans i18nKey="offers_error_title" />} />;

                if (isLoading)
                    return (
                        <Typography textAlign="center" padding="37px" color="primary">
                            <Spinner />
                        </Typography>
                    );

                if (isSuccess && offer)
                    return (
                        <BuildingCard
                            image={offer.image_url}
                            label={t('offers_featured_offer')}
                            title={getPartialTranslation(offer.title)}
                            body={trimText(getPartialTranslation(offer.description), 140)}
                            link={{
                                url: offer.external_url || routes.myBuilding.offers,
                                text: t('offers_go_to_offer_button'),
                            }}
                            onClick={handleOfferClick}
                            paper
                        />
                    );

                return null;
            })()}
        </BuildingNavLink>
    );
};
