import { FC, MouseEventHandler, useMemo, AnchorHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import * as CalendarLink from 'calendar-link';
import { Stack, Typography } from '@mui/material';

import { Dialog } from 'src/components/dialog';

import { ReactComponent as IconApple } from './apple.svg';
import { ReactComponent as IconGoogle } from './google.svg';
import { ReactComponent as IconOutlook } from './outlook.svg';
import { ReactComponent as IconOffice365 } from './office365.svg';
import { ReactComponent as IconYahoo } from './yahoo.svg';

export interface CalendarOption {
    key: string;
    icon: JSX.Element;
    attributes: AnchorHTMLAttributes<HTMLAnchorElement>;
}

const DEFAULT_ANCHOR_PROPS = {
    target: '_blank',
    rel: 'noreferrer',
};

export const AddToCalendarModal: FC<{
    calendarEvent: CalendarLink.CalendarEvent;
    isOpen: boolean;
    onClose: MouseEventHandler;
    onAddToCalendar?: (option: CalendarOption) => void;
}> = ({ calendarEvent, isOpen, onClose, onAddToCalendar }) => {
    const { t } = useTranslation();

    const options: CalendarOption[] = useMemo(
        () => [
            {
                key: 'apple',
                icon: <IconApple />,
                attributes: {
                    href: CalendarLink.ics(calendarEvent),
                    download: 'event.ics',
                },
            },

            {
                key: 'google',
                icon: <IconGoogle />,
                attributes: {
                    href: CalendarLink.google(calendarEvent),
                    ...DEFAULT_ANCHOR_PROPS,
                },
            },

            {
                key: 'outlook',
                icon: <IconOutlook />,
                attributes: {
                    href: CalendarLink.outlook(calendarEvent),
                    ...DEFAULT_ANCHOR_PROPS,
                },
            },

            {
                key: 'office365',
                icon: <IconOffice365 />,
                attributes: {
                    href: CalendarLink.office365(calendarEvent),
                    ...DEFAULT_ANCHOR_PROPS,
                },
            },

            {
                key: 'yahoo',
                icon: <IconYahoo />,
                attributes: {
                    href: CalendarLink.yahoo(calendarEvent),
                    ...DEFAULT_ANCHOR_PROPS,
                },
            },
        ],
        [JSON.stringify(calendarEvent)]
    );

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            onClick={(e) => e.stopPropagation()}
            css={{
                gap: '30px',
            }}
        >
            <Stack justifyContent="center" alignItems="center" gap="32px" width="100%">
                <Typography variant="h3" textAlign="center">
                    {t('event_add_to_calendar_modal_title')}
                </Typography>

                <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap="28px"
                >
                    {options.map((option) => {
                        const handleClick = () => {
                            onAddToCalendar?.(option);
                        };

                        return (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                            <a key={option.key} {...option.attributes} onClick={handleClick}>
                                {option.icon}
                            </a>
                        );
                    })}
                </Stack>
            </Stack>
        </Dialog>
    );
};
