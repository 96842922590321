import { FC, Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CalendarEvent } from 'calendar-link';
import { Divider, IconButton, Stack, Typography } from '@mui/material';

import { routes } from 'src/constants/routes';
import { StateView } from 'src/components/state-view';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/add-to-calendar.svg';
import { ReactComponent as ShareIcon } from 'src/assets/svg/share.svg';
import {
    AddToCalendarModal,
    CalendarOption,
} from 'src/components/add-to-calendar-modal/add-to-calendar-modal';
import { EventType, GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';

import { Event } from '../api';

import { LocationLabel } from './location-label';
import { DateLabel } from './date-label';
import { ShareModal } from './share-modal/share-modal';

export const EventAttendSuccess: FC<{
    event: Event;
    isQuick: boolean;
}> = ({ event, isQuick }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);

    const eventUrl = event.external_event_url || routes.events.id.replace(':id', event.id);

    const title = getPartialTranslation(event.title);
    const description = getPartialTranslation(event.description);

    const calendarEvent: CalendarEvent = {
        start: event.start_date,
        end: event.end_date,
        title,
        description: description.concat(`\n\n${eventUrl}`),
        location: Object.values(event.location).join(', '),
        url: eventUrl,
    };

    const handleToggleShareModal = () => {
        setIsShareModalOpen(!isShareModalOpen);

        GA4.modalEvent({
            name: 'Events Share Modal',
            location: 'Event Attend Success',
            open: !isShareModalOpen,
            label: 'Calendar Icon',
        });
    };

    const handleToggleCalendarModal = () => {
        setIsCalendarModalOpen(!isCalendarModalOpen);

        GA4.modalEvent({
            name: 'Events Add to Calendar Modal',
            location: 'Event Attend Success',
            open: !isCalendarModalOpen,
            label: 'Calendar Icon',
        });
    };

    const handleAddToCalendar = (option: CalendarOption) =>
        GA4.event('Events Add to Calendar', EventType.Cta, {
            platform: option.key,
            location: 'Event Attend Success',
        });

    const handleGoToEvents = () => navigate(routes.events.index);
    const handleGoToEventId = () => navigate(routes.events.id.replace(':id', event.id));

    return (
        <Fragment>
            <StateView
                state="success"
                title={t('event_confirmation_title')}
                actions={
                    isQuick
                        ? {
                              main: {
                                  text: t('event_confirmation_cta_back_to_events'),
                                  callback: handleGoToEvents,
                              },
                              secondary: undefined,
                          }
                        : {
                              main: {
                                  text: t('event_confirmation_cta_back_to_event'),
                                  callback: handleGoToEventId,
                              },
                              secondary: {
                                  text: t('event_confirmation_cta_explore_events'),
                                  callback: handleGoToEvents,
                              },
                          }
                }
                css={{
                    height: '100%',
                    background: 'white',

                    '& > div': {
                        alignItems: 'center',
                    },
                }}
            >
                <Typography variant="body1">
                    <Trans
                        i18nKey="event_confirmation_description"
                        values={{ event_name: title }}
                        components={{
                            strong: <Typography variant="subtitle1" component="strong" />,
                        }}
                    />
                </Typography>

                <Stack gap="16px">
                    <DateLabel event={event} />

                    <Divider />

                    <LocationLabel
                        firstLine={event.location.first_line}
                        secondLine={event.location.second_line}
                    />

                    <Stack direction="row" marginTop="40px" gap="24px" justifyContent="center">
                        <IconButton color="primary" onClick={handleToggleShareModal}>
                            <ShareIcon />
                        </IconButton>

                        <IconButton color="primary" onClick={handleToggleCalendarModal}>
                            <CalendarIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </StateView>

            <ShareModal event={event} isOpen={isShareModalOpen} onClose={handleToggleShareModal} />

            <AddToCalendarModal
                calendarEvent={calendarEvent}
                isOpen={isCalendarModalOpen}
                onClose={handleToggleCalendarModal}
                onAddToCalendar={handleAddToCalendar}
            />
        </Fragment>
    );
};
