import { AlertColor } from '@mui/material';

import { EventStatus } from '../api';

export const EVENT_STATUS_TRANSLATION_KEY: Record<EventStatus, string> = {
    [EventStatus.Open]: 'event_status_open',
    [EventStatus.FillingFast]: 'event_status_filling-fast',
    [EventStatus.NearlyFull]: 'event_status_nearly-full',
    [EventStatus.AtCapacity]: 'event_status_at-capacity',
    [EventStatus.Closed]: 'event_status_closed',
    [EventStatus.Cancelled]: 'event_status_cancelled',
    [EventStatus.NotOpenYet]: '',
};

export const EVENT_STATUS_ALERT_VIEW: Record<EventStatus, false | AlertColor> = {
    [EventStatus.Open]: false,
    [EventStatus.FillingFast]: 'warning',
    [EventStatus.NearlyFull]: 'warning',
    [EventStatus.AtCapacity]: 'error',
    [EventStatus.Closed]: 'error',
    [EventStatus.Cancelled]: 'error',
    [EventStatus.NotOpenYet]: false,
};
