import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Breakpoint, useMediaQuery } from '@mui/material';

export const useBreakpointExpandDescription = (
    defaultExpanded: boolean,
    breakpoint: Breakpoint
) => {
    const { breakpoints } = useTheme();

    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(defaultExpanded);

    const isBelowBreakpoint = useMediaQuery(breakpoints.down(breakpoint));

    const expandDescription = () => {
        setIsDescriptionExpanded(true);
    };

    return {
        isTrimmedDescription: isBelowBreakpoint && !isDescriptionExpanded,
        expandDescription,
    };
};
