import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';

import { Image, SrcSet } from 'src/components/image';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/square-arrow.svg';
import { isRelativeUrl } from 'src/utils/is-link-relative';
import { GA4 } from 'src/lib/ga4';

interface Props {
    title: string;
    subtitle: string;
    body: ReactNode;
    link: string;
    label: ReactNode;
    image_srcset: SrcSet;
    disabled: boolean;
    ga_name: string;
}

export const RebookingLink: FC<Props> = ({
    title,
    subtitle,
    body,
    link,
    label,
    image_srcset,
    disabled,
    ga_name,
}) => {
    const { palette, shadows, breakpoints } = useTheme();

    const linkProps =
        !disabled &&
        (isRelativeUrl(link)
            ? {
                  component: Link,
                  to: link,
              }
            : {
                  component: 'a',
                  target: '_blank',
                  href: link,
              });

    const handleClick = () =>
        GA4.ctaEvent({
            name: ga_name,
            location: 'Rebook Option Cards',
            label: title,
        });

    return (
        <Stack
            {...linkProps}
            boxShadow={shadows[disabled ? 0 : 1]}
            flex={{ md: 1 }}
            css={{
                textDecoration: 'none',
                color: 'inherit',
                background: disabled ? palette.background.paper : palette.background.default,
            }}
            onClick={handleClick}
        >
            <Box position="relative">
                {!disabled && (
                    <ArrowIcon
                        width="40px"
                        height="40px"
                        color={palette.primary.main}
                        css={{ position: 'absolute', zIndex: 1, right: 0, top: 0 }}
                    />
                )}

                <Image
                    srcSet={image_srcset}
                    alt="banner"
                    css={{
                        height: '146px',

                        img: {
                            filter: `grayscale(${disabled ? 1 : 0})`,
                        },

                        [breakpoints.up('md')]: {
                            height: '260px',
                        },
                    }}
                />

                {label}
            </Box>

            <Box padding="16px">
                <Typography
                    component="p"
                    variant="button"
                    color="text.secondary"
                    marginBottom="8px"
                >
                    {subtitle}
                </Typography>

                <Typography variant="h2" marginBottom="16px">
                    {title}
                </Typography>

                <div
                    css={{
                        width: '60px',
                        height: '1px',
                        marginBottom: '16px',
                        background: palette.common.black,
                    }}
                />

                <Typography variant="body2">{body}</Typography>
            </Box>
        </Stack>
    );
};
