import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';

import { routes } from 'src/constants/routes';
import { useGetActiveBooking } from 'src/features/bookings';
import { StateView } from 'src/components/state-view';
import { Spinner } from 'src/components/spinner';
import { EmptyList } from 'src/components/empty-list/empty-list';

import { useGetBuildingIncidents } from '../../hooks/use-get-building-incidents';

import { BuildingIncidentsItem } from './building-incidents-item';

export const BuildingIncidentsList: FC = () => {
    const { data: booking } = useGetActiveBooking();

    const { data: incidents, isLoading, isError, refetch } = useGetBuildingIncidents(booking!.id);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleRetry = () => refetch();

    const handleGoBack = () => navigate(routes.myBuilding.index);

    if (isError) {
        return (
            <StateView
                state="fail"
                title={<Trans i18nKey="building_incidents_error_title" />}
                actions={{
                    main: {
                        text: t('building_incidents_error_retry_cta'),
                        callback: handleRetry,
                    },
                    secondary: {
                        text: t('building_incidents_error_back_cta'),
                        callback: handleGoBack,
                    },
                }}
            />
        );
    }

    if (isLoading) {
        return (
            <Stack flex={1} justifyContent="center" alignItems="center">
                <Spinner size="big" />
            </Stack>
        );
    }

    if (!incidents.length) {
        return <EmptyList mainText={<Trans i18nKey="building_incidents_emtpy_state_title" />} />;
    }

    return (
        <Box flex={1} padding={{ xs: '16px', md: '60px', xl: '60px 100px' }}>
            <Typography
                variant="subtitle2"
                color="text.secondary"
                marginBottom={{ xs: '16px', md: '36px' }}
            >
                {t('building_incidents_latest_incidents')}
            </Typography>

            <Box
                display="grid"
                gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr 1fr' }}
                width="100%"
                gap="24px"
            >
                {incidents.map((incident) => (
                    <BuildingIncidentsItem key={incident.id} incident={incident} />
                ))}
            </Box>
        </Box>
    );
};
