enum NotificationPermission {
    Default = 'default',
    Denied = 'denied',
    Granted = 'granted',
}

export const NotificationService = {
    isSupported: 'Notification' in window,

    get permission() {
        return this.isSupported ? Notification.permission : null;
    },

    requestPermission() {
        return this.isSupported ? Notification.requestPermission() : null;
    },

    status: NotificationPermission,
};
