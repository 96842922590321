import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Stack, Typography, Avatar } from '@mui/material';

import { useGetCurrentUser, UserDataFields } from 'src/features/user';
import { routes } from 'src/constants/routes';

export const UserAvatar: FC<{ onClick?: VoidFunction }> = ({ onClick }) => {
    const { t } = useTranslation();
    const { data } = useGetCurrentUser();

    const user = data!;
    const username = `${user[UserDataFields.FirstName]} ${user[UserDataFields.LastName]}`;

    return (
        <Stack
            component={Link}
            to={routes.profile.index}
            onClick={onClick}
            direction="row"
            alignItems="center"
            gap="16px"
            css={{ textDecoration: 'inherit', color: 'inherit' }}
        >
            <Avatar src={user[UserDataFields.AvatarUrl]!} />

            <div>
                <Typography color="text.primary" variant="h4" textTransform="uppercase">
                    {username}
                </Typography>

                <Typography color="text.secondary" variant="body2">
                    {t('profile_update')}
                </Typography>
            </div>
        </Stack>
    );
};
