import { useQuery } from '@tanstack/react-query';

import type { ConciergeErrorResponse } from 'src/api/concierge';

import { getPostById, Post, PostsEndpoints } from '../api';

export const useGetPostById = (id: string) => {
    const userQuery = useQuery<unknown, ConciergeErrorResponse, Post>({
        queryKey: [PostsEndpoints.getPostById, id],
        queryFn: () => getPostById(id),
        enabled: Boolean(id),
    });

    return userQuery;
};
