import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';

import { EventStatus } from '../../api';
import {
    EVENT_STATUS_ALERT_VIEW,
    EVENT_STATUS_TRANSLATION_KEY,
} from '../../constants/event-status';

export const EventStatusLabel: FC<{ eventStatus: EventStatus; className?: string }> = ({
    eventStatus,
    className,
}) => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={className}
            color={palette.common.white}
            padding="9.25px 16px"
            gap="5px"
            css={{
                backgroundColor: palette.primary.main,
            }}
        >
            {EVENT_STATUS_ALERT_VIEW[eventStatus] && (
                <WarningIcon css={{ height: '18px', width: '18px', flexShrink: 0 }} />
            )}
            <Typography variant="overlineMedium">
                {t(EVENT_STATUS_TRANSLATION_KEY[eventStatus])}
            </Typography>
        </Stack>
    );
};
