import { ReactNode } from 'react';
import { InputLabel, MenuItem, Select, FormControl, SelectProps } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import {
    RebookingCityOption,
    RebookingBuildingOption,
    RebookingRoomTypeOption,
} from '../../api/types';
import { RebookingFilterParams } from '../../api/constants';
import { RebookingFilterForm } from '../../types';

type AvailableOptions =
    | RebookingCityOption[]
    | RebookingBuildingOption[]
    | RebookingRoomTypeOption[]
    | number[];

interface Props<Options extends AvailableOptions> {
    id: Exclude<
        RebookingFilterParams,
        | RebookingFilterParams.RoomCode
        | RebookingFilterParams.CurrentDate
        | RebookingFilterParams.Page
    >;
    labelKey: string;
    placeholder: ReactNode;
    control: Control<Exclude<RebookingFilterForm, RebookingFilterParams.RoomCode>>;
    options: Options;
    getOptionKey: (option: Options[number]) => string;
    onReset?: VoidFunction;
}

export const RebookingFilterSelect = <Options extends AvailableOptions>({
    id,
    labelKey,
    placeholder,
    control,
    options,
    getOptionKey,
    onReset,
}: Props<Options>) => {
    const { t } = useTranslation();

    return (
        <Controller
            name={id}
            control={control}
            // @ts-ignore (fall back for MUI select)
            defaultValue=""
            rules={{
                onChange() {
                    onReset?.();
                },
            }}
            render={({ field }) => {
                const handleChange: SelectProps<Options[number]>['onChange'] = ({ target }) => {
                    GA4.selectEvent({
                        name: `Swap Room Filter: ${t(labelKey, { lng: LanguagesCode.English })}`,
                        location: 'Swap Room Filters',
                        option: getOptionKey(target.value as Options[number]),
                    });

                    field.onChange?.(target.value);
                };

                return (
                    <FormControl fullWidth>
                        <InputLabel id={id} variant="standard" shrink>
                            {t(labelKey)}
                        </InputLabel>

                        <InputLabel id={id} variant="standard" aria-label="placeholder label">
                            {placeholder}
                        </InputLabel>

                        <Select
                            {...field}
                            variant="standard"
                            labelId={id}
                            disabled={!options.length}
                            onChange={handleChange}
                            renderValue={(value) => <span>{getOptionKey(value)}</span>}
                        >
                            {options.map((data) => {
                                const key = getOptionKey(data);

                                return (
                                    // @ts-ignore (allow use object as value)
                                    <MenuItem key={key} value={data}>
                                        {key}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                );
            }}
        />
    );
};
