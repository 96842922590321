import { ElementType } from 'react';
import { useTheme } from '@emotion/react';
import { Button, ButtonProps } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/square-arrow.svg';

import { Spinner } from './spinner';

type Design = 'light' | 'dark' | 'success' | 'error';

interface CustomProps {
    design?: Design;
    bordered?: boolean;
    loading?: boolean;
    rightIcon?: JSX.Element;
}

/**
 * The extended version of the MUI Button. Has special support for "loading state".
 * Also provides additional place for the icon (has different UI styles then MUI default icon prop).
 */
export const ButtonWithIcon = <E extends ElementType>({
    children,
    disabled,
    design = 'dark',
    bordered,
    loading,
    rightIcon,
    startIcon,
    endIcon,
    className,
    ...restProps
}: ButtonProps<E, CustomProps>) => {
    const { palette } = useTheme();

    return (
        <Button
            disabled={disabled || loading}
            fullWidth
            css={{
                alignItems: 'stretch',
                borderRadius: 0,
                padding: 0,
                opacity: disabled ? 0.4 : undefined,

                ...(design === 'light' && {
                    color: palette.text.primary,
                    background: palette.common.white,

                    ':hover': {
                        color: palette.common.white,
                        background: palette.common.black,
                    },

                    '&.Mui-disabled': {
                        color: palette.common.black,
                    },
                }),

                ...(design === 'dark' && {
                    color: palette.common.white,
                    background: palette.common.black,

                    ':hover': {
                        color: palette.text.primary,
                        background: palette.common.white,
                    },

                    '&.Mui-disabled': {
                        color: palette.common.white,
                    },
                }),

                ...(design === 'error' && {
                    color: palette.text.primary,
                    background: palette.error.light,

                    ':hover': {
                        color: palette.text.primary,
                        background: palette.error.light,
                    },
                }),

                ...(design === 'success' && {
                    color: palette.text.primary,
                    background: palette.success.light,

                    ':hover': {
                        color: palette.text.primary,
                        background: palette.success.light,
                    },
                }),
            }}
            className={className}
            {...restProps}
        >
            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '16px',
                    padding: '0 16px',
                    flex: 1,

                    ...(bordered &&
                        design === 'light' && {
                            border: `1px solid ${palette.divider}`,
                        }),

                    ...(bordered &&
                        design === 'dark' && {
                            border: `1px solid ${palette.common.white}`,
                            borderRight: 'none',
                        }),

                    ...(bordered &&
                        design === 'error' && {
                            border: `1px solid ${palette.error.main}`,
                        }),

                    ...(bordered &&
                        design === 'success' && {
                            border: `1px solid ${palette.success.main}`,
                        }),
                }}
            >
                {startIcon}
                {children}
                {endIcon}
            </div>

            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    background: palette.primary.main,
                }}
            >
                {loading ? (
                    <Spinner width="28px" height="28px" css={{ color: palette.common.white }} />
                ) : (
                    rightIcon || (
                        <ArrowIcon
                            color={
                                design === 'success' ? palette.success.main : palette.primary.main
                            }
                        />
                    )
                )}
            </div>
        </Button>
    );
};
