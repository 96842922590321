import { conciergeClient, ConciergeSuccessResponse } from 'src/api/concierge';

import {
    PostFeedback,
    PostFeedbackAttachmentBody,
    PostFeedbackAttachmentResponse,
    PostFeedbackFormBody,
} from './types';
import { FeedbackEndpoints } from './constants';

export const postFeedback = async (payload: PostFeedbackFormBody) => {
    const { data: response } = await conciergeClient.post<ConciergeSuccessResponse<PostFeedback>>(
        FeedbackEndpoints.PostFeedback,
        payload
    );

    return response.data;
};

export const postFeedbackAttachment = async (attachment: PostFeedbackAttachmentBody) => {
    const body = new FormData();
    body.append('attachment', attachment);

    const { data: response } = await conciergeClient.post<PostFeedbackAttachmentResponse>(
        FeedbackEndpoints.PostAttachmentFile,
        body
    );

    return response.data;
};
