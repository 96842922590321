import * as yup from 'yup';

import { ConciergeResponseCode } from 'src/api/concierge';
import { appConfig } from 'src/app/app-config';

import { ALLOWED_ATTACHMENTS_FILE_TYPES } from '../constants';

export const attachmentsSchema = yup
    .array()
    .typeError(ConciergeResponseCode.InvalidUploadFileType!)
    .of(
        yup
            .mixed<File>()
            .test({
                message: ConciergeResponseCode.InvalidUploadFileType,
                test: (file) =>
                    file instanceof File && ALLOWED_ATTACHMENTS_FILE_TYPES.includes(file.type),
            })
            .test({
                message: ConciergeResponseCode.InvalidUploadFileSize,
                test: (file) => file!.size <= appConfig.attachments.maxSizeBytes,
            })
    )
    .max(appConfig.attachments.maxCount, ConciergeResponseCode.MaximumAttachmentsReached);
