import { conciergeClient } from 'src/api/concierge';

import { UserEndpoints } from './constants';

import type {
    PatchUserBody,
    GetCurrentUserSuccessResponse,
    PatchUserSuccessResponse,
    PostUserAvatarSuccessResponse,
} from './types';

export const getCurrentUser = async () => {
    const { data: response } = await conciergeClient.get<GetCurrentUserSuccessResponse>(
        UserEndpoints.getCurrentUser
    );

    return response.data;
};

export const patchUser = async (id: string, payload: PatchUserBody) => {
    const { data: response } = await conciergeClient.patch<PatchUserSuccessResponse>(
        UserEndpoints.patchUser.replace('{id}', id),
        payload
    );

    return response.data;
};

export const postUserAvatar = async (id: string, avatar: File) => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    const { data: response } = await conciergeClient.post<PostUserAvatarSuccessResponse>(
        UserEndpoints.postUserAvatar.replace('{id}', id),
        formData
    );

    return response.data;
};
