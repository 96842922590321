export enum PostsEndpoints {
    getPosts = '/v1/posts',
    getPostById = '/v1/posts/{id}',
}

export enum PostTypes {
    Event = 'Event',
    Trend = 'Trend',
    Poll = 'Poll',
    Marketing = 'Marketing',
}
