/* eslint-disable import/no-extraneous-dependencies */
import {
    getMessaging,
    getToken,
    MessagePayload,
    NextFn,
    Observer,
    onMessage,
    isSupported,
} from 'firebase/messaging';

import { firebaseApp } from '../firebase-app';
import { getTokenOptions } from '../firebase-options';

type Callback = Observer<MessagePayload> | NextFn<MessagePayload>;

export const initFirebaseMessaging = async () => {
    const supported = await isSupported();

    if (!supported) {
        throw new Error('Can not init FCM. PUSH notifications are not supported on this device');
    }

    const messaging = getMessaging(firebaseApp);
    const token = await getToken(messaging, getTokenOptions);

    return {
        token,
        onMessage: (callback: Callback) => onMessage(messaging, callback),
    };
};
