import { RebookingPhase } from '../api/constants';
import { PhaseData } from '../api/types';

export const getPhases = (phases: PhaseData[]) => {
    const swap = phases.find(({ phase }) => phase === RebookingPhase.Swap);

    const keep = phases.find(({ phase }) => phase === RebookingPhase.Keep);

    return { swap, keep };
};
