import { conciergeClient } from 'src/api/concierge';

import { MoveInEndpoints } from './constants';

import type { PostMoveInBookingSuccessResponse, PostMoveInBookingBody } from './types';

export const postMoveInBooking = async (payload: PostMoveInBookingBody) => {
    const { data: response } = await conciergeClient.post<PostMoveInBookingSuccessResponse>(
        MoveInEndpoints.PostMoveInBooking,
        payload
    );

    return response.data;
};
