import { FC, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { Dialog } from 'src/components/dialog';
import { MaintenanceAttachment } from 'src/features/maintenance/api';
import { appConfig } from 'src/app/app-config';
import { ConciergeResponseCode } from 'src/api/concierge';
import {
    addAttachments,
    deleteAttachment,
    setAttachmentError,
    AttachmentError,
    AttachmentsGallery,
} from 'src/components/attachment-gallery';

import { usePatchAttachments } from '../../../hooks/use-patch-attachments';
import { MAINTENANCE_ATTACHMENT_ERROR_KEYS } from '../../../constants/attachments-error-translation-keys';
import { PatchAttachmentsFormData } from '../../../types/create-ticket-form';
import { PatchAttachmentsSchema } from '../../../validation/patch-attachments';
import { PatchAttachmentsFormFields } from '../../../constants/patch-attachments-form';

interface Props {
    attachments: MaintenanceAttachment[];
    ticketId: string;
    open: boolean;
    onClose: VoidFunction;
}

const DEFAULT_PATCH_ATTACHMENT_FORM_VALUES = {
    [PatchAttachmentsFormFields.Attachments]: [],
};

export const AddAttachmentsModal: FC<Props> = ({ attachments, ticketId, open, onClose }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const input = inputRef.current;

    const { control, watch, setError, clearErrors, formState, reset } =
        useForm<PatchAttachmentsFormData>({
            reValidateMode: 'onSubmit',
            resolver: yupResolver(PatchAttachmentsSchema),
            defaultValues: DEFAULT_PATCH_ATTACHMENT_FORM_VALUES,
        });

    const formData = watch();

    const handleFormError = () =>
        setAttachmentError(PatchAttachmentsFormFields.Attachments, setError);

    const { mutate, isLoading } = usePatchAttachments(ticketId, {
        onSuccess: onClose,
        onError: handleFormError,
    });

    const { t } = useTranslation();

    const handleFilesSave = () =>
        mutate({
            oldAttachments: attachments,
            newAttachments: formData[PatchAttachmentsFormFields.Attachments],
        });

    return (
        <Dialog
            open={open}
            loading={isLoading}
            keepMounted
            actions={{
                decline: {
                    children: t('maintenance_attached-files_button_cancel'),
                    onClick: onClose,
                },

                accept: {
                    children: t('maintenance_attached-files_button_attach_files'),
                    onClick: handleFilesSave,
                    disabled:
                        !formData[PatchAttachmentsFormFields.Attachments].length ||
                        !formState.isValid,
                },
            }}
            TransitionProps={{
                onEntered: () => input?.click(),
                onExited: () => reset(),
            }}
            onClose={onClose}
        >
            <Stack flex={1} gap="32px">
                <Typography textAlign="center" variant="h3">
                    {t('maintenance_attached-files_page-title')}
                </Typography>

                <Controller
                    name={PatchAttachmentsFormFields.Attachments}
                    control={control}
                    defaultValue={[]}
                    render={({ field, fieldState }) => (
                        <Fragment>
                            <AttachmentsGallery
                                attachments={field.value}
                                inputRef={inputRef}
                                limit={appConfig.attachments.maxCount}
                                onChange={addAttachments(field, clearErrors, setError)}
                                onDelete={deleteAttachment(field, clearErrors, setError)}
                            />

                            {fieldState.error && (
                                <AttachmentError
                                    translationsMap={MAINTENANCE_ATTACHMENT_ERROR_KEYS}
                                    errorCode={fieldState.error.message as ConciergeResponseCode}
                                />
                            )}
                        </Fragment>
                    )}
                />
            </Stack>
        </Dialog>
    );
};
