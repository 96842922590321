import { FC, ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';

import { EventAttendanceStatus, EventStatus } from '../api';

const EventAttendDescriptionContainer: FC<{ children: ReactNode }> = ({ children }) => {
    const { palette } = useTheme();

    return (
        <Stack direction="row" gap="12px">
            <Box display={{ xs: 'none', lg: 'block' }}>
                <WarningIcon color={palette.common.black} />
            </Box>
            <Typography variant="body2" color="text.secondary">
                {children}
            </Typography>
        </Stack>
    );
};

export const EventAttendDescription: FC<{
    status: EventStatus;
    attendanceStatus: EventAttendanceStatus;
}> = ({ status, attendanceStatus }) => {
    const { t } = useTranslation();

    if (attendanceStatus === EventAttendanceStatus.OnTheWaitingList) {
        return (
            <EventAttendDescriptionContainer>
                {t('events_specific-event_footer_leave_description')}
            </EventAttendDescriptionContainer>
        );
    }

    if (status === EventStatus.AtCapacity && attendanceStatus !== EventAttendanceStatus.Attending) {
        return (
            <EventAttendDescriptionContainer>
                {t('events_specific-event_footer_join_description')}
            </EventAttendDescriptionContainer>
        );
    }

    return null;
};
