import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BuildingIncidentsList } from 'src/features/building-updates';
import { PrivateHat } from 'src/layout/private-hat';

export const MyBuildingIncidentsPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <PrivateHat title={t('building_incidents_title')} backIconText={t('back_cta')} />
            <BuildingIncidentsList />
        </Fragment>
    );
};
