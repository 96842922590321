export enum EventAction {
    Opened = 'Opened',
    Closed = 'Closed',
}

type HistoryAction = 'POP' | 'PUSH' | 'REPLACE';

export type CtaEvent = {
    name: string;
    label: string;
    location: string;
};

export type SelectEvent = {
    name: string;
    option: string;
    location: string;
};

export type AccordionEvent = {
    name: string;
    open: boolean;
    label: string;
    location: string;
};

export type ModalEvent = {
    name: string;
    open: boolean;
    label: string;
    location: string;
};

export type HistoryEvent = {
    name: string;
    from: string;
    to: string;
    action: HistoryAction;
};

export type TabEvent = {
    name: string;
    value: string;
    label: string;
    location: string;
};

export type FilterEvent = {
    name: string;
    value: string;
    location: string;
};

export type DownloadEvent = {
    name: string;
    label: string;
    location: string;
};

export type SubmitEvent = {
    name: string;
    label: string;
    location: string;
};
