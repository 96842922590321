import { FC } from 'react';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Dialog } from 'src/components/dialog';
import { EventType, GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { usePostCancelSocialSpaceBooking } from '../../hooks/use-post-cancel-social-space-booking';

export const CancelBookingModal: FC<{
    isOpen: boolean;
    onClose: VoidFunction;
    bookingId: string;
}> = ({ isOpen, onClose, bookingId }) => {
    const { t } = useTranslation();

    const { isLoading, isSuccess, mutate, isError } = usePostCancelSocialSpaceBooking(bookingId, {
        onSuccess: onClose,
    });

    const handleCancelBooking = () => {
        mutate();

        GA4.event('Cancel Social Space Booking', EventType.Submit, {
            label: t('social_space_cancel_booking_modal_confirm_cta', {
                lng: LanguagesCode.English,
            }),
            location: 'Social Space My Bookings',
            booking: bookingId,
        });
    };

    const dialogContent = isError
        ? {
              decline: t('social_space_cancel_booking_modal_back_cta'),
              accept: t('social_space_cancel_booking_modal_retry_confirm_cta'),
              title: t('social_space_cancel_booking_modal_retry_title'),
          }
        : {
              decline: t('social_space_cancel_booking_modal_back_cta'),
              accept: t('social_space_cancel_booking_modal_confirm_cta'),
              title: t('social_space_cancel_booking_modal_title'),
          };

    return (
        <Dialog
            open={isOpen}
            loading={isLoading || isSuccess}
            onClose={onClose}
            actions={{
                decline: {
                    children: dialogContent.decline,
                    onClick: onClose,
                },
                accept: {
                    children: dialogContent.accept,
                    onClick: handleCancelBooking,
                },
            }}
        >
            <Typography variant="h3" textAlign="center" maxWidth={{ xs: '255px', md: '355px' }}>
                <Trans i18nKey={dialogContent.title} />
            </Typography>
        </Dialog>
    );
};
