import { ConciergeSuccessResponse, conciergeClient } from 'src/api/concierge';

import {
    RebookingFilterOptions,
    RebookingFilterQuery,
    RebookingPhaseInfo,
    RebookingPhaseQuery,
    RebookingRooms,
    RebookingRoomsMeta,
} from './types';
import { RebookingEndpoints } from './constants';

export const getRebookingRooms = async (params: RebookingFilterQuery) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<RebookingRooms, RebookingRoomsMeta>
    >(RebookingEndpoints.GetRebookers, { params });

    return response;
};

export const getRebookingFilterTree = async () => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<RebookingFilterOptions>
    >(RebookingEndpoints.GetRebookersFilterTree);

    return response.data.filter_tree;
};

export const getRebookingPhase = async (params: RebookingPhaseQuery) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<RebookingPhaseInfo>
    >(RebookingEndpoints.GetRebookersPhase, { params });

    return response.data;
};
