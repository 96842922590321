import { FC, useState } from 'react';
import { useTheme } from '@emotion/react';
import { IconButton, Stack, Popover, Fade, Badge } from '@mui/material';

import { useScrollDirection } from 'src/hooks/use-scroll-direction';
import { ReactComponent as NotificationPageIcon } from 'src/assets/svg/nav-notifications.svg';
import {
    NotificationsList,
    useGetUserNotifications,
    NotificationStatus,
} from 'src/features/notifications';
import { useFeatureFlags, UserAvatar } from 'src/features/user';
import { LangSwitcher } from 'src/components/lang-switcher';
import { GA4 } from 'src/lib/ga4';

export const Header: FC = () => {
    const { breakpoints, zIndex, palette, transitions, shadows, custom } = useTheme();

    const [showNotificationModal, setShowNotificationModal] = useState(false);

    const { scrollData, prevScrollData } = useScrollDirection({
        delay: transitions.duration.standard,
    });

    const featureFlags = useFeatureFlags();

    const { data: notifications } = useGetUserNotifications({
        enabled: featureFlags.notifications,
    });

    const unseenMessagesAmount = notifications?.reduce((result, { status }) => {
        const increment = Number(status === NotificationStatus.Unseen);
        return result + increment;
    }, 0);

    const isScrollTop = scrollData.y <= prevScrollData.y;
    const isTop = scrollData.y === 0;

    const toggleModal = () => {
        setShowNotificationModal(!showNotificationModal);

        GA4.modalEvent({
            name: 'Notifications Modal',
            label: 'Notfication Page Icon',
            location: 'Header',
            open: !showNotificationModal,
        });
    };

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            component="header"
            position="sticky"
            top={0}
            width="100%"
            height="80px"
            padding="0 60px"
            css={{
                visibility: isScrollTop ? 'visible' : 'hidden',
                opacity: Number(isScrollTop),
                background: isTop ? undefined : palette.background.default,
                zIndex: zIndex.appBar,
                transition: transitions.create('all'),
                boxShadow: isTop ? undefined : '2px 4px 30px rgba(0, 0, 0, 0.08)',

                [breakpoints.down('md')]: {
                    display: 'none',
                },
            }}
        >
            <Stack
                direction="row"
                flex={1}
                justifyContent="flex-end"
                alignItems="center"
                height="100%"
                gap="32px"
            >
                <LangSwitcher />

                {featureFlags.notifications && (
                    <Badge
                        badgeContent={unseenMessagesAmount}
                        color="primary"
                        overlap="circular"
                        css={{
                            '.MuiBadge-badge': {
                                top: '25%',
                                right: '25%',
                            },
                        }}
                    >
                        <IconButton onClick={toggleModal}>
                            <NotificationPageIcon color={palette.text.primary} />
                        </IconButton>
                    </Badge>
                )}

                <UserAvatar />
            </Stack>

            {featureFlags.notifications && (
                <Popover
                    open={showNotificationModal}
                    anchorEl={document.body}
                    onClose={toggleModal}
                    PaperProps={{
                        square: true,
                    }}
                    TransitionComponent={Fade}
                    css={{
                        '.MuiPopover-paper': {
                            top: `${custom.headerHeight}px !important`,
                            bottom: `${custom.headerHeight}px !important`,
                            left: 'unset !important',
                            right: '40px !important',
                            width: '440px',
                            boxShadow: shadows[1],
                            background: palette.background.default,
                        },
                    }}
                >
                    <NotificationsList isModal onClose={toggleModal} />
                </Popover>
            )}
        </Stack>
    );
};
