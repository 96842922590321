import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { PaymentsEndpoints, MakeMandate, getMakeMandate } from '../api';

import type { AxiosError } from 'axios';

interface Payload {
    bookingId: string;
}

type Config = UseQueryOptions<MakeMandate, AxiosError<ConciergeErrorResponse>>;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const useGetMakeMandate = (config: Config = {}, payload: Payload) =>
    useQuery<MakeMandate, AxiosError<ConciergeErrorResponse>>({
        queryKey: [PaymentsEndpoints.getMakeMandate, payload.bookingId],
        queryFn: () => getMakeMandate(payload.bookingId),
        ...config,
    });
