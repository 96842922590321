import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Interpolation, Theme } from '@emotion/react';

import { routes } from 'src/constants/routes';
import {
    BookASpaceIcon,
    ReportIssueIcon,
    BuildingNavLink,
    BuildingLatestUpdate,
    BuildingFeaturedEvent,
    useGetActiveBuilding,
    BuildingFeaturedOffer,
    BuildingVideosFooter,
} from 'src/features/building';
import { useFeatureFlags } from 'src/features/user';
import { useIsCheckedIn } from 'src/features/bookings';
import { BuildingIncidentsBadge } from 'src/features/building-updates';
import { ReactComponent as FeedbackIcon } from 'src/assets/svg/chat-with-star.svg';
import { GA4 } from 'src/lib/ga4';
import { RebookingBanner } from 'src/features/rebooking';

const getCardCss = ({ breakpoints }: Theme): Interpolation<Theme> => ({
    width: '50%',
    flexShrink: 0,

    [breakpoints.down('lg')]: {
        width: '100%',
    },
});

export const MyBuildingPage: FC = () => {
    const { breakpoints } = useTheme();
    const { t } = useTranslation();

    const { data: activeBuilding } = useGetActiveBuilding();

    const isCheckedIn = useIsCheckedIn();
    const flags = useFeatureFlags();

    const handleFeedbackTitleClick = () =>
        GA4.ctaEvent({
            name: 'Feedback Click',
            label: t('my_building_feedback'),
            location: 'Feedback Card',
        });

    const handleSocialSpaceTitleClick = () =>
        GA4.ctaEvent({
            name: 'Book a Space Click',
            label: t('my_building_book_a_space'),
            location: 'Social Space Card',
        });

    const handleMaintenanceTitleClick = () =>
        GA4.ctaEvent({
            name: 'Maintenance Click',
            label: t('my_building_report_an_issue'),
            location: 'Maintenance Card',
        });

    return (
        <Fragment>
            <Stack
                direction={{ lg: 'row' }}
                alignItems={{ lg: 'flex-end' }}
                gap="20px"
                position="relative"
                padding={{
                    xs: '50px 16px 32px 16px',
                    md: '100px 40px 30px 40px',
                    lg: '100px 60px 30px 60px',
                    xl: '100px 100px 30px 100px',
                }}
            >
                <Box flex={1}>
                    <Typography variant="h4" color="text.secondary" gutterBottom>
                        {t('my_building_your_stay_at')}
                    </Typography>

                    <Typography variant="h1" textTransform="uppercase">
                        {activeBuilding?.title}
                    </Typography>
                </Box>

                {flags.building_incidents && isCheckedIn && <BuildingIncidentsBadge />}
            </Stack>

            <RebookingBanner
                css={{
                    marginBottom: '28px',

                    [breakpoints.up('md')]: {
                        display: 'none',
                    },
                }}
            />

            <Stack
                flex={1}
                direction="column"
                flexWrap={{ lg: 'wrap' }}
                gap={{ xs: '8px', md: '20px 10px', lg: '20px' }}
                padding={{ xs: '0 0 32px 0', md: '30px 40px', lg: '30px 60px', xl: '30px 100px' }}
            >
                <BuildingLatestUpdate
                    checkedIn={isCheckedIn}
                    isFeatureEnabled={flags.building_news}
                    css={getCardCss}
                />

                <BuildingFeaturedEvent
                    checkedIn={isCheckedIn}
                    isFeatureEnabled={flags.events}
                    css={getCardCss}
                />

                <span
                    css={{
                        display: 'none',
                        [breakpoints.up('md')]: {
                            flexGrow: 1,
                            display: 'block',
                            height: '100%',
                            width: '0',
                        },
                    }}
                />

                <BuildingNavLink
                    Icon={FeedbackIcon}
                    title={t('my_building_feedback')}
                    link={{
                        url: routes.feedback.index,
                    }}
                    checkedIn={isCheckedIn}
                    isFeatureEnabled={flags.feedback}
                    onTitleClick={handleFeedbackTitleClick}
                    css={getCardCss}
                />

                <BuildingNavLink
                    Icon={BookASpaceIcon}
                    title={t('my_building_book_a_space')}
                    link={{
                        url: routes.socialSpaces.index,
                    }}
                    checkedIn={isCheckedIn}
                    isFeatureEnabled={flags.social_spaces}
                    onTitleClick={handleSocialSpaceTitleClick}
                    css={getCardCss}
                />

                <BuildingNavLink
                    Icon={ReportIssueIcon}
                    title={t('my_building_report_an_issue')}
                    link={{
                        url: routes.maintenance.index,
                    }}
                    checkedIn={isCheckedIn}
                    isFeatureEnabled={flags.maintenance_tickets}
                    onTitleClick={handleMaintenanceTitleClick}
                    css={getCardCss}
                />

                <BuildingFeaturedOffer
                    checkedIn={isCheckedIn}
                    isFeatureEnabled={flags.offers}
                    css={getCardCss}
                />
            </Stack>
            {flags.building_videos && <BuildingVideosFooter />}
        </Fragment>
    );
};
