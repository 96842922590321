import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

import { useHashAnchor } from 'src/hooks/use-hash-anchor';
import { PostItem, useGetPostById } from 'src/features/community';
import { AppPreloader } from 'src/components/app-preloader';

export const PostIdPage: FC = () => {
    const { id } = useParams();
    const { data: post, isLoading, isError } = useGetPostById(id as string);
    const { palette, breakpoints } = useTheme();

    useHashAnchor();

    if (isError) {
        return (
            <Typography color="error" variant="h2">
                Can not load post#${id}
            </Typography>
        );
    }

    if (isLoading) {
        return <AppPreloader size="big" showLogo />;
    }

    return (
        <div
            css={{
                flex: 1,
                padding: '40px 16px',

                [breakpoints.up('md')]: {
                    padding: 0,
                    background: palette.common.black,
                    color: palette.common.white,
                },
            }}
        >
            <PostItem post={post} mode="page" css={{ margin: 'auto', maxWidth: '720px' }} />
        </div>
    );
};
