import { useQuery } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { useGetActiveBuilding } from 'src/features/building';
import { getLocalIso } from 'src/utils/get-local-iso';
import { useFeatureFlags } from 'src/features/user';

import { RebookingEndpoints } from '../api/constants';
import { getRebookingPhase } from '../api/requests';
import { RebookingPhaseInfo, RebookingPhaseQuery } from '../api/types';

import type { AxiosError } from 'axios';

export const useGetRebookingPhase = () => {
    const { data: building, isError, isSuccess, error } = useGetActiveBuilding();
    const { rebook } = useFeatureFlags();

    const buildingId = building?.id;

    return useQuery<RebookingPhaseInfo, AxiosError<ConciergeErrorResponse>>({
        queryKey: [RebookingEndpoints.GetRebookersPhase, buildingId],
        enabled: rebook && (isError || isSuccess),

        async queryFn() {
            if (isError) {
                throw new Error('Can not get phase because GET: /building failed', {
                    cause: error,
                });
            }

            if (!buildingId) {
                throw new Error('Can not get phase because building_id is undefined');
            }

            const params: RebookingPhaseQuery = {
                building_id: buildingId,
                current_date: getLocalIso(),
            };

            return getRebookingPhase(params);
        },

        onError(err) {
            captureException(err);
        },
    });
};
