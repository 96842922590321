import { conciergeClient, ConciergeSuccessResponse } from 'src/api/concierge';

import { SocialSpacesEndpoints } from './constants';

import type {
    Filters,
    PostSocialSpaceBookingSuccessResponse,
    PostSocialSpaceBookingBody,
    SocialSpaceSlots,
    SocialSpacesList,
    SocialSpaceBookingsList,
    SocialSpaceBooking,
    SocialSpaceDetail,
    GetFiltersTreeParams,
    GetSocialSpaceSlotsParams,
    GetSocialSpacesParams,
} from './types';

export const getSocialSpaces = async (params: GetSocialSpacesParams) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<SocialSpacesList>
    >(SocialSpacesEndpoints.GetSocialSpaces, { params });

    return response.data;
};

export const getFiltersTree = async (params: GetFiltersTreeParams) => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<Filters>>(
        SocialSpacesEndpoints.GetFiltersTree,
        { params }
    );

    return response.data;
};

export const getSocialSpace = async (id: string) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<SocialSpaceDetail>
    >(SocialSpacesEndpoints.GetSocialSpace.replace('{id}', id));

    return response.data;
};

export const getMySocialSpaceBookings = async () => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<SocialSpaceBookingsList>
    >(SocialSpacesEndpoints.GetMySocialSpaceBookings);

    return response.data;
};

export const getSocialSpaceSlots = async (id: string, params: GetSocialSpaceSlotsParams) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<SocialSpaceSlots>
    >(SocialSpacesEndpoints.GetSocialSpaceSlots.replace('{id}', id), { params });

    return response.data;
};

export const postSocialSpaceBooking = async (payload: PostSocialSpaceBookingBody) => {
    const { data: response } = await conciergeClient.post<PostSocialSpaceBookingSuccessResponse>(
        SocialSpacesEndpoints.PostSocialSpaceBooking,
        payload
    );

    return response.data;
};

export const postCancelSocialSpaceBooking = async (id: string) => {
    const { data: response } = await conciergeClient.post<
        ConciergeSuccessResponse<SocialSpaceBooking>
    >(SocialSpacesEndpoints.CancelSocialSpaceBooking.replace('{id}', id));

    return response.data;
};
