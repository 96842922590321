import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { RebookingFilterParams } from '../../api/constants';
import { RebookingFilterOptions } from '../../api/types';
import { rebookingFilterSchema } from '../../validation/rebooking-filter.schema';
import { RebookingFilterForm } from '../../types';
import { useRebookingStore } from '../../store';

import { RebookingFilterSelect } from './rebooking-filter-select';
import { RebookingFilterSearch } from './rebooking-filter-search';

type Filters = RebookingFilterOptions['filter_tree'];

interface Props {
    filters: Filters;
    filterId: string | undefined;
}

type Fields = keyof RebookingFilterForm;

const findCityAndBuilding = (filters: Filters, filterId: string) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const city of filters) {
        const building = city.buildings.find(({ building_id }) => building_id === filterId);

        if (building) {
            return { city, building };
        }
    }

    return null;
};

export const RebookingFilter: FC<Props> = ({ filters, filterId }) => {
    const { t } = useTranslation();

    const { control, watch, reset, formState } = useForm<RebookingFilterForm>({
        mode: 'onChange',
        resolver: yupResolver(rebookingFilterSchema),

        defaultValues: (() => {
            const defaults = filterId && findCityAndBuilding(filters, filterId);

            return defaults
                ? {
                      [RebookingFilterParams.City]: defaults.city,
                      [RebookingFilterParams.BuildingId]: defaults.building,
                  }
                : {
                      [RebookingFilterParams.City]: filters[0],
                      [RebookingFilterParams.BuildingId]: filters[0].buildings[0],
                  };
        })(),
    });

    const formData = watch();

    const { setFilters } = useRebookingStore.getState();

    const selectedCity = formData[RebookingFilterParams.City];
    const selectedBuilding = formData[RebookingFilterParams.BuildingId];
    const selectedRoomType = formData[RebookingFilterParams.RoomType];

    const handleReset = (fields: Fields[]) => () =>
        reset((nextValues) =>
            fields.reduce((accum, value) => {
                if (value === RebookingFilterParams.BuildingId) {
                    // use this to rewrite default values fallback
                    const [fallbackBuilding] = nextValues[RebookingFilterParams.City]!.buildings;
                    accum[value] = fallbackBuilding;
                } else {
                    accum[value] = undefined;
                }

                return accum;
            }, nextValues)
        );

    useEffect(() => {
        if (formState.isValid) {
            setFilters(formData);
        }
    }, [formState.isValid, formData]);

    return (
        <Fragment>
            <RebookingFilterSearch
                id={RebookingFilterParams.RoomCode}
                placeholder={t('rebookers_swap_your_room_search_placeholder')}
                control={control}
            />

            <RebookingFilterSelect
                id={RebookingFilterParams.City}
                labelKey="rebookers_swap_your_room_city_label"
                placeholder={t('rebookers_swap_your_room_city_placeholder')}
                options={filters}
                getOptionKey={(option) => option.city}
                control={control}
                onReset={handleReset([
                    RebookingFilterParams.RoomCode,
                    RebookingFilterParams.BuildingId,
                    RebookingFilterParams.RoomType,
                    RebookingFilterParams.RoomFloor,
                ])}
            />

            <RebookingFilterSelect
                id={RebookingFilterParams.BuildingId}
                labelKey="rebookers_swap_your_room_building_label"
                placeholder={t('rebookers_swap_your_room_building_placeholder')}
                options={selectedCity?.buildings || []}
                getOptionKey={(option) => option.title}
                control={control}
                onReset={handleReset([
                    RebookingFilterParams.RoomCode,
                    RebookingFilterParams.RoomType,
                    RebookingFilterParams.RoomFloor,
                ])}
            />

            <RebookingFilterSelect
                id={RebookingFilterParams.RoomType}
                labelKey="rebookers_swap_your_room_room_type_label"
                placeholder={t('rebookers_swap_your_room_room_type_placeholder')}
                options={selectedBuilding?.room_types || []}
                getOptionKey={(option) => option.room_type}
                control={control}
                onReset={handleReset([
                    RebookingFilterParams.RoomCode,
                    RebookingFilterParams.RoomFloor,
                ])}
            />

            <RebookingFilterSelect
                id={RebookingFilterParams.RoomFloor}
                labelKey="rebookers_swap_your_room_floor_label"
                placeholder={t('rebookers_swap_your _room_floor_placeholder')}
                options={selectedRoomType?.room_floors || []}
                getOptionKey={(option) => String(option)}
                control={control}
                onReset={handleReset([RebookingFilterParams.RoomCode])}
            />
        </Fragment>
    );
};
