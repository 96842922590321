import { enqueueSnackbar } from 'notistack';

import { logger } from 'src/services/logger';

import { NotificationToast } from '../components/notification-toast';

import type { MessagePayload, NextFn } from 'firebase/messaging';

export const handleFcmMessage: NextFn<MessagePayload> = ({ notification, data }) => {
    if (!(notification && data)) {
        logger.error('Notification or data are not defined', { notification, data });
        return;
    }

    const notificationId = data.id;

    enqueueSnackbar({
        key: notificationId,
        persist: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        content: (
            <NotificationToast
                key={notificationId}
                notification={notification}
                data={data}
                duration={10000}
            />
        ),
    });
};
