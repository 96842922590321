import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { PrivateHat } from 'src/layout/private-hat';
import {
    EventCard,
    FeedbackEventsFormFail,
    FeedbackForm,
    FeedbackFormData,
    feedbackFormDefaultValues,
    FeedbackFormFail,
    FeedbackFormFields,
    FeedbackFormSuccess,
    FeedbackSchema,
    Subject,
    SUBJECT_TITLE_TRANSLATION_KEY,
    SubjectValue,
    usePostFeedback,
} from 'src/features/feedback';
import { routes } from 'src/constants/routes';
import { useGetMyPastEvent } from 'src/features/events';
import { AppPreloader } from 'src/components/app-preloader';
import { EventType, GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { setAttachmentError } from 'src/components/attachment-gallery/utils/set-attachment-error';

export const FeedbackEventsFormPage: FC = () => {
    const { t } = useTranslation();

    const { id: eventId } = useParams<{ id: string }>();

    const {
        data: event,
        isLoading: isEventLoading,
        isError: isEventError,
    } = useGetMyPastEvent(eventId!);

    const { control, handleSubmit, formState, setError, clearErrors } = useForm<FeedbackFormData>({
        resolver: yupResolver(FeedbackSchema),
        defaultValues: feedbackFormDefaultValues,
    });

    const {
        mutate,
        isError: isFormError,
        isSuccess: isFeedbackSuccess,
        isLoading: isFeedbackLoading,
        reset: feedbackReset,
        data,
    } = usePostFeedback({
        onError: () => setAttachmentError(FeedbackFormFields.Attachments, setError),
    });

    const handleFormSubmit = handleSubmit((formData) => {
        const submitData = {
            [FeedbackFormFields.Rating]: formData[FeedbackFormFields.Rating]!,
            [FeedbackFormFields.Comment]: formData[FeedbackFormFields.Comment],
            [FeedbackFormFields.Attachments]: formData[FeedbackFormFields.Attachments],
            [FeedbackFormFields.Subject]: SubjectValue.Events,
            [FeedbackFormFields.EventId]: eventId,
        };

        mutate(submitData);

        GA4.event('Feedback Form Submit', EventType.Submit, {
            label: t('feedback_page_cta', { lng: LanguagesCode.English }),
            location: 'Feedback Form',
            data: submitData,
        });
    });

    if (isEventLoading) {
        return <AppPreloader />;
    }

    if (isEventError) {
        return <FeedbackEventsFormFail />;
    }

    if (!event) {
        return (
            <FeedbackEventsFormFail>
                {t('feedback_page_events_error_not-exist-no-access_subtitle')}
            </FeedbackEventsFormFail>
        );
    }

    if (isFormError) {
        return (
            <FeedbackFormFail
                title={t(SUBJECT_TITLE_TRANSLATION_KEY[Subject.Events])}
                onRetry={feedbackReset}
            />
        );
    }

    if (isFeedbackSuccess)
        return (
            <FeedbackFormSuccess subject={Subject.Events} reset={feedbackReset} feedback={data} />
        );

    return (
        <Fragment>
            <PrivateHat
                title={t(SUBJECT_TITLE_TRANSLATION_KEY[Subject.Events])}
                backIconText={t('back_cta')}
            />
            <Stack
                padding={{ xs: '16px', md: '40px' }}
                justifyContent={{ md: 'center' }}
                alignItems="center"
                flexGrow={{ md: 1 }}
            >
                <Stack maxWidth="500px" gap={{ xs: '40px', md: '48px' }}>
                    <EventCard
                        event={event}
                        link={routes.events.id.replace(':id', event.id)}
                        css={{ marginTop: '4px' }}
                    />

                    <Divider />

                    <FeedbackForm
                        onFormSubmit={handleFormSubmit}
                        formState={formState}
                        isLoading={isFeedbackLoading}
                        subject={Subject.Events}
                        setError={setError}
                        clearErrors={clearErrors}
                        control={control}
                    />
                </Stack>
            </Stack>
        </Fragment>
    );
};
