import { FC } from 'react';
import { useTheme } from '@emotion/react';
import { Stack, Typography } from '@mui/material';

import { useDateLocale } from 'src/hooks/use-date-locale';

import { ReactComponent as MailIcon } from '../../../assets/svg/mail.svg';
import { MaintenanceChatMessage } from '../../../api';
import { formatTicketLastMessageDate } from '../../../utils/format-ticket-last-message-date';

export const TicketNotification: FC<{
    lastMessage: MaintenanceChatMessage;
}> = ({ lastMessage }) => {
    const { palette } = useTheme();

    const dateLocale = useDateLocale();

    return (
        <Stack
            direction="row"
            spacing="16px"
            marginTop="28px"
            css={{
                background: palette.background.paper,
                padding: '16px',
                alignItems: 'center',
            }}
        >
            <MailIcon />

            <Stack>
                <Typography
                    component="div"
                    variant="body2"
                    css={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {lastMessage.message}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    {formatTicketLastMessageDate(lastMessage.created_datetime, {
                        locale: dateLocale,
                    })}
                </Typography>
            </Stack>
        </Stack>
    );
};
