import { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';

export const RebookingLinksWrap: FC<{ children: ReactNode }> = ({ children }) => (
    <Stack flex={1} justifyContent="center" alignItems="center">
        <Box
            display="grid"
            width="100%"
            gap={{ xs: '12px', md: '24px' }}
            gridTemplateColumns={{
                xs: '1fr',
                lg: '1fr 1fr',
            }}
            padding={{
                xs: '24px 16px 32px 16px',
                md: '0 30px',
                lg: '0 60px',
            }}
        >
            {children}
        </Box>
    </Stack>
);
