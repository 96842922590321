import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguagesCode } from 'src/i18n/constants';
import { GA4 } from 'src/lib/ga4';

import { LangSwitcherView } from './lang-switcher-view';

export const LangSwitcher = memo<{ className?: string }>(({ className }) => {
    const {
        t,
        i18n: { language, changeLanguage },
    } = useTranslation();

    const handleChange = (nextLang: string) => {
        changeLanguage(nextLang);

        GA4.selectEvent({
            name: 'Language Change',
            location: 'Language Dropdown',
            option: nextLang,
        });
    };

    return (
        <LangSwitcherView
            language={language}
            options={[
                {
                    value: LanguagesCode.English,
                    label: t('language_switcher_english'),
                    code: 'gb',
                },
                {
                    value: LanguagesCode.Spanish,
                    label: t('language_switcher_spanish'),
                    code: 'es',
                },
            ]}
            className={className}
            onChange={handleChange}
        />
    );
});
