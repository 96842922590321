import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';

import { routes } from 'src/constants/routes';
import { useGetActiveBooking } from 'src/features/bookings';
import { StateView } from 'src/components/state-view';
import { Spinner } from 'src/components/spinner';
import { EmptyList } from 'src/components/empty-list/empty-list';

import { useGetBuildingNews } from '../../hooks/use-get-building-news';

import { BuildingNewsItem } from './building-news-item';

export const BuildingNewsList: FC = () => {
    const { data: booking } = useGetActiveBooking();

    const { data: news, isLoading, isError, refetch } = useGetBuildingNews(booking!.id);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleRetry = () => refetch();

    const handleGoBack = () => navigate(routes.myBuilding.index);

    if (isError) {
        return (
            <StateView
                state="fail"
                title={<Trans i18nKey="whats_happening_error_title" />}
                actions={{
                    main: {
                        text: t('whats_happening_error_retry_cta'),
                        callback: handleRetry,
                    },
                    secondary: {
                        text: t('whats_happening_error_back_cta'),
                        callback: handleGoBack,
                    },
                }}
            />
        );
    }

    if (isLoading) {
        return (
            <Stack flex={1} justifyContent="center" alignItems="center">
                <Spinner size="big" />
            </Stack>
        );
    }

    if (!news.length) {
        return <EmptyList mainText={<Trans i18nKey="whats_happening_empty_state_title" />} />;
    }

    return (
        <Box
            flex={1}
            width="100%"
            maxWidth="880px"
            marginX="auto"
            padding={{ xs: '16px 0 0 0', md: '60px' }}
        >
            <Typography
                variant="subtitle2"
                color="text.secondary"
                marginBottom={{ xs: '4px', md: '16px' }}
                paddingLeft={{ xs: '16px', md: '24px' }}
            >
                {t('whats_happening_latest_news')}
            </Typography>

            <Stack width="100%">
                {news.map((update) => (
                    <BuildingNewsItem key={update.id} update={update} />
                ))}
            </Stack>
        </Box>
    );
};
