import { FC, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Stack, Tab, Tabs, TabsProps, Typography } from '@mui/material';

import { useDateLocale } from 'src/hooks/use-date-locale';

import { formatBookingDate } from '../utils/format-booking-date';
import { BookingList } from '../api';

const INSTALLMENTS_ARE_PAID = true;

export const BookingsTabs: FC<{
    bookingId: string;
    bookings: BookingList;
    onBookingIdChange: (id: string) => void;
}> = ({ bookingId, bookings, onBookingIdChange }) => {
    const dateLocale = useDateLocale();
    const { palette, breakpoints } = useTheme();

    const tabsOptions = useMemo(
        () =>
            bookings.map(({ date_from, date_to, id }) => ({
                id,
                label: `${formatBookingDate(date_from, false, dateLocale)} — ${formatBookingDate(
                    date_to,
                    false,
                    dateLocale
                )}`,
                isPaid: INSTALLMENTS_ARE_PAID,
            })),
        [bookings, dateLocale]
    );

    const handleTabChange: TabsProps['onChange'] = (event, id) => onBookingIdChange(id);

    return (
        <Tabs
            value={bookingId}
            onChange={handleTabChange}
            textColor="inherit"
            indicatorColor="primary"
            css={{
                borderBottom: `1px solid ${palette.divider}`,

                '.MuiTab-root': {
                    maxWidth: 'unset',
                    flex: 1,
                },

                [breakpoints.down('md')]: {
                    display: 'none',
                },
            }}
        >
            {tabsOptions.map(({ id, label }) => {
                const isSelected = id === bookingId;

                return (
                    <Tab
                        key={id}
                        value={id}
                        label={
                            <Stack direction="row" gap="16px" alignItems="center">
                                <Typography
                                    variant="body2"
                                    aria-label="booking tab"
                                    color={
                                        isSelected ? palette.text.primary : palette.text.secondary
                                    }
                                    fontWeight={isSelected ? 400 : undefined}
                                >
                                    {label}
                                </Typography>
                            </Stack>
                        }
                        css={{
                            opacity: 1,

                            ':hover [aria-label="booking tab"]': {
                                color: palette.text.primary,
                                fontWeight: 400,
                            },
                        }}
                    />
                );
            })}
        </Tabs>
    );
};
