import { FC } from 'react';
import { Stack, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { format, parseISO } from 'date-fns';

import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { useFilterQueryParameters } from '../../hooks/use-filter-query-parameters';
import { LocationSelector } from '../location-selector/location-selector';
import { DateSelector } from '../date-selector/date-selector';
import { DurationDropdown } from '../duration-dropdown/duration-dropdown';
import { TimeDropdown } from '../time-dropdown/time-dropdown';
import { Filters } from '../../api';
import { getLimitRemaining } from '../../utils/get-limit-remaining';

export const SocialSpacesFilters: FC<{
    filters: Filters;
    isLoading: boolean;
}> = ({ filters, isLoading }) => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    const queryParameters = useFilterQueryParameters();

    const handleSetDate = (newDate: string) => {
        queryParameters.setDate(newDate, 'replaceIn');

        GA4.filterEvent({
            name: 'Social Spaces Date Filter Change',
            location: 'Social Space Filters',
            value: format(parseISO(newDate), 'dd/MM/yyyy'),
        });
    };

    const handleSetTime = (newTime: string | null) => {
        queryParameters.setTime(newTime ?? undefined, 'replaceIn');

        GA4.filterEvent({
            name: 'Social Spaces Time Filter Change',
            location: 'Social Space Filters',
            value: newTime ?? 'Emptied',
        });
    };

    const handleSetDuration = (newDuration: number | null) => {
        queryParameters.setDuration(newDuration ?? undefined, 'replaceIn');

        GA4.filterEvent({
            name: 'Social Spaces Duration Filter Change',
            location: 'Social Space Filters',
            value: newDuration ? `${newDuration}h` : 'Emptied',
        });
    };

    const handleClearFilters = () => {
        queryParameters.setLocations(undefined, 'replaceIn');
        queryParameters.setDate(undefined, 'replaceIn');
        queryParameters.setTime(undefined, 'replaceIn');
        queryParameters.setDuration(undefined, 'replaceIn');

        GA4.ctaEvent({
            name: 'Social Spaces Clear Filters',
            location: 'Social Space Filters',
            label: t('social_space_filter_clear_filters', { lng: LanguagesCode.English }),
        });
    };

    const selectedDate = filters.dates.find(
        (selectorDate) => selectorDate.date === queryParameters.date
    );

    const limitRemaining = getLimitRemaining(selectedDate, filters.two_week_limit_remaining);

    return (
        <Box
            gap="32px"
            display="grid"
            gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr', xl: '3fr 2fr' }}
            marginBottom="auto"
            position="relative"
        >
            <Box display="flex" justifyContent="flex-end" gridColumn={{ xs: '1/2', lg: '1/3' }}>
                <Button
                    css={{
                        alignSelf: 'flex-end',
                        color: palette.text.primary,
                        marginRight: '-8px',
                        marginLeft: 'auto',
                    }}
                    onClick={handleClearFilters}
                >
                    {t('social_space_filter_clear_filters')}
                </Button>
            </Box>

            <Stack width="100%" gap="28px" css={{ overflow: 'hidden' }}>
                {filters.locations && <LocationSelector locations={filters.locations} />}

                <DateSelector
                    selectedDate={selectedDate?.date}
                    options={filters.dates}
                    isShowAllDates
                    onDateChange={handleSetDate}
                />
            </Stack>

            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="15px" alignItems="flex-end">
                <TimeDropdown
                    options={filters.times}
                    selectedTime={queryParameters.time}
                    onTimeChange={handleSetTime}
                    disabled={limitRemaining === 0 || !selectedDate || isLoading}
                />

                <DurationDropdown
                    title={t('social_space_filter_duration')}
                    options={filters.durations}
                    selectedDuration={queryParameters.duration}
                    onDurationChange={handleSetDuration}
                    disabled={limitRemaining === 0 || !selectedDate || isLoading}
                    canDeselect
                />
            </Box>
        </Box>
    );
};
