import { FC } from 'react';
import { IconButton, Stack, Typography, Link, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { LanguagesCode } from 'src/i18n/constants';
import { Image } from 'src/components/image';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { GA4 } from 'src/lib/ga4';
import { useGetCurrentUser, UserDataFields } from 'src/features/user';

import type { Promotion } from '../../../types/promotion';

export const PromotionCard: FC<{ promotion: Promotion }> = ({ promotion }) => {
    const { data: user } = useGetCurrentUser();

    const { palette, breakpoints, transitions } = useTheme();

    const {
        i18n: { language },
    } = useTranslation();

    const languageKey = language as LanguagesCode;

    const handleClick = () =>
        promotion.external_url && GA4.outboundLink('Promotion Click', promotion.external_url);

    const linkProps = promotion.external_url
        ? {
              component: Link,
              href: promotion.external_url
                  .replace('{accountid}', user![UserDataFields.Id])
                  .replace(
                      '{email}',
                      user![UserDataFields.Email]
                          ? encodeURIComponent(user![UserDataFields.Email])
                          : ''
                  ),
              target: '_blank',
          }
        : {};

    return (
        <Stack
            onClick={handleClick}
            {...linkProps}
            css={{
                textDecoration: 'none',

                [breakpoints.down('md')]: {
                    margin: '0 -16px',
                },

                ':hover *.PromotionCardInner__preview img': {
                    transform: 'scale(1.25)',
                },
            }}
        >
            <Image
                alt={promotion.title[languageKey]}
                src={promotion.image_url}
                className="PromotionCardInner__preview"
                css={{
                    height: '180px',

                    [breakpoints.up('md')]: {
                        height: '220px',
                    },

                    [breakpoints.up('lg')]: {
                        height: '260px',
                    },
                    img: {
                        transition: transitions.create('all'),
                    },
                }}
            />

            <Stack
                padding="16px 0"
                flexGrow={1}
                css={{
                    margin: '0 16px',
                    borderBottom: `1px solid ${palette.divider}`,
                    [breakpoints.up('md')]: {
                        margin: '0',
                    },
                }}
            >
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="16px"
                >
                    <Box>
                        <Typography
                            variant="h4"
                            color={palette.text.secondary}
                            textTransform="uppercase"
                            paddingBottom="4px"
                        >
                            {promotion.sub_title[languageKey]}
                        </Typography>

                        <Typography
                            variant="h2"
                            color={palette.text.primary}
                            textTransform="uppercase"
                            paddingBottom="16px"
                        >
                            {promotion.title[languageKey]}
                        </Typography>

                        <span
                            css={{
                                width: '60px',
                                borderBottom: `1px solid ${palette.common.black}`,
                                display: 'block',
                            }}
                        />

                        <Typography
                            variant="h5"
                            color={palette.text.primary}
                            paddingTop="16px"
                            paddingBottom="16px"
                        >
                            {promotion.description[languageKey]}
                        </Typography>
                    </Box>

                    {promotion.external_url && (
                        <IconButton css={{ flexShrink: 0 }}>
                            <ArrowIcon
                                css={{
                                    color: palette.common.black,
                                    width: '24px',
                                    height: '24px',
                                    flexShrink: 0,
                                    alignSelf: 'center',
                                    marginLeft: 'auto',
                                    transform: 'rotate(-90deg)',
                                }}
                            />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
