import * as yup from 'yup';

import { RebookingFilterParams } from '../api/constants';

// We do not display error codes in the layout

export const rebookingFilterSchema = yup.object({
    [RebookingFilterParams.City]: yup.object({
        city: yup.string().typeError(`${RebookingFilterParams.City} - invalid type`).required(),
    }),

    [RebookingFilterParams.BuildingId]: yup.object({
        building_id: yup
            .string()
            .typeError(`${RebookingFilterParams.BuildingId} - invalid type`)
            .required(),
    }),

    [RebookingFilterParams.RoomType]: yup
        .object({
            room_type: yup
                .string()
                .typeError(`${RebookingFilterParams.RoomType} - invalid type`)
                .notRequired(),
        })
        .nullable(),

    [RebookingFilterParams.RoomFloor]: yup
        .string()
        .typeError(`${RebookingFilterParams.RoomFloor} - invalid type`)
        .notRequired(),

    [RebookingFilterParams.RoomCode]: yup
        .string()
        .typeError(`${RebookingFilterParams.RoomCode} - invalid type`)
        .notRequired(),
});
