import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Stack, Typography } from '@mui/material';

import { Spinner } from 'src/components/spinner';

import { MaintenanceTicketStatus } from '../api';
import { ReactComponent as WaitingForResponseIcon } from '../assets/svg/ticket-waiting.svg';
import { ReactComponent as DoneIcon } from '../assets/svg/ticket-done.svg';
import { ReactComponent as NewIcon } from '../assets/svg/ticket-new.svg';

const CHIP_TRANSLATE_MAP = {
    [MaintenanceTicketStatus.InProgress]: 'maintenance_reported-issues_card_status_in-progress',
    [MaintenanceTicketStatus.Done]: 'maintenance_reported-issues_card_status_resolved',
    [MaintenanceTicketStatus.New]: 'maintenance_reported-issues_card_status_new',
    [MaintenanceTicketStatus.ResponseRequired]:
        'maintenance_reported-issues_card_status_waiting-for-response',
};

const ICONS_MAP = {
    [MaintenanceTicketStatus.InProgress]: <Spinner width="24px" height="24px" />,
    [MaintenanceTicketStatus.Done]: <DoneIcon />,
    [MaintenanceTicketStatus.New]: <NewIcon />,
    [MaintenanceTicketStatus.ResponseRequired]: <WaitingForResponseIcon />,
};

export const TicketChip: FC<{ status: MaintenanceTicketStatus }> = ({ status }) => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            css={{
                width: 'fit-content',
                padding: '6px 12px',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '20px',
                background: {
                    [MaintenanceTicketStatus.InProgress]: '#3A86FF',
                    [MaintenanceTicketStatus.Done]: palette.success.dark,
                    [MaintenanceTicketStatus.New]: palette.grey[600],
                    [MaintenanceTicketStatus.ResponseRequired]: palette.primary.main,
                }[status],
                color: palette.common.white,
            }}
        >
            {ICONS_MAP[status]}

            <Typography variant="overlineMedium" color="common.white">
                {t(CHIP_TRANSLATE_MAP[status])}
            </Typography>
        </Stack>
    );
};
