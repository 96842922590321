import { FC, Fragment, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Box, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { PrivateHat } from 'src/layout/private-hat';
import { LanguagesCode } from 'src/i18n/constants';
import { IconLabel } from 'src/components/icon-label';
import { Button } from 'src/components/button';
import {
    BookingConfirmation,
    BookingSummary,
    CapacityIcon,
    DateSelector,
    DurationDropdown,
    LimitAlert,
    LocationIcon as LocationSmallIcon,
    SlotSelector,
    SocialSpaceError,
    useFilterQueryParameters,
    useGetFiltersTree,
    useGetSocialSpace,
    usePostSocialSpaceBooking,
    getLimitRemaining,
} from 'src/features/social-spaces';
import { AppPreloader } from 'src/components/app-preloader';
import { useBreakpointExpandDescription } from 'src/hooks/use-breakpoint-expand-description';
import { Time } from 'src/constants/slots';
import { useFormattedDate } from 'src/hooks/use-formatted-date';
import { StateView } from 'src/components/state-view';
import { routes } from 'src/constants/routes';
import { trimText } from 'src/utils/trim-text';
import { GA4 } from 'src/lib/ga4';

const MAX_DESCRIPTION_LENGTH = 100;

export const SocialSpaceIdPage: FC = () => {
    const { t } = useTranslation();

    const { id } = useParams();

    const {
        i18n: { language },
    } = useTranslation();

    const getFormattedDate = useFormattedDate();

    const languageKey = language as LanguagesCode;

    const { palette } = useTheme();

    const {
        data: space,
        isLoading: isSpaceLoading,
        isError: isSpaceError,
    } = useGetSocialSpace(id!);

    const description = space?.description[languageKey] ?? '';

    const { isTrimmedDescription, expandDescription } = useBreakpointExpandDescription(
        description.length < MAX_DESCRIPTION_LENGTH,
        'md'
    );

    const queryParameters = useFilterQueryParameters();

    const {
        data: filtersData,
        isLoading: isFiltersLoading,
        isError: isFiltersError,
        isFetching: isFiltersFetching,
    } = useGetFiltersTree({ date: queryParameters.date, date_required: true });

    const {
        mutate: mutateBooking,
        isLoading: isBookingLoading,
        isSuccess: isBookingSuccess,
        isError: isBookingError,
        error: bookingError,
        reset: resetBooking,
        data: bookingResponse,
    } = usePostSocialSpaceBooking();

    const navigate = useNavigate();

    const handleDateChange = useCallback((newDate: string) => {
        queryParameters.setTime(null, 'replaceIn');
        queryParameters.setDate(newDate, 'replaceIn');
    }, []);

    const handleGoToSocialSpaces = () => {
        navigate(routes.socialSpaces.index);
    };

    const handleDurationChange = (newDuration: number | null) => {
        queryParameters.setTime(null, 'replaceIn');
        queryParameters.setDuration(newDuration, 'replaceIn');
    };

    const handleTimeChange = (newTime: string) => queryParameters.setTime(newTime, 'replaceIn');

    if (isFiltersError || isSpaceError)
        return (
            <StateView
                state="fail"
                title={<Trans i18nKey="specific_social_space_error" />}
                actions={{
                    main: {
                        text: t('specific_social_space_error_retry_cta'),
                        callback: handleGoToSocialSpaces,
                    },
                }}
            />
        );

    if (isSpaceLoading || ((isFiltersLoading || isFiltersFetching) && !filtersData))
        return <AppPreloader size="small" />;

    // the condition is needed because otherwise filters have to refetch to mark as selected
    const selectedDate =
        filtersData.dates.find(({ date }) => date === queryParameters.date) ??
        filtersData.dates.find(({ selected }) => selected);

    const selectedDuration =
        filtersData.durations.find(
            ({ duration, is_valid }) => duration === queryParameters.duration && is_valid
        ) || filtersData.durations.find(({ is_valid }) => is_valid);

    const selectedTime = queryParameters.time;

    const limitRemaining = getLimitRemaining(selectedDate, filtersData.two_week_limit_remaining);

    const twoWeekLimitResetDate =
        filtersData.two_week_limit_reset_date &&
        getFormattedDate(filtersData.two_week_limit_reset_date);

    const durationOverLimit =
        queryParameters.duration && limitRemaining && queryParameters.duration > limitRemaining;

    // reset selections if no limit remaining and duration is over limit
    if (limitRemaining === 0 || durationOverLimit) {
        queryParameters.setDuration(undefined, 'replaceIn');
        queryParameters.setTime(undefined, 'replaceIn');
    }

    if (isBookingSuccess) {
        return <BookingConfirmation booking={bookingResponse} />;
    }

    if (isBookingError) {
        return (
            <SocialSpaceError
                onResetBooking={resetBooking}
                errorCode={bookingError.response?.data?.code}
            />
        );
    }

    const isOutOfOrder = space.out_of_order;

    const handleSubmit = () => {
        if (isOutOfOrder) {
            return;
        }

        mutateBooking({
            social_space_id: space!.id,
            date: selectedDate!.date,
            duration: selectedDuration!.duration,
            time: selectedTime!,
        });

        GA4.submitEvent({
            name: 'Social Space Booking Submit',
            location: 'Social Space Booking Form',
            label: t('specific_social_space_cta', { lng: LanguagesCode.English }),
        });
    };

    return (
        <Fragment>
            <PrivateHat title={t('social_space_page_title')} backIconText={t('back_cta')} />

            {space.out_of_order && (
                <Alert variant="filled" severity="info" css={{ width: '100%' }}>
                    {t('social_space_out_of_order')}
                </Alert>
            )}

            <Box
                display="grid"
                gridTemplateColumns={{ xs: '1fr', lg: '3fr 2fr' }}
                gridTemplateRows={{ xs: 'auto minmax(auto,1fr)', lg: 'auto' }}
                flexGrow={1}
                position="relative"
            >
                <Stack
                    gridColumn={{ lg: 2 }}
                    gridRow={1}
                    flexGrow={1}
                    gap={{ xs: '24px', md: '32px' }}
                    padding={{ xs: '32px 0', lg: '0 60px' }}
                    margin={{ xs: '0 16px', md: '0 40px', lg: '60px 0' }}
                    borderBottom={{ xs: `1px solid ${palette.common.black}`, lg: 'none' }}
                    borderLeft={{ lg: `1px solid ${palette.divider}` }}
                >
                    <Stack gap="20px">
                        {space.image_url && (
                            <img
                                src={space.image_url}
                                alt="icon"
                                width="24px"
                                height="24px"
                                css={{ objectFit: 'contain' }}
                            />
                        )}

                        <Typography variant="h3" css={{ textTransform: 'uppercase' }}>
                            {space.title}
                        </Typography>
                    </Stack>

                    <Stack direction={{ md: 'row' }} gap={{ xs: '10px', md: '25px' }}>
                        <IconLabel icon={<LocationSmallIcon />}>{space.location}</IconLabel>

                        <IconLabel icon={<CapacityIcon />}>
                            <Trans
                                i18nKey="social_space_occupancy"
                                values={{ count: space.occupancy }}
                            />
                        </IconLabel>
                    </Stack>

                    <Typography variant="body2">
                        {isTrimmedDescription
                            ? trimText(description, MAX_DESCRIPTION_LENGTH)
                            : description}
                        {isTrimmedDescription && (
                            <button
                                type="button"
                                onClick={expandDescription}
                                css={{ background: 'none', border: 'none', cursor: 'pointer' }}
                            >
                                <Typography
                                    variant="body2"
                                    fontWeight="400"
                                    color={palette.text.secondary}
                                >
                                    {t('specific_social_space_description_read_more')}
                                </Typography>
                            </button>
                        )}
                    </Typography>
                </Stack>

                <Stack
                    gridColumn={1}
                    minWidth="100%"
                    gridRow={{ xs: 2, lg: 1 }}
                    gap={{ xs: '24px', md: '32px' }}
                    padding={{
                        xs: '16px 16px 40px 16px',
                        md: '40px 40px 60px 40px',
                        lg: '40px 60px 60px 60px',
                        xl: '40px 100px 60px 100px',
                    }}
                    justifyContent="flex-start"
                >
                    <DateSelector
                        label={t('specific_social_space_select_date')}
                        selectedDate={selectedDate?.date}
                        onDateChange={handleDateChange}
                        options={filtersData.dates}
                        disabled={isOutOfOrder}
                    />

                    <DurationDropdown
                        title={t('specific_social_space_select_duration')}
                        options={filtersData.durations}
                        selectedDuration={selectedDuration?.duration}
                        onDurationChange={handleDurationChange}
                        disabled={
                            limitRemaining === 0 ||
                            isOutOfOrder ||
                            isFiltersFetching ||
                            isFiltersLoading
                        }
                    />

                    <LimitAlert
                        dailyLimitRemaining={selectedDate?.daily_limit_remaining}
                        twoWeekLimitRemaining={filtersData.two_week_limit_remaining}
                        twoWeekLimitResetDate={twoWeekLimitResetDate}
                    />

                    {limitRemaining !== 0 && selectedDate && selectedDuration && !isOutOfOrder && (
                        <SlotSelector
                            socialSpaceId={id!}
                            selectedTime={selectedTime}
                            onTimeChange={handleTimeChange}
                            selectedDate={selectedDate.date}
                            selectedDuration={selectedDuration.duration}
                        />
                    )}

                    <Stack
                        padding={{ xs: '16px', md: '40px' }}
                        marginX={{ xs: '-16px', md: '-40px' }}
                        gap="16px"
                        position={{ xs: 'sticky', lg: 'relative' }}
                        bottom={0}
                        left={0}
                        flexGrow={1}
                        alignItems={{ lg: 'center' }}
                        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
                        css={{
                            background: palette.common.white,
                        }}
                    >
                        {selectedDate && selectedTime && selectedDuration && (
                            <BookingSummary
                                selectedDate={selectedDate.date}
                                selectedTime={selectedTime as Time}
                                selectedDuration={selectedDuration.duration}
                                location={space.location?.toUpperCase()}
                            />
                        )}
                        <Button
                            design="primary"
                            css={{ minWidth: '260px' }}
                            loading={isBookingLoading}
                            disabled={
                                !(
                                    limitRemaining !== 0 &&
                                    selectedDuration &&
                                    selectedDate &&
                                    selectedTime
                                )
                            }
                            onClick={handleSubmit}
                        >
                            {t('specific_social_space_cta')}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Fragment>
    );
};
