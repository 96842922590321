import ReactGA from 'react-ga4';
import { GaOptions } from 'react-ga4/types/ga4';

import {
    AccordionEvent,
    CtaEvent,
    DownloadEvent,
    EventAction,
    FilterEvent,
    HistoryEvent,
    ModalEvent,
    SelectEvent,
    SubmitEvent,
    TabEvent,
} from './types';
import { EventType } from './constants';

let isInitialised = false;

const initialise = (gaId: string, gaOptions?: GaOptions) => {
    ReactGA.initialize(gaId, { gaOptions });
    isInitialised = true;
};

const pageView = (page: string) => {
    if (!isInitialised) return;

    ReactGA.set({ page });
    ReactGA.send({ hitType: 'pageview', page });
};

const event = (name: string, type: EventType | string, params: { [key: string]: unknown }) => {
    if (!isInitialised) return;

    ReactGA.event(name, {
        type,
        ...params,
    });
};

const ctaEvent = ({ name, ...params }: CtaEvent) => {
    ReactGA.event(name, {
        type: EventType.Cta,
        ...params,
    });
};

const selectEvent = ({ name, ...params }: SelectEvent) => {
    ReactGA.event(name, {
        type: EventType.Select,
        ...params,
    });
};

const accordionEvent = ({ name, ...params }: AccordionEvent) => {
    const { open, ...otherParams } = params;

    ReactGA.event(name, {
        type: EventType.Accordion,
        action: open ? EventAction.Opened : EventAction.Closed,
        ...otherParams,
    });
};

const modalEvent = ({ name, ...params }: ModalEvent) => {
    const { open, ...otherParams } = params;

    ReactGA.event(name, {
        type: EventType.Modal,
        action: open ? EventAction.Opened : EventAction.Closed,
        ...otherParams,
    });
};

const historyEvent = ({ name, ...params }: HistoryEvent) => {
    ReactGA.event(name, {
        type: EventType.History,
        ...params,
    });
};

const tabEvent = ({ name, ...params }: TabEvent) => {
    ReactGA.event(name, {
        type: EventType.Tab,
        ...params,
    });
};

const filterEvent = ({ name, ...params }: FilterEvent) => {
    ReactGA.event(name, {
        type: EventType.Filter,
        ...params,
    });
};

const downloadEvent = ({ name, ...params }: DownloadEvent) => {
    ReactGA.event(name, {
        type: EventType.Download,
        ...params,
    });
};

const submitEvent = ({ name, ...params }: SubmitEvent) => {
    ReactGA.event(name, {
        type: EventType.Submit,
        ...params,
    });
};

const setUser = (userId: string) => {
    if (isInitialised) {
        ReactGA.set({ userId });
    }
};

const outboundLink = (name: string, link: string) => {
    ReactGA.event(name, {
        type: EventType.OutboundLink,
        link,
    });
};

export const GA4 = {
    initialise,
    pageView,
    ctaEvent,
    selectEvent,
    accordionEvent,
    modalEvent,
    historyEvent,
    tabEvent,
    filterEvent,
    downloadEvent,
    submitEvent,
    outboundLink,
    event,
    setUser,
};
