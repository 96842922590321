import { FC } from 'react';
import { Alert } from '@mui/material';
import { Trans } from 'react-i18next';

import { ConciergeResponseCode } from 'src/api/concierge';
import { appConfig } from 'src/app/app-config';

const getInterpolationValue = (errorCode: ConciergeResponseCode) => {
    switch (errorCode) {
        case ConciergeResponseCode.MaximumAttachmentsReached:
            return appConfig.attachments.maxCount;
        case ConciergeResponseCode.InvalidUploadFileSize:
            return appConfig.attachments.maxSizeMegabytes;
        default:
            return undefined;
    }
};

export const AttachmentError: FC<{
    translationsMap: Map<ConciergeResponseCode, string>;
    errorCode: ConciergeResponseCode;
}> = ({ translationsMap, errorCode }) => {
    const count = getInterpolationValue(errorCode);

    return (
        <Alert severity="error" css={{ justifyContent: 'unset' }}>
            <Trans
                i18nKey={translationsMap.get(errorCode)}
                values={{
                    count,
                }}
            />
        </Alert>
    );
};
