import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { differenceInCalendarDays, parseISO, format } from 'date-fns';
import { captureException } from '@sentry/react';
import { Stack, Typography } from '@mui/material';

import { routes } from 'src/constants/routes';
import { PaymentStatus } from 'src/features/payments';
import { useDateLocale } from 'src/hooks/use-date-locale';
import { formatAmount } from 'src/utils/format-amount';
import { logger } from 'src/services/logger';
import { GA4, EventType } from 'src/lib/ga4';

import { BookingSummary } from '../../../api';

import { OverviewNotification } from './overview-notification';

export const OverviewContainer: FC<{ bookingSummary: BookingSummary }> = ({ bookingSummary }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const amountRemaining = formatAmount(
        bookingSummary.payment_amount_remaining,
        bookingSummary.payment_currency
    );

    const paymentDueDate = parseISO(bookingSummary.payment_due_date);

    const payInDays = differenceInCalendarDays(paymentDueDate, new Date());
    const overdueDays = -payInDays;
    const paymentDateDueFormatted = format(paymentDueDate, 'do MMMM', {
        locale: dateLocale,
    });

    const link = routes.myBooking.id.replace(':id', bookingSummary.id);

    const handleClick = () =>
        GA4.event('Payment Overdue Card Click', EventType.Cta, {
            status: bookingSummary.payment_status,
            overdueDays,
        });

    const toPayMessage = (
        <Stack
            direction="row"
            alignItems="flex-end"
            flexWrap="wrap"
            gap="0.25em"
            marginBottom="4px"
        >
            <Typography variant="h2" fontSize="40px">
                {amountRemaining}
            </Typography>
            <Typography variant="subtitle1">{t('instalment_amount_due')}</Typography>
        </Stack>
    );

    const paymentIsOverdueDays = (
        <Typography variant="body2" color="text.secondary">
            <Trans i18nKey="instalment_overdue" values={{ number_of_days: overdueDays }}>
                Payment is
                <Typography variant="subtitle2" color="error" component="span">
                    days overdue
                </Typography>
            </Trans>
        </Typography>
    );

    const firstInstalmentDue = (
        <Typography variant="body2" color="text.secondary">
            <Trans
                i18nKey="first_instalment_due"
                values={{
                    number_of_days: payInDays,
                }}
                components={{
                    1: <Typography variant="subtitle2" component="strong" color="text.primary" />,
                }}
            />
        </Typography>
    );

    const paymentDueIn = (
        <Typography variant="body2" color="text.secondary">
            <Trans
                i18nKey="instalment_due"
                values={{
                    number_of_days: payInDays,
                }}
            >
                Payment due in
                <Typography variant="body2" color="text.primary" component="span">
                    days
                </Typography>
            </Trans>
        </Typography>
    );

    switch (bookingSummary.payment_status) {
        // Scenario 1: Direct debit is not set up
        case PaymentStatus.GoCardlessNoMandate:
            return (
                <OverviewNotification
                    severity="error"
                    link={link}
                    year={bookingSummary.year}
                    title={t('direct_debit_set_up').toLocaleUpperCase()}
                    message={
                        <Typography variant="h2">
                            {t('direct_debit_set_up').toLocaleUpperCase()}
                        </Typography>
                    }
                    underMessage={firstInstalmentDue}
                    onClick={handleClick}
                />
            );

        // Scenario 3: Direct Debit setup overdue
        // Scenario 5: Direct Debit setup Failed and there is overdue payment
        case PaymentStatus.GoCardlessOverdue:
        case PaymentStatus.GoCardlessSetupFailedOverdue:
            return (
                <OverviewNotification
                    severity="error"
                    link={link}
                    year={bookingSummary.year}
                    title={t('direct_debit_overdue_payment_status').toLocaleUpperCase()}
                    message={
                        <Typography variant="h2">
                            {t('direct_debit_overdue_payment').toLocaleUpperCase()}
                        </Typography>
                    }
                    underMessage={
                        overdueDays > 0 ? (
                            paymentIsOverdueDays
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                Payment due on{' '}
                                <Typography variant="body2" color="error" component="span">
                                    {paymentDateDueFormatted}
                                </Typography>
                            </Typography>
                        )
                    }
                    onClick={handleClick}
                />
            );

        // Scenario 4: Direct Debit setup Failed (redirect failure scenario) and there are no overdue payment
        case PaymentStatus.GoCardlessSetupFailedNoOverdue:
            return (
                <OverviewNotification
                    severity="error"
                    link={link}
                    year={bookingSummary.year}
                    title={t('direct_debit_set_up_failed_status').toLocaleUpperCase()}
                    message={
                        <Typography variant="h2">
                            {t('direct_debit_set_up_failed').toLocaleUpperCase()}
                        </Typography>
                    }
                    underMessage={firstInstalmentDue}
                    onClick={handleClick}
                />
            );

        // Scenario 6: Direct Debit setup complete, payment has failed and overdue payment
        case PaymentStatus.GoCardlessPaymentFailed:
            return (
                <OverviewNotification
                    severity="error"
                    link={link}
                    year={bookingSummary.year}
                    title={t('direct_debit_payment_failed_status').toLocaleUpperCase()}
                    message={
                        <Typography variant="h2">
                            {t('direct_debit_overdue_payment').toLocaleUpperCase()}
                        </Typography>
                    }
                    underMessage={paymentIsOverdueDays}
                    onClick={handleClick}
                />
            );

        // Scenario 7: Direct Debit setup completed, due instalment in the future
        case PaymentStatus.GoCardlessUpcoming:
            return (
                <OverviewNotification
                    severity="success"
                    link={link}
                    year={bookingSummary.year}
                    title={t('direct_debit_active_status').toLocaleUpperCase()}
                    message={
                        <Stack
                            direction="row"
                            alignItems="flex-end"
                            gap="0.25em"
                            marginBottom="4px"
                        >
                            <Typography variant="h2" fontSize="40px">
                                {amountRemaining}
                            </Typography>
                            <Typography variant="subtitle1">
                                {t('direct_debit_amount_due')}
                            </Typography>
                        </Stack>
                    }
                    underMessage={
                        <Typography variant="body2" color="text.secondary">
                            <Trans
                                i18nKey="next_instalment_due"
                                values={{ date: paymentDateDueFormatted }}
                                components={{
                                    1: (
                                        <Typography
                                            variant="subtitle2"
                                            color="text.primary"
                                            component="strong"
                                        />
                                    ),
                                }}
                            />
                        </Typography>
                    }
                    onClick={handleClick}
                />
            );

        // Scenario 8: Direct Debit cancelled
        case PaymentStatus.GoCardlessCanceled:
            return (
                <OverviewNotification
                    severity="error"
                    link={link}
                    year={bookingSummary.year}
                    title={t('direct_debit_failed_status').toLocaleUpperCase().toLocaleUpperCase()}
                    message={
                        <Typography variant="h2">
                            {t('direct_debit_failed').toLocaleUpperCase()}
                        </Typography>
                    }
                    underMessage={firstInstalmentDue}
                    onClick={handleClick}
                />
            );

        case PaymentStatus.TransfermatePaid:
        case PaymentStatus.GoCardlessPaid:
            return (
                <OverviewNotification
                    link={link}
                    year={bookingSummary.year}
                    message={<Typography variant="h5">{t('instalment_paid')}</Typography>}
                    onClick={handleClick}
                />
            );

        case PaymentStatus.TransfermateOverdue:
            return (
                <OverviewNotification
                    severity="error"
                    link={link}
                    title={t('instalment_overdue_status', {
                        number_of_days: overdueDays,
                    }).toLocaleUpperCase()}
                    year={bookingSummary.year}
                    message={toPayMessage}
                    underMessage={paymentIsOverdueDays}
                    onClick={handleClick}
                />
            );

        case PaymentStatus.TransfermateUpcoming:
            return (
                <OverviewNotification
                    severity="info"
                    link={link}
                    year={bookingSummary.year}
                    message={toPayMessage}
                    underMessage={paymentDueIn}
                    onClick={handleClick}
                />
            );

        case PaymentStatus.TransfermateOnTrack:
            return (
                <OverviewNotification
                    severity="warning"
                    link={link}
                    title={t('instalment_due_status', {
                        number_of_days: payInDays,
                    }).toLocaleUpperCase()}
                    year={bookingSummary.year}
                    message={toPayMessage}
                    underMessage={paymentDueIn}
                    onClick={handleClick}
                />
            );

        default: {
            const msg = `booking overview unknown status`;

            captureException(new Error(msg), {
                extra: {
                    booking_summary: bookingSummary,
                },
            });

            logger.error(msg);

            return null;
        }
    }
};
