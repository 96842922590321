import { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LinkIcon } from 'src/assets/svg/link.svg';
import { BRANDFOLDER_BASE_URL } from 'src/api/concierge';

export const FaqRulesEngagementStrategy: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="16px" marginTop="16px">
            <Link
                target="_blank"
                download
                href={`${BRANDFOLDER_BASE_URL}/2EZAWJN8/at/7c8bmc3xcsnt4gcm2pb4rh4/VS23-24-034_Resident_Engagement_Strategy_5.pdf`}
                display="flex"
                alignItems="center"
                gap="8px"
            >
                <Typography variant="subtitle2">
                    {t('faq_answer_resident_engagement_strategy_link_text')}
                </Typography>
                <LinkIcon />
            </Link>
        </Stack>
    );
};
