import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { SocialSpacesEndpoints, getSocialSpace, SocialSpaceDetail } from '../api';

type Config = UseQueryOptions<SocialSpaceDetail, AxiosError<ConciergeErrorResponse>>;

export const useGetSocialSpace = (id: string, config: Config = {}) => {
    const query = useQuery<SocialSpaceDetail, AxiosError<ConciergeErrorResponse>>({
        queryKey: [SocialSpacesEndpoints.GetSocialSpace, id],
        queryFn: () => getSocialSpace(id),
        ...config,
    });

    return query;
};
