import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { appConfig } from 'src/app/app-config';

import { BookingsEndpoints, getBookingsList, Booking, BookingList } from '../api';

type QueryConfig = UseQueryOptions<
    BookingList,
    AxiosError<ConciergeErrorResponse>,
    Booking | undefined
>;

const selectUpcomingBooking = (bookings: BookingList) =>
    bookings.find(({ year }) => year === appConfig.upcomingYear);

export const useGetUpcomingBooking = (config: QueryConfig = {}) =>
    useQuery<BookingList, AxiosError<ConciergeErrorResponse>, Booking | undefined>({
        queryKey: [BookingsEndpoints.getMyBookings],
        queryFn: getBookingsList,
        select: selectUpcomingBooking,
        ...config,
    });
