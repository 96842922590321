import { FC, useState, RefCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useTheme } from '@emotion/react';
import {
    SwipeableDrawer,
    Stack,
    Typography,
    useMediaQuery,
    IconButton,
    Divider,
} from '@mui/material';

import { PrivateHat } from 'src/layout/private-hat';
import { AppPreloader } from 'src/components/app-preloader';
import { ReactComponent as ControlArrow } from 'src/assets/svg/control-arrow.svg';
import { MaintenanceChat, TicketInfo, useGetTicket } from 'src/features/maintenance';

import { Page404 } from '../page404';

const CHAT_HEADER_HEIGHT = 105; // px

export const MaintenanceIdPage: FC = () => {
    const { data: ticket, isLoading, isError } = useGetTicket();

    const { breakpoints, palette, shadows, custom } = useTheme();
    const showChatModal = useMediaQuery(breakpoints.down('lg'));

    const [isChatOpen] = useQueryParam('is-chat-open', BooleanParam);
    const [showComments, setShowComments] = useState(Boolean(isChatOpen));

    const [container, setContainer] = useState<HTMLDivElement>();
    const [width, setWidth] = useState(0);

    const { t } = useTranslation();

    const toggleComments = () => setShowComments(!showComments);

    const refCallback: RefCallback<HTMLDivElement> = (element) => {
        if (element) {
            setContainer(element);
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (container) {
            const resizeObserver = new ResizeObserver(([entries]) => {
                setWidth(entries.contentRect.width);
            });

            resizeObserver.observe(container);

            return () => resizeObserver.disconnect();
        }
    }, [container]);

    if (isError) {
        return <Page404 />;
    }

    if (isLoading) {
        return <AppPreloader />;
    }

    const messagesLength = (ticket.comments || []).length;

    const chatSection = <MaintenanceChat ticket={ticket} onClose={toggleComments} />;

    return (
        <Stack
            ref={refCallback}
            position="absolute"
            overflow="hidden"
            left={0}
            right={0}
            bottom={0}
            top={0}
            flex={1}
        >
            <PrivateHat
                title={t('maintenance_ticket-page_title', { case_number: ticket.case_number })}
            />

            <Stack
                flex="1 1 min-content"
                direction={{ xs: 'column', lg: 'row' }}
                overflow={showComments ? 'hidden' : 'auto'}
            >
                <TicketInfo ticket={ticket} />

                <Divider
                    orientation="vertical"
                    flexItem
                    css={{
                        margin: '60px 0',
                        [breakpoints.down('lg')]: {
                            display: 'none',
                            margin: '16px 0',
                        },
                    }}
                />

                {!showChatModal && chatSection}
            </Stack>

            {showChatModal && container && (
                <SwipeableDrawer
                    container={container}
                    swipeAreaWidth={CHAT_HEADER_HEIGHT}
                    open={showComments}
                    anchor="bottom"
                    keepMounted
                    disableSwipeToOpen
                    disableDiscovery
                    hideBackdrop
                    onOpen={toggleComments}
                    onClose={toggleComments}
                    PaperProps={{ style: { width } }}
                    style={{ width }}
                    css={{
                        left: 'unset',
                        right: 'unset',

                        '.MuiDrawer-paper': {
                            overflow: 'visible',
                            left: 'unset',
                            right: 'unset',
                            top: CHAT_HEADER_HEIGHT + custom.privateHeaderHeight,
                        },
                    }}
                >
                    <Stack
                        onClick={toggleComments}
                        justifyContent="center"
                        alignItems="center"
                        spacing="8px"
                        css={{
                            background: palette.background.default,
                            top: `-${CHAT_HEADER_HEIGHT}px`,
                            position: 'absolute',
                            padding: '20px 0',
                            visibility: 'visible',
                            pointerEvents: 'all',
                            cursor: 'pointer',
                            left: 0,
                            right: 0,
                            boxShadow: shadows[1],
                            zIndex: 0,
                        }}
                    >
                        <IconButton>
                            <ControlArrow
                                css={{
                                    transform: showComments ? undefined : 'rotate(180deg)',
                                }}
                            />
                        </IconButton>

                        <Typography variant="overlineMedium">
                            {t('maintenance_specific-ticket_chat_comments_title', {
                                count: messagesLength,
                            })}
                        </Typography>
                    </Stack>

                    {chatSection}
                </SwipeableDrawer>
            )}
        </Stack>
    );
};
