import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { routes } from 'src/constants/routes';
import { useGetActiveBooking } from 'src/features/bookings';
import { ASSETS_BASE_URL } from 'src/api/concierge';

import { useGetRebookingPhase } from '../../hooks/use-get-rebooking-room-phase';
import { createTransactionsLink } from '../../utils/create-transactions-link';
import { getPhases } from '../../utils/get-phases';
import { RebookStatus } from '../../api/constants';

import { RebookingLink } from './rebooking-link';
import { RebookingLinkSkeleton } from './rebooking-link-skeleton';
import { RebookingLinksWrap } from './rebooking-links-wrap';
import { RebookingLinkLabel } from './rebooking-link-label';

const getKeepItDescriptionKey = (keepActive: boolean, rebookStatus: RebookStatus) => {
    if (!keepActive) {
        return 'rebookers_keep-it-card_campaign-inactive_description';
    }

    if (rebookStatus === RebookStatus.Ended) {
        return 'rebookers_keep-it-card_campaign-ended_description';
    }

    return 'rebookers_keep-it-card_campaign-active_description';
};

const getSwapItDescriptionKey = (swapActive: boolean, rebookStatus: RebookStatus) => {
    if (rebookStatus === RebookStatus.Ended) {
        return 'rebookers_swap-it-card_campaign-ended_description';
    }

    if (swapActive) {
        return 'rebookers_swap-it-card_campaign-active_description';
    }

    return 'rebookers_swap-it-card_coming-soon_description';
};

export const RebookingLinks: FC = () => {
    const { t } = useTranslation();

    const { data: booking } = useGetActiveBooking();
    const { data: phasesInfo, isLoading, isError } = useGetRebookingPhase();

    const phases = phasesInfo?.phases;
    const rebookStatus = phasesInfo?.rebook_status;

    if (isLoading) {
        return (
            <RebookingLinksWrap>
                <RebookingLinkSkeleton />
                <RebookingLinkSkeleton />
            </RebookingLinksWrap>
        );
    }

    if (
        isError ||
        !phasesInfo.is_available ||
        rebookStatus === RebookStatus.ComingSoon ||
        rebookStatus === RebookStatus.NotAllowed
    ) {
        return <Navigate to={routes.home} />;
    }

    const { swap, keep } = getPhases(phases!);

    const keepLink = createTransactionsLink({
        room_code: booking!.room,
    });

    const jsx = [
        keep && (
            <RebookingLink
                key="keep"
                title={t('rebookers_keep-it-card_title')}
                subtitle={t('rebookers_keep-it-card_subtitle')}
                body={t(getKeepItDescriptionKey(keep.active, rebookStatus!))}
                link={keepLink}
                label={<RebookingLinkLabel phase={keep} />}
                image_srcset={{
                    lg: `${ASSETS_BASE_URL}/images/rebooking-keep-your-room_1366px.png`,
                    xs: `${ASSETS_BASE_URL}/images/rebooking-keep-your-room_375px.png`,
                }}
                disabled={!keep.active}
                ga_name="Keep Room Option Card Click"
            />
        ),

        swap && (
            <RebookingLink
                key="swap"
                title={t('rebookers_swap-it-card_title')}
                subtitle={t('rebookers_keep-it-card_subtitle')}
                body={
                    <Trans
                        i18nKey={getSwapItDescriptionKey(swap.active, rebookStatus!)}
                        components={{ strong: <strong css={{ fontWeight: '400' }} /> }}
                    />
                }
                link={routes.rebooking.swap}
                label={<RebookingLinkLabel phase={swap} />}
                image_srcset={{
                    lg: `${ASSETS_BASE_URL}/images/rebooking-swap-your-room_1366px.png`,
                    xs: `${ASSETS_BASE_URL}/images/rebooking-swap-your-room_375px.png`,
                }}
                disabled={!swap.active}
                ga_name="Swap Room Option Card Click"
            />
        ),
    ];

    if (!keep?.active) {
        jsx.reverse();
    }

    return <RebookingLinksWrap>{jsx}</RebookingLinksWrap>;
};
