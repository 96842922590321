export const DATE_SELECTOR_SWIPER_BREAKPOINTS = {
    420: {
        slidesPerView: 5,
    },
    520: {
        slidesPerView: 6,
    },
    620: {
        slidesPerView: 7,
    },
    768: {
        slidesPerView: 8,
    },
    992: {
        slidesPerView: 9,
    },
    1080: {
        slidesPerView: 10,
    },
    1365: {
        slidesPerView: 7,
    },
    1466: {
        slidesPerView: 8,
    },
    1566: {
        slidesPerView: 9,
    },
    1666: {
        slidesPerView: 10,
    },
    1766: {
        slidesPerView: 11,
    },
    1866: {
        slidesPerView: 12,
    },
    1966: {
        slidesPerView: 13,
    },
    2066: {
        slidesPerView: 14,
    },
};

export const CAROUSEL_SWIPE_ENABLED_LIMIT = Math.max(
    ...Object.keys(DATE_SELECTOR_SWIPER_BREAKPOINTS).map((num) => parseInt(num, 10))
);
