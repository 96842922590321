import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { queryClient } from 'src/lib/query-client';

import { MaintenanceEndpoints, MaintenanceTicket, postCloseTicket } from '../api';

type Config = UseMutationOptions<MaintenanceTicket, AxiosError<ConciergeErrorResponse>>;

export const usePostCloseTicket = (id: string, config: Config = {}) => {
    const { onSuccess, ...restConfig } = config;

    const mutation = useMutation<MaintenanceTicket, AxiosError<ConciergeErrorResponse>>({
        mutationFn: () => postCloseTicket(id),

        onSuccess(data, variables, context) {
            onSuccess?.(data, variables, context);
            queryClient.setQueryData([MaintenanceEndpoints.getTicket, id], data);
            queryClient.invalidateQueries([MaintenanceEndpoints.getTickets]);
        },

        ...restConfig,
    });

    return mutation;
};
