export enum EventType {
    Cta = 'CTA',
    Select = 'Select Change',
    Accordion = 'Accordion State',
    Modal = 'Modal State',
    History = 'History Change',
    Tab = 'Tab Change',
    Submit = 'Submit',
    Filter = 'Filter Change',
    Download = 'Download',
    OutboundLink = 'Outbound Link',
    Exception = 'Exception',
}
