import { FC, ReactNode, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { ReactComponent as ControlArrow } from 'src/assets/svg/control-arrow.svg';

export const QuestionAnswer: FC<{
    question: ReactNode;
    answer: ReactNode;
    onClick?: (isOpened: boolean) => void;
}> = ({ question, answer, onClick }) => {
    const { palette } = useTheme();

    const [expanded, setExpanded] = useState(false);

    const handleClick = () => {
        onClick?.(!expanded);

        setExpanded(!expanded);
    };

    return (
        <Accordion
            disableGutters
            elevation={0}
            css={{ background: 'none' }}
            expanded={expanded}
            onClick={handleClick}
        >
            <AccordionSummary
                expandIcon={<ControlArrow css={{ color: palette.common.black }} />}
                css={{
                    gap: '1em',
                    padding: '24px 0',
                    margin: 0,
                    '.MuiAccordionSummary-content': {
                        margin: 0,
                    },
                }}
            >
                <Typography variant="subtitle1">{question}</Typography>
            </AccordionSummary>

            <AccordionDetails css={{ padding: ' 0 0 24px 0' }}>
                <Typography component="div" variant="body2">
                    {answer}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};
