import { FC, MouseEventHandler, useState } from 'react';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'src/constants/routes';
import { Image } from 'src/components/image';
import { LanguagesCode } from 'src/i18n/constants';
import { IconLabel } from 'src/components/icon-label';
import { useFormattedDate } from 'src/hooks/use-formatted-date';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/calendar.svg';
import { ReactComponent as LocationIcon } from 'src/assets/svg/location.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';

import { Event } from '../../api';
import {
    EVENT_STATUS_ALERT_VIEW,
    EVENT_STATUS_TRANSLATION_KEY,
} from '../../constants/event-status';
import { EventAttendModal } from '../event-attend-modal';
import { EventCancelAttendanceModal } from '../event-cancel-attendance-modal';
import { EventLeaveWaitlistModal } from '../event-leave-waitlist-modal';
import { EventJoinWaitlistModal } from '../event-join-waitlist-modal';
import { EventQuickAttendButton } from '../attend-button/event-quick-atttend-button';

export const EventCard: FC<{ event: Event }> = ({ event }) => {
    const { breakpoints, palette } = useTheme();

    const { t } = useTranslation();

    const getFormattedDate = useFormattedDate();

    const [isCancelAttendanceModalOpen, setIsCancelAttendanceModalOpen] = useState(false);
    const [isLeaveWaitlistModalOpen, setIsLeaveWaitlistModalOpen] = useState(false);
    const [isAttendModalOpen, setIsAttendModalOpen] = useState(false);
    const [isJoinWaitlistModalOpen, setIsJoinWaitlistModalOpen] = useState(false);

    const alertSeverity = EVENT_STATUS_ALERT_VIEW[event.status];

    const title = getPartialTranslation(event.title);
    const description = getPartialTranslation(event.description);

    const handleLinkClick: MouseEventHandler = (e) => {
        const current = e.currentTarget;
        const target = e.target as HTMLElement;

        const isOutsideElement = !current.contains(target);
        const isInteractiveElement = target.closest('button') !== null;

        if (isOutsideElement || isInteractiveElement) {
            e.preventDefault();
        }
    };

    const handleToggleLeaveWaitlistModal = () => {
        setIsLeaveWaitlistModalOpen(!isLeaveWaitlistModalOpen);
    };

    const handleToggleJoinWaitlistModal = () =>
        setIsJoinWaitlistModalOpen(!isJoinWaitlistModalOpen);

    const handleOpenAttendModal: MouseEventHandler = (e) => {
        const target = e.currentTarget as HTMLElement;

        if (target.hasAttribute('href')) {
            e.stopPropagation();
        } else {
            setIsAttendModalOpen(true);

            GA4.ctaEvent({
                name: 'Event Quick Attendance Click',
                location: 'Event Card',
                label: t('event_quick_attend_cta', { lng: LanguagesCode.English }),
            });
        }
    };

    const handleCloseAttendModal = () => setIsAttendModalOpen(false);

    const handleToggleCancelAttendanceModal = () => {
        setIsCancelAttendanceModalOpen(!isCancelAttendanceModalOpen);

        GA4.modalEvent({
            name: 'Events Cancel Attendance Modal',
            location: 'Event Card',
            label: t(
                isCancelAttendanceModalOpen
                    ? 'event_cancel_attendance_modal_back_cta'
                    : 'event_quick_attending_cta',
                { lng: LanguagesCode.English }
            ),
            open: !isCancelAttendanceModalOpen,
        });
    };

    return (
        <Stack
            component={Link}
            to={routes.events.id.replace(':id', event.id)}
            direction={{ lg: 'row' }}
            onClick={handleLinkClick}
            css={{
                textDecoration: 'none',
                color: palette.text.primary,

                [breakpoints.down('lg')]: {
                    ':not(:last-child)::after': {
                        content: '""',
                        display: 'inline-block',
                        width: 'calc(100% - 32px)',
                        height: '1px',
                        margin: 'auto',
                        background: palette.divider,
                    },
                },
            }}
        >
            <Box position="relative" flex="1 1 auto" height={{ xs: '240px', lg: '100%' }}>
                {alertSeverity && (
                    <Alert
                        variant="filled"
                        severity={alertSeverity}
                        css={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '8px 16px',
                        }}
                    >
                        <Typography variant="overlineMedium">
                            {t(EVENT_STATUS_TRANSLATION_KEY[event.status])}
                        </Typography>
                    </Alert>
                )}

                <Image
                    alt={title}
                    src={event.image_url}
                    css={{ position: 'absolute', zIndex: -1 }}
                />
            </Box>

            <Stack
                flex={1}
                padding={{ xs: '20px 16px', md: '28px 40px' }}
                css={{
                    [breakpoints.up('lg')]: {
                        backgroundColor: palette.background.paper,
                    },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="22px"
                    marginBottom="12px"
                >
                    <Typography variant="h2" color={palette.text.primary} textTransform="uppercase">
                        {title}
                    </Typography>

                    <ArrowIcon width="22px" height="22px" css={{ transform: 'rotate(-90deg)' }} />
                </Stack>

                <Typography
                    variant="body2"
                    color={palette.text.secondary}
                    marginBottom="22px"
                    css={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {description}
                </Typography>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="12px"
                >
                    <Stack gap="12px">
                        <IconLabel icon={<CalendarIcon width="22px" height="22px" />}>
                            {getFormattedDate(event.start_date, 'dd.MM.yyyy, HH:mm')}
                        </IconLabel>

                        <IconLabel icon={<LocationIcon width="22px" height="22px" />}>
                            {event.location.first_line}
                        </IconLabel>
                    </Stack>

                    <EventQuickAttendButton
                        status={event.status}
                        attendanceStatus={event.attendance_status!}
                        externalUrl={event.external_event_url}
                        onOpenWaitlistModal={handleToggleJoinWaitlistModal}
                        onOpenLeaveWaitlistModal={handleToggleLeaveWaitlistModal}
                        onOpenAttendModal={handleOpenAttendModal}
                        onOpenCancelAttendanceModal={handleToggleCancelAttendanceModal}
                    />
                </Stack>
            </Stack>

            <EventCancelAttendanceModal
                open={isCancelAttendanceModalOpen}
                id={event.id}
                onClose={handleToggleCancelAttendanceModal}
            />

            <EventLeaveWaitlistModal
                open={isLeaveWaitlistModalOpen}
                id={event.id}
                onClose={handleToggleLeaveWaitlistModal}
            />

            <EventAttendModal
                open={isAttendModalOpen}
                event={event}
                isQuick
                onClose={handleCloseAttendModal}
            />

            <EventJoinWaitlistModal
                open={isJoinWaitlistModalOpen}
                event={event}
                onClose={handleToggleJoinWaitlistModal}
            />
        </Stack>
    );
};
