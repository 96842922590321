import { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { DurationOption } from '../../api';

export const DurationDropdown: FC<{
    title: string;
    options: DurationOption[];
    selectedDuration?: number;
    onDurationChange: (newDuration: number | null) => void;
    disabled?: boolean;
    canDeselect?: boolean;
}> = ({ title, options, selectedDuration, onDurationChange, disabled, canDeselect }) => {
    const { t } = useTranslation();

    const handleChange: SelectProps<string | null>['onChange'] = (event) => {
        const newDuration = event.target.value as string | null;

        onDurationChange(newDuration ? parseInt(newDuration, 10) : null);
    };

    const selectedDurationAsString = selectedDuration?.toString() ?? '';

    return (
        <FormControl disabled={disabled}>
            <InputLabel id="time" variant="standard" shrink>
                {title}
            </InputLabel>

            <InputLabel id="time" variant="standard" aria-label="placeholder label">
                {t('social_space_filter_duration_placeholder')}
            </InputLabel>

            <Select
                variant="standard"
                labelId="time"
                value={selectedDurationAsString}
                onChange={handleChange}
            >
                {canDeselect && (
                    <MenuItem value="">{t('social_space_filters_duration_deselected')}</MenuItem>
                )}
                {options.map(({ duration, is_valid }) => (
                    <MenuItem
                        key={duration}
                        value={duration}
                        disabled={!is_valid}
                        css={{
                            '&.Mui-disabled': {
                                textDecoration: 'line-through',
                            },
                        }}
                    >
                        <Trans
                            i18nKey="social_space_filter_time_option"
                            values={{ count: duration }}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
