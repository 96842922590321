import * as yup from 'yup';
import { differenceInCalendarYears, parseISO, isValid } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { PhoneNumber, UserDataFields, universitiesCountryMap } from '../api';
import { phoneNumberPlus } from '../../../utils/phone-number-plus';

const PHONE_NUMBER_MESSAGE = 'profile_validation_phone-number_error';
const GENDER_MESSAGE = 'profile_validation_gender_error';
const DATE_OF_BIRTH_MESSAGE = 'profile_validation_date-of-birth-date-error';
const ADDRESS_COUNTRY_MESSAGE = 'profile_validation_country_error';
const ADDRESS_CITY_MESSAGE = 'profile_validation_address-city_error';
const ADDRESS_HOME_MESSAGE = 'profile_validation_home-address_error';
const ADDRESS_POSTCODE_MESSAGE = 'profile_validation_address-postcode_error';
const LANGUAGE_MESSAGE = 'profile_validation_preferred-language_error';
const NATIONALITY_MESSAGE = 'profile_validation_nationality_error';
const UNIVERSITY_MESSAGE = 'profile_validation_university_error';
const UNIVERSITY_OUT_OF_ENUM_MESSAGE = 'profile_validation_university_error_out-of-enum';
const UNIVERSITY_YEAR_MESSAGE = 'profile_validation_year-of-study_error';
const COURSE_MESSAGE = 'profile_validation_course_error';
const EMERGENCY_CONTACT_NAME_MESSAGE = 'profile_validation_emergency-contact-name_error';
const EMERGENCY_CONTACT_PHONE_MESSAGE = 'profile_validation_emergency-contact-number_error';

const PHONE_NUMBER_MIN_LENGTH = 5;

const DEFAULT_SF_TEXT_LENGTH_LIMIT = 80;

const ALLOWED_UNIVERSITIES = [...universitiesCountryMap.en, ...universitiesCountryMap.es];

const validatePhoneNumber = (isRequired: boolean) => (phoneNumber?: PhoneNumber | null) => {
    const number = phoneNumber?.number || '';
    const callingCode = phoneNumber?.callingCode || '';
    const isNumberEmpty = !number || number === '+';

    if (!isRequired && isNumberEmpty) {
        return true;
    }

    if (isNumberEmpty) {
        return false;
    }

    if (callingCode) {
        const numberAndCallingCode = phoneNumberPlus(phoneNumberPlus(callingCode + number));
        return isValidPhoneNumber(numberAndCallingCode); // if phone number includes the code, use lib validation
    }

    return number.length > PHONE_NUMBER_MIN_LENGTH; // otherwise we use simple length validation
};
export const EditUserSchema = yup.object({
    [UserDataFields.PhoneNumber]: yup
        .mixed<PhoneNumber>()
        .typeError(PHONE_NUMBER_MESSAGE)
        .required(PHONE_NUMBER_MESSAGE)
        .test({ message: PHONE_NUMBER_MESSAGE, test: validatePhoneNumber(true) }),

    [UserDataFields.BirthDate]: yup
        .string()
        .typeError(DATE_OF_BIRTH_MESSAGE)
        .required(DATE_OF_BIRTH_MESSAGE)
        .test({
            message: DATE_OF_BIRTH_MESSAGE,
            test: (iso, ctx) => {
                const date = parseISO(iso!);

                if (!isValid(date) || differenceInCalendarYears(new Date(), date) < 16) {
                    return ctx.createError({ message: DATE_OF_BIRTH_MESSAGE });
                }

                return true;
            },
        }),

    [UserDataFields.Language]: yup.string().typeError(LANGUAGE_MESSAGE).required(LANGUAGE_MESSAGE),

    [UserDataFields.Nationality]: yup
        .string()
        .typeError(NATIONALITY_MESSAGE)
        .required(NATIONALITY_MESSAGE),

    [UserDataFields.Gender]: yup.string().nullable().typeError(GENDER_MESSAGE),

    [UserDataFields.University]: yup
        .string()
        .typeError(UNIVERSITY_MESSAGE)
        .required(UNIVERSITY_MESSAGE)
        .oneOf(ALLOWED_UNIVERSITIES, UNIVERSITY_OUT_OF_ENUM_MESSAGE),

    [UserDataFields.Course]: yup
        .string()
        .nullable()
        .typeError(COURSE_MESSAGE)
        .max(DEFAULT_SF_TEXT_LENGTH_LIMIT, COURSE_MESSAGE),

    [UserDataFields.UniversityYear]: yup
        .string()
        .typeError(UNIVERSITY_YEAR_MESSAGE)
        .required(UNIVERSITY_YEAR_MESSAGE),

    [UserDataFields.EmergencyContactName]: yup
        .string()
        .nullable()
        .typeError(EMERGENCY_CONTACT_NAME_MESSAGE)
        .max(DEFAULT_SF_TEXT_LENGTH_LIMIT, EMERGENCY_CONTACT_NAME_MESSAGE),

    [UserDataFields.AddressCountry]: yup
        .string()
        .typeError(ADDRESS_COUNTRY_MESSAGE)
        .required(ADDRESS_COUNTRY_MESSAGE),

    [UserDataFields.AddressCity]: yup
        .string()
        .typeError(ADDRESS_CITY_MESSAGE)
        .required(ADDRESS_CITY_MESSAGE)
        .max(40, ADDRESS_CITY_MESSAGE),

    [UserDataFields.AddressPostalCode]: yup
        .string()
        .typeError(ADDRESS_POSTCODE_MESSAGE)
        .required(ADDRESS_POSTCODE_MESSAGE)
        .max(20, ADDRESS_POSTCODE_MESSAGE),

    [UserDataFields.AddressStreet]: yup
        .string()
        .typeError(ADDRESS_HOME_MESSAGE)
        .required(ADDRESS_HOME_MESSAGE)
        .max(DEFAULT_SF_TEXT_LENGTH_LIMIT, ADDRESS_HOME_MESSAGE),

    [UserDataFields.EmergencyContactPhone]: yup
        .mixed<PhoneNumber>()
        .optional()
        .test({
            message: EMERGENCY_CONTACT_PHONE_MESSAGE,
            test: validatePhoneNumber(false),
        }),
});
