export enum LanguagesCode {
    English = 'en',
    Spanish = 'es',
}

export const LanguagesCodes = Object.values(LanguagesCode);

export const FALLBACK_LNG = LanguagesCode.English;

export const LANGUAGES_IMPORT_MAP = {
    [LanguagesCode.Spanish]: () => import('./locales/es.json'),
    [LanguagesCode.English]: () => import('./locales/en.json'),
};
