import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Dialog } from 'src/components/dialog';
import { ErrorState } from 'src/components/error-state';

import { usePostAttendEvent } from '../hooks/use-post-attend-event';
import { Event } from '../api';

export const EventJoinWaitlistModal: FC<{
    open: boolean;
    event: Event;
    onClose: VoidFunction;
}> = ({ open, event, onClose }) => {
    const { t } = useTranslation();

    const { isLoading, isSuccess, mutate, isError } = usePostAttendEvent(event.id, {
        onSuccess: onClose,
    });

    const handleSubmit = () => mutate();

    return (
        <Dialog
            open={open}
            loading={isLoading || isSuccess}
            actions={{
                accept: {
                    children: t('event_error_cta_try_again'),
                    onClick: handleSubmit,
                },

                decline: {
                    children: t('event_error_cta_back_to_event'),
                    onClick: onClose,
                },
            }}
            TransitionProps={{
                onEnter: handleSubmit,
            }}
            onClose={onClose}
            css={{
                '.MuiPaper-root': {
                    minHeight: '600px',
                },
            }}
        >
            {isError && (
                <ErrorState title={<Trans i18nKey="general_error_something_went_wrong_title" />} />
            )}
        </Dialog>
    );
};
