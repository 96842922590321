import { FC } from 'react';
import { Divider, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';

import { PostsList, PostsFilter } from 'src/features/community';

export const CommunityPage: FC = () => {
    const { custom, breakpoints } = useTheme();

    return (
        <Stack
            flex={1}
            direction={{ xs: 'column', lg: 'row-reverse' }}
            justifyContent="flex-start"
            width="100%"
            maxWidth="1221px"
            gap={{ xs: '40px', lg: '60px' }}
            marginX="auto"
            position="relative"
            paddingTop={{
                xs: `${custom.headerHeight}px`,
                md: `${custom.headerHeight + 72}px`,
                lg: `${custom.headerHeight + 32}px`,
            }}
            paddingBottom="32px"
        >
            <PostsFilter />

            <Divider
                flexItem
                orientation="vertical"
                css={{
                    position: 'sticky',
                    top: '40px',
                    height: 'calc(100vh - 80px)',
                    [breakpoints.down('lg')]: { display: 'none' },
                }}
            />

            <PostsList />
        </Stack>
    );
};
