import { useQuery } from '@tanstack/react-query';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { PaymentsEndpoints, PaymentsReceiptPdf, getPaymentReceiptPDF } from '../api';

import type { AxiosError } from 'axios';

const selectFile = (encodedString: PaymentsReceiptPdf) => {
    const decodedString = atob(encodedString);
    const byteArray = Uint8Array.from(decodedString, (char) => char.charCodeAt(0));

    return new Blob([byteArray], { type: 'application/pdf' });
};

export const useGetPaymentsReceiptPdf = (id: string) =>
    useQuery<PaymentsReceiptPdf, AxiosError<ConciergeErrorResponse>, Blob>({
        queryKey: [PaymentsEndpoints.getPaymentReceiptPDF, id],
        queryFn: () => getPaymentReceiptPDF(id),

        enabled: false,
        cacheTime: 0,

        select: selectFile,
    });
