import { FC, Fragment, useEffect } from 'react';
import { Route, Routes, useNavigationType, NavigationType, useLocation } from 'react-router-dom';

import { routes } from 'src/constants/routes';
import { HomePage } from 'src/pages/home-page/home.page';
import { CommunityPage } from 'src/pages/community/community.page';
import { PostIdPage } from 'src/pages/community/post-id.page';
import { NotificationsPage } from 'src/pages/notifications.page';
import { ProfilePage } from 'src/pages/profile/profile.page';
import { MyBookingsPage } from 'src/pages/my-booking.page';
import { MaintenancePage } from 'src/pages/maintenance/maintenance.page';
import { MaintenanceIdPage } from 'src/pages/maintenance/maintenance-id.page';
import { SupportHubPage } from 'src/pages/support-hub/support-hub.page';
import { MoveInDayPage } from 'src/pages/move-in-day.page';
import { MoveInWeekPage } from 'src/pages/move-in-week.page';
import { useFeatureFlags } from 'src/features/user';
import { Page404 } from 'src/pages/page404';
import { EventsPage } from 'src/pages/events/events.page';
import { EventIdPage } from 'src/pages/events/event-id.page';
import { SocialSpacesPage } from 'src/pages/social-spaces/social-spaces.page';
import { SocialSpaceIdPage } from 'src/pages/social-spaces/social-space-id.page';
import { SocialSpaceBookingsPage } from 'src/pages/social-spaces/social-space-bookings.page';
import { useIsCheckedIn } from 'src/features/bookings';
import { MyBuildingPage } from 'src/pages/my-building/my-building.page';
import { MyBuildingNewsPage } from 'src/pages/my-building/my-building-news.page';
import { MyEventsPage } from 'src/pages/events/my-events.page';
import { MyBuildingIncidentsPage } from 'src/pages/my-building/my-building-incidents';
import { MyBuildingOffersPage } from 'src/pages/my-building/my-building-offers.page';
import { EventAttendSuccessPage } from 'src/pages/events/event-atttend-success.page';
import { RebookingSwapPage } from 'src/pages/rebooking/rebooking-swap.page';
import { RebookingPage } from 'src/pages/rebooking/rebooking.page';
import { FeedbackSubjectPage } from 'src/pages/feedback/feedback-subject.page';
import { FeedbackFormPage } from 'src/pages/feedback/feedback-form.page';
import { FeedbackStaffPage } from 'src/pages/feedback/feedback-staff.page';
import { FeedbackEventsPage } from 'src/pages/feedback/feedback-events.page';
import { FeedbackEventsFormPage } from 'src/pages/feedback/feedback-events-form.page';
import { EditProfilePage } from 'src/pages/profile/edit-profile.page';
import { SupportHubSearchPage } from 'src/pages/support-hub/support-hub-search.page';
import { GuidesPage } from 'src/pages/guides.page';
import { LogoutPage } from 'src/pages/logout.page';

export const InnerRouting: FC = () => {
    const featureFlags = useFeatureFlags();
    const isCheckedIn = useIsCheckedIn();

    const location = useLocation();
    const routerAction = useNavigationType();

    const isPushAction = routerAction === NavigationType.Push;

    // Scrolls to the top, when the page is changed (not search or hash). Only on "PUSH" to keep default browser scroll keeping for "POP"
    useEffect(() => {
        if (isPushAction) {
            window.scrollTo(0, 0);
        }
    }, [isPushAction, location.pathname]);

    return (
        <Routes location={location}>
            <Route index element={<HomePage />} />

            <Route path={routes.home} element={<HomePage />} />

            {featureFlags.notifications && (
                <Route path={routes.notifications.index} element={<NotificationsPage />} />
            )}

            <Route path={routes.profile.index} element={<ProfilePage />} />
            <Route path={routes.profile.edit} element={<EditProfilePage />} />

            <Route path={routes.myBooking.index} element={<MyBookingsPage />} />
            <Route path={routes.myBooking.id} element={<MyBookingsPage />} />

            {featureFlags.events && isCheckedIn && (
                <Fragment>
                    <Route path={routes.events.index} element={<EventsPage />} />
                    <Route path={routes.events.my} element={<MyEventsPage />} />
                    <Route
                        path={routes.events.attendSuccess}
                        element={<EventAttendSuccessPage />}
                    />
                    <Route path={routes.events.id} element={<EventIdPage />} />
                </Fragment>
            )}

            {featureFlags.social_spaces && isCheckedIn && (
                <Fragment>
                    <Route path={routes.socialSpaces.index} element={<SocialSpacesPage />} />
                    <Route
                        path={routes.socialSpaces.myBookings}
                        element={<SocialSpaceBookingsPage />}
                    />
                    <Route path={routes.socialSpaces.id} element={<SocialSpaceIdPage />} />
                </Fragment>
            )}

            <Route path={routes.supportHub.index} element={<SupportHubPage />} />
            <Route path={routes.supportHub.search} element={<SupportHubSearchPage />} />

            {featureFlags.posts && (
                <Fragment>
                    <Route path={routes.community.index} element={<CommunityPage />} />
                    <Route path={routes.community.id} element={<PostIdPage />} />
                </Fragment>
            )}

            <Route path={routes.myBuilding.index} element={<MyBuildingPage />} />

            {featureFlags.building_news && isCheckedIn && (
                <Route path={routes.myBuilding.news} element={<MyBuildingNewsPage />} />
            )}

            {featureFlags.building_incidents && isCheckedIn && (
                <Route path={routes.myBuilding.incidents} element={<MyBuildingIncidentsPage />} />
            )}

            {featureFlags.offers && isCheckedIn && (
                <Route path={routes.myBuilding.offers} element={<MyBuildingOffersPage />} />
            )}

            {featureFlags.maintenance_tickets && isCheckedIn && (
                <Fragment>
                    <Route path={routes.maintenance.index} element={<MaintenancePage />} />
                    <Route path={routes.maintenance.id} element={<MaintenanceIdPage />} />
                </Fragment>
            )}

            {featureFlags.move_in && (
                <Route path={routes.moveInDay.index} element={<MoveInDayPage />} />
            )}

            {featureFlags.move_in && (
                <Route path={routes.moveInWeek.index} element={<MoveInWeekPage />} />
            )}

            {featureFlags.rebook && (
                <Fragment>
                    <Route path={routes.rebooking.index} element={<RebookingPage />} />
                    <Route path={routes.rebooking.swap} element={<RebookingSwapPage />} />
                </Fragment>
            )}

            {featureFlags.feedback && isCheckedIn && (
                <Fragment>
                    <Route path={routes.feedback.index} element={<FeedbackSubjectPage />} />

                    <Route path={routes.feedback.staff.index} element={<FeedbackStaffPage />} />
                    <Route path={routes.feedback.staff.category} element={<FeedbackFormPage />} />

                    <Route path={routes.feedback.events.index} element={<FeedbackEventsPage />} />
                    <Route path={routes.feedback.events.id} element={<FeedbackEventsFormPage />} />

                    <Route path={routes.feedback.subject} element={<FeedbackFormPage />} />
                </Fragment>
            )}

            {featureFlags.building_videos && (
                <Route path={routes.guides.index} element={<GuidesPage />} />
            )}

            <Route path={routes.logout} element={<LogoutPage />} />

            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};
