import { FC } from 'react';
import { Typography } from '@mui/material';

import { IconLabel } from 'src/components/icon-label';
import { ReactComponent as LocationIcon } from 'src/assets/svg/location.svg';

export const LocationLabel: FC<{ firstLine: string; secondLine: string; className?: string }> = ({
    firstLine,
    secondLine,
    className,
}) => (
    <IconLabel icon={<LocationIcon width="24px" />} className={className}>
        {firstLine}{' '}
        <Typography variant="body2" color="text.secondary">
            {secondLine}
        </Typography>
    </IconLabel>
);
