import { FC, Suspense, useEffect } from 'react';
import { NavigationType, Route, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { withSentryReactRouterV6Routing } from '@sentry/react';

import { AppPreloader } from 'src/components/app-preloader';
import { routes } from 'src/constants/routes';
import { Auth0Error } from 'src/features/auth';
import { historyService } from 'src/services/history.service';
import { GA4 } from 'src/lib/ga4';

import { Main } from './main';
import { appConfig } from './app-config';

import 'swiper/css';

if (appConfig.ga.enabled && appConfig.ga.id) {
    GA4.initialise(appConfig.ga.id);
}

const SentryRoutes =
    process.env.NODE_ENV === 'production' ? withSentryReactRouterV6Routing(Routes) : Routes;

export const App: FC = () => {
    const { key } = useLocation();
    const navigationType = useNavigationType();

    useEffect(() => {
        if (navigationType === NavigationType.Push) {
            historyService.push();
        } else if (navigationType === NavigationType.Pop) {
            historyService.pop();
        }
    }, [key, navigationType]);

    useEffect(() => {
        const page = window.location.href;

        GA4.pageView(page);
    }, [key]);

    return (
        <Suspense fallback={<AppPreloader size="big" showLogo css={{ flex: 1 }} />}>
            <SentryRoutes>
                <Route path={routes.auth0InternalErrorPage} element={<Auth0Error />} />
                <Route path="*" element={<Main />} />
            </SentryRoutes>
        </Suspense>
    );
};
