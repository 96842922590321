import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { BookingsEndpoints, getBookingsList, BookingList } from '../api';

type QueryConfig = UseQueryOptions<BookingList, AxiosError<ConciergeErrorResponse>>;

export const useGetBookings = (config: QueryConfig = {}) => {
    const userQuery = useQuery<BookingList, AxiosError<ConciergeErrorResponse>>({
        queryKey: [BookingsEndpoints.getMyBookings],
        queryFn: getBookingsList,
        ...config,
    });

    return userQuery;
};
