import { createTheme } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { ReactComponent as AvatarPlaceholder } from 'src/assets/svg/avatar-placeholder.svg';
import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/svg/success-in-circle.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'src/assets/svg/checkbox-checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'src/assets/svg/checkbox-unchecked.svg';

import {
    CUSTOM_VARIABLES,
    PRIMARY_FONT_FAMILY_2,
    SECONDARY_FONT_FAMILY_2,
    breakpoints,
    palette2 as palette,
} from './constants';

const breakpointTheme = createTheme({ breakpoints });

const TABLET_MEDIA_QUERY = breakpointTheme.breakpoints.up('md');

export const theme2 = createTheme({
    palette,

    shape: { borderRadius: 8 },

    breakpoints,

    typography: {
        fontFamily: PRIMARY_FONT_FAMILY_2,

        h1: {
            fontSize: '44px',
            lineHeight: '100%',
            fontWeight: 700,
            fontFamily: 'Times New Roman, -apple-system, sans-serif',
            letterSpacing: 0,

            [TABLET_MEDIA_QUERY]: {
                fontSize: '72px',
            },
        },

        h2: {
            fontSize: '28px',
            lineHeight: '100%',
            fontWeight: 700,
            fontFamily: SECONDARY_FONT_FAMILY_2,
            letterSpacing: 0,
        },

        h3: {
            fontSize: '21px',
            lineHeight: '100%',
            fontWeight: 700,
            fontFamily: SECONDARY_FONT_FAMILY_2,
            letterSpacing: 0,
        },

        h4: {
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '100%',
            fontFamily: SECONDARY_FONT_FAMILY_2,
            letterSpacing: 0,
        },

        h5: {
            fontWeight: 300,
            fontSize: '18px',
            lineHeight: '150%',
            letterSpacing: '0.03em',
            fontFamily: PRIMARY_FONT_FAMILY_2,
        },

        /** NOT specified with UI-kit. Temporary solution */
        h6: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '100%',
            letterSpacing: '0.03em',
            fontFamily: SECONDARY_FONT_FAMILY_2,
        },

        subtitle1: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '140%',
            letterSpacing: '0.03em',
            fontFamily: PRIMARY_FONT_FAMILY_2,
        },

        body1: {
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '140%',
            letterSpacing: '0.03em',
            fontFamily: PRIMARY_FONT_FAMILY_2,
        },

        subtitle2: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '140%',
            letterSpacing: '0.03em',
            fontFamily: PRIMARY_FONT_FAMILY_2,
        },

        body2: {
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '0.03em',
            fontFamily: PRIMARY_FONT_FAMILY_2,
        },

        subtitle3: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '140%',
            letterSpacing: '0.03em',
            fontFamily: PRIMARY_FONT_FAMILY_2,
            [TABLET_MEDIA_QUERY]: {
                fontSize: '16px',
            },
        },

        body3: {
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '0.03em',
            [TABLET_MEDIA_QUERY]: {
                fontSize: '16px',
                lineHeight: '150%',
            },
        },

        overlineMedium: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '125%',
            letterSpacing: '0.025em',
            textTransform: 'none',
            fontFamily: PRIMARY_FONT_FAMILY_2,
        },

        overlineRegular: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '125%',
            letterSpacing: '0.025em',
            textTransform: 'none',
            fontFamily: PRIMARY_FONT_FAMILY_2,
            [TABLET_MEDIA_QUERY]: {
                fontWeight: 400,
            },
        },

        button: {
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '100%',
            letterSpacing: '0.03em',
            textTransform: 'none',
            fontFamily: SECONDARY_FONT_FAMILY_2,
        },
    },

    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },

        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    success: <SuccessIcon width="24px" height="24px" />,
                    error: <WarningIcon width="24px" height="24px" />,
                    info: <WarningIcon width="24px" height="24px" />,
                    warning: <WarningIcon width="24px" height="24px" />,
                },
            },

            styleOverrides: {
                standardError: {
                    color: palette.error.main,
                    backgroundColor: palette.error.light,
                    padding: '8px 16px',
                    borderRadius: '0',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '15px',
                    minHeight: '52px',
                },

                standardWarning: {
                    backgroundColor: palette.warning.light,
                    color: palette.text.primary,
                },

                filled: {
                    padding: '10px',
                    borderRadius: '0',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    fontWeight: '500',
                },

                filledInfo: {
                    color: palette.primary.contrastText,
                    backgroundColor: palette.primary.main,
                },

                filledWarning: {
                    color: palette.text.primary,
                    backgroundColor: palette.warning.dark,
                },

                filledSuccess: {
                    color: palette.success.contrastText,
                    backgroundColor: palette.success.dark,
                },

                icon: {
                    padding: 0,
                    marginRight: 0,
                    width: '24px',
                    height: '24px',
                    color: 'inherit !important',
                },

                message: {
                    padding: 0,
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: palette.primary.main,
                },
            },
        },

        MuiSelect: {
            defaultProps: {
                IconComponent: ArrowIcon,
                disableUnderline: true,
            },
            styleOverrides: {
                icon: {
                    width: '18px',
                    height: '18px',
                    color: palette.common.black,
                },
                select: {
                    padding: '10px 0',
                    background: 'none',
                },
            },
        },

        MuiFormLabel: {
            styleOverrides: {
                root: {
                    padding: '6px 0',
                    '&[data-shrink=true]': {
                        transform: 'translateY(-4px)',
                        fontSize: '14px',
                        padding: '0',
                    },
                    '&[aria-label="placeholder label"]': {
                        color: palette.text.placeholder,
                        '&[data-shrink="true"]': {
                            display: 'none',
                        },
                    },
                },
            },
        },

        MuiAutocomplete: {
            defaultProps: {
                popupIcon: (
                    <ArrowIcon
                        width={18}
                        height={18}
                        css={{
                            color: palette.common.black,
                        }}
                    />
                ),
            },
            styleOverrides: {
                input: {
                    padding: '10px 0 !important',
                },
                paper: {
                    margin: '10px 0',
                    borderRadius: 0,
                    background: palette.background.default,
                    boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.2)',
                },
                listbox: {
                    padding: '8px',
                },
                option: {
                    color: palette.text.primary,
                    padding: '11px',
                    '&:hover': {
                        background: palette.background.paper,
                    },
                    '&[aria-selected="true"]': {
                        background: `${palette.common.black} !important`,
                        color: palette.primary.contrastText,
                    },
                },
            },
        },

        MuiMenu: {
            styleOverrides: {
                paper: {
                    margin: '8px 0 !important',
                    borderRadius: '0 !important',
                    background: `${palette.background.default} !important`,
                    boxShadow: '2px 4px 40px 0 #00000014',
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-focused:not(.Mui-disabled)': {
                        borderBottom: `1px solid ${palette.common.black}`,
                    },
                    borderBottom: `1px solid ${palette.divider}`,
                },
                input: {
                    '::placeholder': {
                        color: palette.text.placeholder,
                        opacity: 1,
                    },

                    '&.Mui-disabled::placeholder': {
                        color: palette.text.disabled,
                    },
                },
            },
        },

        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${palette.divider}`,

                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: `1px solid ${palette.common.black}`,
                    },

                    '&.Mui-focused:not(.Mui-disabled)': {
                        borderBottom: `1px solid ${palette.common.black}`,
                    },

                    '&.Mui-error': {
                        borderBottom: `1px solid ${palette.error.main}`,
                    },
                },
            },
        },

        MuiList: {
            styleOverrides: {
                root: {
                    padding: '8px',
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: palette.text.primary,
                    padding: '10px',
                    '&:hover': {
                        background: palette.background.paper,
                    },
                    '&[aria-selected="true"]': {
                        background: `${palette.common.black} !important`,
                        color: palette.primary.contrastText,
                    },
                },
            },
        },

        MuiBadge: {
            styleOverrides: {
                badge: {
                    padding: '3px',
                    height: '16px',
                    minWidth: '16px',
                    fontSize: '9px',
                    fontWeight: 700,
                },
            },
        },

        MuiAvatar: {
            styleOverrides: {
                root: {
                    background: palette.grey[100],
                },
            },

            defaultProps: {
                children: (
                    <AvatarPlaceholder width="32px" height="32px" aria-label="avatar placeholder" />
                ),
            },
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.background.paper,
                },

                label: {
                    padding: 0,
                },
            },
        },

        MuiSlider: {
            styleOverrides: {
                mark: {
                    width: '1px',
                    height: '8px',
                    background: palette.divider,
                    '&.MuiSlider-markActive': {
                        background: palette.text.primary,
                    },
                },
                markLabel: {
                    fontSize: '14px',
                    color: palette.common.black,
                },
                rail: {
                    background: palette.divider,
                    height: '2px',
                    opacity: 1,
                },
                track: {
                    color: palette.text.primary,
                    border: 'none',
                    height: '2px',
                },
                thumb: {
                    width: '12px',
                    height: '12px',
                    background: palette.text.primary,
                    boxShadow: 'none !important',
                    '&:before': {
                        boxShadow: 'none',
                    },
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '2px !important',
                    color: palette.common.black,
                    '&:hover': {
                        color: palette.primary.main,
                    },
                },
            },
            defaultProps: {
                icon: <CheckboxUncheckedIcon />,
                checkedIcon: <CheckboxCheckedIcon color={palette.primary.main} />,
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 'unset',
                    maxWidth: 'unset',
                },
            },

            defaultProps: {
                disableRipple: true,
            },
        },

        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    body3: 'p',
                    subtitle3: 'h6',
                },
            },
        },

        MuiSkeleton: {
            styleOverrides: {
                rounded: {
                    borderRadius: '100px',
                },
            },
        },
    },

    custom: CUSTOM_VARIABLES,
});

theme2.shadows[1] = '2px 4px 30px rgba(0, 0, 0, 0.08)';
