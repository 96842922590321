import { FC } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { endOfDay, max, startOfDay, Interval, parseISO } from 'date-fns';

import { Booking } from 'src/features/bookings';

import { isYearOutsideRange } from '../../../utils/is-year-outside-range';
import { getYearsFromConfigStartYear } from '../../../utils/get-years-from-config-start-year';

export const CalendarYears: FC<{
    updateSelectedYear: (year: number) => void;
    booking: Booking;
}> = ({ updateSelectedYear, booking }) => {
    const { palette } = useTheme();

    const years = getYearsFromConfigStartYear();

    const dateFrom = max([parseISO(booking.date_from), new Date()]);
    const dateTo = new Date(dateFrom.getFullYear() + 1, 0, 31);
    const calendarRange: Interval = { start: startOfDay(dateFrom), end: endOfDay(dateTo) };

    return (
        <Box display="grid" gridTemplateColumns="repeat(3,1fr)" padding="16px 0">
            {years.map((year: number) => {
                const yearObject = new Date(year, 1, 1);
                const isUnavailable = isYearOutsideRange(yearObject, calendarRange);
                return (
                    <Button
                        key={year}
                        css={{
                            textAlign: 'center',
                            padding: '19px 32px',
                            width: '100%',
                            borderRadius: 0,
                            background: 'none',
                            color: palette.text.primary,
                            '&:hover': {
                                background: palette.background.paper,
                            },
                            ...(isUnavailable && {
                                textDecoration: 'line-through',
                                color: palette.text.disabled,
                                pointerEvents: 'none',
                            }),
                        }}
                        onClick={() => updateSelectedYear(year)}
                    >
                        <Typography variant="body1">{year}</Typography>
                    </Button>
                );
            })}
        </Box>
    );
};
