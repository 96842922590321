import { Stack } from '@mui/material';

import { AppPreloader } from 'src/components/app-preloader';
import { EmptyList } from 'src/components/empty-list/empty-list';

import { NULL_FILTER } from '../../constants/filter';
import { useGetPosts } from '../../hooks/use-get-posts';

import { PostItem } from './parts/post-item';

export const PostsList = () => {
    const { data: posts, isError, isLoading } = useGetPosts();
    const selectedFilterId = NULL_FILTER; // TODO: replace with query params

    const filtredPosts = Array.isArray(posts)
        ? posts.filter((post) => selectedFilterId === NULL_FILTER || post.type === selectedFilterId)
        : null;

    const emptyState = (
        <EmptyList
            mainText="THERE ARE NO POSTS YET"
            secondaryText="You will see community posts as soon as at least one appears"
        />
    );

    if (isError) {
        return emptyState;
    }

    if (isLoading) {
        return <AppPreloader size="big" showLogo />;
    }

    return (
        <Stack
            gap="32px"
            paddingRight={{ xs: '16px', md: '60px', lg: 0 }}
            paddingLeft={{ xs: '16px', md: '60px' }}
            paddingTop={{ xs: '16px', md: 0 }}
            flex={1}
            width="100%"
            marginX="auto"
        >
            {filtredPosts?.length
                ? filtredPosts.map((post) => <PostItem key={post.id} post={post} />)
                : emptyState}
        </Stack>
    );
};
