import { memo } from 'react';
import { useTheme } from '@emotion/react';
import { Button, Stack, Typography, Box } from '@mui/material';

import { Image } from 'src/components/image';

import { useGetGridOptionTranslator } from '../../../hooks/use-grid-option-translator';

import type { GridOption } from '../../../types/create-ticket-form';

interface Props<V extends GridOption> {
    value: V | undefined;
    options: V[] | readonly V[] | undefined;
    onChange: (nextValue: V | undefined) => void;
}

const GridOptionsSelectUnwrapped = <V extends GridOption>({
    value,
    options = [],
    onChange,
}: Props<V>): JSX.Element => {
    const { palette, breakpoints, transitions } = useTheme();

    const translateGridOption = useGetGridOptionTranslator();

    return (
        <Box display="grid" gap="12px" gridTemplateColumns={{ sm: '1fr 1fr', md: '1fr 1fr 1fr' }}>
            {options.map((option) => {
                const isSelected = option.id === value?.id;
                const src = option.image_url;

                const handleClick = () => onChange(option);

                return (
                    <Button
                        key={option.id}
                        color="secondary"
                        disableFocusRipple
                        onClick={handleClick}
                        css={{
                            borderRadius: 0,
                            padding: 0,

                            flexFlow: 'column',
                            transition: transitions.create('background'),
                            border: `1px solid ${palette.divider}`,
                            background: isSelected
                                ? palette.common.black
                                : palette.background.default,

                            ':hover': {
                                background: isSelected
                                    ? palette.common.black
                                    : palette.background.paper,
                            },
                        }}
                    >
                        {src && (
                            <Image
                                src={src}
                                alt="option"
                                css={{
                                    flex: '0 0 80px',

                                    [breakpoints.up('md')]: {
                                        flex: '0 0 120px',
                                    },
                                }}
                            />
                        )}

                        <Stack
                            width="100%"
                            height="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                variant={isSelected ? 'subtitle2' : 'body2'}
                                css={{
                                    width: '100%',
                                    padding: '16px 24px',
                                    color: isSelected
                                        ? palette.primary.contrastText
                                        : palette.text.primary,
                                }}
                            >
                                {translateGridOption(option)}
                            </Typography>
                        </Stack>
                    </Button>
                );
            })}
        </Box>
    );
};

export const GridOptionsSelect = memo(
    GridOptionsSelectUnwrapped,
    (prev, next) => prev.value === next.value && prev.options === next.options
) as typeof GridOptionsSelectUnwrapped;
