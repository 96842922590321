import * as yup from 'yup';

import { attachmentsSchema } from 'src/components/attachment-gallery/validation/attachment-schema';

import { RATING_OPTIONS, FeedbackFormFields } from '../constants/form-options';

const RATING_INVALID_VALUE_MESSAGE = 'feedback_page_form-error_rating_required';
const RATING_REQUIRED_MESSAGE = 'feedback_page_form-error_rating_invalid-value';
const COMMENT_TEXT_ONLY_MESSAGE = 'feedback_page_form-error_comment_text-only';
const COMMENT_REQUIRED_MESSAGE = 'feedback_page_form-error_comment_required';

export const FeedbackSchema = yup.object({
    [FeedbackFormFields.Rating]: yup
        .string()
        .nullable()
        .oneOf(RATING_OPTIONS, RATING_INVALID_VALUE_MESSAGE)
        .required(RATING_REQUIRED_MESSAGE),

    [FeedbackFormFields.Comment]: yup
        .string()
        .typeError(COMMENT_TEXT_ONLY_MESSAGE)
        .required(COMMENT_REQUIRED_MESSAGE),
    [FeedbackFormFields.Attachments]: attachmentsSchema,
});
