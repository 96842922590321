import { FC, useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { Stack, Box, Typography } from '@mui/material';

import { ErrorState } from 'src/components/error-state';
import { LangSwitcher } from 'src/components/lang-switcher';
import { Button } from 'src/components/button';
import { useAuth0 } from 'src/features/auth';

export const Auth0ErrorPage: FC = () => {
    const { t } = useTranslation();
    const { buildAuthorizeUrl, loginWithRedirect } = useAuth0();

    const [authorizeUrl, setAuthorizeUrl] = useState<string>();

    useLayoutEffect(() => {
        const handleAuthorizeUrl = async () => {
            try {
                const url = await buildAuthorizeUrl();
                setAuthorizeUrl(url);
            } catch (error) {
                captureException(error);
                loginWithRedirect();
            }
        };

        handleAuthorizeUrl();
    }, []);

    return (
        <Stack
            flex={1}
            padding={{ xs: '16px', sm: 0 }}
            direction={{ xs: 'column-reverse', md: 'column' }}
            position="relative"
        >
            <Box
                padding={{ xs: '30px', md: '40px' }}
                margin={{ xs: '0 auto 0 auto', md: '0 0 0 auto' }}
            >
                <LangSwitcher css={{ width: 'min-content' }} />
            </Box>

            <Stack
                flex={1}
                justifyContent="center"
                alignItems="center"
                width="fit-content"
                marginX="auto"
                position="relative"
            >
                <Box>
                    <ErrorState
                        title={<Trans i18nKey="auth0_redirect_page_error_title" />}
                        subtitle={<Trans i18nKey="auth0_redirect_page_error_subtitle" />}
                    />

                    <Box
                        position={{ xs: 'absolute', md: 'static' }}
                        bottom={0}
                        marginTop="100px"
                        width="100%"
                        textAlign="center"
                    >
                        <Button
                            href={authorizeUrl}
                            fullWidth
                            loading={!authorizeUrl}
                            design="primary"
                        >
                            <Typography variant="h6">
                                {t('auth0_redirect_page_error_cta')}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Stack>
        </Stack>
    );
};
