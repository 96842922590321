import { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Box, Stack, Typography, Paper } from '@mui/material';

import { Button } from 'src/components/button';
import { ContactsRow } from 'src/components/contacts-row/contacts-row';
import { ReactComponent as LockIcon } from 'src/assets/svg/lock.svg';
import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';

import { ReactComponent as GoCardlessIcon } from '../../assets/svg/gocardless.svg';
import { ReactComponent as SepaIcon } from '../../assets/svg/sepa.svg';

interface Props {
    alert?: {
        message: ReactNode;
        showContacts?: boolean;
    };
    message?: ReactNode;
    payment?: {
        amount: string;
        name: string;
        overdueDays?: number;
        payDay?: string;
    };
    action?: {
        link: string | null;
        disabled?: boolean;
    };
}

export const CardlessPaymentBase: FC<Props> = ({ message, alert, payment, action }) => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            data-testid="MakeMandate.Container"
            padding={{ xs: '32px 20px', md: '40px' }}
            maxWidth={{ lg: '420px' }}
            textAlign="center"
            css={{
                border: alert && `1px solid ${palette.primary.main}`,
                boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.08)',
            }}
        >
            <Box marginBottom="40px">
                {alert && (
                    <Box padding="16px" css={{ background: palette.error.light }}>
                        <Stack direction="row" gap="8px" textAlign="left">
                            <WarningIcon css={{ flex: '0 0 auto', color: palette.error.main }} />
                            <Typography variant="body2" color="error.main">
                                {alert.message}
                            </Typography>
                        </Stack>

                        {alert.showContacts && <ContactsRow variant="light" css={{ gap: '4px' }} />}
                    </Box>
                )}

                {payment && (
                    <Paper
                        elevation={0}
                        square
                        css={{
                            padding: '16px',
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="body1" color="text.secondary">
                            {t('my_bookings_payment_component_you_are_paying')}
                        </Typography>

                        <Typography variant="subtitle1">
                            {payment.name.toLocaleUpperCase()}
                        </Typography>
                    </Paper>
                )}
            </Box>

            {message && (
                <Typography variant="h2" textAlign="center">
                    {message}
                </Typography>
            )}

            {action && (
                <Button
                    data-testid="MakeMandate.Cta"
                    href={action.link || undefined}
                    disabled={action.disabled}
                    design="primary"
                    endIcon={<LockIcon />}
                    disableElevation
                    fullWidth
                    css={{
                        padding: '16px',
                        margin: '32px 0 20px 0',
                        alignItems: 'center',
                        textTransform: 'none',
                        borderRadius: '100px',
                    }}
                >
                    {t('direct_debit_set_up_status')}
                </Button>
            )}

            {payment && (
                <Box textAlign="center" marginY="32px">
                    <Typography variant="body1" color="text.secondary">
                        {t('my_bookings_direct_debit_active_line_1')}
                    </Typography>
                    <Typography fontWeight="700" fontSize="38px">
                        {payment.amount}
                    </Typography>

                    {payment.payDay ? (
                        <Typography color="text.secondary" textAlign="right" component="span">
                            <Trans
                                i18nKey="next_instalment_due"
                                values={{ date: payment.payDay }}
                                components={{
                                    1: (
                                        <Typography
                                            color="primary"
                                            variant="subtitle1"
                                            component="strong"
                                        />
                                    ),
                                }}
                            />
                        </Typography>
                    ) : (
                        <Typography color="text.secondary" textAlign="right" component="span">
                            <Trans
                                i18nKey="instalment_overdue"
                                values={{ number_of_days: payment.overdueDays }}
                                components={{
                                    1: (
                                        <Typography
                                            color="primary"
                                            variant="subtitle1"
                                            component="strong"
                                        />
                                    ),
                                }}
                            />
                        </Typography>
                    )}
                </Box>
            )}

            <Paper elevation={0} square css={{ padding: '24px 16px' }}>
                <Typography variant="body2" color="text.secondary" textAlign="left">
                    {t('my_bookings_direct_debit_component_gocardless_description')}
                </Typography>

                <Stack direction="row" justifyContent="space-between" marginTop="32px">
                    <GoCardlessIcon />
                    <SepaIcon />
                </Stack>
            </Paper>
        </Box>
    );
};
