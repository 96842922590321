import { EventAttendanceStatus, EventStatus } from '../api';
import { AttendButtonState } from '../constants/attend-button-state';

export const getAttendButtonState = (
    status: EventStatus,
    attendanceStatus: EventAttendanceStatus
) => {
    if (attendanceStatus === EventAttendanceStatus.Attending) {
        return AttendButtonState.Attending;
    }

    if (attendanceStatus === EventAttendanceStatus.OnTheWaitingList) {
        return AttendButtonState.OnWaitingList;
    }

    if (status === EventStatus.AtCapacity) {
        return AttendButtonState.NotOnWaitingList;
    }

    return AttendButtonState.NotAttending;
};
