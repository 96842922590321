import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@emotion/react';

import { EventList } from 'src/features/events/api';

import { EventCard } from './parts/event-card';

interface Props {
    events: EventList;
    selectedEventId: string | null;
    onEventChange: (id: string) => void;
}

export const EventsList: FC<Props> = ({ events, selectedEventId, onEventChange }) => {
    const { palette } = useTheme();

    return (
        <Stack
            marginTop={{ xs: '26px', md: '52px' }}
            gap="16px"
            marginX={{ xs: '-16px', md: 0 }}
            width={{ md: '100%' }}
            maxWidth={{ md: '600px' }}
        >
            {events.map((event) => {
                const selected = event.id === selectedEventId;

                return (
                    <EventCard
                        key={event.id}
                        event={event}
                        onEventChange={onEventChange}
                        css={{
                            background: selected
                                ? `${palette.common.black} !important`
                                : `${palette.background.paper} !important`,
                            color: selected ? palette.primary.contrastText : palette.text.primary,
                        }}
                    />
                );
            })}
        </Stack>
    );
};
