import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { PrivateHat } from 'src/layout/private-hat';
import { SubjectList } from 'src/features/feedback';

export const FeedbackSubjectPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <PrivateHat title={t('feedback_page_title')} backIconText={t('back_cta')} />

            <Stack padding={{ xs: '16px', md: '40px' }} alignItems={{ md: 'center' }}>
                <Typography
                    variant="body1"
                    color="text.secondary"
                    aria-label="link text"
                    maxWidth="350px"
                    textAlign={{ md: 'center' }}
                >
                    {t('feedback_page_description')}
                </Typography>

                <Typography
                    variant="h2"
                    color="text.primary"
                    marginTop={{ xs: '40px', md: '60px' }}
                >
                    {t('feedback_page_choose-a-topic')}
                </Typography>

                <SubjectList />
            </Stack>
        </Fragment>
    );
};
