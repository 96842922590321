export enum EventsEndpoints {
    GetEvents = '/v1/events',
    GetMyEvents = '/v1/events/my',
    GetMyPastEvents = '/v1/events/my/past',
    GetEvent = '/v1/events/{id}',
    PostAttendEvent = '/v1/events/{id}/attend',
    PostCancelAttendanceEvent = '/v1/events/{id}/cancel-attendance',
}

export enum EventStatus {
    Open = 'open',
    FillingFast = 'filling fast',
    NearlyFull = 'nearly full',
    AtCapacity = 'at capacity',
    Closed = 'closed',
    Cancelled = 'event cancelled',
    NotOpenYet = 'not open yet',
}

export enum EventAttendanceStatus {
    Attending = 'Attending',
    Cancelled = 'Cancelled',
    OnTheWaitingList = 'On the waiting list',
}
