import { FC, Fragment, ReactNode, SVGProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Stack, Typography } from '@mui/material';

import { Dialog } from 'src/components/dialog';
import { ReactComponent as ControlArrow } from 'src/assets/svg/control-arrow.svg';
import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';
import { ReactComponent as LockIcon } from 'src/assets/svg/lock.svg';
import { ButtonWithIcon } from 'src/components/button-with-icon';

enum Status {
    Available = 'available',
    ComingSoon = 'coming_soon',
    NoAccess = 'no_access',
}

const ACTION_ICONS = new Map<Status, JSX.Element>([
    [
        Status.Available,
        <ControlArrow
            key={Status.Available}
            width="24px"
            height="24px"
            css={{ transform: 'rotate(270deg)' }}
        />,
    ],
    [Status.ComingSoon, <WarningIcon key={Status.ComingSoon} width="24px" height="24px" />],
    [Status.NoAccess, <LockIcon key={Status.NoAccess} width="24px" height="24px" />],
]);

const MODAL_CONTENT = new Map([
    [
        Status.ComingSoon,
        {
            title: 'my_building_coming_soon_book_a_space_model_title',
            body: 'my_building_coming_soon_book_a_space_subtitle',
        },
    ],
    [
        Status.NoAccess,
        {
            title: 'my_building_locked_model_title',
            body: 'my_building_locked_model_subtitle',
        },
    ],
]);

interface Props {
    Icon: FC<SVGProps<SVGSVGElement>>;
    title: string;
    link: {
        text?: string;
        url: string;
    };
    checkedIn: boolean;
    isFeatureEnabled: boolean;
    onTitleClick?: VoidFunction;
    onCTAClick?: VoidFunction;
    children?: ReactNode;
    className?: string;
}

export const BuildingNavLink: FC<Props> = ({
    Icon,
    title,
    link,
    checkedIn,
    isFeatureEnabled,
    onTitleClick,
    onCTAClick,
    children,
    className,
}) => {
    const { palette, shadows } = useTheme();

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    let status: Status;

    if (!isFeatureEnabled) {
        status = Status.ComingSoon;
    } else if (!checkedIn) {
        status = Status.NoAccess;
    } else {
        status = Status.Available;
    }

    const isDisabled = status !== Status.Available;
    const hasContent = Boolean(children);

    const modalContent = MODAL_CONTENT.get(status);

    const handleToggleModal = () => setShowModal(!showModal);

    const getLinkProps = () =>
        isDisabled
            ? { onClick: handleToggleModal }
            : { component: Link, to: link.url, onClick: onTitleClick };

    return (
        <Fragment>
            <Stack
                alignItems="stretch"
                width="100%"
                aria-disabled={isDisabled}
                className={className}
                css={{
                    position: 'relative',
                    color: 'inherit',
                    textDecoration: 'none',
                    background: palette.background.default,
                    zIndex: 'auto',

                    '::after': {
                        content: '""',
                        position: 'absolute',
                        zIndex: -1,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        boxShadow: shadows[1],
                    },
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    width="100%"
                    gap="16px"
                    padding={{ xs: '32px 16px', md: '36px' }}
                    {...getLinkProps()}
                    css={{ textDecoration: 'none', color: palette.common.black }}
                >
                    <Icon color={isDisabled ? palette.text.disabled : palette.primary.main} />

                    <Typography
                        variant="h3"
                        flex={1}
                        color={isDisabled ? palette.text.disabled : palette.text.primary}
                    >
                        {title}
                    </Typography>

                    {isDisabled || !hasContent ? ACTION_ICONS.get(status) : null}
                </Stack>

                {hasContent && !isDisabled && (
                    <Stack gap="32px" padding={{ xs: '0 16px 32px 16px', md: '0 36px 36px 36px' }}>
                        {children}

                        <ButtonWithIcon
                            component={Link}
                            to={link.url}
                            onClick={onCTAClick}
                            design="light"
                            bordered
                            fullWidth
                        >
                            {link.text}
                        </ButtonWithIcon>
                    </Stack>
                )}
            </Stack>

            {modalContent && (
                <Dialog open={showModal} onClose={handleToggleModal}>
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        gap="16px"
                    >
                        <Typography variant="h3">{t(modalContent.title)}</Typography>
                        <Typography variant="body2">{t(modalContent.body)}</Typography>
                    </Stack>
                </Dialog>
            )}
        </Fragment>
    );
};
