import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { queryClient } from 'src/lib/query-client';
import { ConciergeErrorResponse } from 'src/api/concierge';

import { postUserAvatar, PostUserAvatarBody, UserAvatar, UserData, UserEndpoints } from '../api';

import type { AxiosError } from 'axios';

type Config = UseMutationOptions<
    PostUserAvatarBody,
    AxiosError<ConciergeErrorResponse>,
    UserAvatar
>;

interface Payload {
    id: string;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const usePostUserAvatar = (config: Config = {}, payload: Payload) => {
    const userEndpointKey = [UserEndpoints.getCurrentUser];
    const userData = queryClient.getQueryData<UserData>(userEndpointKey);

    return useMutation<PostUserAvatarBody, AxiosError<ConciergeErrorResponse>, UserAvatar>({
        mutationFn: (avatar) => postUserAvatar(payload.id, avatar),
        onSuccess({ avatar_url }) {
            queryClient.setQueryData(userEndpointKey, {
                ...userData,
                avatar_url,
            });
        },
        ...config,
    });
};
