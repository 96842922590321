import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';

export const RebookingLinkSkeleton: FC = () => (
    <Box flex={{ md: 1 }}>
        <Skeleton
            variant="rectangular"
            css={({ breakpoints }) => ({
                height: '146px',

                [breakpoints.up('md')]: {
                    height: '260px',
                },
            })}
        />

        <Box padding={{ xs: '16px', md: '32px' }}>
            <Skeleton
                variant="rectangular"
                width="25%"
                height="16px"
                css={{ marginBottom: '8px' }}
            />

            <Skeleton
                variant="rectangular"
                width="50%"
                height="28px"
                css={{ marginBottom: '16px' }}
            />

            <Skeleton variant="rectangular" width="100%" height="40px" />
        </Box>
    </Box>
);
