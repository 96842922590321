import { Skeleton } from '@mui/material';
import { FC, Fragment } from 'react';

const inputSkeleton = <Skeleton variant="rectangular" height="100%" />;

export const RebookingFilterSkeleton: FC = () => (
    <Fragment>
        <Skeleton variant="rounded" height="100%" css={{ gridColumn: '1/-1' }} />

        {inputSkeleton}
        {inputSkeleton}
        {inputSkeleton}
        {inputSkeleton}
    </Fragment>
);
