interface HistoryService {
    stack: number;
    push: VoidFunction;
    pop: VoidFunction;
}

export const historyService: HistoryService = {
    stack: 1,

    push() {
        return ++this.stack;
    },

    pop() {
        return --this.stack;
    },
};
