import omit from 'lodash/omit';

import { getAsPhoneNumber } from 'src/utils/get-as-phone-number';

import { UserData, UserDataFields } from '../api';
import { EditProfileFormData } from '../types/edit-profile';

export const getUserDefaultValues = (user: UserData): EditProfileFormData => ({
    ...omit(
        user,
        UserDataFields.FirstName,
        UserDataFields.LastName,
        UserDataFields.Email,
        UserDataFields.Id,
        UserDataFields.Auth0Sub,
        UserDataFields.AvatarUrl,
        UserDataFields.StudentId
    ),
    [UserDataFields.PhoneNumber]: getAsPhoneNumber(user[UserDataFields.PhoneNumber]),
    [UserDataFields.EmergencyContactPhone]: getAsPhoneNumber(
        user[UserDataFields.EmergencyContactPhone]
    ),
});
