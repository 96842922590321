import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { PrivateHat } from 'src/layout/private-hat';
import { EventsList, useGetMyEvents } from 'src/features/events';

export const MyEventsPage: FC = () => {
    const { t } = useTranslation();
    const { data, isLoading, isError, isSuccess } = useGetMyEvents();

    return (
        <Fragment>
            <PrivateHat title={t('events_tab_my-events')} backIconText={t('back_cta')} />

            <Stack
                flex={1}
                padding={{
                    xs: '40px 16px 20px 16px',
                    md: '40px 40px 20px 40px',
                    lg: '40px 60px 20px 60px',
                    xl: '40px 100px 20px 100px',
                }}
            >
                {isSuccess && (
                    <Typography
                        variant="h4"
                        marginBottom="22px"
                        paddingLeft={{ xs: '16px', md: '42px', lg: 0 }}
                    >
                        {t('events_tab_upcoming', {
                            count: data?.length,
                        })}
                    </Typography>
                )}

                <EventsList events={data} isError={isError} isLoading={isLoading} />
            </Stack>
        </Fragment>
    );
};
