import { FC, Fragment, useState } from 'react';
import { format, isPast, parseISO } from 'date-fns';
import { useTheme } from '@emotion/react';
import {
    Collapse,
    Divider,
    IconButton,
    Paper,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { formatAmount } from 'src/utils/format-amount';

import { PaymentStatus, Payment } from '../../api';

enum RowVariant {
    Paid = 'paid',
    Overdue = 'overdue',
    Active = 'active',
}

const getRowVariant = (paymentStatus: PaymentStatus, dateDueISO: string): RowVariant => {
    const dateDue = parseISO(dateDueISO);
    const paymentIsInPast = isPast(dateDue);

    if (
        paymentStatus === PaymentStatus.TransfermatePaid ||
        paymentStatus === PaymentStatus.GoCardlessPaid
    ) {
        return RowVariant.Paid;
    }

    if (paymentIsInPast) {
        return RowVariant.Overdue;
    }

    return RowVariant.Active;
};

export const PaymentScheduleRow: FC<{ payment: Payment }> = ({ payment }) => {
    const [isOpen, setOpen] = useState(false);
    const { palette, transitions, breakpoints } = useTheme();

    const rowVariant = getRowVariant(payment.status, payment.date_due);

    const textColor = {
        [RowVariant.Paid]: palette.success.main,
        [RowVariant.Overdue]: palette.error.main,
        [RowVariant.Active]: palette.text.primary,
    }[rowVariant];

    const toggleIsOpen = () => setOpen(!isOpen);

    return (
        <Fragment>
            <TableRow
                data-testid="PaymentsSchedule.Row"
                css={{
                    background: {
                        [RowVariant.Paid]: palette.success.light,
                        [RowVariant.Overdue]: palette.error.light,
                        [RowVariant.Active]: palette.background.default,
                    }[rowVariant],
                }}
            >
                <TableCell headers="name">
                    <Typography color={textColor} variant="body2">
                        {payment.name}
                    </Typography>
                </TableCell>

                <TableCell
                    headers="amount_total"
                    css={{
                        [breakpoints.down('md')]: {
                            display: 'none',
                        },
                    }}
                >
                    <Typography color={textColor} variant="body2">
                        {formatAmount(payment.amount_total, payment.amount_total_currency)}
                    </Typography>
                </TableCell>

                <TableCell
                    headers="amount_paid"
                    css={{
                        [breakpoints.down('md')]: {
                            display: 'none',
                        },
                    }}
                >
                    <Typography color={textColor} variant="body2">
                        {formatAmount(payment.amount_paid, payment.amount_paid_currency)}
                    </Typography>
                </TableCell>

                <TableCell headers="remaining_amount">
                    <Typography color={textColor} variant="body2">
                        {formatAmount(payment.remaining_amount, payment.amount_paid_currency)}
                    </Typography>
                </TableCell>

                <TableCell headers="date_due">
                    <Typography color={textColor} variant="body2">
                        {format(parseISO(payment.date_due), 'dd/MM/yyyy')}
                    </Typography>
                </TableCell>

                {/* <TableCell align="center">
                    <DownloadIcon color={textColor} />
                </TableCell> */}

                <TableCell
                    css={{
                        padding: '0 !important',
                        [breakpoints.up('md')]: {
                            display: 'none',
                        },
                    }}
                >
                    <IconButton onClick={toggleIsOpen}>
                        <ArrowIcon
                            width="16px"
                            height="16px"
                            css={{
                                color: textColor,
                                transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: transitions.create('transform'),
                            }}
                        />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    colSpan={8}
                    css={{
                        padding: isOpen ? undefined : '0 !important',
                        borderBottom: palette.divider,
                        transition: transitions.create('padding'),
                    }}
                >
                    <Collapse in={isOpen} timeout="auto">
                        <Paper elevation={0} css={{ padding: '20px' }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="subtitle2">Amount</Typography>
                                <Typography variant="body2">
                                    {formatAmount(
                                        payment.amount_total,
                                        payment.amount_total_currency
                                    )}
                                </Typography>
                            </Stack>
                            <Divider css={{ margin: '12px 0' }} />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="subtitle2">Paid</Typography>
                                <Typography variant="body2">
                                    {formatAmount(
                                        payment.amount_paid,
                                        payment.amount_paid_currency
                                    )}
                                </Typography>
                            </Stack>
                        </Paper>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};
