import {
    ChangeEventHandler,
    FC,
    KeyboardEventHandler,
    MouseEventHandler,
    useEffect,
    useState,
} from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'src/components/search-input';
import { GA4 } from 'src/lib/ga4';

import { config } from '../constants/config';

export const FaqSearchInput: FC<{
    redirectPath?: string;
}> = ({ redirectPath }) => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const searchValue = searchParams.get(config.search_query_key) ?? '';

    const [inputValue, setInputValue] = useState(searchValue);

    const { t } = useTranslation();

    const saveSearchValue = (input: string) => {
        if (input.length < config.minimal_search_length) return;

        const search = {
            [config.search_query_key]: input.trim(),
        };

        navigate(
            {
                pathname: redirectPath,
                search: `?${createSearchParams(search).toString()}`,
            },
            {
                replace: true,
            }
        );

        GA4.submitEvent({
            name: 'FAQ Search',
            location: 'FAQ Search',
            label: input,
        });
    };

    const onInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
        setInputValue(target.value);

    const onInputClickSave: MouseEventHandler<HTMLButtonElement> = () =>
        saveSearchValue(inputValue);

    const onInputClean: MouseEventHandler<HTMLButtonElement> = () => setInputValue('');

    const onInputEnterSave: KeyboardEventHandler<HTMLInputElement> = ({ target, code }) => {
        if (code !== 'Enter') return;

        const { value } = target as HTMLInputElement;
        saveSearchValue(value);
    };

    useEffect(() => window.scrollTo(0, 0), [searchValue]);

    return (
        <SearchInput
            value={inputValue}
            placeholder={t('help_search_placeholder')}
            onKeyDown={onInputEnterSave}
            onChange={onInputChange}
            onClear={onInputClean}
            onSearch={onInputClickSave}
        />
    );
};
