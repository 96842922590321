import { FC } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { ReactComponent as SuccessIcon } from 'src/assets/svg/success-in-circle.svg';
import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';
import { ReactComponent as EditIcon } from 'src/assets/svg/pen.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';

import { BookingMoveInDetails } from '../../../types/booking-move-in-details';

export const MoveInWeekInner: FC<{
    moveInDetails: BookingMoveInDetails;
    isMainOccupant: boolean;
}> = ({ moveInDetails, isMainOccupant }) => {
    const { palette, transitions } = useTheme();

    const { t } = useTranslation();

    const moveInWeek = moveInDetails.week_movein;

    return (
        <Stack
            padding="20px"
            gap="16px"
            width="100%"
            direction="row"
            css={{
                borderBottom: `1px solid ${palette.divider}`,
                color: palette.common.black,
                position: 'relative',
            }}
        >
            {moveInDetails.week_movein ? (
                <SuccessIcon
                    width="32px"
                    height="32px"
                    color={palette.success.main}
                    css={{ flexShrink: 0 }}
                />
            ) : (
                <WarningIcon
                    width="32px"
                    height="32px"
                    color={palette.error.main}
                    css={{ flexShrink: 0 }}
                />
            )}

            <Stack flex={1} direction="column" gap="4px">
                <Typography
                    variant="h4"
                    aria-label="link title"
                    width="fit-content"
                    css={{
                        paddingBottom: '4px',
                        position: 'relative',
                        ':after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            height: '1px',
                            width: 0,
                            bottom: 0,
                            background: palette.text.primary,
                            transition: transitions.create('width'),
                        },
                    }}
                >
                    {isMainOccupant
                        ? t('move_in_week_title')
                        : t('move_in_time_slot_dual_occ_title')}
                </Typography>
                {moveInWeek ? (
                    <Typography variant="body2" color={palette.text.secondary}>
                        <Trans
                            i18nKey="move_in_week_subtitle_complete"
                            values={{ week: moveInWeek }}
                            components={{
                                b: <b css={{ color: palette.text.primary, fontWeight: 600 }} />,
                            }}
                        />
                    </Typography>
                ) : (
                    <Typography variant="body2" color={palette.text.secondary}>
                        {t('move_in_week_subtitle_incomplete')}
                    </Typography>
                )}
            </Stack>

            {isMainOccupant && (
                <IconButton css={{ alignSelf: 'center' }}>
                    {moveInDetails.date_movein ? (
                        <EditIcon
                            css={{
                                color: palette.common.black,
                                width: '24px',
                                height: '24px',
                            }}
                        />
                    ) : (
                        <ArrowIcon
                            css={{
                                color: palette.common.black,
                                width: '24px',
                                height: '24px',
                                transform: 'rotate(-90deg)',
                            }}
                        />
                    )}
                </IconButton>
            )}
        </Stack>
    );
};
