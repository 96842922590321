import { FC } from 'react';
import { Box } from '@mui/material';

import { useGetActiveBuilding } from 'src/features/building';

import { useGetRebookingFilter } from '../../hooks/use-get-rebooking-filters';

import { RebookingFilterSkeleton } from './rebooking-filter-skeleton';
import { RebookingFilter } from './rebooking-filter';

export const RebookingFilterContainer: FC = () => {
    const { data: filters, isLoading: filtersIsLoading, isError } = useGetRebookingFilter();
    const { data: building, isLoading: buildingIsLoading } = useGetActiveBuilding();

    if (isError) return null; // expect parent handle this state

    return (
        <Box
            component="form"
            display="grid"
            gridAutoRows="62px"
            gap={{ xs: '20px', lg: '40px' }}
            gridTemplateColumns={{ xs: '1fr 1fr', lg: '1fr' }}
            padding={{ md: '36px', lg: 0 }}
            width={{
                xs: '100%',
                lg: '300px',
                xl: '400px',
            }}
        >
            {filtersIsLoading || buildingIsLoading ? (
                <RebookingFilterSkeleton />
            ) : (
                <RebookingFilter filters={filters} filterId={building?.filter_id} />
            )}
        </Box>
    );
};
