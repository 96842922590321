export enum BookingsEndpoints {
    getMyBookings = '/v1/bookings',
    getBookingsSummary = '/v1/bookings/summary',
    patchMyBookings = '/v1/bookings/{id}',
}

export enum BookingStatus {
    Past = 'PAST',
    Upcoming = 'UPCOMING',
    Current = 'CURRENT',
}
