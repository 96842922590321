import { useTheme } from '@emotion/react';
import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    ButtonTypeMap,
    ExtendButtonBase,
} from '@mui/material';

import { Spinner } from './spinner';

export interface ButtonProps extends MuiButtonProps {
    design?: 'light' | 'primary' | 'success';
    /** The parent's background. To show opposite color hover effects */
    surrounding?: 'dark' | 'light';
    bordered?: boolean;
    loading?: boolean;
    className?: string;
}

/**
 * The extended version of the MUI Button. Has special support for "loading state".
 * Also provides additional place for the icon (has different UI styles then MUI default icon prop).
 */
export const Button: ExtendButtonBase<ButtonTypeMap<ButtonProps>> = ({
    design = 'light',
    disabled,
    loading,
    bordered,
    startIcon,
    endIcon,
    children,
    className,
    surrounding = 'light',
    ...restProps
}: ButtonProps) => {
    const { palette } = useTheme();

    const isSurroundingLight = surrounding === 'light';

    return (
        <MuiButton
            disabled={disabled || loading}
            css={{
                borderRadius: '100px',
                padding: bordered ? '12.5px 20.5px' : '14.5px 22.5px',
                justifyContent: 'center',
                opacity: disabled ? 0.4 : undefined,

                ...(design === 'light' && {
                    color: palette.common.black,
                    background: bordered ? 'transparent' : palette.common.white,
                    border: bordered ? `2px solid ${palette.common.black}` : undefined,

                    '&.Mui-disabled': {
                        color: palette.common.black,
                    },
                }),

                ...(design === 'primary' && {
                    color: bordered ? palette.primary.main : palette.common.white,
                    background: bordered ? 'transparent' : palette.primary.main,
                    border: bordered ? `2px solid ${palette.primary.main}` : undefined,

                    '&.Mui-disabled': {
                        color: bordered ? palette.primary.main : palette.common.white,
                    },
                }),

                ...(design === 'success' && {
                    color: palette.common.white,
                    background: palette.success.main,

                    '&.Mui-disabled': {
                        color: palette.common.white,
                    },
                }),

                ':hover': {
                    color: isSurroundingLight ? palette.common.white : palette.text.primary,
                    background: isSurroundingLight ? palette.common.black : palette.common.white,
                },
            }}
            className={className}
            {...restProps}
        >
            {loading ? (
                <Spinner
                    width={30}
                    height={30}
                    css={{
                        color: 'inherit',
                        margin: '-7px auto',
                        display: 'block',
                    }}
                />
            ) : (
                <div
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        gap: '16px',
                        flex: 1,
                        ':hover': {
                            border: 'none',
                        },
                    }}
                >
                    {startIcon}
                    {children}
                    {endIcon}
                </div>
            )}
        </MuiButton>
    );
};
