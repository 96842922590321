import { FC } from 'react';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { Time } from 'src/constants/slots';
import { useFormattedDate } from 'src/hooks/use-formatted-date';

import { getSlotByDuration } from '../../utils/get-slot-by-duration';

export const BookingSummary: FC<{
    selectedDate: string;
    selectedTime: Time;
    selectedDuration: number;
    location?: string;
}> = ({ selectedDate, selectedTime, selectedDuration, location }) => {
    const getFormattedDate = useFormattedDate();

    const formattedDate = selectedDate && getFormattedDate(selectedDate).toUpperCase();

    const slot = getSlotByDuration(selectedTime, selectedDuration);

    return (
        <Typography variant="overlineMedium" textAlign="center">
            <Trans
                i18nKey="specific_social_space_summary"
                values={{
                    date: formattedDate,
                    slot,
                    location,
                }}
                components={{
                    span: <span css={{ fontWeight: '400' }} />,
                }}
            />
        </Typography>
    );
};
