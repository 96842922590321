import { FC } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export const RebookingCardSkeleton: FC = () => (
    <Stack direction={{ lg: 'row' }}>
        <Box
            position="relative"
            width={{ lg: '301px' }}
            height={{ xs: '301px', md: '280px', lg: 'auto' }}
        >
            <Skeleton aria-label="preview" variant="rectangular" width="100%" height="100%" />
        </Box>

        <Box flex={1} padding={{ xs: '32px 24px', md: '32px 40px', lg: '32px' }}>
            <Skeleton
                aria-label="title"
                variant="rectangular"
                width="70px"
                height="29px"
                css={{
                    marginBottom: '24px',
                }}
            />

            <Box
                display="grid"
                gap="12px"
                gridTemplateColumns={{
                    xs: '1fr 1fr',
                    md: '1fr 1fr 1fr 1fr',
                    lg: '1fr 1fr',
                    xl: '1fr 1fr 1fr 1fr',
                }}
                marginBottom="36px"
            >
                <Skeleton aria-label="info" variant="rectangular" height="42px" />
                <Skeleton aria-label="info" variant="rectangular" height="42px" />
                <Skeleton aria-label="info" variant="rectangular" height="42px" />
                <Skeleton aria-label="info" variant="rectangular" height="42px" />
            </Box>

            <Stack direction={{ md: 'row' }} gap="20px" justifyContent="space-between">
                <Skeleton aria-label="price" variant="rectangular" width="180px" height="28px" />

                <Box width={{ md: '150px' }} height="45px">
                    <Skeleton aria-label="cta" variant="rounded" height="100%" />
                </Box>
            </Stack>
        </Box>
    </Stack>
);
