import { FC } from 'react';
import { useTheme } from '@emotion/react';
import { Typography, Box, Avatar } from '@mui/material';

import { AppPreloader } from 'src/components/app-preloader';
import { UserData, UserDataFields } from 'src/features/user/api';

import { ProfileAvatarBadge } from './profile-avatar-badge';

export const ProfileAvatarExtended: FC<{
    user: UserData;
    errorElement: JSX.Element | undefined;
    inputElement: JSX.Element;
    isLoading: boolean;
}> = ({ user, inputElement, errorElement, isLoading }) => {
    const { palette } = useTheme();

    const fullName = `${user[UserDataFields.FirstName]} ${user[UserDataFields.LastName]}`;
    const src = user[UserDataFields.AvatarUrl] || undefined;

    return (
        <Box display={{ xs: 'none', md: 'flex' }} height="261px">
            <Box
                width={{
                    md: '332px',
                    lg: '578px',
                    xl: '760px',
                }}
            >
                {isLoading ? (
                    <AppPreloader background="paper" />
                ) : (
                    <ProfileAvatarBadge
                        htmlFor={inputElement.props.id}
                        css={{
                            width: '100%',
                            height: '100%',

                            '.MuiBadge-badge': {
                                bottom: '30px',
                                right: '30px',
                            },
                        }}
                    >
                        {errorElement}
                        {inputElement}
                        <Avatar
                            src={src}
                            css={{
                                borderRadius: 0,
                                width: '100%',
                                height: '100%',

                                'svg[aria-label="avatar placeholder"]': {
                                    width: '72px',
                                    height: '72px',
                                },
                            }}
                        />
                    </ProfileAvatarBadge>
                )}
            </Box>

            <div
                css={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    flex: 1,
                    padding: '32px',
                    position: 'relative',
                    background: '#212121',
                    overflow: 'hidden',
                }}
            >
                <Typography
                    variant="h2"
                    color="background.default"
                    zIndex={1}
                    textTransform="uppercase"
                >
                    {fullName}
                </Typography>
                <div
                    css={{
                        position: 'absolute',
                        background: palette.primary.main,
                        opacity: 0.5,
                        width: '170px',
                        height: '200%',
                        top: '-59%',
                        right: 0,
                        transform: 'rotate(315deg)',
                    }}
                />
                <div
                    css={{
                        position: 'absolute',
                        background: palette.primary.main,
                        width: '170px',
                        height: '200%',
                        top: '-42%',
                        right: 0,
                        transform: 'rotate(45deg)',
                    }}
                />
            </div>
        </Box>
    );
};
