import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

import { PrivateHat } from 'src/layout/private-hat';
import { BrowseFaq, ContactUs } from 'src/features/support-hub';

export const SupportHubPage: FC = () => {
    const { t } = useTranslation();
    const { breakpoints } = useTheme();

    return (
        <Fragment>
            <PrivateHat title={t('help_page_link_title')} backIconText={t('back_cta')} />

            <div
                css={{
                    maxWidth: '748px',
                    margin: '40px auto',
                    padding: '0 16px',
                    [breakpoints.up('lg')]: {
                        margin: '60px auto',
                    },
                }}
            >
                <Typography variant="h2" textAlign="center">
                    <Trans i18nKey="help_intro_title" />
                </Typography>
            </div>

            <BrowseFaq />

            <ContactUs />
        </Fragment>
    );
};
