import { areIntervalsOverlapping, endOfMonth, startOfMonth, Interval } from 'date-fns';

export const isMonthOutsideRange = (sourceDate: Date, calendarRange: Interval) => {
    const sourceMonthRange: Interval = {
        start: startOfMonth(sourceDate),
        end: endOfMonth(sourceDate),
    };

    return !areIntervalsOverlapping(sourceMonthRange, calendarRange);
};
