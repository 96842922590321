import { FC, useLayoutEffect, useState } from 'react';
import i18n from 'i18next';

import { AppPreloader } from 'src/components/app-preloader';
import { initI18Next } from 'src/i18n/instance';
import { Auth0ErrorPage } from 'src/pages/auth0-eror.page';

export const Auth0Error: FC = () => {
    const [intInitialized, setIntInitialized] = useState(i18n.isInitialized);

    useLayoutEffect(() => {
        initI18Next(undefined, () => setIntInitialized(true));
    }, []);

    return intInitialized ? (
        <Auth0ErrorPage />
    ) : (
        <AppPreloader size="big" showLogo css={{ flex: 1 }} />
    );
};
