import { ConciergeResponseCode } from 'src/api/concierge';

export const FEEDBACK_ATTACHMENT_ERROR_KEYS = new Map<ConciergeResponseCode, string>([
    [
        ConciergeResponseCode.MaximumAttachmentsReached,
        'feedback_page_attach-files_error_files-amount',
    ],
    [ConciergeResponseCode.InvalidUploadFileType, 'feedback_page_attach-files_error_file-type'],
    [ConciergeResponseCode.InvalidUploadFileSize, 'feedback_page_attach-files_error_file-size'],
]);
