import { ChatCallAction } from './live-chat.constants';

import type { ChatWindow } from './live-chat.types';

const global = window as ChatWindow;

export const liveChat = {
    minimize() {
        global.LiveChatWidget?.call(ChatCallAction.Minimize);
    },

    maximize() {
        global.LiveChatWidget?.call(ChatCallAction.Maximize);
    },

    hide() {
        global.LiveChatWidget?.call(ChatCallAction.Hide);
    },
};
