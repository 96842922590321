import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { queryClient } from 'src/lib/query-client';
import { BookingsEndpoints } from 'src/features/bookings';
import { UserEndpoints } from 'src/features/user/api';
import { ConciergeErrorResponse } from 'src/api/concierge';

import { PostMoveInBooking, postMoveInBooking, PostMoveInBookingBody } from '../api';

export const usePostMoveInBooking = () => {
    const mutation = useMutation<
        PostMoveInBooking,
        AxiosError<ConciergeErrorResponse<PostMoveInBooking>>,
        PostMoveInBookingBody
    >({
        mutationFn: (moveInBody) => postMoveInBooking(moveInBody),
        onSuccess: async () => {
            await queryClient.invalidateQueries([BookingsEndpoints.getMyBookings]);
            queryClient.invalidateQueries([UserEndpoints.getCurrentUser]);
        },
        cacheTime: 0,
    });

    return mutation;
};
