import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { IconButton, Modal } from '@mui/material';

import {
    CreateMaintenanceTicket,
    TicketsList,
    useGetTicketFormDataTree,
} from 'src/features/maintenance';
import { PrivateHat } from 'src/layout/private-hat';
import { Button } from 'src/components/button';
import { ReactComponent as PlusIcon } from 'src/assets/svg/plus.svg';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

export const MaintenancePage: FC = () => {
    const { data: formDataTree, isSuccess } = useGetTicketFormDataTree();

    const [isModalOpen, setModalOpen] = useState(false);

    const { palette, breakpoints } = useTheme();

    const { t } = useTranslation();

    const toggleModal = () => {
        setModalOpen(!isModalOpen);

        GA4.modalEvent({
            name: 'Report an Issue Modal',
            open: !isModalOpen,
            location: 'Private Hat',
            label: t('maintenance_report_page-title', { lng: LanguagesCode.English }),
        });
    };

    return (
        <Fragment>
            <PrivateHat
                title={t('maintenance_reported-issues_page-title')}
                actionLabel={
                    <Fragment>
                        <Button
                            design="primary"
                            onClick={toggleModal}
                            disabled={!isSuccess}
                            css={{
                                display: 'flex',
                                gap: '16px',

                                [breakpoints.down('md')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <span>{t('maintenance_report_page-title')}</span>
                            <PlusIcon width="16px" height="16px" />
                        </Button>

                        <IconButton
                            onClick={toggleModal}
                            css={{
                                [breakpoints.up('md')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <PlusIcon width="24px" height="24px" color={palette.primary.main} />
                        </IconButton>
                    </Fragment>
                }
                backIconText={t('maintenance_reported-issues_button_back')}
            />

            <TicketsList onClick={toggleModal} />

            {isSuccess && (
                <Modal open={isModalOpen} onClose={toggleModal}>
                    <CreateMaintenanceTicket dataTree={formDataTree} onClose={toggleModal} />
                </Modal>
            )}
        </Fragment>
    );
};
