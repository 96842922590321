import { FC } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { ReactComponent as SuccessIcon } from 'src/assets/svg/success-in-circle.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { useFormattedDate } from 'src/hooks/use-formatted-date';

export const MoveOutInnerComplete: FC<{
    requestedDateCheckOut: string;
}> = ({ requestedDateCheckOut }) => {
    const { palette, transitions } = useTheme();

    const { t } = useTranslation();

    const getFormattedDate = useFormattedDate();

    const requestedDateCheckOutFormatted = getFormattedDate(requestedDateCheckOut, 'PPP');

    return (
        <Stack
            padding="20px"
            gap="16px"
            width="100%"
            direction="row"
            css={{
                borderBottom: `1px solid ${palette.divider}`,
                color: palette.common.black,
                position: 'relative',
                pointerEvents: 'none',
            }}
        >
            <SuccessIcon
                width="32px"
                height="32px"
                color={palette.success.main}
                css={{ flexShrink: 0 }}
            />

            <Stack flex={1} direction="column" gap="6px">
                <Typography
                    variant="h4"
                    aria-label="link title"
                    width="fit-content"
                    css={{
                        paddingBottom: '4px',
                        position: 'relative',

                        ':after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            height: '1px',
                            width: 0,
                            bottom: 0,
                            background: palette.text.primary,
                            transition: transitions.create('width'),
                        },
                    }}
                >
                    {t('check_out_form_title')}
                </Typography>
                <Typography variant="body2" color={palette.text.secondary}>
                    <Trans
                        i18nKey="check_out_form_subtitle_complete"
                        values={{
                            date: requestedDateCheckOutFormatted,
                        }}
                        components={{
                            b: (
                                <b
                                    css={{
                                        color: palette.text.primary,
                                        fontWeight: '600',
                                    }}
                                />
                            ),
                        }}
                    />
                </Typography>
            </Stack>

            <IconButton>
                <ArrowIcon
                    css={{
                        color: palette.text.primary,
                        width: '24px',
                        height: '24px',
                        transform: 'rotate(-90deg)',
                    }}
                />
            </IconButton>
        </Stack>
    );
};
