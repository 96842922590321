import { isFuture, parseISO } from 'date-fns';

import { useGetActiveBooking } from './use-get-active-booking';

export const useIsCheckedIn = () => {
    const { data: activeBooking } = useGetActiveBooking();

    const isCheckedIn = Boolean(
        activeBooking!.date_check_in && !isFuture(parseISO(activeBooking!.date_check_in))
    );

    return isCheckedIn;
};
