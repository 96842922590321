import { format, parseISO } from 'date-fns';

import { useDateLocale } from './use-date-locale';

export const useFormattedDate = () => {
    const dateLocale = useDateLocale();

    const getFormattedDate = (iso: string, formatString = 'PPPP') => {
        const date = parseISO(iso);

        return format(date, formatString, { locale: dateLocale });
    };

    return getFormattedDate;
};
