import { FC, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
    List,
    ListItemButton,
    Typography,
    Box,
    Button,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

import { useScrollDirection } from 'src/hooks/use-scroll-direction';
import { ReactComponent as CrossIcon } from 'src/assets/svg/cross.svg';

import { FilterKeys } from '../types/filter';
import { FILTER_OPTIONS, NULL_FILTER, OPTIONS_MAP } from '../constants/filter';

import { PostsShowFilter } from './icons';

export const PostsFilter: FC = () => {
    const { breakpoints, shape, palette, zIndex, transitions, custom } = useTheme();
    const { scrollData, prevScrollData } = useScrollDirection({
        delay: transitions.duration.standard,
    });

    const [isOpen, setOpen] = useState(false);

    const selectedFilterId = NULL_FILTER;
    const selectedFilter = OPTIONS_MAP[NULL_FILTER];

    const toggleOpen = () => setOpen(!isOpen);

    const deleteActiveFilter = () => {
        toggleOpen();
    };

    const setActiveFilter = (nextFilter: FilterKeys) => () => {
        if (nextFilter === selectedFilterId) {
            deleteActiveFilter();
            return;
        }

        toggleOpen();
    };

    return (
        <Box
            width={{ xs: 'stretch', lg: '280px' }}
            height="fit-content"
            position={{ xs: 'fixed', lg: 'sticky' }}
            zIndex={{ xs: zIndex.appBar - 1, lg: 0 }}
            borderBottom={{ xs: `1px solid ${palette.divider}`, lg: 'none' }}
            padding={{
                xs: '16px',
                md:
                    scrollData.y <= prevScrollData.y
                        ? `${custom.headerHeight + 16}px 60px 16px 60px`
                        : '16px 60px',
                lg: 0,
            }}
            top={{ xs: 0, lg: `${custom.headerHeight}px` }}
            css={{
                background: palette.background.default,
                transition: transitions.create('all'),
            }}
        >
            <Box maxWidth="760px" marginX="auto">
                <Typography variant="h2" display={{ xs: 'none', lg: 'block' }}>
                    FILTERS
                </Typography>

                <Accordion
                    expanded={isOpen}
                    onChange={toggleOpen}
                    disableGutters
                    css={{
                        background: 'inherit',
                        boxShadow: 'none',

                        '& .MuiCollapse-root': {
                            [breakpoints.up('lg')]: {
                                height: 'auto !important',
                                visibility: 'visible',
                            },
                        },
                    }}
                >
                    <AccordionSummary
                        css={{
                            padding: 0,
                            margin: 0,
                            '.MuiAccordionSummary-content': { margin: 0, gap: '16px' },

                            [breakpoints.up('lg')]: {
                                display: 'none',
                            },
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="inherit"
                            endIcon={<PostsShowFilter />}
                            css={{
                                padding: '8px 16px',
                                border: `1px solid ${palette.divider}`,

                                [breakpoints.up('lg')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <Typography variant="subtitle2">Filters</Typography>
                        </Button>

                        {selectedFilter && (
                            <Chip
                                label={selectedFilterId}
                                icon={<selectedFilter.icon width="16px" height="16px" />}
                                deleteIcon={<CrossIcon width="16px" height="16px" />}
                                onDelete={deleteActiveFilter}
                                css={{
                                    height: 'auto',
                                    padding: '8px',
                                    background: palette.background.paper,
                                    borderRadius: `${shape.borderRadius}px`,
                                }}
                            />
                        )}
                    </AccordionSummary>

                    <AccordionDetails css={{ padding: 0, marginTop: '24px' }}>
                        <List
                            component="nav"
                            aria-label="posts filter"
                            disablePadding
                            css={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '8px',

                                [breakpoints.up('lg')]: {
                                    flexDirection: 'column',
                                    gap: '16px',
                                },
                            }}
                        >
                            {FILTER_OPTIONS.map((filter) => {
                                const isSelected = filter === selectedFilterId;

                                return (
                                    <ListItemButton
                                        key={filter}
                                        selected={isSelected}
                                        onClick={setActiveFilter(filter)}
                                        css={{
                                            flex: '0 1 auto',
                                            width: 'fit-content',
                                            gap: '16px',
                                            borderRadius: `${shape.borderRadius}px`,

                                            [breakpoints.down('lg')]: {
                                                justifyContent: 'center',
                                                gap: '8px',
                                            },
                                        }}
                                    >
                                        <selectedFilter.icon
                                            width="16px"
                                            height="16px"
                                            fillOpacity={isSelected ? 1 : 0.4}
                                        />
                                        <Typography
                                            color={isSelected ? 'text.primary' : 'text.secondary'}
                                            variant={isSelected ? 'subtitle2' : 'body2'}
                                        >
                                            {filter}
                                        </Typography>

                                        {isSelected && (
                                            <CrossIcon
                                                width="16px"
                                                height="16px"
                                                css={{
                                                    [breakpoints.down('lg')]: { display: 'none' },
                                                }}
                                            />
                                        )}
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};
