import { AxiosError } from 'axios';
import { FieldValues, Path, UseFormSetError } from 'react-hook-form';

import { attachmentResponseCodes, ConciergeErrorResponse } from 'src/api/concierge';

export const setAttachmentError =
    <TFormData extends FieldValues>(
        field: Path<TFormData>,
        setFormError: UseFormSetError<TFormData>
    ) =>
    (error: AxiosError<ConciergeErrorResponse>) => {
        const code = error?.response?.data?.code;

        if (code && attachmentResponseCodes.includes(code)) {
            setFormError(field, {
                type: 'server',
                message: code,
            });
        }
    };
