import { initFirebaseMessaging } from 'src/services/firebase';

import { postMessagingToken } from '../api/requests';

import { handleFcmMessage } from './handle-fcm-message';

export const handleSetUpMessaging = async (userId: string) => {
    const { token, onMessage } = await initFirebaseMessaging();

    await postMessagingToken(userId, token);

    return onMessage(handleFcmMessage);
};
