import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isPast } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Alert } from '@mui/material';

import { PhaseData } from '../../api/types';

interface Props {
    phase: PhaseData;
}

export const RebookingLinkLabel: FC<Props> = ({ phase }) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const isStarted = isPast(new Date(phase.start_date));

    let term = null;
    let primary = false;

    if (!phase.active && !isStarted) {
        term = t('rebookers_link_label_coming-soon');
        primary = true;
    } else if (!phase.active && isStarted) {
        term = t('rebookers_link_label_inactive');
    } else if (phase.discount) {
        term = t('rebookers_swap_your_room_discount', { count: phase.discount });
        primary = true;
    }

    return term ? (
        <Alert
            variant="filled"
            severity="error"
            icon={false}
            css={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                padding: '6px 12px',
                background: primary ? undefined : palette.common.black,
            }}
        >
            {term}
        </Alert>
    ) : null;
};
