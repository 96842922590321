import { ONE_HOUR_SLOTS, THREE_HOUR_SLOTS, Time, TWO_HOUR_SLOTS } from 'src/constants/slots';

export const getSlotByDuration = (time: Time, duration: number) => {
    switch (duration) {
        case 1:
            return ONE_HOUR_SLOTS.get(time);
        case 2:
            return TWO_HOUR_SLOTS.get(time);
        default:
            return THREE_HOUR_SLOTS.get(time);
    }
};
