import { Stack, Typography } from '@mui/material';
import { FC, Fragment, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import { Image } from 'src/components/image';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { CARD_BACKGROUND_URL } from '../constants/card-background';

import { AlumniModal } from './alumni-modal';

export const AlumniCard: FC = () => {
    const { palette, breakpoints, transitions } = useTheme();

    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const handleToggleModalOpened = () => {
        setModalOpen(!modalOpen);

        GA4.modalEvent({
            name: 'Alumni Modal',
            location: ' Home Page',
            label: t('home_exclusive-offers_alumni_title', { lng: LanguagesCode.English }),
            open: !modalOpen,
        });
    };

    return (
        <Fragment>
            <Stack
                onClick={handleToggleModalOpened}
                css={{
                    textDecoration: 'none',
                    cursor: 'pointer',

                    ':hover *.PromotionCardInner__preview img': {
                        transform: 'scale(1.25)',
                    },
                }}
            >
                <Stack
                    padding="24px"
                    height={{ xs: '180px', md: '220px', lg: '260px' }}
                    position="relative"
                >
                    <Typography
                        variant="h2"
                        color={palette.common.white}
                        paddingBottom="16px"
                        position="relative"
                        padding={0}
                        zIndex={2}
                    >
                        <Trans i18nKey="home_exclusive-offers_alumni_image-content" />
                        <span
                            css={{
                                position: 'absolute',
                                bottom: '1px',
                                left: '14px',
                                background: palette.primary.main,
                                height: '6px',
                                width: '93px',
                                zIndex: -1,
                            }}
                        />
                    </Typography>

                    <Image
                        alt="Vita Student Alumni"
                        src={CARD_BACKGROUND_URL}
                        className="PromotionCardInner__preview"
                        css={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',

                            img: {
                                transition: transitions.create('all'),
                            },
                        }}
                    />
                </Stack>

                <Stack
                    padding={{ xs: '16px 0' }}
                    flexGrow={1}
                    css={{
                        margin: '0 16px',
                        borderBottom: `1px solid ${palette.divider}`,
                        [breakpoints.up('md')]: {
                            margin: '0',
                        },
                    }}
                >
                    <Typography variant="h4" paddingBottom="4px" color={palette.text.secondary}>
                        {t('home_exclusive-offers_alumni_subtitle')}
                    </Typography>

                    <Typography variant="h2" color={palette.text.primary} paddingBottom="16px">
                        {t('home_exclusive-offers_alumni_title')}
                    </Typography>

                    <span
                        css={{
                            width: '60px',
                            borderBottom: `1px solid ${palette.common.black}`,
                            display: 'block',
                        }}
                    />

                    <Typography
                        variant="body1"
                        color={palette.text.primary}
                        paddingTop="16px"
                        paddingBottom="16px"
                    >
                        {t('home_exclusive-offers_alumni_description')}
                    </Typography>
                </Stack>
            </Stack>

            <AlumniModal open={modalOpen} onClose={handleToggleModalOpened} />
        </Fragment>
    );
};
