import { FC, Fragment, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { PrivateHat } from 'src/layout/private-hat';
import { StateView } from 'src/components/state-view';
import { routes } from 'src/constants/routes';

import { Subject } from '../constants/form-options';
import { SUBJECT_TITLE_TRANSLATION_KEY } from '../constants/translation-keys';

export const FeedbackEventsFormFail: FC<{ children?: ReactNode }> = ({ children }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleBackToFeedback = () => navigate(routes.feedback.index);

    const handleBackToEvents = () => navigate(routes.feedback.events.index);

    return (
        <Fragment>
            <PrivateHat
                title={t(SUBJECT_TITLE_TRANSLATION_KEY[Subject.Events])}
                backIconText={t('back_cta')}
            />
            <StateView
                state="fail"
                title={<Trans i18nKey="feedback_page_events_error_not-exist-no-access_title" />}
                actions={{
                    main: {
                        text: t(
                            'feedback_page_events_error_not-exist-no-access_cta_back-to-event-feedback'
                        ),
                        callback: handleBackToEvents,
                    },
                    secondary: {
                        text: t(
                            'feedback_page_events_error_not-exist-no-access_cta_back-to-feedback-reviews'
                        ),
                        callback: handleBackToFeedback,
                    },
                }}
            >
                {children}
            </StateView>
        </Fragment>
    );
};
