import { FC, Fragment } from 'react';
import {
    Controller,
    Control,
    FormState,
    UseFormSetError,
    UseFormClearErrors,
} from 'react-hook-form';
import {
    ToggleButtonGroup,
    ToggleButton,
    Stack,
    TextField,
    Typography,
    FormHelperText,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/button';
import { appConfig } from 'src/app/app-config';
import { ConciergeResponseCode } from 'src/api/concierge';
import { GA4 } from 'src/lib/ga4';
import {
    addAttachments,
    deleteAttachment,
    AttachmentsGallery,
    AttachmentError,
} from 'src/components/attachment-gallery';

import {
    FeedbackFormFields,
    RATING_OPTION_LABELS,
    RATING_OPTIONS,
    Subject,
} from '../constants/form-options';
import { SUBJECT_RATING_TITLE_TRANSLATION_KEY } from '../constants/translation-keys';
import { FEEDBACK_ATTACHMENT_ERROR_KEYS } from '../constants/attachments-error-translation-keys';

export interface FeedbackFormData {
    [FeedbackFormFields.Rating]: string | null;
    [FeedbackFormFields.Comment]: string;
    [FeedbackFormFields.Attachments]: File[];
}

interface Props {
    onFormSubmit: VoidFunction;
    formState: FormState<FeedbackFormData>;
    isLoading: boolean;
    subject: Subject;
    control: Control<FeedbackFormData>;
    setError: UseFormSetError<FeedbackFormData>;
    clearErrors: UseFormClearErrors<FeedbackFormData>;
}

export const FeedbackForm: FC<Props> = ({
    onFormSubmit,
    formState,
    isLoading,
    subject,
    control,
    setError,
    clearErrors,
}) => {
    const { palette, breakpoints } = useTheme();

    const { t } = useTranslation();

    const handleRatingChange = (option: string) => () => {
        GA4.selectEvent({
            name: 'Feedback Rating Selected',
            option,
            location: 'Feedback Form',
        });
    };

    return (
        <Stack
            component="form"
            gap={{ xs: '52px', md: '60px' }}
            flexGrow={{ xs: 1, md: 0 }}
            width="100%"
            onSubmit={onFormSubmit}
            alignItems={{ md: 'center' }}
        >
            <Controller
                name={FeedbackFormFields.Rating}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack gap="6px" width="100%">
                        <Typography variant="h4" component="label" htmlFor={field.name}>
                            {t(SUBJECT_RATING_TITLE_TRANSLATION_KEY[subject])}
                            <span css={{ color: palette.primary.main }}>&nbsp;*</span>
                        </Typography>

                        <ToggleButtonGroup
                            {...field}
                            exclusive
                            aria-label="Rating"
                            fullWidth
                            css={{
                                marginTop: '18px',
                                justifyContent: 'space-between',

                                [breakpoints.up('md')]: {
                                    marginTop: '28px',
                                },
                            }}
                        >
                            {RATING_OPTIONS.map((option) => (
                                <ToggleButton
                                    key={option}
                                    value={option}
                                    onClick={handleRatingChange(option)}
                                    css={{
                                        border: 'none',
                                        borderRadius: 0,
                                        width: '53px',
                                        height: '53px',
                                        fontSize: '20px',
                                        background: palette.background.paper,
                                        color: palette.common.black, // by default, reduces opacity of non-selected buttons

                                        '&.Mui-selected': {
                                            background: `${palette.common.black} !important`,
                                        },
                                    }}
                                >
                                    {RATING_OPTION_LABELS[option]}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>

                        {fieldState.error?.message && (
                            <FormHelperText variant="standard" error>
                                {t(fieldState.error.message)}
                            </FormHelperText>
                        )}
                    </Stack>
                )}
            />

            <Controller
                name={FeedbackFormFields.Comment}
                control={control}
                render={({ field, fieldState }) => (
                    <Stack gap="20px" flexGrow={1} width="100%">
                        <Typography variant="h4" component="label" htmlFor={field.name}>
                            {t('feedback_page_details_title')}
                            <span css={{ color: palette.primary.main }}>&nbsp;*</span>
                        </Typography>

                        <TextField
                            {...field}
                            variant="standard"
                            placeholder={t('feedback_page_details_placeholder')}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message && t(fieldState.error.message)}
                            css={{
                                '.MuiInput-root': {
                                    padding: '10px 0',
                                },
                            }}
                        />
                    </Stack>
                )}
            />

            <Stack width="100%" gap="12px">
                <Controller
                    name={FeedbackFormFields.Attachments}
                    control={control}
                    defaultValue={[]}
                    render={({ field, fieldState }) => (
                        <Fragment>
                            <Stack gap="28px" flexGrow={1} width="100%">
                                <Typography variant="h4" component="label" htmlFor={field.name}>
                                    {t('feedback_page_attach-files_title')}
                                </Typography>

                                <AttachmentsGallery
                                    attachments={field.value}
                                    limit={appConfig.attachments.maxCount}
                                    onChange={addAttachments(field, clearErrors, setError)}
                                    onDelete={deleteAttachment(field, clearErrors, setError)}
                                    css={{
                                        gap: '8px !important',

                                        [breakpoints.up('md')]: {
                                            gap: '25px !important',
                                        },
                                    }}
                                />
                            </Stack>
                            {fieldState.error && (
                                <AttachmentError
                                    translationsMap={FEEDBACK_ATTACHMENT_ERROR_KEYS}
                                    errorCode={fieldState.error.message as ConciergeResponseCode}
                                />
                            )}
                        </Fragment>
                    )}
                />
            </Stack>

            <Button
                type="submit"
                design="primary"
                color="primary"
                loading={isLoading}
                disabled={!formState.isValid}
                css={{
                    [breakpoints.up('md')]: {
                        width: '260px',
                    },
                }}
            >
                {t('feedback_page_cta')}
            </Button>
        </Stack>
    );
};
