import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { queryClient } from 'src/lib/query-client';

import { postCancelSocialSpaceBooking, SocialSpacesEndpoints, SocialSpaceBooking } from '../api';

type Config = UseMutationOptions<SocialSpaceBooking, AxiosError<ConciergeErrorResponse>>;

export const usePostCancelSocialSpaceBooking = (id: string, config: Config = {}) => {
    const { onSuccess, ...restConfig } = config;

    const mutation = useMutation<SocialSpaceBooking, AxiosError<ConciergeErrorResponse>>({
        mutationFn: async () => {
            const data = await postCancelSocialSpaceBooking(id);
            return data;
        },

        onSuccess(data, variables, context) {
            queryClient.invalidateQueries([SocialSpacesEndpoints.GetMySocialSpaceBookings]);

            onSuccess?.(data, variables, context);
        },

        ...restConfig,
    });

    return mutation;
};
