import { ConciergeSuccessResponse, conciergeClient } from 'src/api/concierge';

import { BuildingEndpoints } from './constants';

import type { BuildingOffers, Buildings } from './types';

export const getActiveBuilding = async () => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<Buildings>>(
        BuildingEndpoints.getBuildings,
        { params: { active: true } }
    );

    return response.data;
};

export const getBuildingOffers = async (id: string) => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<BuildingOffers>>(
        BuildingEndpoints.getOffers.replace('{id}', id)
    );

    return response.data;
};
