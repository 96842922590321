import { FeedbackAttachment, CreateFeedbackForm, PostFeedbackFormBody } from '../api/types';
import { FeedbackFormFields } from '../constants/form-options';

export const mapCreateFeedbackForm = (
    form: CreateFeedbackForm,
    attachments: FeedbackAttachment[]
): PostFeedbackFormBody => ({
    ...form,
    [FeedbackFormFields.Attachments]: attachments,
});
