import { useTheme } from '@emotion/react';
import { Box, Stack } from '@mui/material';

import { useGetBookings } from 'src/features/bookings';
import { useGetCurrentUser } from 'src/features/user';
import { appConfig } from 'src/app/app-config';

import mockMovingOutData from '../../mocks/moving-out-data.json';

import { MoveOutCardImage } from './parts/move-out-card-image';
import { MoveOut } from './parts/move-out';

export const MoveOutCard = () => {
    const { palette } = useTheme();

    const { data: bookings } = useGetBookings();

    const { data: user } = useGetCurrentUser();

    const currentBooking = bookings!.find(({ year }) => year === appConfig.activeYear);

    if (!currentBooking || currentBooking.date_check_out || !currentBooking.is_main_occupant)
        return null;

    const requestedDateCheckOut = appConfig.mockData
        ? mockMovingOutData.requested_date_check_out
        : currentBooking.requested_date_check_out;

    const bookingId = currentBooking.id;
    const accountId = user!.id!;

    const checkOutFormURL = appConfig.moveIn.checkOutFormURL
        .replace('{bkgid}', bookingId)
        .replace('{accid}', accountId);

    return (
        <Box
            maxWidth={{ md: '640px' }}
            textAlign="center"
            css={{
                width: '100%',
                background: palette.common.white,
                boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.08)',
            }}
        >
            <Stack textAlign="left" alignItems="center">
                <MoveOutCardImage
                    bookingYear={currentBooking.year}
                    isComplete={Boolean(requestedDateCheckOut)}
                />

                <MoveOut
                    requestedDateCheckOut={requestedDateCheckOut}
                    checkOutFormURL={checkOutFormURL}
                />
            </Stack>
        </Box>
    );
};
