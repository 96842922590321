import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Divider, Stack, Typography } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { PrivateHat } from 'src/layout/private-hat';
import {
    FeedbackForm,
    FeedbackFormData,
    feedbackFormDefaultValues,
    FeedbackFormFail,
    FeedbackFormFields,
    FeedbackFormSuccess,
    FeedbackSchema,
    STAFF_CATEGORY_TRANSLATION_KEY,
    STAFF_CATEGORY_VALUES,
    StaffCategory,
    Subject,
    SUBJECT_TITLE_TRANSLATION_KEY,
    SUBJECT_VALUES,
    usePostFeedback,
} from 'src/features/feedback';
import { routes } from 'src/constants/routes';
import { EventType, GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { setAttachmentError } from 'src/components/attachment-gallery/utils/set-attachment-error';

export const FeedbackFormPage: FC = () => {
    const { t } = useTranslation();

    const { subject, staffCategory } = useParams<{
        subject: Subject;
        staffCategory: StaffCategory;
    }>();

    const { control, handleSubmit, formState, setError, clearErrors } = useForm<FeedbackFormData>({
        resolver: yupResolver(FeedbackSchema),
        defaultValues: feedbackFormDefaultValues,
    });

    const { mutate, isSuccess, isLoading, isError, reset, data } = usePostFeedback({
        onError: () => setAttachmentError(FeedbackFormFields.Attachments, setError),
    });

    const handleFormSubmit = handleSubmit((formData) => {
        const submitData = {
            [FeedbackFormFields.Rating]: formData[FeedbackFormFields.Rating]!,
            [FeedbackFormFields.Comment]: formData[FeedbackFormFields.Comment],
            [FeedbackFormFields.Attachments]: formData[FeedbackFormFields.Attachments],
            [FeedbackFormFields.Subject]: SUBJECT_VALUES[subject!],
            ...(subject === Subject.Staff && {
                [FeedbackFormFields.StaffCategory]: STAFF_CATEGORY_VALUES[staffCategory!],
            }),
        };

        mutate(submitData);

        GA4.event('Feedback Form Submit', EventType.Submit, {
            label: t('feedback_page_cta', { lng: LanguagesCode.English }),
            location: 'Feedback Form',
            data: submitData,
        });
    });

    // check if subject is a valid value
    if (!subject || !Object.values(Subject).includes(subject)) {
        return <Navigate to={routes.feedback.index} />;
    }

    // check if staff category is a valid value
    if (subject === Subject.Staff && !Object.values(StaffCategory).includes(staffCategory!)) {
        return <Navigate to={routes.feedback.staff.index} />;
    }

    if (isError && formState.isValid) {
        return (
            <FeedbackFormFail title={t(SUBJECT_TITLE_TRANSLATION_KEY[subject])} onRetry={reset} />
        );
    }

    if (isSuccess) {
        return <FeedbackFormSuccess subject={subject} reset={reset} feedback={data} />;
    }

    return (
        <Fragment>
            <PrivateHat
                title={t(SUBJECT_TITLE_TRANSLATION_KEY[subject])}
                backIconText={t('back_cta')}
            />

            <Stack
                padding={{ xs: '16px', md: '40px' }}
                justifyContent={{ md: 'center' }}
                alignItems="center"
                flexGrow={1}
            >
                <Stack maxWidth="500px" gap={{ xs: '40px', md: '48px' }}>
                    {subject === Subject.Staff && (
                        <Fragment>
                            <Typography
                                variant="overlineRegular"
                                color="text.primary"
                                aria-label="link text"
                                textAlign={{ md: 'center' }}
                            >
                                <Trans
                                    i18nKey="feedback_page_staff_description"
                                    components={{
                                        strong: (
                                            <Typography
                                                variant="overlineMedium"
                                                color="primary.main"
                                            />
                                        ),
                                    }}
                                    values={{
                                        team: t(
                                            STAFF_CATEGORY_TRANSLATION_KEY[
                                                staffCategory as StaffCategory
                                            ]
                                        ),
                                    }}
                                />
                            </Typography>

                            <Divider />
                        </Fragment>
                    )}

                    <FeedbackForm
                        onFormSubmit={handleFormSubmit}
                        formState={formState}
                        control={control}
                        isLoading={isLoading}
                        subject={subject}
                        setError={setError}
                        clearErrors={clearErrors}
                    />
                </Stack>
            </Stack>
        </Fragment>
    );
};
