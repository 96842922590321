import { type FC } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { Image } from 'src/components/image';
import { ReactComponent as PlayIcon } from 'src/assets/svg/play.svg';

export const GuideVideo: FC<{ thumbnail_url: string; video_url: string; title: string }> = ({
    thumbnail_url,
    video_url,
    title,
}) => {
    const { palette } = useTheme();

    return (
        <Link
            target="_blank"
            href={video_url}
            css={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                    '[aria-label="play icon"]': {
                        backgroundColor: palette.common.white,
                        color: palette.primary.main,
                    },
                    '[aria-label="link title"]': {
                        borderColor: palette.common.black,
                        backgroundColor: palette.common.black,
                        color: palette.common.white,
                    },
                },
            }}
        >
            <Box position="relative" width="100%" paddingTop={{ xs: '56%', md: '42.85%' }}>
                <Image
                    src={thumbnail_url}
                    css={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        inset: 0,
                        filter: 'brightness(0.7)',
                    }}
                />
                <PlayIcon
                    aria-label="play icon"
                    css={{
                        position: 'absolute',
                        top: 'calc(50% - 26px)',
                        left: 'calc(50% - 26px)',
                        width: '52px',
                        height: '52px',
                        padding: '14px',
                        borderRadius: '50%',
                        backgroundColor: palette.primary.main,
                        color: palette.common.white,
                        transitionProperty: 'color, background-color',
                        transitionDuration: '0.5s',
                    }}
                />
            </Box>

            <Box
                aria-label="link title"
                border={`1px solid ${palette.divider}`}
                padding="11px"
                color={palette.text.primary}
                textAlign="center"
                css={{
                    transitionProperty: 'color, background-color, border-color',
                    transitionDuration: '0.5s',
                }}
            >
                <Typography variant="h6">{title}</Typography>
            </Box>
        </Link>
    );
};
