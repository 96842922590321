import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import type { ConciergeErrorResponse } from 'src/api/concierge';

import { BuildingNews } from '../api/types';
import { getBuildingNews } from '../api/requests';
import { BuildingUpdatesEndpoints } from '../api/constants';

type Config = UseQueryOptions<BuildingNews, ConciergeErrorResponse>;

export const useGetBuildingNews = (id: string, config: Config = {}) =>
    useQuery<BuildingNews, ConciergeErrorResponse>({
        queryKey: [BuildingUpdatesEndpoints.getNews, id],
        queryFn: () => getBuildingNews(id),
        ...config,
    });
