import { FC, SVGProps } from 'react';
import { keyframes } from '@emotion/react';

const rotateAnimation = keyframes({
    to: {
        transform: 'rotate(360deg)',
    },
});

type Props = SVGProps<SVGSVGElement> & {
    size?: 'small' | 'big';
};

export const Spinner: FC<Props> = ({ size = 'small', ...svgProps }) => {
    switch (size) {
        case 'big':
            return (
                <svg
                    width="182"
                    height="182"
                    viewBox="0 0 182 182"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={{
                        animation: `${rotateAnimation} 1s linear infinite`,
                    }}
                    {...svgProps}
                >
                    <circle cx="91" cy="91" r="90.5" stroke="currentColor" opacity="0.25" />
                    <path
                        d="M91 2.5C102.622 2.5 114.13 4.78912 124.867 9.23666C135.605 13.6842 145.361 20.2031 153.579 28.421C161.797 36.639 168.316 46.3952 172.763 57.1325C177.211 67.8698 179.5 79.378 179.5 91"
                        stroke="url(#paint0_linear_5747_28207)"
                        strokeWidth="5"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_5747_28207"
                            x1="182"
                            y1="91"
                            x2="119"
                            y2="-12"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="currentColor" />
                            <stop offset="1" stopColor="currentColor" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            );
        case 'small':
        default:
            return (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={{
                        animation: `${rotateAnimation} 1s linear infinite`,
                    }}
                    {...svgProps}
                >
                    <circle cx="20" cy="20" r="19.5" stroke="currentColor" opacity="0.25" />
                    <path
                        d="M20 1.5C22.4295 1.5 24.8351 1.97852 27.0796 2.90823C29.3242 3.83794 31.3636 5.20064 33.0815 6.91852C34.7994 8.63641 36.1621 10.6758 37.0918 12.9204C38.0215 15.1649 38.5 17.5705 38.5 20"
                        stroke="url(#paint0_linear_5747_28231)"
                        strokeWidth="3"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_5747_28231"
                            x1="40"
                            y1="20"
                            x2="24.5"
                            y2="8.9504e-07"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="currentColor" />
                            <stop offset="1" stopColor="currentColor" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
            );
    }
};
