import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { conciergeClient, ConciergeErrorResponse, ConciergeResponseCode } from 'src/api/concierge';
import { useAuth0 } from 'src/features/auth';
import { appConfig } from 'src/app/app-config';

import { setPreLogoutPath } from '../services/pre-logout-path';

import type { AxiosError } from 'axios';

export const useLogin = () => {
    const auth0 = useAuth0();
    const { pathname, search } = useLocation();

    const [isAuthorized, setIsAuthorized] = useState(false);
    const logoutRef = useRef(pathname + search);

    const isLoadedAndNotAuthorized = !auth0.isLoading && !auth0.isAuthenticated;
    logoutRef.current = pathname + search;

    if (isLoadedAndNotAuthorized) {
        auth0.loginWithRedirect({
            appState: {
                returnTo: logoutRef.current,
            },
            'ga4-id': appConfig.ga.enabled ? appConfig.ga.id : undefined,
        });
    }

    useEffect(() => {
        let redirectStarted = false;

        conciergeClient.interceptors.response.use(
            undefined,
            (error: AxiosError<ConciergeErrorResponse>) => {
                const errorCode = error.response?.data?.code;

                if (errorCode === ConciergeResponseCode.TokenProblem && !redirectStarted) {
                    redirectStarted = true;

                    setPreLogoutPath(logoutRef.current);

                    auth0.logout({ returnTo: window.location.origin });
                }

                return Promise.reject(error);
            }
        );
    }, []);

    useEffect(() => {
        const authorize = async () => {
            const idTokenClaims = await auth0.getIdTokenClaims();

            if (!idTokenClaims) {
                throw new Error('App can not work without a token');
            }

            // eslint-disable-next-line no-underscore-dangle
            conciergeClient.defaults.headers.common.Authorization = `Bearer ${idTokenClaims.__raw}`;

            setIsAuthorized(true);
        };

        if (auth0.isAuthenticated && !auth0.isLoading) {
            authorize();
        }
    }, [auth0.isAuthenticated, auth0.isLoading]);

    return isAuthorized;
};
