import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { captureException } from '@sentry/react';

import { AppPreloader } from 'src/components/app-preloader';
import { useDateLocale } from 'src/hooks/use-date-locale';
import { formatAmount } from 'src/utils/format-amount';
import { logger } from 'src/services/logger';
import { ErrorState } from 'src/components/error-state';

import { PaymentStatus } from '../../api';
import { useGetMakeMandate } from '../../hooks/use-get-make-mandate';
import { UpToDate } from '../up-to-date';

import { CardlessPaymentBase } from './cardless-payment-base';

export const CardlessPayment: FC<{ bookingId: string }> = ({ bookingId }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const { data, isLoading, isError } = useGetMakeMandate(undefined, { bookingId });

    if (isError) {
        return (
            <ErrorState
                title={
                    <Fragment>
                        Failure <br /> generating <br /> make mandate
                    </Fragment>
                }
            />
        );
    }

    if (isLoading) {
        return <AppPreloader background="paper" css={{ minHeight: '600px' }} />;
    }

    const [payment] = data;

    if (!payment || payment.payment_status === PaymentStatus.GoCardlessPaid) {
        return <UpToDate />;
    }

    const amount = formatAmount(payment.amount_outstanding_due, payment.amount_currency);

    const link = payment.authorisation_url;

    const dateDue = payment.date_due ? parseISO(payment.date_due) : null;
    const overdueDays = dateDue ? differenceInCalendarDays(new Date(), dateDue) : 0;
    const payDay = dateDue ? format(dateDue, 'dd MMM, yyyy', { locale: dateLocale }) : '';

    switch (payment.payment_status) {
        // Scenario 1: Direct debit is not set up
        case PaymentStatus.GoCardlessNoMandate:
            return (
                <CardlessPaymentBase
                    alert={{
                        message: (
                            <Trans i18nKey="my_bookings_direct_debit_component_available_setup_urgency" />
                        ),
                    }}
                    message={t('my_bookings_direct_debit_component_available_setup_title')}
                    action={{ link }}
                />
            );

        // Scenario 3: Direct Debit setup overdue
        // Scenario 5: Direct Debit setup Failed and there is overdue payment
        case PaymentStatus.GoCardlessOverdue:
        case PaymentStatus.GoCardlessSetupFailedOverdue:
            return (
                <CardlessPaymentBase
                    alert={{
                        message: t('my_bookings_direct_debit_overdue_setup_contact'),
                        showContacts: true,
                    }}
                    message={t('my_bookings_direct_debit_component_available_setup_title')}
                    action={{
                        link,
                        disabled: true,
                    }}
                />
            );

        // Scenario 4: Direct Debit setup Failed (redirect failure scenario) and there are no overdue payment
        case PaymentStatus.GoCardlessSetupFailedNoOverdue:
            return (
                <CardlessPaymentBase
                    alert={{
                        message: t('my_bookings_direct_debit_component_failed_setup_urgency'),
                    }}
                    action={{ link }}
                    message={t('my_bookings_direct_debit_component_failed_setup_title')}
                />
            );

        // Scenario 6: Direct Debit setup complete, payment has failed and overdue payment
        case PaymentStatus.GoCardlessPaymentFailed:
            return (
                <CardlessPaymentBase
                    alert={{
                        message: t('my_bookings_direct_debit_component_payment_failed_urgency'),
                        showContacts: true,
                    }}
                    payment={{
                        name: payment.name,
                        amount,
                        overdueDays,
                    }}
                />
            );

        // Scenario 7: Direct Debit setup completed, due instalment in the future
        case PaymentStatus.GoCardlessUpcoming:
            return (
                <CardlessPaymentBase
                    payment={{
                        name: payment.name,
                        payDay,
                        amount,
                    }}
                />
            );

        // Scenario 8: Direct Debit cancelled
        case PaymentStatus.GoCardlessCanceled:
            return (
                <CardlessPaymentBase
                    alert={{
                        message: t('my_bookings_direct_debit_component_failed_urgency'),
                    }}
                    message={t('my_bookings_direct_debit_component_failed_title')}
                    action={{ link }}
                />
            );

        default: {
            const msg = 'CardlessPayment payment unknown status';

            captureException(new Error(msg), {
                extra: {
                    madate: data,
                },
            });

            logger.error(msg);

            return null;
        }
    }
};
