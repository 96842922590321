import { forwardRef, Fragment, useImperativeHandle } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValid } from 'date-fns';
import { useTheme } from '@emotion/react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Box,
    Stack,
    Typography,
    Button,
    Autocomplete,
    FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';

import { LanguagesCode, LanguagesCodes } from 'src/i18n/constants';
import { routes } from 'src/constants/routes';
import { AppPreloader } from 'src/components/app-preloader';
import { getLocalIso } from 'src/utils/get-local-iso';
import { useGetActiveBooking, useIsCheckedIn } from 'src/features/bookings';
import { SfCountryCode } from 'src/api/concierge';
import { GA4 } from 'src/lib/ga4';

import {
    countries,
    genders,
    nationalities,
    studyYears,
    universitiesCountryMap,
    UserDataFields,
} from '../../api';
import { usePatchUser } from '../../hooks/use-patch-user';
import { EditUserSchema } from '../../validation/edit-user';
import { EditProfileFormData } from '../../types/edit-profile';
import { getUserDefaultValues } from '../../utils/get-user-form-default-values';
import { useGetCurrentUser } from '../../hooks/use-get-current-user';
import { getEditProfileFormDataAsPatchBody } from '../../utils/get-edit-profile-form-data-as-patch-body';
import { FeedbackAndReviewsCTA } from '../user-settings/parts/feedback-and-reviews-cta';
import { useFeatureFlags } from '../../hooks/use-feature-flags';

import { PhoneInput } from './parts/phone-input';

export const EditProfile = forwardRef<{ onSubmit: VoidFunction }>((props, ref) => {
    const navigate = useNavigate();

    const {
        t,
        i18n: { language },
    } = useTranslation();

    const { breakpoints } = useTheme();

    const featureFlags = useFeatureFlags();
    const isCheckedIn = useIsCheckedIn();

    const { data } = useGetCurrentUser();
    const user = data!;

    const { data: activeBooking } = useGetActiveBooking();
    const countryCode: SfCountryCode = activeBooking!.country_code;

    const universities = universitiesCountryMap[countryCode];

    const { control, handleSubmit } = useForm<EditProfileFormData>({
        resolver: yupResolver(EditUserSchema),
        defaultValues: getUserDefaultValues(user),
    });

    const { mutate, isLoading, isSuccess } = usePatchUser(user[UserDataFields.Id]!);

    const languages = new Intl.DisplayNames([language], { type: 'language' });

    const goBack = () => {
        navigate(routes.profile.index);

        GA4.ctaEvent({
            name: 'Edit Profile Cancel',
            label: t('profile_edit_details_cancel', { lng: LanguagesCode.English }),
            location: 'Edit Profile Form',
        });
    };

    const onSubmit = handleSubmit((formData) => {
        mutate(getEditProfileFormDataAsPatchBody(formData));

        GA4.submitEvent({
            name: 'Edit Profile Submit',
            label: t('profile_edit_details_save', { lng: LanguagesCode.English }),
            location: 'Edit Profile Form',
        });
    });

    useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit]);

    if (isSuccess) {
        return <Navigate to={routes.profile.index} />;
    }

    return (
        <Fragment>
            <Box padding={{ xs: '16px 16px 0 16px', md: '60px 60px 0 60px' }} flex={1}>
                <Stack
                    flex={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    display={{ xs: 'none', md: 'flex' }}
                >
                    <Typography variant="h4">{t('user_details_title')}</Typography>

                    <Box>
                        <Button color="inherit" onClick={goBack}>
                            {t('profile_edit_details_cancel')}
                        </Button>
                        <Button onClick={onSubmit}>{t('profile_edit_details_save')}</Button>
                    </Box>
                </Stack>

                {isLoading ? (
                    <AppPreloader size="big" />
                ) : (
                    <Stack
                        direction={{ lg: 'row' }}
                        component="form"
                        marginY="32px"
                        columnGap="120px"
                        rowGap="20px"
                    >
                        <Stack flex={1} gap="20px">
                            <Controller
                                name={UserDataFields.Gender}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <FormControl css={{ width: '100%' }}>
                                        <InputLabel
                                            id={UserDataFields.Gender}
                                            variant="standard"
                                            shrink
                                        >
                                            {t('gender_label')}
                                        </InputLabel>

                                        <InputLabel
                                            id={UserDataFields.Gender}
                                            variant="standard"
                                            aria-label="placeholder label"
                                        >
                                            {t('gender_placeholder')}
                                        </InputLabel>

                                        <Select
                                            variant="standard"
                                            labelId={UserDataFields.Gender}
                                            {...field}
                                        >
                                            {genders.map((gender) => (
                                                <MenuItem key={gender} value={gender}>
                                                    {gender}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {fieldState.error?.message && (
                                            <FormHelperText variant="standard" error>
                                                {t(fieldState.error.message)}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name={UserDataFields.BirthDate}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DatePicker
                                        {...field}
                                        onChange={(date) => {
                                            if (isValid(date)) {
                                                const iso = getLocalIso(date!);
                                                field.onChange(iso);
                                            } else {
                                                field.onChange(date);
                                            }
                                        }}
                                        maxDate={new Date()}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(textFieldProps) => (
                                            <TextField
                                                {...textFieldProps}
                                                variant="standard"
                                                label={t('dob_label')}
                                                placeholder={t('dob_placeholder')}
                                                error={!!fieldState.error}
                                                helperText={
                                                    fieldState.error?.message &&
                                                    t(fieldState.error?.message)
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Controller
                                name={UserDataFields.PhoneNumber}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <PhoneInput
                                        value={field.value || ''}
                                        onChange={field.onChange}
                                        error={!!fieldState.error}
                                        label={t('number_label')}
                                        helperText={
                                            fieldState.error?.message && t(fieldState.error.message)
                                        }
                                        required
                                    />
                                )}
                            />

                            <Controller
                                name={UserDataFields.AddressCountry}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        {...field}
                                        onChange={(_, value) => field.onChange(value)}
                                        options={countries}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label={t('country_label')}
                                                placeholder={t('country_placeholder')}
                                                InputLabelProps={{ shrink: true }}
                                                error={!!fieldState.error}
                                                helperText={
                                                    fieldState.error?.message &&
                                                    t(fieldState.error?.message)
                                                }
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />

                            <Controller
                                name={UserDataFields.AddressCity}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        type="email"
                                        label={t('city_label')}
                                        placeholder={t('city_placeholder')}
                                        InputLabelProps={{ shrink: true }}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error?.message &&
                                            t(fieldState.error?.message)
                                        }
                                        fullWidth
                                        required
                                    />
                                )}
                            />

                            <Controller
                                name={UserDataFields.AddressPostalCode}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        type="email"
                                        label={t('postcode_label')}
                                        placeholder={t('postcode_placeholder')}
                                        InputLabelProps={{ shrink: true }}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error?.message &&
                                            t(fieldState.error?.message)
                                        }
                                        fullWidth
                                        required
                                    />
                                )}
                            />

                            <Controller
                                name={UserDataFields.AddressStreet}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        type="email"
                                        label={t('address_label')}
                                        placeholder={t('address_placeholder')}
                                        InputLabelProps={{ shrink: true }}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error?.message &&
                                            t(fieldState.error?.message)
                                        }
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </Stack>

                        <Stack flex={1} gap="20px">
                            <Controller
                                name={UserDataFields.Language}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <FormControl css={{ width: '100%' }}>
                                        <InputLabel
                                            id={UserDataFields.Language}
                                            variant="standard"
                                            shrink
                                            required
                                        >
                                            {t('language_label')}
                                        </InputLabel>

                                        <InputLabel
                                            id={UserDataFields.Language}
                                            variant="standard"
                                            aria-label="placeholder label"
                                        >
                                            {t('language_placeholder')}
                                        </InputLabel>

                                        <Select
                                            variant="standard"
                                            labelId={UserDataFields.Language}
                                            {...field}
                                        >
                                            {LanguagesCodes.map((lng) => (
                                                <MenuItem key={lng} value={lng}>
                                                    {languages.of(lng)}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {fieldState.error?.message && (
                                            <FormHelperText variant="standard" error>
                                                {t(fieldState.error.message)}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name={UserDataFields.Nationality}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        {...field}
                                        onChange={(_, value) => field.onChange(value)}
                                        options={nationalities}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label={t('nationality_label')}
                                                placeholder={t('nationality_placeholder')}
                                                InputLabelProps={{ shrink: true }}
                                                error={!!fieldState.error}
                                                helperText={
                                                    fieldState.error?.message &&
                                                    t(fieldState.error?.message)
                                                }
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name={UserDataFields.University}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        {...field}
                                        options={universities}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label={t('university_label')}
                                                placeholder={t('university_placeholder')}
                                                InputLabelProps={{ shrink: true }}
                                                error={!!fieldState.error}
                                                helperText={
                                                    fieldState.error?.message &&
                                                    t(fieldState.error?.message)
                                                }
                                                fullWidth
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name={UserDataFields.Course}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        label={t('course_label')}
                                        placeholder={t('course_placeholder')}
                                        InputLabelProps={{ shrink: true }}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error?.message &&
                                            t(fieldState.error?.message)
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name={UserDataFields.UniversityYear}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <FormControl css={{ width: '100%' }}>
                                        <InputLabel
                                            id={UserDataFields.UniversityYear}
                                            variant="standard"
                                            shrink
                                            required
                                        >
                                            {t('yos_label')}
                                        </InputLabel>

                                        <InputLabel
                                            id={UserDataFields.UniversityYear}
                                            variant="standard"
                                            aria-label="placeholder label"
                                        >
                                            {t('yos_placeholder')}
                                        </InputLabel>

                                        <Select
                                            variant="standard"
                                            labelId={UserDataFields.UniversityYear}
                                            {...field}
                                        >
                                            {studyYears.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {fieldState.error?.message && (
                                            <FormHelperText variant="standard" error>
                                                {t(fieldState.error.message)}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name={UserDataFields.EmergencyContactName}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        label={t('emergency_name_label')}
                                        placeholder={t('emergency_name_placeholder')}
                                        InputLabelProps={{ shrink: true }}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error?.message &&
                                            t(fieldState.error?.message)
                                        }
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name={UserDataFields.EmergencyContactPhone}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <PhoneInput
                                        value={field.value || ''}
                                        onChange={field.onChange}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error?.message && t(fieldState.error.message)
                                        }
                                        label={t('emergency_number_label')}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                )}
            </Box>
            {featureFlags.feedback && isCheckedIn && (
                <FeedbackAndReviewsCTA
                    css={{
                        display: 'none',
                        [breakpoints.up('md')]: { display: 'flex', margin: '12px 0 0 0' },
                    }}
                />
            )}
        </Fragment>
    );
});
