import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { EventsEndpoints, getEvents, EventList } from '../api';

export const useGetEvents = <TQueryFnData = EventList>(
    config: UseQueryOptions<EventList, AxiosError<ConciergeErrorResponse>, TQueryFnData> = {}
) => {
    const query = useQuery<EventList, AxiosError<ConciergeErrorResponse>, TQueryFnData>({
        queryKey: [EventsEndpoints.GetEvents],
        queryFn: getEvents,
        ...config,
    });

    return query;
};
