import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { ModalProps, Stack, Typography } from '@mui/material';

import { Dialog } from 'src/components/dialog';
import { NotificationService } from 'src/services/notification';
import { logger } from 'src/services/logger';
import { useGetCurrentUser } from 'src/features/user';
import { theme } from 'src/styles/theme';

import { handleSetUpMessaging } from '../utils/handle-set-up-messaging';

const STORAGE_KEY = 'notifications_dialog_closed';

export const NotificationPermissionDialog: FC = () => {
    const { data: user } = useGetCurrentUser();
    const userId = user!.id;

    const [permissionStatus, setPermissionStatus] = useState(NotificationService.permission);

    const [isLoading, setIsLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(
        !sessionStorage.getItem(STORAGE_KEY) &&
            permissionStatus === NotificationService.status.Default
    );

    const { t } = useTranslation();

    const closeDialog = () => {
        sessionStorage.setItem(STORAGE_KEY, 'true');
        setIsOpen(false);
    };

    const handleCloseDialog: ModalProps['onClose'] = (event, reason) => {
        if (reason === 'backdropClick') return;

        closeDialog();
    };

    const handleRequestPermission = async () => {
        try {
            setIsLoading(true);

            const status = await NotificationService.requestPermission();

            setIsLoading(false);
            setPermissionStatus(status);
            closeDialog();

            if (status === NotificationService.status.Granted) {
                await handleSetUpMessaging(userId);
            }
        } catch (error) {
            captureException(error);
            logger.error(error);
        }
    };

    useEffect(() => {
        if (permissionStatus === NotificationService.status.Granted) {
            handleSetUpMessaging(userId).catch((error) => logger.error(error));
        }
    }, []);

    return (
        <Dialog
            open={isOpen}
            onClose={isLoading ? undefined : handleCloseDialog}
            actions={{
                accept: {
                    children: t('allow_notifications_model_cta'),
                    loading: isLoading,
                    onClick: handleRequestPermission,
                },
            }}
            css={{
                '.MuiDialog-paper': {
                    margin: 'auto 0 0 0',
                    maxWidth: 'unset',
                },

                '.MuiButton-root': {
                    [theme.breakpoints.up('md')]: {
                        maxWidth: '260px',
                    },
                },
            }}
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                gap="28px"
                maxWidth="280px"
            >
                <Typography variant="h2">{t('allow_notifications_model_title')}</Typography>
                <Typography>{t('allow_notifications_model_subtitle')}</Typography>
            </Stack>
        </Dialog>
    );
};
