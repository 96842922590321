import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { ASSETS_BASE_URL } from 'src/api/concierge';

export const MoveOutCardImage: FC<{ bookingYear: string; isComplete: boolean }> = ({
    bookingYear,
    isComplete,
}) => {
    const { palette } = useTheme();

    return (
        <Stack
            padding="20px"
            width="100%"
            css={{
                borderBottom: `1px solid ${palette.divider}`,
                color: palette.common.white,
                position: 'relative',
            }}
        >
            <Typography
                variant="h1"
                fontSize={{ xs: '46px', md: '52px' }}
                css={{
                    lineHeight: '85%',
                    letterSpacing: '0.02rem',
                    zIndex: 1,
                }}
            >
                <Trans i18nKey="check_out_online_title" values={{ year: bookingYear }} />
            </Typography>

            <img
                css={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    filter: isComplete ? 'grayscale(100)' : undefined,
                }}
                alt="move out background"
                src={`${ASSETS_BASE_URL}/images/move-out-card.jpg`}
            />
        </Stack>
    );
};
