import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { queryClient } from 'src/lib/query-client';

import { getTicket, MaintenanceEndpoints, MaintenanceTicket, MaintenanceTickets } from '../api';

type Config = UseQueryOptions<MaintenanceTicket, AxiosError<ConciergeErrorResponse>>;

export const useGetTicket = (config: Config = {}) => {
    const { id: ticketId = '' } = useParams();

    const tickets = queryClient.getQueryData<MaintenanceTickets>([MaintenanceEndpoints.getTickets]);
    const initialData = tickets?.find(({ id }) => id === ticketId);

    return useQuery({
        queryKey: [MaintenanceEndpoints.getTicket, ticketId],
        queryFn: () => getTicket(ticketId),
        initialData,
        ...config,
    });
};
