import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { queryClient } from 'src/lib/query-client';

import { Event, EventsEndpoints, postAttendEvent } from '../api';

export const usePostAttendEvent = (
    id: string,
    config: UseMutationOptions<Event, AxiosError> = {}
) => {
    const { onSuccess, ...restConfig } = config;

    return useMutation({
        mutationFn: () => postAttendEvent(id),

        onSuccess(data, variables, context) {
            queryClient.setQueryData([EventsEndpoints.GetEvent, id], data);

            queryClient.invalidateQueries([EventsEndpoints.GetMyEvents]);
            queryClient.invalidateQueries([EventsEndpoints.GetEvents]);

            onSuccess?.(data, variables, context);
        },

        ...restConfig,
    });
};
