import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { captureException } from '@sentry/react';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { logger } from 'src/services/logger';

import { EventsEndpoints, Event, EventList, getMyPastEventsList } from '../api';

type QueryConfig = UseQueryOptions<
    EventList,
    AxiosError<ConciergeErrorResponse>,
    Event | undefined
>;

const selectPastEvent = (eventId: string, events: EventList) => {
    const event = events?.find(({ id }) => id === eventId);

    if (!event) {
        const message = 'Event does not exist in past events';
        const exception = new Error(message);

        logger.error(message);
        captureException(exception);
    }

    return event;
};

export const useGetMyPastEvent = (eventId: string, config: QueryConfig = {}) => {
    const query = useQuery<EventList, AxiosError<ConciergeErrorResponse>, Event | undefined>({
        queryKey: [EventsEndpoints.GetMyPastEvents],
        queryFn: getMyPastEventsList,
        select: (data) => selectPastEvent(eventId, data),
        ...config,
    });

    return query;
};
