import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { Dialog } from 'src/components/dialog';

import { usePostCloseTicket } from '../../../hooks/use-post-close-ticket';

interface Props {
    ticketId: string;
    ticketIsResolved: boolean;
    showDialog: boolean;
    toggleDialog: VoidFunction;
}

export const ResolveTicketDialog: FC<Props> = ({
    ticketId,
    ticketIsResolved,
    showDialog,
    toggleDialog,
}) => {
    const { isLoading, mutate } = usePostCloseTicket(ticketId, {
        onSuccess: toggleDialog,
    });

    const { t } = useTranslation();

    const handleResolveIssue = () => mutate();

    return ticketIsResolved ? null : (
        <Dialog
            open={showDialog}
            loading={isLoading}
            onClose={toggleDialog}
            keepMounted
            actions={{
                decline: {
                    children: t('maintenance_resolve_model_button_cancel'),
                    onClick: toggleDialog,
                },

                accept: {
                    children: t('maintenance_resolve_model_button_resolve'),
                    onClick: handleResolveIssue,
                    disabled: ticketIsResolved,
                },
            }}
        >
            <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                gap="16px"
            >
                <Typography variant="h3" textAlign="center">
                    {t('maintenance_resolve_model_title')}
                </Typography>

                <Typography variant="body2">
                    {t('maintenance_resolve_model_description')}
                </Typography>
            </Stack>
        </Dialog>
    );
};
