import {
    ChangeEventHandler,
    FC,
    FocusEventHandler,
    KeyboardEventHandler,
    MouseEventHandler,
    ReactNode,
    useState,
} from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'src/components/search-input';
import { EventType, GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { RebookingFilterParams } from '../../api/constants';
import { RebookingFilterForm } from '../../types';

interface Props {
    id: RebookingFilterParams.RoomCode;
    placeholder: ReactNode;
    control: Control<RebookingFilterForm>;
}

const INITIAL_INPUT = '';

export const RebookingFilterSearch: FC<Props> = ({ id, placeholder, control }) => {
    const { t } = useTranslation();

    const [input, setInput] = useState(INITIAL_INPUT);

    const handleChange: ChangeEventHandler = ({ target }) =>
        setInput((target as HTMLInputElement).value);

    return (
        <Controller
            name={id}
            control={control}
            defaultValue=""
            render={({ field }) => {
                const handleSearch: MouseEventHandler = () => field.onChange(input);

                const handleBlur: FocusEventHandler = () => field.onChange(input);

                const handleClear: MouseEventHandler = () => {
                    setInput(INITIAL_INPUT);
                    field.onChange(INITIAL_INPUT);
                };

                const handleEnterPressSave: KeyboardEventHandler = ({ code }) => {
                    if (code === 'Enter') {
                        field.onChange(input);

                        GA4.event('Swap Room Search', EventType.Submit, {
                            location: 'Swap Room Filters',
                            label: t('rebookers_swap_your_room_search_placeholder', {
                                lng: LanguagesCode.English,
                            }),
                            value: input,
                        });
                    }
                };

                return (
                    <SearchInput
                        {...field}
                        value={input}
                        placeholder={placeholder as string}
                        onChange={handleChange}
                        onKeyDown={handleEnterPressSave}
                        onClear={handleClear}
                        onSearch={handleSearch}
                        onBlur={handleBlur}
                        css={{
                            gridColumn: '1/-1',
                        }}
                    />
                );
            }}
        />
    );
};
