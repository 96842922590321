import { FC, RefCallback } from 'react';
import { IconButton, Modal, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';

import { ReactComponent as CrossIcon } from 'src/assets/svg/cross-in-square.svg';
import { PrivateHat } from 'src/layout/private-hat';
import { Image } from 'src/components/image';

export const AttachmentsModal: FC<{
    open: boolean;
    attachments: string[];
    activeIndex: number;
    onClose?: VoidFunction;
}> = ({ open, attachments, activeIndex, onClose }) => {
    const { palette, breakpoints } = useTheme();

    const refCallback: RefCallback<HTMLDivElement> = (element) => {
        if (open && element) {
            requestAnimationFrame(() => {
                element.scrollIntoView({ behavior: 'smooth' });
            });
        }
    };

    return (
        <Modal open={open} onClose={onClose} keepMounted>
            <Stack
                css={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    overflow: 'auto',
                    background: palette.common.white,

                    [breakpoints.up('md')]: {
                        background: 'rgba(16, 16, 16, 0.95)',
                        backdropFilter: 'blur(5px)',
                    },
                }}
            >
                <PrivateHat
                    title="ATTACHED FILES"
                    showBackIcon={false}
                    actionLabel={
                        <IconButton
                            onClick={onClose}
                            css={{ position: 'absolute', right: 0, top: 0, padding: 0 }}
                        >
                            <CrossIcon width="50px" height="50px" />
                        </IconButton>
                    }
                    css={{
                        [breakpoints.up('md')]: {
                            background: 'none !important',
                            color: palette.common.white,
                        },
                    }}
                />

                <Stack
                    flex={1}
                    overflow="auto"
                    gap={{ xs: '12px', md: '32px' }}
                    paddingY={{ xs: '36px', md: '56px' }}
                >
                    {attachments.map((url, index) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${url}-${index}`}
                            ref={index === activeIndex ? refCallback : undefined}
                        >
                            <Image
                                src={url}
                                alt={`attachment ${index}`}
                                css={{
                                    maxHeight: `calc(100vh - 103px)`,
                                    flex: '1 0 auto',
                                    maxWidth: '640px',
                                    margin: '0 auto',
                                }}
                            />
                        </div>
                    ))}
                </Stack>
            </Stack>
        </Modal>
    );
};
