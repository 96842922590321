import { FC, Fragment, MouseEventHandler, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';

import { useDateLocale } from 'src/hooks/use-date-locale';
import { AppPreloader } from 'src/components/app-preloader';
import { Button } from 'src/components/button';
import { ReactComponent as LockIcon } from 'src/assets/svg/lock.svg';
import { ReactComponent as InfoSmallIcon } from 'src/assets/svg/warning.svg';
import { formatAmount } from 'src/utils/format-amount';
import { EventType, GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { ErrorState } from 'src/components/error-state';

import { transferMateRedirect } from '../../services/transfermate';
import { PaymentStatus } from '../../api';
import { useGetMakePayment } from '../../hooks/use-get-make-payment';
import { UpToDate } from '../up-to-date';
import { ReactComponent as TransferMateIcon } from '../../assets/svg/transfer-mate.svg';
import { ReactComponent as VisaIcon } from '../../assets/svg/visa.svg';
import { ReactComponent as AmericanExpressIcon } from '../../assets/svg/american-express.svg';
import { ReactComponent as MastercardIcon } from '../../assets/svg/mastercard-monochrome.svg';
import { ReactComponent as BankTransferIcon } from '../../assets/svg/bank-transfer-monochrome.svg';
import { ReactComponent as WeChatIcon } from '../../assets/svg/we-chat-monochrome.svg';
import { ReactComponent as UnionPayIcon } from '../../assets/svg/union-pay.svg';

const icons = [
    <TransferMateIcon key="Transfermate" />,
    <VisaIcon key="Visa" />,
    <AmericanExpressIcon key="American Express" />,
    <MastercardIcon key="Mastercard" />,
    <WeChatIcon key="WeChat" />,
    <BankTransferIcon key="Bank Transfer" />,
    <UnionPayIcon key="Union pay" />,
];

export const MakePayment: FC<{ bookingId: string }> = ({ bookingId }) => {
    const { palette, breakpoints } = useTheme();

    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const { data, isError, isLoading } = useGetMakePayment(undefined, { bookingId });
    const [isRedirecting, setRedirecting] = useState(false);

    if (isError) {
        return (
            <ErrorState
                title={
                    <Fragment>
                        Failure <br /> generating <br /> make payment
                    </Fragment>
                }
            />
        );
    }

    if (isLoading) {
        return <AppPreloader background="paper" css={{ minHeight: '600px' }} />;
    }

    const [payment] = data;

    if (!payment || payment.payment_status === PaymentStatus.TransfermatePaid) {
        return <UpToDate />;
    }

    const isOverdue = payment.payment_status === PaymentStatus.TransfermateOverdue;

    const overdueDate = parseISO(payment.date_due);
    const overdueDays = differenceInCalendarDays(new Date(), overdueDate);
    const dueByFormatted = format(overdueDate, 'dd MMM, yyyy', { locale: dateLocale });

    const handleMakePayment: MouseEventHandler = () => {
        window.addEventListener('pagehide', () => setRedirecting(false), { once: true }); // to solve Apple page caching issues

        setRedirecting(true);

        transferMateRedirect(payment);

        GA4.event('Make Payment', EventType.Cta, {
            location: 'Payment Card',
            label: t('my_bookings_instalment_component_make_payment_cta', {
                lng: LanguagesCode.English,
            }),
            method: payment.payment_status,
        });
    };

    return (
        <Box
            data-testid="MakePayment.container"
            padding={{ xs: '32px 20px', md: '40px' }}
            maxWidth={{ lg: '420px' }}
            textAlign="center"
            css={{
                border: isOverdue ? `1px solid ${palette.error.main}` : undefined,
                boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.08)',
            }}
        >
            <Box marginBottom="32px">
                {isOverdue && (
                    <Stack
                        direction="row"
                        gap="8px"
                        padding="16px"
                        textAlign="left"
                        css={{
                            background: palette.error.light,
                        }}
                    >
                        <InfoSmallIcon
                            css={{
                                flex: '0 0 auto',
                                color: palette.error.main,
                                width: '24',
                                height: '24px',
                            }}
                        />
                        <Typography variant="body2" color="error.main">
                            <Trans
                                i18nKey="my_bookings_instalment_component_overdue_payment_urgency"
                                values={{
                                    number_of_days: overdueDays,
                                }}
                            />
                        </Typography>
                    </Stack>
                )}

                <Stack css={{ background: palette.background.paper, padding: '16px' }}>
                    <Typography variant="body1" color="text.secondary">
                        {t('my_bookings_payment_component_you_are_paying')}
                    </Typography>
                    <Typography variant="subtitle1">{payment.name.toLocaleUpperCase()}</Typography>
                </Stack>
            </Box>

            <Box textAlign="center">
                <Typography variant="body1" color="text.secondary">
                    {t('my_bookings_instalment_component_remaining_to_pay')}
                </Typography>

                <Typography variant="h2" fontSize="38px">
                    {formatAmount(payment.amount_outstanding_due, payment.amount_currency)}
                </Typography>

                <Typography color="text.secondary" textAlign="right" component="span">
                    <Trans
                        i18nKey="my_bookings_instalment_component_due_by"
                        values={{
                            date: dueByFormatted,
                        }}
                        components={{
                            b: <Typography color="error" component="strong" fontWeight={700} />,
                        }}
                    />
                </Typography>
            </Box>

            <Button
                data-testid="MakePayment.cta"
                design="primary"
                endIcon={<LockIcon />}
                loading={isRedirecting}
                onClick={handleMakePayment}
                css={{
                    margin: '28px 0 12px 0',
                    width: '240px',

                    [breakpoints.up('md')]: {
                        width: '100%',
                    },
                }}
            >
                {t('my_bookings_instalment_component_make_payment_cta')}
            </Button>

            <Paper elevation={0} css={{ padding: '24px 16px' }}>
                <Typography variant="body2" color="text.secondary" textAlign="left">
                    {t('my_bookings_instalment_component_transfermate_decription')}
                </Typography>

                <Divider css={{ margin: '20px 0' }} />

                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridTemplateRows="auto auto"
                    justifyItems="center"
                    rowGap="24px"
                    css={{
                        svg: {
                            maxWidth: '100%',
                            gridColumn: 'span 4',

                            ':nth-of-type(n+4)': {
                                gridColumn: 'span 3',
                            },
                        },
                    }}
                >
                    {icons}
                </Box>
            </Paper>

            <Stack spacing="8px" marginTop="24px">
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                        {t('my_bookings_instalment_component_paid_label')}
                    </Typography>

                    <Typography variant="subtitle2">
                        {formatAmount(payment.amount_total_paid, payment.amount_currency)}
                    </Typography>
                </Stack>

                <Divider />

                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                        {t('my_bookings_instalment_component_total_amount_label')}
                    </Typography>

                    <Typography variant="subtitle2">
                        {formatAmount(payment.amount_total, payment.amount_currency)}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
};
