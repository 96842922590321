import i18next from 'i18next';

import { LanguagesCode } from 'src/i18n/constants';
import { PartialTranslation } from 'src/i18n/types';

/**
 * Some Spanish terms can be nullable. In this case we should provide English value
 */
export const getPartialTranslation = (data: PartialTranslation) =>
    data[i18next.language as LanguagesCode] || data[LanguagesCode.English];
