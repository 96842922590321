import { useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import omitBy from 'lodash/omitBy';

import { type ConciergeErrorResponse, type ConciergeSuccessResponse } from 'src/api/concierge';
import { getLocalIso } from 'src/utils/get-local-iso';

import { RebookingEndpoints, RebookingFilterParams } from '../api/constants';
import { getRebookingRooms } from '../api/requests';
import { RebookingFilterQuery, type RebookingRooms, type RebookingRoomsMeta } from '../api/types';
import { useRebookingStore } from '../store';

export const useGetRebookingRooms = () => {
    const { filters, page } = useRebookingStore();

    const rawParams =
        filters &&
        ({
            [RebookingFilterParams.BuildingId]:
                filters[RebookingFilterParams.BuildingId]?.building_id,
            [RebookingFilterParams.RoomType]: filters[RebookingFilterParams.RoomType]?.room_type,
            [RebookingFilterParams.RoomCode]: filters[RebookingFilterParams.RoomCode],
            [RebookingFilterParams.RoomFloor]: filters[RebookingFilterParams.RoomFloor],
            [RebookingFilterParams.CurrentDate]: getLocalIso(),
            page,
        } as RebookingFilterQuery);

    const params = rawParams && (omitBy(rawParams, (value) => !value) as RebookingFilterQuery);

    return useQuery<
        ConciergeSuccessResponse<RebookingRooms, RebookingRoomsMeta>,
        AxiosError<ConciergeErrorResponse>
    >({
        queryKey: [RebookingEndpoints.GetRebookers, params],
        queryFn: () => getRebookingRooms(params!),
        enabled: Boolean(filters),
    });
};
