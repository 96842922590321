import { FC, Fragment } from 'react';
import { Stack } from '@mui/material';

import { AppPreloader } from 'src/components/app-preloader';
import { ErrorState } from 'src/components/error-state';

import { useGetBookingsSummary } from '../../hooks/use-get-bookings-summary';

import { OverviewContainer } from './parts/overview-container';

export const BookingsOverview: FC = () => {
    const { data: bookingsSummary, isLoading, isError } = useGetBookingsSummary();

    if (isError) {
        return (
            <ErrorState
                title={
                    <Fragment>
                        Error
                        <br />
                        retrieving
                        <br />
                        booking summary
                    </Fragment>
                }
            />
        );
    }

    if (isLoading) {
        return (
            <div css={{ position: 'relative', minHeight: '190px' }}>
                <AppPreloader background="default" />
            </div>
        );
    }

    return (
        <Stack spacing="16px" marginTop="12px !important">
            {bookingsSummary.map((bookingSummary) => (
                <OverviewContainer key={bookingSummary.id} bookingSummary={bookingSummary} />
            ))}
        </Stack>
    );
};
