import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Box, Stack, Typography, Button } from '@mui/material';

import { useFeatureFlags } from 'src/features/user';
import { routes } from 'src/constants/routes';
import { ReactComponent as ControlArrow } from 'src/assets/svg/control-arrow.svg';
import { getPartialTranslation } from 'src/utils/get-partial-translation';

import { BuildingNewsItem as IBuildingNewsItem } from '../../api/types';
import { BuildingUpdatesStatus } from '../../api/constants';
import { formatBuildingNewsDate } from '../../utils/format-building-news-date';

export const BuildingNewsItem: FC<{ update: IBuildingNewsItem }> = ({ update }) => {
    const { t } = useTranslation();

    const { palette } = useTheme();

    const flags = useFeatureFlags();

    const isResolved = update.status === BuildingUpdatesStatus.Resolved;

    const dateFormatted = formatBuildingNewsDate(update.created_date);

    return (
        <Box
            padding={{ xs: '16px', md: 0 }}
            css={{
                ':not(:last-child)::after': {
                    content: '""',
                    display: 'block',
                    height: '1px',
                    background: palette.divider,
                },
            }}
        >
            <Box padding={{ xs: '16px 0', md: '36px 24px 32px 24px' }}>
                <Typography
                    variant="h4"
                    marginBottom="10px"
                    color={isResolved ? 'text.disabled' : undefined}
                    textTransform="uppercase"
                    css={{
                        textDecoration: isResolved ? 'line-through' : undefined,
                    }}
                >
                    {getPartialTranslation(update.title)}
                </Typography>

                <Typography variant="body3" marginBottom="16px">
                    {getPartialTranslation(update.description)}
                </Typography>

                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                        {t(dateFormatted.key, { count: dateFormatted.diff })}
                    </Typography>

                    {flags.building_incidents && update.incident_update && (
                        <Button
                            component={Link}
                            to={routes.myBuilding.incidents}
                            endIcon={<ControlArrow width="16px" height="16px" />}
                            css={{
                                padding: 0,

                                '.MuiButton-endIcon': { transform: 'rotate(270deg)' },

                                ':hover': {
                                    background: 'none',
                                },
                            }}
                        >
                            <Typography variant="subtitle2" color="inherit">
                                {t('whats_happening_see_full_update')}
                            </Typography>
                        </Button>
                    )}
                </Stack>
            </Box>
        </Box>
    );
};
