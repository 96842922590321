import { FC, useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Drawer, List, Stack } from '@mui/material';

import { LogoutDialog } from 'src/features/auth';
import { routes } from 'src/constants/routes';
import { ReactComponent as VitaLogoClosed } from 'src/assets/svg/vita-logo.svg';
import { ReactComponent as CommunityPageIcon } from 'src/assets/svg/nav-community.svg';
import { ReactComponent as HomePageIcon } from 'src/assets/svg/tiles.svg';
import { ReactComponent as MyBuildingPageIcon } from 'src/assets/svg/nav-building.svg';
import { ReactComponent as FeedbackIcon } from 'src/assets/svg/chat-with-star.svg';
import { ReactComponent as QuestionIcon } from 'src/assets/svg/question-in-circle.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/svg/exit.svg';
import { useFeatureFlags } from 'src/features/user';
import { GA4 } from 'src/lib/ga4';

import { SidebarNavButton } from './parts/nav-button';

const DRAWER_WIDTH = 120;

export const Sidebar: FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { transitions, palette, breakpoints } = useTheme();

    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const flags = useFeatureFlags();

    const basePath = `/${pathname.split('/')[1]}`;
    const transition = transitions.create('all');

    const handleNavClick = (route: string) => () => navigate(route);

    const toggleLogoutDialog = useCallback(
        () => setShowLogoutDialog((state) => !state),
        [setShowLogoutDialog]
    );

    const handleLogoutTracking = () =>
        GA4.ctaEvent({ name: 'Logout', location: 'Sidebar', label: 'Logout Icon' });

    return (
        <Drawer
            variant="permanent"
            css={{
                width: DRAWER_WIDTH,
                transition,
                boxSizing: 'content-box',

                [breakpoints.down('md')]: {
                    display: 'none',
                },

                '.MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    transition,
                    background: palette.common.black,
                    border: 'none',
                    overflow: 'hidden',
                },
            }}
        >
            <Link
                to={routes.home}
                css={{ padding: '36px 0', textAlign: 'center', color: palette.common.white }}
            >
                <VitaLogoClosed />
            </Link>

            <Stack flex={1} paddingY="40px">
                <List css={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {[
                        {
                            label: 'sidebar_navigation_home',
                            icon: <HomePageIcon />,
                            path: routes.home,
                            relates: [routes.home, '/'],
                        },
                        {
                            label: 'sidebar_navigation_community',
                            icon: <CommunityPageIcon />,
                            path: routes.community.index,
                            relates: [routes.community.index],
                            hidden: !flags.posts,
                        },
                        {
                            label: 'sidebar_navigation_my_building',
                            icon: <MyBuildingPageIcon />,
                            path: routes.myBuilding.index,
                            relates: [routes.myBuilding.index],
                        },
                    ].map((route) => {
                        if (route.hidden) return null;

                        const isSelected = route.relates.includes(basePath as string);

                        return (
                            <SidebarNavButton
                                key={route.label}
                                selected={isSelected}
                                expanded={false}
                                icon={route.icon}
                                label={t(route.label)}
                                onClick={handleNavClick(route.path)}
                            />
                        );
                    })}
                </List>

                <List css={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <SidebarNavButton
                        label={t('sidebar_navigation_feedback')}
                        expanded={false}
                        selected={basePath === routes.feedback.index}
                        icon={<FeedbackIcon />}
                        onClick={handleNavClick(routes.feedback.index)}
                    />
                    <SidebarNavButton
                        label={t('sidebar_navigation_help')}
                        expanded={false}
                        selected={basePath === routes.supportHub.index}
                        icon={<QuestionIcon />}
                        onClick={handleNavClick(routes.supportHub.index)}
                    />

                    <SidebarNavButton
                        label={t('sidebar_navigation_logout')}
                        expanded={false}
                        icon={<LogoutIcon />}
                        onClick={toggleLogoutDialog}
                    />
                </List>
            </Stack>

            <LogoutDialog
                isOpen={showLogoutDialog}
                onClose={toggleLogoutDialog}
                onLogout={handleLogoutTracking}
            />
        </Drawer>
    );
};
