import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Badge, Skeleton, Box } from '@mui/material';

import { useGetActiveBooking } from 'src/features/bookings';
import { ButtonWithIcon } from 'src/components/button-with-icon';
import { routes } from 'src/constants/routes';
import { ReactComponent as CheckIcon } from 'src/assets/svg/success-in-circle.svg';
import { ReactComponent as WarnIcon } from 'src/assets/svg/warning.svg';
import { GA4 } from 'src/lib/ga4';

import { ReactComponent as SettingsIcon } from '../assets/svg/settings.svg';
import { useGetBuildingActiveIncidentsCount } from '../hooks/use-get-building-active-incidents-count';

export const BuildingIncidentsBadge: FC = () => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    const { data: booking } = useGetActiveBooking();
    const { data: amount, isLoading, isError } = useGetBuildingActiveIncidentsCount(booking!.id);

    const linkProps = {
        component: Link,
        to: routes.myBuilding.incidents,
    };

    if (isError) {
        return null;
    }

    const label = amount
        ? t('building_incidents_number_of_active_incidents', { count: amount })
        : t('building_incidents_no_active_incidents');

    const handleClick = () =>
        GA4.ctaEvent({
            name: 'Incidents Badge Click',
            label,
            location: 'My Building Page Header',
        });

    return (
        <Fragment>
            <Box display={{ xs: 'none', md: 'block' }}>
                {isLoading ? (
                    <Skeleton variant="rectangular" width="300px" height="40px" />
                ) : (
                    <ButtonWithIcon
                        {...linkProps}
                        design={amount ? 'error' : 'success'}
                        fullWidth={false}
                        bordered
                        onClick={handleClick}
                        startIcon={
                            amount ? (
                                <WarnIcon width="24px" height="24px" color={palette.error.main} />
                            ) : (
                                <CheckIcon
                                    width="24px"
                                    height="24px"
                                    color={palette.success.main}
                                />
                            )
                        }
                    >
                        {label}
                    </ButtonWithIcon>
                )}
            </Box>

            <Box display={{ md: 'none' }} position="absolute" top="20px" right="16px">
                {isLoading ? (
                    <Skeleton variant="circular" width="26px" height="26px" />
                ) : (
                    <Badge
                        {...linkProps}
                        overlap="circular"
                        color={amount ? 'primary' : 'default'}
                        showZero
                        badgeContent={
                            amount || (
                                <CheckIcon
                                    width="16px"
                                    height="16px"
                                    color={palette.success.main}
                                />
                            )
                        }
                    >
                        <SettingsIcon />
                    </Badge>
                )}
            </Box>
        </Fragment>
    );
};
