import { FC, Fragment, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Stack, Typography } from '@mui/material';

import { Link } from 'src/components/link';
import { ErrorState } from 'src/components/error-state';
import { LangSwitcherView } from 'src/components/lang-switcher-view';
import { LanguagesCode } from 'src/i18n/constants';
import { ReactComponent as VitaLogoMobile } from 'src/assets/svg/vita-logo.svg';
import { ReactComponent as VitaLogoDesktop } from 'src/assets/svg/vita-logo-opened.svg';

// Use bundled translates to avoid using i18n and PoEditor
const content = {
    title: {
        [LanguagesCode.English]: (
            <Fragment>
                SOMETHING
                <br />
                WENT
                <br />
                WRONG
            </Fragment>
        ),

        [LanguagesCode.Spanish]: (
            <Fragment>
                ALGO
                <br />
                HA IDO
                <br />
                MAL
            </Fragment>
        ),
    },

    subtitle: {
        [LanguagesCode.English]: (
            <Fragment>
                There seems to be a problem.
                <br />
                Please try again later.
            </Fragment>
        ),

        [LanguagesCode.Spanish]: (
            <Fragment>
                Parece que hay un problema.
                <br />
                Por favor, inténtalo de nuevo más tarde.
            </Fragment>
        ),
    },

    help_message: {
        [LanguagesCode.English]: (
            <Fragment>
                If you have any issues or questions, please
                <br />
                contact{' '}
                <Link variant="light" href="mailto:hello@vitastudent.com">
                    hello@vitastudent.com
                </Link>
            </Fragment>
        ),

        [LanguagesCode.Spanish]: (
            <Fragment>
                Si tienes algún problema o pregunta, ponte en
                <br />
                contacto con{' '}
                <Link variant="light" href="mailto:hello@vitastudent.com">
                    hello@vitastudent.com
                </Link>
            </Fragment>
        ),
    },
};

export const RootErrorPage: FC = () => {
    const { palette, breakpoints } = useTheme();
    const [language, setLanguage] = useState(LanguagesCode.English);

    return (
        <Stack flex={1} position="relative">
            <Stack padding={{ xs: '16px', md: '30px 40px' }}>
                <VitaLogoMobile css={{ [breakpoints.up('md')]: { display: 'none' } }} />
                <VitaLogoDesktop css={{ [breakpoints.down('md')]: { display: 'none' } }} />
            </Stack>

            <Stack flex={1} justifyContent="center" alignItems="center">
                <ErrorState
                    title={content.title[language]}
                    subtitle={
                        <Typography
                            variant="body2"
                            color={palette.text.secondary}
                            textAlign="center"
                        >
                            {content.subtitle[language]}
                        </Typography>
                    }
                    css={{ flex: 0, marginBottom: '60px' }}
                />

                <div
                    css={{
                        width: '100%',
                        maxWidth: '287px',
                        height: '5px',
                        marginBottom: '24px',
                        background: palette.primary.main,
                    }}
                />

                <Typography variant="body2" textAlign="center">
                    {content.help_message[language]}
                </Typography>

                <LangSwitcherView
                    language={language}
                    options={[
                        {
                            value: LanguagesCode.English,
                            label: 'English',
                            code: 'gb',
                        },
                        {
                            value: LanguagesCode.Spanish,
                            label: 'Español',
                            code: 'es',
                        },
                    ]}
                    onChange={setLanguage}
                    css={{
                        position: 'absolute',
                        bottom: '24px',

                        [breakpoints.up('md')]: { bottom: 'unset', top: '30px', right: '40px' },
                    }}
                />
            </Stack>
        </Stack>
    );
};
