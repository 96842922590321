import { useQuery } from '@tanstack/react-query';

import type { ConciergeErrorResponse } from 'src/api/concierge';

import { PaymentsEndpoints, getPaymentReceipts, GetPaymentsReceiptsResponse } from '../api';

export const useGetPaymentsReceipts = (bookingId: string) =>
    useQuery<GetPaymentsReceiptsResponse, ConciergeErrorResponse>({
        queryKey: [PaymentsEndpoints.getPaymentReceipts, bookingId],
        queryFn: () => getPaymentReceipts(bookingId),
    });
