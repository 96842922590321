import { useTranslation } from 'react-i18next';

import type { LanguagesCode } from 'src/i18n/constants';

import type { GridOption } from '../types/create-ticket-form';

export const useGetGridOptionTranslator = () => {
    const { t, i18n } = useTranslation();
    const lngCode = i18n.language as LanguagesCode;

    return (option?: GridOption) => {
        if (!option) return undefined;

        return 'i18nKey' in option ? t(option.i18nKey) : option.display_name[lngCode];
    };
};
