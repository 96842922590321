import axios from 'axios';
import pick from 'lodash/pick';
import { captureException } from '@sentry/react';

import { ConciergeErrorResponse } from './types';

export const conciergeClient = axios.create({
    baseURL: process.env.REACT_APP_CONCIERGE_BASE_URL,
});

const captureAxiosError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
        const { response } = error;

        const data = response?.data as ConciergeErrorResponse;
        const message = data?.message;

        error.name = 'Concierge client';

        // Token expired
        if (message === 'Auth token expired') {
            return Promise.reject(error);
        }

        if (message) {
            error.message = message;
        }

        captureException(error, {
            extra: {
                request: pick(response?.config, ['data', 'url', 'method', 'headers']),
                response: data,
            },
        });
    } else {
        captureException(error);
    }

    return Promise.reject(error);
};

conciergeClient.interceptors.request.use(undefined, captureAxiosError);
conciergeClient.interceptors.response.use(undefined, captureAxiosError);
