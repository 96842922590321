import { addDays } from '../utils/add-days';
import { formatDate } from '../utils/format-date';

export type Week = string;

export function generateWeeks(dateFrom: Date, dateTo: Date): Week[] {
    const weeks: Week[] = [];

    // Convert date strings to Date objects
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);

    // Start from the fromDate and iterate week by week until reaching toDate
    let currentWeekStart = new Date(fromDate);
    while (currentWeekStart <= toDate) {
        const weekStartStr = formatDate(currentWeekStart);
        const weekEndStr = formatDate(addDays(currentWeekStart, 6));
        const week = `${weekStartStr} - ${weekEndStr}`;
        weeks.push(week);

        // Move to the start of next week
        currentWeekStart = addDays(currentWeekStart, 7);
    }

    return weeks;
}
