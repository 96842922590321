import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { RebookingEndpoints } from '../api/constants';
import { RebookingFilterOptions } from '../api/types';
import { getRebookingFilterTree } from '../api/requests';

type Options = RebookingFilterOptions['filter_tree'];

export const useGetRebookingFilter = () =>
    useQuery<Options, AxiosError<ConciergeErrorResponse>>({
        queryKey: [RebookingEndpoints.GetRebookersFilterTree],

        queryFn: () => getRebookingFilterTree(),
    });
