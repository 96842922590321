import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Alert, IconButton, Stack, Typography } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';

export const OverviewNotification: FC<{
    severity?: 'warning' | 'error' | 'success' | 'info';
    link: string;
    title?: string;
    year: string;
    message?: ReactNode;
    underMessage?: ReactNode;
    onClick?: VoidFunction;
}> = ({ severity = 'info', link, title, year, message, underMessage, onClick }) => {
    const { shadows, palette } = useTheme();

    return (
        <Link
            to={link}
            css={{
                position: 'relative',
                textDecoration: 'none',
                color: 'inherit',
                boxShadow: shadows[1],
                border: severity === 'error' ? `1px solid ${palette.primary.main}` : undefined,
                background: palette.common.white,
            }}
            onClick={onClick}
        >
            {title && (
                <Alert
                    variant="filled"
                    severity={severity}
                    icon={severity === 'success' ? false : undefined}
                    css={{
                        height: '36px',
                        padding: '0 20px',
                        justifyContent: 'unset',
                    }}
                >
                    {title}
                </Alert>
            )}

            <Stack
                padding={{ xs: '20px', md: '28px 24px' }}
                gap="16px"
                alignItems="center"
                flexDirection="row"
            >
                <Stack flex={1}>
                    <Typography variant="subtitle2" marginBottom="12px">
                        {year}
                    </Typography>

                    {message}

                    {underMessage}
                </Stack>

                <IconButton>
                    <ArrowIcon
                        css={{
                            color: palette.common.black,
                            width: '24px',
                            height: '24px',
                            transform: 'rotate(-90deg)',
                        }}
                    />
                </IconButton>
            </Stack>
        </Link>
    );
};
