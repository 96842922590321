import { useAuth0 as useRealAuth0 } from '@auth0/auth0-react';

import { devAuth } from 'src/utils/dev-config';

const useMockedAuth0: typeof useRealAuth0 = () => ({
    error: undefined,
    isAuthenticated: true,
    isLoading: false,
    user: undefined,
    // @ts-ignore
    getAccessTokenSilently: () => Promise.resolve(devAuth.getToken()),
    // @ts-ignore
    getAccessTokenWithPopup: () => Promise.resolve(devAuth.getToken()),
    // @ts-ignore
    getIdTokenClaims: () => Promise.resolve({ __raw: devAuth.getToken() }),
    loginWithRedirect: () => Promise.resolve(),
    loginWithPopup: () => Promise.resolve(),
    logout: () => devAuth.removeToken(),
});

export const useAuth0 =
    process.env.REACT_APP_AUTH0_ENABLE === 'true' ? useRealAuth0 : useMockedAuth0;
