import { CSSProperties, forwardRef, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { closeSnackbar } from 'notistack';
import { useTheme, keyframes } from '@emotion/react';
import { Alert, AlertTitle, IconButton, Typography } from '@mui/material';

import { logger } from 'src/services/logger';
import { isRelativeUrl } from 'src/utils/is-link-relative';
import { ReactComponent as BellIcon } from 'src/assets/svg/nav-notifications.svg';
import { ReactComponent as CrossIcon } from 'src/assets/svg/cross.svg';

import { NotificationStatus } from '../api/constants';
import { patchNotificationStatus } from '../api/requests';

import type { NotificationPayload, MessagePayload } from 'firebase/messaging';

const progressAnimation = keyframes({
    from: {
        width: '100%',
    },

    to: {
        width: '0%',
    },
});

const CLOSE_ICON_ARIA_LABEL = 'close notification';

interface Props {
    /** milliseconds */
    duration: number;
    data: MessagePayload['data'];
    notification: NotificationPayload;
    style?: CSSProperties;
}

export const NotificationToast = forwardRef<HTMLDivElement, Props>(
    ({ duration, data = {}, notification, style }, ref) => {
        const { palette, breakpoints } = useTheme();

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { navigation_url, status, id } = data;

        const linkProps =
            navigation_url &&
            (isRelativeUrl(navigation_url)
                ? { component: Link, to: navigation_url, state: { notification: data } }
                : { component: 'a', href: navigation_url, target: '_blank' });

        const closeToast = () => closeSnackbar(id);

        const handleClose: MouseEventHandler = (event) => {
            event.preventDefault();
            closeToast();
        };

        const handleClick: MouseEventHandler = (event) => {
            closeToast();

            const target = event.target as HTMLElement;
            const notCloseIcon = !target.closest(`[aria-label="${CLOSE_ICON_ARIA_LABEL}"]`);

            if (notCloseIcon && status !== NotificationStatus.Actioned) {
                patchNotificationStatus(id, NotificationStatus.Actioned).catch((error) =>
                    logger.error(error)
                );
            }
        };

        return (
            <Alert
                {...linkProps}
                ref={ref}
                elevation={1}
                icon={<BellIcon color={palette.primary.main} />}
                square
                style={style}
                onClick={handleClick}
                css={{
                    background: palette.background.default,
                    padding: '20px 24px',
                    gap: '20px',
                    position: 'relative',
                    textDecoration: 'none',

                    [breakpoints.up('md')]: {
                        width: '360px',
                    },

                    '.MuiAlert-icon': {
                        width: 'auto',
                        height: 'auto',
                    },

                    '.MuiAlert-message': {
                        width: '100%',
                    },
                }}
            >
                <AlertTitle
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '16px',
                    }}
                >
                    <Typography variant="h4" color="text.primary">
                        {notification.title}
                    </Typography>

                    <IconButton
                        disableRipple
                        aria-label={CLOSE_ICON_ARIA_LABEL}
                        onClick={handleClose}
                    >
                        <CrossIcon color={palette.text.primary} />
                    </IconButton>
                </AlertTitle>

                <Typography variant="body2" color="text.primary">
                    {notification.body}
                </Typography>

                <div
                    onAnimationEnd={closeToast}
                    css={{
                        position: 'absolute',
                        height: '6px',
                        bottom: 0,
                        left: 0,
                        background: palette.primary.main,
                        animation: `${progressAnimation} ${duration}ms linear`,
                    }}
                />
            </Alert>
        );
    }
);
