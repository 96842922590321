import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import { init as initSentry, BrowserTracing, reactRouterV6Instrumentation } from '@sentry/react';

import { AppProvider } from './providers/app.provider';
import { App } from './app/app';
import { appConfig } from './app/app-config';

initSentry({
    enabled: appConfig.sentry.enabled,
    environment: appConfig.env,
    dsn: appConfig.sentry.dsn,
    tracesSampleRate: 1.0,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ],
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <AppProvider>
        <App />
    </AppProvider>
);
