import { FC, Fragment, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { isPast } from 'date-fns';
import { Alert, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { CalendarEvent } from 'calendar-link';

import { PrivateHat } from 'src/layout/private-hat';
import { LanguagesCode } from 'src/i18n/constants';
import {
    AttendingNotice,
    DateLabel,
    EVENT_STATUS_ALERT_VIEW,
    EVENT_STATUS_TRANSLATION_KEY,
    EventAttendanceStatus,
    EventAttendModal,
    EventCancelAttendanceModal,
    EventStatus,
    LocationLabel,
    ShareModal,
    useGetEvent,
    EventLeaveWaitlistModal,
    EventJoinWaitlistModal,
    EventAttendButton,
    EventAttendDescription,
} from 'src/features/events';
import { AppPreloader } from 'src/components/app-preloader';
import { ErrorState } from 'src/components/error-state';
import { Image } from 'src/components/image';
import { ReactComponent as AddToCalendarIcon } from 'src/assets/svg/add-to-calendar.svg';
import { ReactComponent as ShareIcon } from 'src/assets/svg/share.svg';
import {
    AddToCalendarModal,
    CalendarOption,
} from 'src/components/add-to-calendar-modal/add-to-calendar-modal';
import { Notification, NotificationMessageId } from 'src/features/notifications';
import { EventType, GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';

import { Page404 } from '../page404';

export const EventIdPage: FC = () => {
    const { id } = useParams();
    const { state } = useLocation();

    const { t } = useTranslation();

    const { breakpoints, palette, custom } = useTheme();

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
    const [isCancelAttendanceModalOpen, setIsCancelAttendanceModalOpen] = useState(false);
    const [isLeaveWaitlistModalOpen, setIsLeaveWaitlistModalOpen] = useState(false);
    const [isAttendModalOpen, setIsAttendModalOpen] = useState(false);
    const [isJoinWaitlistModalOpen, setIsJoinWaitlistModalOpen] = useState(false);

    const { data: event, isLoading, isError } = useGetEvent(id!);

    if (isLoading) {
        return <AppPreloader size="big" />;
    }

    if (isError) {
        return <ErrorState title={<Trans i18nKey="event_error_retrieving_content" />} />;
    }

    if (!event) {
        return <Page404 />;
    }

    const eventUrl = event.external_event_url || window.location.href;

    const title = getPartialTranslation(event.title);
    const description = getPartialTranslation(event.description);

    const calendarEvent: CalendarEvent = {
        start: event.start_date,
        end: event.end_date,
        title,
        description,
        location: Object.values(event.location).join(', '),
        url: eventUrl,
    };

    const notification = state?.notification as Notification | undefined;

    const locationWasChanged = notification?.message_id === NotificationMessageId.LocationUpdate;
    const isPastEvent = isPast(new Date(event.end_date));

    const alertSeverity = EVENT_STATUS_ALERT_VIEW[event.status];

    const showAttendanceNotice =
        event.status !== EventStatus.Closed &&
        event.attendance_status === EventAttendanceStatus.Attending;

    const handleCloseShareModal = () => setIsShareModalOpen(false);

    const handleToggleAddToCalendarModal = () => {
        setIsAddCalendarModalOpen(!isAddCalendarModalOpen);

        GA4.modalEvent({
            name: 'Events Add to Calendar Modal',
            location: 'Event',
            open: !isAddCalendarModalOpen,
            label: 'Calendar Icon',
        });
    };

    const handleToggleLeaveWaitlistModal = () =>
        setIsLeaveWaitlistModalOpen(!isLeaveWaitlistModalOpen);

    const handleToggleJoinWaitlistModal = () =>
        setIsJoinWaitlistModalOpen(!isJoinWaitlistModalOpen);

    const handleToggleCancelAttendanceModal = () => {
        setIsCancelAttendanceModalOpen(!isCancelAttendanceModalOpen);

        GA4.modalEvent({
            name: 'Events Cancel Attendance Modal',
            location: 'Event',
            label: t('event_cancel_attendance_modal_confirm_cta', { lng: LanguagesCode.English }),
            open: !isCancelAttendanceModalOpen,
        });
    };

    const handleToggleAttendModal = () => setIsAttendModalOpen(!isAttendModalOpen);

    const handleShare = async () => {
        if (navigator.canShare?.()) {
            await navigator.share({ url: window.location.href });

            GA4.ctaEvent({
                name: 'Events Share Native',
                location: 'Event',
                label: 'Calendar Icon',
            });
        } else {
            setIsShareModalOpen(true);

            GA4.modalEvent({
                name: 'Events Share Modal',
                location: 'Event',
                open: true,
                label: 'Calendar Icon',
            });
        }
    };

    const handleAddToCalendar = (option: CalendarOption) => {
        GA4.event('Event Add to calendar', EventType.Cta, {
            platform: option.key,
            location: 'Event',
        });
    };

    return (
        <Fragment>
            <PrivateHat
                title={t('events_title')}
                backIconText={t('back_cta')}
                actionLabel={
                    <IconButton onClick={handleShare}>
                        <ShareIcon
                            css={{
                                color: palette.primary.main,
                                [breakpoints.up('lg')]: { display: 'none' },
                            }}
                        />
                    </IconButton>
                }
            />

            {alertSeverity && (
                <Alert variant="filled" severity={alertSeverity} css={{ width: '100%' }}>
                    {t(EVENT_STATUS_TRANSLATION_KEY[event.status])}
                </Alert>
            )}

            {locationWasChanged && (
                <Alert variant="filled" severity="warning" css={{ width: '100%' }}>
                    {t('events_specific-event_status_location-changed')}
                </Alert>
            )}

            <Box
                display={{ xs: 'flex', lg: 'grid' }}
                flexDirection="column"
                gridTemplateColumns={{ lg: '1fr 1fr' }}
                position="relative"
                width="100%"
                flexGrow={1}
            >
                <Image
                    alt={title}
                    src={event.image_url}
                    css={{
                        [breakpoints.down('lg')]: {
                            height: '220px',
                        },
                    }}
                />

                <Stack flex={1}>
                    <Box
                        flex={{ xs: 1, lg: 0 }}
                        padding={{
                            xs: `20px 16px ${40 + custom.footerHeight}px 16px`,
                            md: '24px 40px',
                            lg: `${alertSeverity ? '24px' : '0'} 60px 60px 60px`,
                        }}
                    >
                        {showAttendanceNotice && <AttendingNotice />}

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            marginTop={!showAttendanceNotice ? { lg: '40px' } : undefined}
                            marginBottom={{
                                xs: showAttendanceNotice ? '16px' : '40px',
                                md: '60px',
                                lg: '45px',
                            }}
                        >
                            <Typography
                                variant="h2"
                                color={palette.text.primary}
                                textTransform="uppercase"
                            >
                                {title}
                            </Typography>

                            <ShareIcon
                                onClick={handleShare}
                                css={{
                                    cursor: 'pointer',
                                    color: palette.primary.main,
                                    [breakpoints.down('lg')]: { display: 'none' },
                                }}
                            />
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            css={{ borderBottom: `1px solid ${palette.divider}` }}
                            paddingBottom="16px"
                        >
                            <DateLabel event={event} />

                            <IconButton
                                disableRipple
                                color="primary"
                                disabled={isPastEvent}
                                onClick={handleToggleAddToCalendarModal}
                                css={{ padding: 0 }}
                            >
                                <AddToCalendarIcon width="24px" height="24px" />
                            </IconButton>
                        </Stack>

                        <LocationLabel
                            firstLine={event.location.first_line}
                            secondLine={event.location.second_line}
                            css={{ paddingTop: '16px' }}
                        />

                        <Typography
                            variant="h3"
                            marginTop={{ xs: '40px', md: '60px' }}
                            marginBottom="17px"
                        >
                            {t('event_details_title')}
                        </Typography>

                        <Typography variant="body2" color={palette.text.secondary}>
                            {description}
                        </Typography>
                    </Box>

                    {event.status !== EventStatus.Closed && (
                        <Stack
                            textAlign={{ xs: 'center', lg: 'left' }}
                            alignItems={{ xs: 'stretch', md: 'center', lg: 'flex-start' }}
                            padding={{
                                xs: '20px 16px',
                                md: '20px 0',
                                lg: '0 60px',
                            }}
                            gap="16px"
                            position={{ xs: 'fixed', md: 'static' }}
                            width="100%"
                            bottom={custom.footerHeight}
                            css={{
                                [breakpoints.down('lg')]: {
                                    backgroundColor: palette.background.paper,
                                },
                            }}
                        >
                            <EventAttendButton
                                attendanceStatus={event.attendance_status!}
                                status={event.status}
                                onOpenCancelAttendanceModal={handleToggleCancelAttendanceModal}
                                onOpenLeaveWaitlistModal={handleToggleLeaveWaitlistModal}
                                externalUrl={event.external_event_url}
                                onOpenAttendModal={handleToggleAttendModal}
                                onOpenWaitlistModal={handleToggleJoinWaitlistModal}
                            />

                            <EventAttendDescription
                                status={event.status}
                                attendanceStatus={event.attendance_status!}
                            />
                        </Stack>
                    )}
                </Stack>
            </Box>

            <ShareModal event={event} isOpen={isShareModalOpen} onClose={handleCloseShareModal} />

            <AddToCalendarModal
                calendarEvent={calendarEvent}
                isOpen={isAddCalendarModalOpen}
                onAddToCalendar={handleAddToCalendar}
                onClose={handleToggleAddToCalendarModal}
            />

            <EventCancelAttendanceModal
                open={isCancelAttendanceModalOpen}
                id={event.id}
                onClose={handleToggleCancelAttendanceModal}
            />

            <EventJoinWaitlistModal
                open={isJoinWaitlistModalOpen}
                event={event}
                onClose={handleToggleJoinWaitlistModal}
            />

            <EventLeaveWaitlistModal
                open={isLeaveWaitlistModalOpen}
                id={event.id}
                onClose={handleToggleLeaveWaitlistModal}
            />

            <EventAttendModal
                open={isAttendModalOpen}
                event={event}
                isQuick={false}
                onClose={handleToggleAttendModal}
            />
        </Fragment>
    );
};
