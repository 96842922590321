import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dialog } from 'src/components/dialog';
import { ErrorState } from 'src/components/error-state';
import { routes } from 'src/constants/routes';

import { usePostAttendEvent } from '../hooks/use-post-attend-event';
import { Event } from '../api';

export const EventAttendModal: FC<{
    open: boolean;
    event: Event;
    isQuick: boolean;
    onClose: VoidFunction;
}> = ({ open, event, isQuick, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isLoading, mutate } = usePostAttendEvent(event.id, {
        onSuccess(data) {
            navigate(routes.events.attendSuccess, {
                state: {
                    event: data,
                    isQuick,
                },
            });
        },
    });

    const handleSubmit = () => mutate();

    return (
        <Dialog
            open={open}
            loading={isLoading}
            actions={{
                accept: {
                    children: t('event_error_cta_try_again'),
                    onClick: handleSubmit,
                },

                decline: {
                    children: t('event_error_cta_back_to_event'),
                    onClick: onClose,
                },
            }}
            TransitionProps={{
                onEnter: handleSubmit,
            }}
            onClose={onClose}
            css={{
                '.MuiPaper-root': {
                    minHeight: '600px',
                },
            }}
        >
            <ErrorState title={<Trans i18nKey="general_error_something_went_wrong_title" />} />
        </Dialog>
    );
};
