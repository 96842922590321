import { FC, Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import { setContext, setUser } from '@sentry/react';

import { Layout } from 'src/layout/layout';
import { AppPreloader } from 'src/components/app-preloader';
import { useGetActiveBooking } from 'src/features/bookings';
import { useGetCurrentUser, UserDataFields } from 'src/features/user';
import { NoBookingsPage } from 'src/pages/no-bookings.page';
import { useLogin } from 'src/features/auth/hooks/use-login';
import { initLiveChat } from 'src/services/live-chat';
import { initI18Next } from 'src/i18n/instance';
import { Onboarding } from 'src/features/onboarding';
import { NotificationPermissionDialog } from 'src/features/notifications';
import { GA4 } from 'src/lib/ga4';

import { appConfig } from './app-config';

const preloader = <AppPreloader size="big" showLogo css={{ flex: 1 }} />;

const ONBOARDING_STORAGE_KEY = 'onboarding_complete';

export const Main: FC = () => {
    const isAuthorized = useLogin();

    const [isBookingsInvalid, setIsBookingsInvalid] = useState(false);
    const [intInitialized, setIntInitialized] = useState(i18n.isInitialized);

    const [passedOnboarding, setPassedOnboarding] = useState(
        Boolean(localStorage.getItem(ONBOARDING_STORAGE_KEY))
    );

    const { isLoading: loadingBookings } = useGetActiveBooking({
        enabled: isAuthorized,

        onError() {
            setIsBookingsInvalid(true);
        },

        onSuccess(booking) {
            setContext('active_booking', booking);

            if (!booking) {
                setIsBookingsInvalid(true);
            }
        },
    });

    const {
        data: user,
        isLoading: isUserLoading,
        error: userError,
    } = useGetCurrentUser({
        enabled: isAuthorized,

        onSettled(data) {
            initI18Next(data?.language);
            setIntInitialized(true);
        },

        onSuccess(data) {
            // @ts-ignore allow nullable emails
            setUser(data);

            GA4.setUser(data[UserDataFields.Id]);
        },
    });

    useEffect(initLiveChat, []);

    const handleSkipOnboarding = () => {
        localStorage.setItem(ONBOARDING_STORAGE_KEY, 'true');
        setPassedOnboarding(true);
    };

    if (!isAuthorized || loadingBookings || isUserLoading || !intInitialized) {
        return preloader;
    }

    if (isBookingsInvalid) {
        return <NoBookingsPage />;
    }

    if (userError || !user) {
        // There is nothing we can do. Provide clear message for Sentry
        throw new Error('User data fetch fail', { cause: userError });
    }

    const flags = user.feature_flags;

    const showNotificationDialog =
        flags.notifications &&
        appConfig.firebase.messaging.enabled &&
        !(flags.onboarding && !passedOnboarding); // ignore onboarding if the feature is disabled

    return (
        <Fragment>
            <Layout />

            {flags.onboarding && (
                <Onboarding passedOnboarding={passedOnboarding} onSkip={handleSkipOnboarding} />
            )}

            {showNotificationDialog && <NotificationPermissionDialog />}
        </Fragment>
    );
};
