import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button as MuiButton, Stack, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { CalendarEvent } from 'calendar-link';

import { StateView } from 'src/components/state-view';
import { routes } from 'src/constants/routes';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/calendar.svg';
import { ReactComponent as LocationIcon } from 'src/assets/svg/location.svg';
import { IconLabel } from 'src/components/icon-label';
import { useFormattedDate } from 'src/hooks/use-formatted-date';
import { ReactComponent as CalendarSmallIcon } from 'src/assets/svg/calendar-empty.svg';
import {
    AddToCalendarModal,
    CalendarOption,
} from 'src/components/add-to-calendar-modal/add-to-calendar-modal';
import { EventType, GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { SocialSpaceBooking } from '../../api';

export const BookingConfirmation: FC<{ booking: SocialSpaceBooking }> = ({ booking }) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const getFormattedDate = useFormattedDate();

    const { palette, breakpoints } = useTheme();

    const [showCalendarModal, setShowCalendarModal] = useState(false);

    const date = getFormattedDate(booking.booking_start_date);

    const calendarEvent: CalendarEvent = {
        title: booking.title,
        location: booking.location,
        start: `${booking.booking_start_date} ${booking.booking_start_time}`,
        end: `${booking.booking_start_date} ${booking.booking_end_time}`,
    };

    const handleGoToBookings = () => {
        navigate(routes.socialSpaces.myBookings);

        GA4.ctaEvent({
            name: 'Social Space Confirmation CTA',
            label: t('social_space_confirmation_my_bookings_cta', { lng: LanguagesCode.English }),
            location: 'Social Space Booking Confirmation',
        });
    };

    const handleGoToRooms = () => {
        navigate(routes.socialSpaces.index);

        GA4.ctaEvent({
            name: 'Social Space Confirmation CTA',
            label: t('social_space_confirmation_book_a_room_cta', { lng: LanguagesCode.English }),
            location: 'Social Space Booking Confirmation',
        });
    };

    const handleToggleCalendarModal = () => {
        setShowCalendarModal(!showCalendarModal);

        GA4.modalEvent({
            name: 'Social Space Booking Add to Calendar Modal',
            location: 'Social Space Booking Confirmation',
            open: !showCalendarModal,
            label: t('social_space_confirmation_add_to_calendar', { lng: LanguagesCode.English }),
        });
    };

    const handleAddToCalendar = (option: CalendarOption) => {
        GA4.event('Social Space Booking Add to calendar', EventType.Cta, {
            platform: option.key,
            location: 'Social Space Booking Confirmation',
        });
    };

    return (
        <StateView
            state="success"
            title={t('social_space_confirmation_title')}
            actions={{
                main: {
                    text: t('social_space_confirmation_book_a_room_cta'),
                    callback: handleGoToBookings,
                },
                secondary: {
                    text: t('social_space_confirmation_my_bookings_cta'),
                    callback: handleGoToRooms,
                },
            }}
        >
            <Stack gap="16px" alignItems="center">
                <Typography variant="body1">
                    <Trans
                        i18nKey="social_space_confirmation_subtitle"
                        values={{
                            title: booking!.title,
                        }}
                        components={{
                            span: <span css={{ fontWeight: '400' }} />,
                        }}
                    />
                </Typography>
                <IconLabel
                    css={{ paddingTop: '24px', width: '260px' }}
                    icon={<CalendarIcon width="24px" height="24px" />}
                >
                    {date}
                    <br />
                    {booking.booking_start_time} - {booking.booking_end_time}
                </IconLabel>

                <div
                    css={{
                        borderTop: `1px solid ${palette.divider}`,
                        width: '355px',
                        [breakpoints.down('md')]: {
                            maxWidth: '100%',
                        },
                    }}
                />

                <IconLabel
                    css={{
                        width: '260px',
                    }}
                    icon={<LocationIcon width="24px" height="24px" />}
                >
                    {booking.location}
                </IconLabel>

                <MuiButton
                    startIcon={<CalendarSmallIcon />}
                    css={{ marginTop: '50px' }}
                    onClick={handleToggleCalendarModal}
                >
                    {t('social_space_confirmation_add_to_calendar')}
                </MuiButton>
            </Stack>

            <AddToCalendarModal
                isOpen={showCalendarModal}
                calendarEvent={calendarEvent}
                onClose={handleToggleCalendarModal}
                onAddToCalendar={handleAddToCalendar}
            />
        </StateView>
    );
};
