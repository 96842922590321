import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

import { FaqSearchInput } from '../faq-search-input';

import { QuestionsCategories } from './parts/questions-categories';
import { QuestionsMap } from './parts/questions-map';

export const BrowseFaq: FC = () => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    return (
        <Fragment>
            <div css={{ maxWidth: '748px', margin: '0 auto', padding: '0 16px' }}>
                <QuestionsCategories />
                <QuestionsMap />
            </div>

            <section
                css={{
                    flex: 1,
                    padding: '40px 16px',
                    background: palette.background.paper,
                }}
            >
                <div
                    css={{
                        maxWidth: '420px',
                        margin: '0 auto',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h4" marginBottom="32px">
                        {t('help_search_title')}
                    </Typography>

                    <Typography variant="subtitle1" marginBottom="24px">
                        {t('help_search_subtitle')}
                    </Typography>

                    <FaqSearchInput redirectPath="search" />
                </div>
            </section>
        </Fragment>
    );
};
