import { FC, Fragment, KeyboardEventHandler, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import {
    Box,
    IconButton,
    CircularProgress,
    Stack,
    TextField,
    Typography,
    Alert,
} from '@mui/material';

import { useGetCurrentUser } from 'src/features/user';
import { EmptyList } from 'src/components/empty-list/empty-list';
import { ReactComponent as IconWarning } from 'src/assets/svg/warning.svg';

import { usePostTicketComment } from '../../hooks/use-post-ticket-comment';
import { ReactComponent as PaperPlaneIcon } from '../../assets/svg/paper-plane.svg';
import { MaintenanceTicket, MaintenanceTicketStatus, PostMaintenanceCommentBody } from '../../api';

import { Message } from './parts/message';

export const MaintenanceChat: FC<{ ticket: MaintenanceTicket; onClose: VoidFunction }> = ({
    ticket,
    onClose,
}) => {
    const { control, handleSubmit, reset, getValues } = useForm<PostMaintenanceCommentBody>();
    const messagesListRef = useRef<HTMLDivElement | null>(null);

    const { mutate, isLoading } = usePostTicketComment(ticket.id, { onSuccess: () => reset() });
    const { data: user } = useGetCurrentUser();

    const { palette, breakpoints, shape } = useTheme();
    const { t } = useTranslation();

    const messages = ticket.comments || [];
    const messagesLength = messages.length;
    const disableChat = ticket.status === MaintenanceTicketStatus.Done;

    const handleChatMessageSubmit = handleSubmit((comment) => mutate(comment));

    const handleEnterSubmit: KeyboardEventHandler = (event) => {
        if (event.code === 'Enter') {
            handleChatMessageSubmit(event);
        }
    };

    useEffect(() => {
        const element = messagesListRef.current;
        if (!element) return;

        element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
    }, [messages]);

    return (
        <Stack
            component="form"
            overflow="auto"
            flex="1 0 calc(50% - 61px)"
            padding={{ xs: '0', lg: '60px' }}
            zIndex={1}
            css={{ background: palette.background.default }}
        >
            <div
                css={{
                    textAlign: 'center',
                    marginBottom: '32px',
                    [breakpoints.down('lg')]: { display: 'none' },
                }}
            >
                <Typography variant="overlineMedium">
                    {t('maintenance_specific-ticket_chat_comments_title', {
                        count: messagesLength,
                    })}
                </Typography>
            </div>

            <Stack
                css={{
                    overflow: 'auto',
                    flex: 1,
                    position: 'relative',
                    justifyContent: 'flex-end',
                }}
            >
                <Stack
                    ref={messagesListRef}
                    css={{
                        overflow: 'auto',
                        gap: '20px',
                        padding: '16px 16px 20px 16px',
                        [breakpoints.up('lg')]: { padding: '0 8px 20px 0' },
                    }}
                >
                    {messagesLength ? (
                        <Fragment>
                            {messages.map((message) => (
                                <Message key={message.id} message={message} currentUser={user!} />
                            ))}
                        </Fragment>
                    ) : (
                        <EmptyList
                            mainText={
                                <Trans i18nKey="maintenance_specific-ticket_chat_empty_title" />
                            }
                            secondaryText={t('maintenance_specific-ticket_chat_empty_description')}
                            css={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
                        />
                    )}
                </Stack>
            </Stack>

            <Box
                borderRadius={{ lg: `${shape.borderRadius}px ${shape.borderRadius}px 0 0` }}
                overflow="auto"
            >
                {disableChat ? (
                    <Alert
                        severity="error"
                        css={{
                            justifyContent: 'unset',
                            [breakpoints.down('lg')]: { paddingLeft: '36px', paddingRight: '36px' },
                        }}
                    >
                        {t('maintenance_specific-ticket_chat_comments_ticket_closed')}
                    </Alert>
                ) : (
                    <Stack
                        direction="row"
                        gap="12px"
                        padding="8px 16px"
                        alignItems="center"
                        css={{
                            background: palette.background.paper,
                        }}
                    >
                        <IconWarning width="24px" height="24px" color={palette.common.black} />

                        <div>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                display={{ lg: 'none' }}
                            >
                                <Trans
                                    i18nKey="maintenance_specific-ticket_chat_attach_files"
                                    components={{
                                        a: (
                                            <Typography
                                                role="button"
                                                component="span"
                                                variant="subtitle2"
                                                color="text.primary"
                                                onClick={onClose}
                                                css={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            </Typography>

                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                display={{ xs: 'none', lg: 'inline' }}
                            >
                                <Trans i18nKey="maintenance_specific-ticket_chat_empty_attach_files" />
                            </Typography>
                        </div>
                    </Stack>
                )}

                <Controller
                    name="comment_body"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            variant="outlined"
                            placeholder={t(
                                'maintenance_specific-ticket_chat_empty_comment_placeholder'
                            )}
                            fullWidth
                            disabled={disableChat}
                            InputProps={{
                                maxRows: 5,
                                multiline: true,
                                endAdornment: (
                                    <Box alignSelf="flex-end">
                                        {isLoading ? (
                                            <CircularProgress color="inherit" size="24px" />
                                        ) : (
                                            <IconButton
                                                color="inherit"
                                                disabled={!getValues().comment_body}
                                                disableRipple
                                                onClick={handleChatMessageSubmit}
                                                css={{ padding: 0 }}
                                            >
                                                <PaperPlaneIcon
                                                    width="24px"
                                                    height="24px"
                                                    color="inherit"
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                ),
                            }}
                            onKeyDown={handleEnterSubmit}
                            css={{
                                '.MuiInputBase-root': {
                                    padding: '16px',
                                    background: 'none',
                                    borderRadius: `0 0 ${shape.borderRadius}px ${shape.borderRadius}px`,

                                    fieldset: {
                                        borderColor: `${palette.divider} !important`,
                                        borderWidth: '1px !important',
                                    },

                                    '&.Mui-disabled': {
                                        background: palette.divider,
                                    },
                                },
                            }}
                        />
                    )}
                />
            </Box>
        </Stack>
    );
};
