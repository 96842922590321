import { FC, MouseEventHandler } from 'react';
import { Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as CrossInSquareIcon } from 'src/assets/svg/cross-in-square.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { ReactComponent as VitaLogo } from 'src/assets/svg/vita-logo.svg';
import { GA4 } from 'src/lib/ga4';
import { appConfig } from 'src/app/app-config';
import { Link } from 'src/components/link';

import { ReactComponent as AlumniLogo } from '../assets/alumni-logo.svg';
import { CARD_BACKGROUND_URL } from '../constants/card-background';

export const AlumniModal: FC<{ onClose: VoidFunction; open: boolean }> = ({ onClose, open }) => {
    const { palette, breakpoints } = useTheme();

    const { t } = useTranslation();

    const handleLinkedInLinkClick = () =>
        GA4.outboundLink('Alumni LinkedIn Link Click', appConfig.alumni.linkedinLink);

    const handleWeixinLinkClick = () =>
        GA4.outboundLink('ALumni Weixin Link Click', appConfig.alumni.weixinLink);

    return (
        <Dialog
            open={open}
            maxWidth={false}
            disableAutoFocus
            onClose={onClose}
            css={{
                height: '100%',

                '.MuiDialog-container': {
                    display: 'grid',
                    height: 'auto',
                    minHeight: '100%',
                    gridTemplateRows: '40px auto 40px',
                },

                '.MuiDialog-paper': {
                    gridRow: '2/3',
                    margin: 'auto 16px',
                    maxWidth: '613px',
                    borderRadius: 0,
                    background: palette.background.default,
                    gap: '44px',

                    [breakpoints.up('md')]: {
                        gap: '94px',
                    },
                },
            }}
        >
            <IconButton
                onClick={onClose as MouseEventHandler}
                css={{
                    padding: 0,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 2,
                    background: palette.background.default,
                }}
            >
                <CrossInSquareIcon width="50px" height="50px" color={palette.primary.main} />
            </IconButton>

            <Stack flex={1} gap="32px">
                <Stack
                    padding={{ xs: '18px 22px 12px 22px', md: '30px 40px 18px 40px' }}
                    position="relative"
                    gap={{ xs: '16px', md: '22px' }}
                    color={palette.common.white}
                    css={{
                        backgroundImage: `url(${CARD_BACKGROUND_URL})`,
                        backgroundSize: 'cover',
                    }}
                >
                    <VitaLogo
                        css={{
                            width: '28px',
                            height: '28px',

                            [breakpoints.down('md')]: {
                                width: '18px',
                                height: '18px',
                            },
                        }}
                    />
                    <Typography
                        variant="h1"
                        paddingBottom="16px"
                        position="relative"
                        padding={0}
                        zIndex={1}
                        lineHeight="88%"
                        letterSpacing="-4%"
                    >
                        <Trans i18nKey="home_exclusive-offers_alumni_modal_image-content" />

                        <span
                            css={{
                                position: 'absolute',
                                bottom: '1px',
                                left: '39px',
                                background: palette.primary.main,
                                height: '9px',
                                width: '185px',
                                zIndex: -1,

                                [breakpoints.down('md')]: {
                                    width: '112px',
                                    height: '6px',
                                    left: '26px',
                                },
                            }}
                        />
                    </Typography>

                    <AlumniLogo
                        css={{
                            width: '155px',
                            height: '58px',

                            [breakpoints.down('md')]: {
                                width: '108px',
                                height: '40px',
                            },
                        }}
                    />
                </Stack>

                <Stack
                    padding={{ xs: '0 20px', md: '8px 60px 0 60px' }}
                    alignItems="center"
                    textAlign="center"
                    gap="20px"
                    color={palette.text.primary}
                >
                    <Typography variant="h2" maxWidth="300px">
                        {t('home_exclusive-offers_alumni_modal_title')}
                    </Typography>

                    <Typography variant="body3">
                        {t('home_exclusive-offers_alumni_modal_description')}
                    </Typography>
                </Stack>

                <Stack
                    component="a"
                    href={appConfig.alumni.linkedinLink}
                    onClick={handleLinkedInLinkClick}
                    target="_blank"
                    css={{ backgroundColor: palette.primary.main, textDecoration: 'none' }}
                    padding="20px"
                    justifyContent="center"
                    gap="12px"
                    flexDirection="row"
                    alignItems="center"
                    color={palette.common.white}
                    textAlign="center"
                >
                    <Typography variant="h4">
                        {t('home_exclusive-offers_alumni_modal_link')}
                    </Typography>

                    <ArrowIcon css={{ transform: 'rotate(-90deg)', flexShrink: 0 }} />
                </Stack>

                <Typography
                    variant="body2"
                    color={palette.text.secondary}
                    padding={{ xs: '0 20px 32px 20px', md: '0 60px 40px 60px' }}
                    textAlign="center"
                >
                    <Trans
                        i18nKey="home_exclusive-offers_alumni_modal_note"
                        components={{
                            a: (
                                <Link
                                    variant="primary"
                                    href={appConfig.alumni.weixinLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={handleWeixinLinkClick}
                                    css={{
                                        color: palette.primary.main,
                                        fontWeight: 400,
                                    }}
                                />
                            ),
                        }}
                    />
                </Typography>
            </Stack>
        </Dialog>
    );
};
