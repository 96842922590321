import i18n, { Callback } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { captureException } from '@sentry/react';

import { FALLBACK_LNG, LanguagesCode, LANGUAGES_IMPORT_MAP } from './constants';

i18n.use(
    resourcesToBackend(async (language, namespace, callback) => {
        try {
            const importLang = LANGUAGES_IMPORT_MAP[language as LanguagesCode];
            const data = await importLang();

            callback(null, data);
        } catch (error) {
            captureException(error);
            callback(error, null);
        }
    })
).use(initReactI18next);

export const initI18Next = (lng?: string | null, callback?: Callback) => {
    if (i18n.isInitialized) {
        return;
    }

    i18n.init(
        {
            lng: lng || FALLBACK_LNG,
            fallbackLng: FALLBACK_LNG,
            supportedLngs: [LanguagesCode.English, LanguagesCode.Spanish],
            nonExplicitSupportedLngs: true,
            debug: process.env.REACT_APP_I18N === 'true',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
                prefix: '[',
                suffix: ']',
            },
        },
        callback
    );
};
