import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { CreateFeedbackForm, PostFeedback } from '../api/types';
import { postFeedback, postFeedbackAttachment } from '../api/requests';
import { FeedbackFormFields } from '../constants/form-options';
import { mapCreateFeedbackForm } from '../utils/map-create-feedback-form';

type Config = UseMutationOptions<
    PostFeedback,
    AxiosError<ConciergeErrorResponse>,
    CreateFeedbackForm
>;

export const usePostFeedback = (config: Config = {}) =>
    useMutation<PostFeedback, AxiosError<ConciergeErrorResponse>, CreateFeedbackForm>({
        async mutationFn(formData: CreateFeedbackForm) {
            const attachments = await Promise.all(
                formData[FeedbackFormFields.Attachments].map((file) => postFeedbackAttachment(file))
            );

            const mappedData = mapCreateFeedbackForm(formData, attachments);

            return postFeedback(mappedData);
        },

        ...config,
    });
