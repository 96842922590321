import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import clsx from 'clsx';

import 'flag-icons/css/flag-icons.min.css';

interface InternalProps {
    code: string;
    square?: boolean;
}

type Props = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & InternalProps;

export const FlagIcon: FC<Props> = ({ code, className, square, ...restProps }) => (
    <span
        className={clsx(className, square ? 'fis' : 'fi', `fi-${code.toLocaleLowerCase()}`)}
        {...restProps}
    />
);
