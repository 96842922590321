import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import {
    RebookingList,
    useGetRebookingFilter,
    RebookingFilterContainer,
    useGetRebookingPhase,
    getPhases,
    RebookStatus,
} from 'src/features/rebooking';
import { PrivateHat } from 'src/layout/private-hat';
import { ErrorState } from 'src/components/error-state';
import { Spinner } from 'src/components/spinner';
import { routes } from 'src/constants/routes';

export const RebookingSwapPage = () => {
    const { t } = useTranslation();

    const { isError: isRebookingFilterError } = useGetRebookingFilter();

    const {
        data: phaseInfo,
        isLoading: isLoadingRebookingPhase,
        isError: isErrorRebookingPhase,
    } = useGetRebookingPhase();

    const rebookStatus = phaseInfo?.rebook_status;

    if (isLoadingRebookingPhase) {
        return (
            <Stack flex={1} justifyContent="center" alignItems="center">
                <Typography color="primary.main">
                    <Spinner size="big" />
                </Typography>
            </Stack>
        );
    }

    if (
        isErrorRebookingPhase ||
        !phaseInfo.is_available ||
        rebookStatus === RebookStatus.ComingSoon ||
        rebookStatus === RebookStatus.NotAllowed ||
        !getPhases(phaseInfo.phases).swap?.active
    ) {
        return <Navigate to={routes.home} />;
    }

    if (isRebookingFilterError) {
        return (
            <ErrorState title={<Trans i18nKey="rebookers_swap_your_room_error_state_title" />} />
        );
    }

    return (
        <Fragment>
            <PrivateHat title={t('rebookers_swap_your_room_title')} backIconText={t('back_cta')} />

            <Stack
                flex={1}
                direction={{ xs: 'column-reverse', lg: 'row' }}
                gap={{ xs: '40px', md: '44px', lg: '80px', xl: '160px' }}
                padding={{
                    xs: '24px 16px 32px 16px',
                    md: '60px 0 30px 0',
                    lg: '60px 60px 30px 60px',
                    xl: '64px 100px 30px 100px',
                }}
            >
                <RebookingList />

                <RebookingFilterContainer />
            </Stack>
        </Fragment>
    );
};
