import { ChangeEventHandler, FC, useRef } from 'react';
import {
    CountryCode,
    getCountries,
    getCountryCallingCode,
    AsYouType,
    validatePhoneNumberLength,
} from 'libphonenumber-js';
import {
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    Stack,
    TextField,
    Typography,
} from '@mui/material';

import { FlagIcon } from 'src/components/flag-icon';
import { phoneNumberPlus } from 'src/utils/phone-number-plus';

import { PhoneNumber } from '../../../api';

const PHONE_COUNTRIES_MAP = getCountries();
const regionNames = new Intl.DisplayNames(['en'], { type: 'region' }); // intl: integrate with i18n

PHONE_COUNTRIES_MAP.sort((a, b) => {
    const aName = regionNames.of(a) as string;
    const bName = regionNames.of(b) as string;

    return aName.localeCompare(bName);
});

export const PhoneInput: FC<{
    value: PhoneNumber;
    error?: boolean;
    helperText?: string;
    label: string;
    required?: boolean;
    onChange: (phoneNumber: PhoneNumber) => void;
}> = ({ value, error, helperText, label, required, onChange }) => {
    const { countryCode, callingCode, number } = value;
    const inputValue = phoneNumberPlus((callingCode || '') + (number || ''));

    const handleChangeCountry: SelectProps<CountryCode>['onChange'] = (event) => {
        const newCountryCode = event.target.value as CountryCode;
        const newCallingCode = getCountryCallingCode(newCountryCode);

        onChange({
            countryCode: newCountryCode,
            callingCode: newCallingCode,
            number: '',
        });
    };

    const numberInput = useRef<HTMLInputElement>(null);

    const handleChangeNumber: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        const newNumber = phoneNumberPlus(target.value);

        if (!countryCode) {
            onChange({
                countryCode: undefined,
                callingCode: undefined,
                number: newNumber,
            });
            return;
        }

        const formatter = new AsYouType(countryCode);
        formatter.input(newNumber);

        const formatterNumber = formatter.getNumberValue() || '';
        const lengthStatus = validatePhoneNumberLength(formatterNumber, countryCode);

        if (formatterNumber.includes(`+${callingCode}`) && lengthStatus !== 'TOO_LONG') {
            onChange({
                countryCode,
                callingCode,
                number: formatter.getNationalNumber(),
            });
        }
    };

    return (
        <Stack>
            <InputLabel shrink>{label}</InputLabel>

            <Stack direction="row" gap="5px" alignItems="flex-start">
                <Select
                    value={countryCode || ''}
                    onChange={handleChangeCountry}
                    variant="standard"
                    css={{
                        '.MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',

                            '.phone-input__country-name': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    {PHONE_COUNTRIES_MAP.map((code) => (
                        <MenuItem key={code} value={code}>
                            {code && <FlagIcon code={code} />}

                            <Typography className="phone-input__country-name" marginLeft="1em">
                                {regionNames.of(code)}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>

                <TextField
                    ref={numberInput}
                    type="tel"
                    required={required}
                    value={inputValue}
                    onChange={handleChangeNumber}
                    error={error}
                    helperText={helperText}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    css={{
                        '.MuiFormHelperText-root': {
                            color: 'red',
                        },
                        input: {
                            padding: '10px 0',
                        },
                        flexGrow: 1,
                    }}
                />
            </Stack>
        </Stack>
    );
};
