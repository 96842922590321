import { endOfDay } from 'date-fns';

/**
 * @returns ISO without time and time zone
 * @example new Date() => '2022-11-15'
 */
export const getLocalIso = (source = new Date()) => {
    const date = endOfDay(source);
    const iso = date.toISOString();
    const [result] = iso.split('T');

    return result;
};
