import { differenceInCalendarDays } from 'date-fns';

export const formatBuildingNewsDate = (iso: string) => {
    const diff = differenceInCalendarDays(new Date(), new Date(iso));

    switch (diff) {
        case 0:
            return { key: 'whats_happening_news_posted_today', diff };
        case 1:
            return { key: 'whats_happening_news_posted_yesterday', diff };
        default:
            return { key: 'whats_happening_news_date', diff };
    }
};
