import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import type { ConciergeErrorResponse } from 'src/api/concierge';

import { getBuildingActiveIncidentsCount } from '../api/requests';
import { BuildingUpdatesEndpoints } from '../api/constants';
import { ActiveBuildingIncidentCount } from '../api/types';

type Config = UseQueryOptions<ActiveBuildingIncidentCount, ConciergeErrorResponse, number>;

const selectAmount = ({ active_incidents_count }: ActiveBuildingIncidentCount) =>
    active_incidents_count;

export const useGetBuildingActiveIncidentsCount = (id: string, config: Config = {}) =>
    useQuery<ActiveBuildingIncidentCount, ConciergeErrorResponse, number>({
        queryKey: [BuildingUpdatesEndpoints.getIncidentsCount, id],
        queryFn: () => getBuildingActiveIncidentsCount(id),
        select: selectAmount,
        ...config,
    });
