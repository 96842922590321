import { FC } from 'react';

import { useAuth0, setPreLogoutPath } from 'src/features/auth';

export const LogoutPage: FC = () => {
    const { logout } = useAuth0();

    setPreLogoutPath('/');
    logout({ returnTo: window.location.origin });

    return null;
};
