import { FC, ReactNode } from 'react';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

interface UnavailableSlot {
    startTime: string;
    isAvailable: false;
    isSelected?: never;
    onTimeChange?: never;
    children: ReactNode;
}

interface AvailableSlot {
    startTime: string;
    isAvailable: true;
    isSelected: boolean;
    onTimeChange: (newTime: string) => void;
    children: ReactNode;
}

export const SlotOption: FC<UnavailableSlot | AvailableSlot> = ({
    startTime,
    isAvailable,
    isSelected,
    onTimeChange,
    children,
}) => {
    const { palette } = useTheme();

    const handleTimeChange = () => {
        onTimeChange!(startTime);
    };

    return (
        <Button
            onClick={handleTimeChange}
            css={{
                padding: '20px',
                background: isSelected
                    ? `${palette.common.black} !important`
                    : palette.background.paper,
                color: isSelected ? palette.common.white : palette.common.black,
                borderRadius: '0',
            }}
            disabled={!isAvailable}
        >
            <Typography
                variant="body2"
                css={{
                    textDecoration: !isAvailable ? 'line-through' : undefined,
                    color: !isAvailable ? palette.text.disabled : undefined,
                }}
            >
                {children}
            </Typography>
        </Button>
    );
};
