import { AsYouType } from 'libphonenumber-js';

import { PhoneNumber } from 'src/features/user/api';

export const getAsPhoneNumber = (phoneNumber: string | null): PhoneNumber => {
    if (!phoneNumber) {
        return {
            countryCode: undefined,
            callingCode: undefined,
            number: null,
        };
    }
    const formatter = new AsYouType();

    formatter.input(phoneNumber);

    return {
        countryCode: formatter.getCountry(),
        callingCode: formatter.getCallingCode(),
        number: formatter.getNationalNumber(),
    };
};
