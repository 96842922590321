import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { routes } from 'src/constants/routes';
import { useGetEvents, EventList } from 'src/features/events';
import { Spinner } from 'src/components/spinner';
import { ErrorState } from 'src/components/error-state';
import { trimText } from 'src/utils/trim-text';
import { ReactComponent as EventsIcon } from 'src/assets/svg/party.svg';
import { GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';

import { BuildingCard } from './building-card';
import { BuildingNavLink } from './building-nav-link';

interface Props {
    checkedIn: boolean;
    isFeatureEnabled: boolean;
    className?: string;
}

const selectEvent = (events: EventList) => events.find(({ featured }) => featured);

export const BuildingFeaturedEvent: FC<Props> = ({ checkedIn, isFeatureEnabled, className }) => {
    const {
        data: featuredEvent,
        isError,
        isLoading,
        isSuccess,
    } = useGetEvents({
        select: selectEvent,
        enabled: isFeatureEnabled,
    });

    const { t } = useTranslation();

    const handleTitleClick = () =>
        GA4.ctaEvent({
            name: 'Events Title Click',
            label: t('my_building_events'),
            location: 'Events Card',
        });

    const handleCTAClick = () =>
        GA4.ctaEvent({
            name: 'Events View More Click',
            label: t('events_see-all'),
            location: 'Events Card',
        });

    return (
        <BuildingNavLink
            Icon={EventsIcon}
            title={t('my_building_events')}
            link={{
                url: routes.events.index,
                text: t('events_see-all'),
            }}
            checkedIn={checkedIn}
            isFeatureEnabled={isFeatureEnabled}
            onTitleClick={handleTitleClick}
            onCTAClick={handleCTAClick}
            className={className}
        >
            {/* Use function because we need pass a node to BuildingNavLink not array. */}
            {(() => {
                if (isError)
                    return <ErrorState title={<Trans i18nKey="whats_happening_error_title" />} />;

                if (isLoading)
                    return (
                        <Typography textAlign="center" padding="37px" color="primary">
                            <Spinner />
                        </Typography>
                    );

                if (isSuccess && featuredEvent) {
                    const title = getPartialTranslation(featuredEvent.title);
                    const description = getPartialTranslation(featuredEvent.description);

                    const handleEventClick = () =>
                        GA4.ctaEvent({
                            name: 'Featured Event Click',
                            label: title,
                            location: 'Events Card',
                        });

                    return (
                        <BuildingCard
                            image={featuredEvent.image_url}
                            label={t('building_event-preview_label')}
                            title={title}
                            body={trimText(description, 140)}
                            link={{
                                url: routes.events.id.replace(':id', featuredEvent.id),
                                text: t('building_event-preview_link'),
                            }}
                            onClick={handleEventClick}
                            paper
                            datetime={featuredEvent.start_date}
                        />
                    );
                }

                return null;
            })()}
        </BuildingNavLink>
    );
};
