import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import { PrivateHat } from 'src/layout/private-hat';
import {
    StaffCategory,
    StaffCategoryList,
    Subject,
    SUBJECT_TITLE_TRANSLATION_KEY,
} from 'src/features/feedback';
import { routes } from 'src/constants/routes';
import { Button } from 'src/components/button';

export const FeedbackStaffPage: FC = () => {
    const { t } = useTranslation();

    const { breakpoints } = useTheme();

    const [staffCategory, setStaffCategory] = useState<StaffCategory | null>(null);

    const handleStaffCategoryChange = (option: string) => setStaffCategory(option as StaffCategory);

    return (
        <Fragment>
            <PrivateHat
                title={t(SUBJECT_TITLE_TRANSLATION_KEY[Subject.Staff])}
                backIconText={t('back_cta')}
            />

            <Stack
                padding={{ xs: '16px', md: '40px' }}
                flexGrow={1}
                gap={{ md: '60px' }}
                justifyContent={{ md: 'center' }}
                alignItems={{ md: 'center' }}
            >
                <Stack
                    flexGrow={{ xs: 1, md: 0 }}
                    gap={{ xs: '32px', md: '52px' }}
                    maxWidth={{ md: '600px' }}
                >
                    <Typography
                        variant="subtitle1"
                        color="text.primary"
                        aria-label="link text"
                        textAlign={{ md: 'center' }}
                    >
                        {t('feedback_page_select-staff_description')}
                    </Typography>

                    <StaffCategoryList
                        onStaffCategoryChange={handleStaffCategoryChange}
                        selectedStaffCategory={staffCategory}
                    />
                </Stack>

                <Button
                    design="primary"
                    disabled={staffCategory === null}
                    component={Link}
                    to={routes.feedback.staff.category
                        .replace(':subject', Subject.Staff)
                        .replace(':staffCategory', staffCategory ?? '')}
                    css={{
                        [breakpoints.up('md')]: {
                            width: '260px',
                        },
                    }}
                >
                    {t('feedback_page_select-staff_cta')}
                </Button>
            </Stack>
        </Fragment>
    );
};
