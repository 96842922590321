import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { ReactComponent as ErrorIcon } from 'src/assets/svg/error.svg';

interface Props {
    direction?: 'row' | 'column';
    paper?: boolean;
    title: ReactNode;
    subtitle?: ReactNode;
    className?: string;
}

export const ErrorState: FC<Props> = ({ direction = 'row', paper, title, subtitle, className }) => {
    const { palette } = useTheme();

    const isRow = direction === 'row';
    const iconSize = isRow ? 56 : 24;

    return (
        <Stack
            flex={1}
            alignItems="center"
            justifyContent="center"
            gap="32px"
            className={className}
            css={{ background: paper ? palette.background.paper : undefined }}
        >
            <Stack
                direction={direction}
                alignItems="center"
                justifyContent="center"
                gap={isRow ? '20.5px' : '8px'}
            >
                <ErrorIcon width={iconSize} height={iconSize} color={palette.primary.main} />

                <Typography
                    variant="h3"
                    textTransform="uppercase"
                    textAlign={isRow ? 'left' : 'center'}
                    color={isRow ? palette.common.black : palette.text.secondary}
                >
                    {title}
                </Typography>
            </Stack>

            {subtitle && (
                <Typography variant="body2" color={palette.text.secondary} textAlign="center">
                    {subtitle}
                </Typography>
            )}
        </Stack>
    );
};
