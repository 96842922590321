import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';

import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { appConfig } from 'src/app/app-config';

import { BookingDocsItem } from './booking-docs-item';

export const BookingDocs: FC<{
    developmentSlug: string;
    termsAndConditionsLink: string;
    licenseAgreementLink: string;
}> = ({ developmentSlug, termsAndConditionsLink, licenseAgreementLink }) => {
    const { t } = useTranslation();

    const { codeOfPracticeURL } = appConfig.codeOfPractice;

    const showCodeOfPracticeForDevelopments = [
        'vita-student-edinburgh',
        'edinburgh-iona-street',
        'vita-student-glasgow',
    ];

    const handleAccomodationContractDownload = () =>
        GA4.downloadEvent({
            name: 'Accomodation Contract Download',
            label: t('my_bookings_payment_documents_licence_agreement_title', {
                lng: LanguagesCode.English,
            }),
            location: 'My Booking Documents',
        });

    const handleBookingTermsDownload = () =>
        GA4.downloadEvent({
            name: 'Booking Terms & Conditions Download',
            label: t('my_bookings_payment_documents_tc_title', { lng: LanguagesCode.English }),
            location: 'My Booking Documents',
        });

    const handleCodeOfPracticeClick = () =>
        GA4.downloadEvent({
            name: 'Code Of Practice Click',
            label: t('my_bookings_code_of_practice_title', { lng: LanguagesCode.English }),
            location: 'My Booking Documents',
        });

    return (
        <Stack spacing="12px" flex={1}>
            <BookingDocsItem
                name={t('my_bookings_payment_documents_licence_agreement_title')}
                href={licenseAgreementLink}
                onClick={handleAccomodationContractDownload}
            />
            <BookingDocsItem
                name={t('my_bookings_payment_documents_tc_title')}
                href={termsAndConditionsLink}
                download
                onClick={handleBookingTermsDownload}
            />

            {showCodeOfPracticeForDevelopments.includes(developmentSlug) && (
                <BookingDocsItem
                    name={t('my_bookings_code_of_practice_title')}
                    href={codeOfPracticeURL}
                    onClick={handleCodeOfPracticeClick}
                />
            )}
        </Stack>
    );
};
