export enum PaymentsEndpoints {
    getPayments = '/v1/payments',
    getMakePayment = '/v1/bookings/{id}/make-payment',
    getMakeMandate = '/v1/bookings/{id}/make-mandate',
    getPaymentReceipts = '/v1/payment-receipts',
    getPaymentReceiptPDF = '/v1/payment-receipts/{id}/pdf',
}

export enum PaymentStatus {
    TransfermatePaid = 'TRANSFERMATE_PAID',
    TransfermateUpcoming = 'TRANSFERMATE_UPCOMING',
    TransfermateOverdue = 'TRANSFERMATE_OVERDUE',
    TransfermateOnTrack = 'TRANSFERMATE_ON_TRACK',

    GoCardlessPaid = 'GOCARDLESS_PAID', // Direct Debit already has been setup and all payments were made
    GoCardlessOverdue = 'GOCARDLESS_OVERDUE', // Direct Debit setup overdue / 7 days AND direct debit setup = false AND payment GC_Mandate_Id__c is null
    GoCardlessUpcoming = 'GOCARDLESS_UPCOMING', // Direct Debit already has been set up and next payment is not overdue
    GoCardlessNoMandate = 'GOCARDLESS_NO_MANDATE', //  Direct debit is not set up
    GoCardlessSetupFailedNoOverdue = 'GOCARDLESS_SETUP_FAILED_WITHOUT_OVERDUE', // Direct Debit setup Failed (redirect failure scenario) and there are no overdue payment
    GoCardlessSetupFailedOverdue = 'GOCARDLESS_SETUP_FAILED_WITH_OVERDUE', // Direct Debit setup Failed and there is overdue payment [GoCardlessSetupFailed]
    GoCardlessPaymentFailed = 'GOCARDLESS_PAYMENT_FAILED', // Direct Debit setup complete, payment has failed
    GoCardlessCanceled = 'GOCARDLESS_CANCELED', // Direct Debit cancelled
}
