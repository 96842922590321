import { conciergeClient } from 'src/api/concierge';

import { PostsEndpoints } from './constants';

import type { GetPostsResponse, GetPostByIdResponse } from './types';

export const getPosts = async () => {
    const { data: response } = await conciergeClient.get<GetPostsResponse>(PostsEndpoints.getPosts);
    return response.data;
};

export const getPostById = async (id: string) => {
    const { data: response } = await conciergeClient.get<GetPostByIdResponse>(
        PostsEndpoints.getPostById.replace('{id}', id)
    );
    return response.data;
};
