export enum ChatCallAction {
    Hide = 'hide',
    Destroy = 'destroy',
    Maximize = 'maximize',
    Minimize = 'minimize',
}

export enum ChatEvents {
    VisibilityChange = 'visibility_changed',
    AvailabilityChanged = 'availability_changed',
    Ready = 'ready',
}

export enum ChatVisibility {
    Maximized = 'maximized',
    Minimized = 'minimized',
    Hidden = 'hidden',
}

export enum ChatAvailability {
    Online = 'online',
    Offline = 'offline',
}

export enum PostMateType {
    HandShake = 'handshake-reply',
    Emit = 'emit',
}
