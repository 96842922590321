import { format, formatRelative, parseISO, Locale, isToday, isYesterday } from 'date-fns';

interface Options {
    locale?: Locale;
}

export const formatTicketLastMessageDate = (date: string, options: Options = {}) => {
    const sourceDate = parseISO(date);
    const { locale } = options;

    if (isToday(sourceDate) || isYesterday(sourceDate)) {
        return formatRelative(sourceDate, new Date(), { locale });
    }

    return format(sourceDate, 'MMM d, yyyy', { locale: options.locale });
};
