import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { queryClient } from 'src/lib/query-client';
import { BookingsEndpoints } from 'src/features/bookings';
import { ConciergeErrorResponse } from 'src/api/concierge';

import { UserEndpoints, patchUser, UserData, PatchUserBody } from '../api';

export const usePatchUser = (id: string) => {
    const mutation = useMutation<
        UserData,
        AxiosError<ConciergeErrorResponse<UserData>>,
        PatchUserBody
    >({
        mutationFn: (user) => patchUser(id, user),
        onSuccess: async (user) => {
            await queryClient.invalidateQueries([BookingsEndpoints.getMyBookings]);
            await queryClient.setQueryData([UserEndpoints.getCurrentUser], user);
        },
    });

    return mutation;
};
