import { ConciergeSuccessResponse, conciergeClient } from 'src/api/concierge';

import mockedUpcomingEvents from '../mocks/upcoming-events.json';

import { EventsEndpoints } from './constants';

import type { Event, EventList } from './types';

export const getUpcomingEventsList = async () => mockedUpcomingEvents as EventList;

export const getMyPastEventsList = async () => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<EventList>>(
        EventsEndpoints.GetMyPastEvents
    );

    return response.data;
};

export const getMyEventsList = async () => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<EventList>>(
        EventsEndpoints.GetMyEvents
    );

    return response.data;
};

export const getEvent = async (id: string) => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<Event>>(
        EventsEndpoints.GetEvent.replace('{id}', id)
    );

    return response.data;
};

export const getEvents = async () => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<EventList>>(
        EventsEndpoints.GetEvents
    );

    return response.data;
};

export const postAttendEvent = async (id: string) => {
    const { data: response } = await conciergeClient.post<ConciergeSuccessResponse<Event>>(
        EventsEndpoints.PostAttendEvent.replace('{id}', id)
    );

    return response.data;
};

export const postCancelAttendanceEvent = async (id: string) => {
    const { data: response } = await conciergeClient.post<ConciergeSuccessResponse<Event>>(
        EventsEndpoints.PostCancelAttendanceEvent.replace('{id}', id)
    );

    return response.data;
};
