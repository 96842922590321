import { FC } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTheme } from '@emotion/react';

import { ReactComponent as ControlArrow } from 'src/assets/svg/control-arrow.svg';
import { appConfig } from 'src/app/app-config';

export const CalendarNavigation: FC<{
    updateMonth: (month: number | null) => void;
    decrementMonth: () => void;
    incrementMonth: () => void;
    updateYear: (year: number | null) => void;
    focusedMonth: number | null;
    focusedYear: number | null;
}> = ({ updateMonth, decrementMonth, incrementMonth, updateYear, focusedMonth, focusedYear }) => {
    const { palette } = useTheme();

    const decrementYear = () =>
        focusedYear !== null &&
        focusedYear > appConfig.moveIn.calendarFromYear &&
        updateYear(focusedYear - 1);

    const incrementYear = () => {
        if (focusedYear !== null && focusedYear < appConfig.moveIn.calendarFromYear + 11)
            updateYear(focusedYear + 1);
    };

    const openMonthSelector = () => updateMonth(null);

    const openYearSelector = () => updateYear(null);

    if (focusedMonth !== null && focusedYear !== null) {
        return (
            <Stack justifyContent="space-between" direction="row" width="100%" paddingY="10px">
                <IconButton
                    onClick={decrementMonth}
                    css={{
                        width: 31,
                        height: 31,
                    }}
                >
                    <ControlArrow
                        width="100%"
                        height="100%"
                        css={{
                            color: palette.common.black,
                            transform: 'rotate(90deg)',
                        }}
                    />
                </IconButton>

                <Button onClick={openMonthSelector}>
                    <Typography marginRight="8px" variant="subtitle1" color={palette.common.black}>
                        {format(new Date(focusedYear, focusedMonth, 1), 'MMM yyyy')}
                    </Typography>
                    <ControlArrow
                        css={{ color: palette.common.black, marginTop: '2px' }}
                        width={12}
                        height={12}
                    />
                </Button>

                <IconButton
                    onClick={incrementMonth}
                    css={{
                        width: 31,
                        height: 31,
                    }}
                >
                    <ControlArrow
                        width="100%"
                        height="100%"
                        css={{ color: palette.common.black, transform: 'rotate(-90deg)' }}
                    />
                </IconButton>
            </Stack>
        );
    }

    if (focusedMonth === null && focusedYear !== null) {
        return (
            <Stack justifyContent="space-between" direction="row" width="100%" paddingY="10px">
                <IconButton
                    onClick={decrementYear}
                    css={{
                        width: 31,
                        height: 31,
                    }}
                >
                    <ControlArrow
                        width="100%"
                        height="100%"
                        css={{ color: palette.common.black, transform: 'rotate(90deg)' }}
                    />
                </IconButton>

                <Button onClick={openYearSelector}>
                    <Typography marginRight="8px" variant="subtitle1" color={palette.common.black}>
                        {focusedYear}
                    </Typography>
                    <ControlArrow
                        css={{ color: palette.common.black, marginTop: '2px' }}
                        width={12}
                        height={12}
                    />
                </Button>

                <IconButton
                    onClick={incrementYear}
                    css={{
                        width: 31,
                        height: 31,
                    }}
                >
                    <ControlArrow
                        width="100%"
                        height="100%"
                        css={{ color: palette.common.black, transform: 'rotate(-90deg)' }}
                    />
                </IconButton>
            </Stack>
        );
    }

    if (focusedMonth === null && focusedYear === null) {
        return (
            <Stack justifyContent="center" direction="row" width="100%" paddingY="10px">
                <Typography
                    marginRight="8px"
                    padding="6px 8px"
                    variant="subtitle1"
                    color={palette.common.black}
                >
                    Select Year
                </Typography>
            </Stack>
        );
    }

    return null;
};
