import { Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SuccessIcon } from 'src/assets/svg/success-in-circle.svg';

export const AttendingNotice = () => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            alignItems="center"
            padding="9px 12.5px"
            gap="10px"
            marginBottom={{ xs: '26px', md: '40px', lg: '60px' }}
            color={palette.common.white}
            css={{ background: palette.success.main }}
        >
            <SuccessIcon width="18px" height="18px" />

            <Typography variant="overlineMedium">{t('event_attendance_notice')}</Typography>
        </Stack>
    );
};
