export enum PostTicketFields {
    IssueType = 'issue_type',
    Issue = 'issue',
    Problem = 'problem',
    Location = 'location',
    RoomId = 'room_id',
    BookingId = 'booking_id',
    Description = 'description',
    Attachments = 'attachments',
}

export enum MaintenanceTicketStatus {
    InProgress = 'in-progress',
    New = 'new',
    Done = 'closed',
    ResponseRequired = 'response-required',
}

export enum MaintenanceEndpoints {
    getTickets = '/v1/maintenance-tickets',
    getTicket = '/v1/maintenance-tickets/{id}',
    postTicket = '/v1/maintenance-tickets',
    getTicketFormDataTree = '/v1/maintenance-tickets/category-tree',
    postTicketComment = '/v1/maintenance-tickets/{id}/comments',
    postCloseTicket = '/v1/maintenance-tickets/{id}/close',
    patchAttachmentsUrls = '/v1/maintenance-tickets/{id}/attachments',
    postAttachmentFile = '/v1/maintenance-tickets/attachment',
}

export enum MaintenanceTicketLocation {
    CommunalSpaces = 'Communal Spaces',
    MyBathroom = 'My Bathroom',
    MyBedroom = 'My Bedroom',
    MyKitchen = 'My Kitchen',
    MyLivingSpace = 'My Living Space',
    OtherMyRoom = 'Other - My Room',
}
