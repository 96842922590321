import { FC, MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { Button } from 'src/components/button';
import { ErrorState } from 'src/components/error-state';

interface Action {
    text: string;
    callback?: MouseEventHandler;
    to?: string;
}

interface Actions {
    main?: Action;
    secondary?: Action;
}

export const StateView: FC<{
    state: 'success' | 'fail';
    title: ReactNode;
    subtitle?: ReactNode;
    children?: ReactNode;
    actions?: Actions;
    className?: string;
}> = ({ state, title, subtitle, children, actions, className }) => {
    const { palette } = useTheme();

    const mainLinkProps = actions?.main?.to && {
        component: Link,
        to: actions.main.to,
    };

    const secondaryLinkProps = actions?.secondary?.to && {
        component: Link,
        to: actions.secondary.to,
    };

    return (
        <Box
            flexGrow={1}
            display="grid"
            justifyContent="center"
            gridTemplateRows="1fr auto 1fr"
            className={className}
        >
            <Stack
                width="280px"
                gridRow={2}
                justifyContent="center"
                gap="40px"
                textAlign="center"
                marginBottom="80px"
                className="state-view__descriptor"
            >
                {state === 'success' ? (
                    <Typography
                        variant="h2"
                        borderTop={`6px solid ${palette.primary.main}`}
                        paddingTop="20px"
                        marginX="auto"
                    >
                        {title}
                    </Typography>
                ) : (
                    <ErrorState title={title} subtitle={subtitle} />
                )}

                {children}
            </Stack>

            {actions && (
                <Stack
                    width={{ md: '280px' }}
                    gridRow={3}
                    bottom="16px"
                    right="16px"
                    left="16px"
                    gap="10px"
                    position={{ xs: 'absolute', md: 'static' }}
                >
                    {actions.main && (
                        <Button
                            {...mainLinkProps}
                            design="primary"
                            fullWidth
                            onClick={actions.main.callback}
                        >
                            {actions.main.text}
                        </Button>
                    )}
                    {actions.secondary && (
                        <Button
                            {...secondaryLinkProps}
                            design="light"
                            bordered
                            fullWidth
                            onClick={actions.secondary.callback}
                        >
                            {actions.secondary.text}
                        </Button>
                    )}
                </Stack>
            )}
        </Box>
    );
};
