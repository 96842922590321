import { SfCountryCode } from 'src/api/concierge';

export enum UserEndpoints {
    getCurrentUser = '/v1/users/me',
    patchUser = '/v1/users/{id}',
    postUserAvatar = '/v1/users/{id}/avatar',
}

export enum UserDataFields {
    Id = 'id',
    Auth0Sub = 'auth0_sub',
    FirstName = 'first_name',
    LastName = 'last_name',
    Email = 'email',
    PhoneNumber = 'phone_number',
    BirthDate = 'birth_date',
    Language = 'language',
    Nationality = 'nationality',
    Gender = 'gender',
    University = 'university',
    Course = 'course',
    UniversityYear = 'university_year',
    StudentId = 'student_id',
    EmergencyContactName = 'emergency_contact_name',
    EmergencyContactPhone = 'emergency_contact_phone',
    AvatarUrl = 'avatar_url',
    AddressCity = 'address_city',
    AddressCountry = 'address_country',
    AddressPostalCode = 'address_postal_code',
    AddressStreet = 'address_street',
    FeatureFlags = 'feature_flags',
}

export const genders = ['Male', 'Female', 'Rather not say', 'Other'];

export const languages = ['English', 'Spanish'] as const;

export const nationalities = [
    'Afghan',
    'Albanian',
    'Algerian',
    'American',
    'Andorran',
    'Angolan',
    'Anguillan',
    'Argentine',
    'Armenian',
    'Australian',
    'Austrian',
    'Azerbaijani',
    'Bahamian',
    'Bahraini',
    'Bangladeshi',
    'Barbadian',
    'Belarusian',
    'Belgian',
    'Belizean',
    'Beninese',
    'Bermudian',
    'Bhutanese',
    'Bolivian',
    'Botswanan',
    'Brazilian',
    'British',
    'British Virgin Islander',
    'Bruneian',
    'Bulgarian',
    'Burkinan',
    'Burmese',
    'Burundian',
    'Cambodian',
    'Cameroonian',
    'Canadian',
    'Cape Verdean',
    'Cayman Islander',
    'Central African',
    'Chadian',
    'Chilean',
    'Chinese',
    'Citizen of Antigua and Barbuda',
    'Citizen of Bosnia and Herzegovina',
    'Citizen of Guinea-Bissau',
    'Citizen of Kiribati',
    'Citizen of Seychelles',
    'Citizen of the Dominican Republic',
    'Citizen of Vanuatu',
    'Colombian',
    'Comoran',
    'Congolese (Congo)',
    'Congolese (DRC)',
    'Cook Islander',
    'Costa Rican',
    'Croatian',
    'Cuban',
    'Cymraes',
    'Cymro',
    'Cypriot',
    'Czech',
    'Danish',
    'Djiboutian',
    'Dominican',
    'Dutch',
    'East Timorese',
    'Ecuadorean',
    'Egyptian',
    'Emirati',
    'English',
    'Equatorial Guinean',
    'Eritrean',
    'Estonian',
    'Ethiopian',
    'Faroese',
    'Fijian',
    'Filipino',
    'Finnish',
    'French',
    'Gabonese',
    'Gambian',
    'Georgian',
    'German',
    'Ghanaian',
    'Gibraltarian',
    'Greek',
    'Greenlandic',
    'Grenadian',
    'Guamanian',
    'Guatemalan',
    'Guinean',
    'Guyanese',
    'Haitian',
    'Honduran',
    'Hong Konger',
    'Hungarian',
    'Icelandic',
    'Indian',
    'Indonesian',
    'Iranian',
    'Iraqi',
    'Irish',
    'Israeli',
    'Italian',
    'Ivorian',
    'Jamaican',
    'Japanese',
    'Jordanian',
    'Kazakh',
    'Kenyan',
    'Kittitian',
    'Kosovan',
    'Kuwaiti',
    'Kyrgyz',
    'Lao',
    'Latvian',
    'Lebanese',
    'Liberian',
    'Libyan',
    'Liechtenstein citizen',
    'Lithuanian',
    'Luxembourger',
    'Macanese',
    'Macedonian',
    'Malagasy',
    'Malawian',
    'Malaysian',
    'Maldivian',
    'Malian',
    'Maltese',
    'Marshallese',
    'Martiniquais',
    'Mauritanian',
    'Mauritian',
    'Mexican',
    'Micronesian',
    'Moldovan',
    'Monegasque',
    'Mongolian',
    'Montenegrin',
    'Montserratian',
    'Moroccan',
    'Mosotho',
    'Mozambican',
    'Namibian',
    'Nauruan',
    'Nepalese',
    'New Zealander',
    'Nicaraguan',
    'Nigerian',
    'Nigerien',
    'Niuean',
    'North Korean',
    'Northern Irish',
    'Norwegian',
    'Omani',
    'Pakistani',
    'Palauan',
    'Palestinian',
    'Panamanian',
    'Papua New Guinean',
    'Paraguayan',
    'Peruvian',
    'Pitcairn Islander',
    'Polish',
    'Portuguese',
    'Prydeinig',
    'Puerto Rican',
    'Qatari',
    'Romanian',
    'Russian',
    'Rwandan',
    'Salvadorean',
    'Sammarinese',
    'Samoan',
    'Sao Tomean',
    'Saudi Arabian',
    'Scottish',
    'Senegalese',
    'Serbian',
    'Sierra Leonean',
    'Singaporean',
    'Slovak',
    'Slovenian',
    'Solomon Islander',
    'Somali',
    'South African',
    'South Korean',
    'South Sudanese',
    'Spanish',
    'Sri Lankan',
    'St Helenian',
    'St Lucian',
    'Stateless',
    'Sudanese',
    'Surinamese',
    'Swazi',
    'Swedish',
    'Swiss',
    'Syrian',
    'Taiwanese',
    'Tajik',
    'Tanzanian',
    'Thai',
    'Togolese',
    'Tongan',
    'Trinidadian',
    'Tristanian',
    'Tunisian',
    'Turkish',
    'Turkmen',
    'Turks and Caicos Islander',
    'Tuvaluan',
    'Ugandan',
    'Ukrainian',
    'Uruguayan',
    'Uzbek',
    'Vatican citizen',
    'Venezuelan',
    'Vietnamese',
    'Vincentian',
    'Wallisian',
    'Welsh',
    'Yemeni',
    'Zambian',
    'Zimbabwean',
];

export const countries = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua & Deps',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Central African Rep',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo {Democratic Rep}',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland {Republic}',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea North',
    'Korea South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar, {Burma}',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'St Kitts & Nevis',
    'St Lucia',
    'Saint Vincent & the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
];

export const studyYears = [
    '1st Undergraduate',
    '2nd Undergraduate',
    '3rd Undergraduate',
    '4th Undergraduate',
    'Foundation',
    'Post Graduate',
    'Masters',
    'PhD',
    'Other',
];

const universitiesEn = [
    'Aberystwyth University',
    'Anglia Ruskin University',
    'Aston University',
    'Bangor University',
    'Bath Spa University',
    'Birkbeck College',
    'Birmingham City University',
    'Bishop Grosseteste University',
    'Bournemouth University',
    'Brunel University London',
    'Buckinghamshire New University',
    'Canterbury Christ Church University',
    'Cardiff Metropolitan University',
    'Cardiff University',
    'Conservatoire for Dance and Drama',
    'Courtauld Institute of Art',
    'Coventry University',
    'Cranfield University',
    'De Montfort University',
    'Edge Hill University',
    'Edinburgh Napier University',
    'Falmouth University',
    'Glasgow Caledonian University',
    'Glasgow School of Art',
    'Glyndŵr University',
    'Goldsmiths College',
    'Guildhall School of Music and Drama',
    'Harper Adams University',
    'Heriot-Watt University',
    'Heythrop College',
    'Imperial College of Science, Technology and Medicine',
    'Institute of Education',
    "King's College London",
    'Kingston University',
    'Leeds Beckett University',
    'Leeds College of Art',
    'Leeds Trinity University',
    'Liverpool Hope University',
    'Liverpool John Moores University',
    'Liverpool School of Tropical Medicine',
    'London Business School',
    'London Metropolitan University',
    'London School of Economics and Political Science',
    'London School of Hygiene and Tropical Medicine',
    'London South Bank University',
    'Loughborough University',
    'Middlesex University',
    'Newman University',
    'Norwich University of the Arts',
    'Other',
    'Oxford Brookes University',
    'Plymouth College of Art',
    'Queen Margaret University, Edinburgh',
    'Queen Mary University of London',
    'Ravensbourne',
    'Roehampton University',
    'Rose Bruford College',
    'Royal Academy of Music',
    'Royal Agricultural University',
    'Royal College of Art',
    'Royal College of Music',
    'Royal Conservatoire of Scotland',
    'Royal Holloway and Bedford New College',
    'Royal Northern College of Music',
    'SRUC',
    'Sheffield Hallam University',
    'Southampton Solent University',
    "St George's Hospital Medical School",
    "St Mary's University College",
    "St Mary's University, Twickenham",
    'Staffordshire University',
    'Stranmillis University College',
    'Swansea University',
    'Teesside University',
    'The Arts University Bournemouth',
    'The City University',
    'The Institute of Cancer Research',
    'The Liverpool Institute for Performing Arts',
    'The Manchester Metropolitan University',
    'The National Film and Television School',
    'The Nottingham Trent University',
    'The Open University',
    "The Queen's University of Belfast",
    'The Robert Gordon University',
    'The Royal Central School of Speech and Drama',
    'The Royal Veterinary College',
    'The School of Oriental and African Studies',
    'The University of Aberdeen',
    'The University of Bath',
    'The University of Birmingham',
    'The University of Bolton',
    'The University of Bradford',
    'The University of Brighton',
    'The University of Bristol',
    'The University of Buckingham',
    'The University of Cambridge',
    'The University of Central Lancashire',
    'The University of Chichester',
    'The University of Dundee',
    'The University of East Anglia',
    'The University of East London',
    'The University of Edinburgh',
    'The University of Essex',
    'The University of Exeter',
    'The University of Glasgow',
    'The University of Greenwich',
    'The University of Huddersfield',
    'The University of Hull',
    'The University of Keele',
    'The University of Kent',
    'The University of Lancaster',
    'The University of Leeds',
    'The University of Leicester',
    'The University of Lincoln',
    'The University of Liverpool',
    'The University of Manchester',
    'The University of Northampton',
    'The University of Oxford',
    'The University of Portsmouth',
    'The University of Reading',
    'The University of Salford',
    'The University of Sheffield',
    'The University of Southampton',
    'The University of St Andrews',
    'The University of Stirling',
    'The University of Strathclyde',
    'The University of Sunderland',
    'The University of Surrey',
    'The University of Sussex',
    'The University of Wales',
    'The University of Warwick',
    'The University of West London',
    'The University of Westminster',
    'The University of Winchester',
    'The University of Wolverhampton',
    'The University of York',
    'The University of the West of Scotland',
    'Trinity Laban Conservatoire of Music and Dance',
    'University Campus Suffolk',
    'University Centre Leeds',
    'University College Birmingham',
    'University College London',
    'University for the Creative Arts',
    'University of Abertay Dundee',
    'University of Bedfordshire',
    'University of Chester',
    'University of Cumbria',
    'University of Derby',
    'University of Durham',
    'University of Gloucestershire',
    'University of Hertfordshire',
    'University of London (Institutes and activities)',
    'University of Newcastle-upon-Tyne',
    'University of Northumbria at Newcastle',
    'University of Nottingham',
    'University of Plymouth',
    'University of South Wales',
    'University of St Mark and St John',
    'Ulster University',
    'University of Wales Trinity Saint David',
    'University of Worcester',
    'University of the Arts, London',
    'University of the Highlands and Islands',
    'University of the West of England, Bristol',
    'Writtle College',
    'York St John University',
];

const universitiesEs = [
    'Àrtidi',
    'Aules Escola d’Arts Escèniques a Barcelona',
    'Barcelona Culinary Hub - UB',
    'Barcelona School of Creativity',
    'BAU Centre Universitari d’Arts i Disseny',
    'C3S Business School',
    'Central Barcelona - Escuela Aeronáutica',
    'CESDA - Universitat Rovira i Virgili - Escuela Oficial de Pilotos',
    'CEV - Centre de Comunicació, Imatge i So',
    'CIB - Escuela Culinaria de Barcelona',
    'EADA Business School',
    'EAE Business School',
    'ECIB - Escola de Cinema de Barcelona',
    'EINA - Centre Universitari de Disseny i Art de Barcelona',
    'El Taller - Escuela de Joyería Barcelona',
    'ELISAVA - Campus de les Arts Can Ricart',
    'ENEB - Escuela de Negocios Europea de Barcelona',
    'ENTI - UB (Escuela de Nuevas Tecnologías Interactivas)',
    "Eòlia - Centre Superior d'Art Dramàtic",
    'EPITECH - European Institute of Technology',
    'ESADE Business School Sant Cugat',
    'ESADE Law School Barcelona',
    "Escola Massana - Centre Municipal d'Art i Disseny",
    'Escuela de Hostelería Hofmann',
    'ESEI International Business School',
    'ESERP - The Barcelona School of Business and Social Science',
    "ESHOB Escola Superior d'Hostaleria de Barcelona",
    'ESIC University',
    'ESMUC (Escola Superior de Música de Catalunya)',
    'EUM - UdG (Escola Universitària del Mediterrani)',
    'European University (EU) Business School',
    'FX Animation Barcelona 3D & Film School',
    'GBS - Geneva Business School',
    'IAAC | Fab Lab Barcelona',
    'IED - Istituto Europeo Di Design Barcelona',
    'IESE Business School',
    'INSA Barcelona - Business, Marketing and Communication School',
    'IQS School of Engineering',
    'IQS School of Management',
    'ISDE - Instituto Superior de Derecho y Economía',
    'ISDI Education',
    'ISEP Barcelona (Instituto Superior de Estudios Psicológicos)',
    'L’Idem Creative Arts School',
    'La Salle - Escuela Técnica Superior de Arquitectura',
    'La Salle - Facultad Internacional de Comercio y Economía Digital',
    'La Salle - URL - Escuela Técnica Superior de Ingeniería',
    'La Salle - Otras',
    'LCI Barcelona - Escuela Superior de Diseño',
    'NBS - Neuroscience Business School',
    'Nuclio Digital School',
    'Sant Joan de Déu Campus Docente - UB',
    'SBS Swiss Business School Barcelona Campus',
    'Scouting Model Academy',
    'TBS - Toulouse Business School',
    'UIBS - United International Business School',
    'Universidad Blanquerna - Facultad de Comunicación y Relaciones Internacionales',
    'Universidad Blanquerna - Facultat de Ciències de la Salut Blanquerna-URL',
    "Universidad Blanquerna - Facultat de Psicologia, Ciències de l'Educació i de l'Esport Blanquerna-URL",
    'Universidad Blanquerna - Otras',
    'UB - Barcelona School of Tourism, Hospitality and Gastronomy (CETT)',
    'UB - Centro Universitario Internacional de Barcelona (UNIBA)',
    'UB - Escuela Superior de Cine y Audiovisuales de Cataluña (ESCAC)',
    'UB - Escuela Superior de Comercio y Distribución (ESCODI)',
    'UB - Escuela Superior de Relaciones Públicas (ESRP)',
    'UB - Escuela Universitaria de Enfermería Sant Joan de Déu',
    'UB - Facultat de Belles Arts - Campus Diagonal Sud',
    'UB - Facultat de Biologia - Campus Diagonal Sud',
    'UB - Facultat de Filologia i Comunicació',
    'UB - Facultat de Filosofia',
    'UB - Facultat de Física',
    'UB - Facultat de Geografia i Història',
    'UB - Facultat de Psicologia - Campus Mundet',
    "UB - Facultat d'Economia i Empresa - Campus Diagonal Nord",
    "UB - Facultat d'Educació - Campus Mundet",
    "UB - Facultat d'Informació i Mitjans Audiovisuals",
    'UB - Facultat de Ciències de la Terra - Campus Diagonal Sud',
    'UB - Facultat de Dret - Campus Diagonal Nord',
    "UB - Facultat de Farmàcia i Ciències de l'Alimentació - Campus de la Alimentación de Torribera",
    "UB - Facultat de Farmàcia i Ciències de l'Alimentació - Campus Diagonal",
    'UB - Facultat de Matemàtiques i Informàtica',
    'UB - Facultat de Medicina i Ciències de la Salut - Campus de Ciencias de la Salud de Bellvitge (Enfermeria, Odontologia)',
    'UB - Facultat de Medicina i Ciències de la Salut - Campus de Medicina - Clínic August Pi i Sunyer',
    'UB - Instituto de Seguridad Pública de Cataluña (ISPC)',
    'UB - Instituto Nacional de Educación Física de Cataluña (INEFC)',
    'UB - Institut de Formació contínua IL3',
    'UB - Otras',
    'UIC - Campus salud - Medicina, Enfermería, Fisioterapia, Odontología (Sant Cugat)',
    'UIC - Derecho, Arquitectura, Comunicación, Humanidades, Magisterio (Barcelona)',
    'Universitat Abat Oliba CEU - Facultad de Comunicación, Educación y Humanidades',
    'Universitat Abat Oliba CEU - Facultad de Derecho y Empresa',
    'Universitat Abat Oliba CEU - Facultad de Psicología',
    'Universitat Abat Oliba CEU - Instituto de Ciencias de la Salud',
    'Universitat Abat Oliba CEU - Otras',
    'UAB - Escuela Universitaria de Enfermería UAB - Campus Sant Pau',
    'UAB - Escola Universitària d\'Infermeria i de Fisioteràpia "Gimbernat"',
    "UAB - Escola Universitària d'Infermeria i Teràpia Ocupacional",
    'UAB - Escola Universitària d\'Informàtica "Tomàs Cerdà"',
    'UAB - EUSS School of Engineering (Escola Universitària Salesiana de Sarrià)',
    'UAB - Facultad de Medicina de la UAB - Campus Sant Pau',
    'UAB - Institut Guttmann',
    "UAB - Vall d'Hebron Institut de Recerca (VHIR)",
    'UAB - Otras',
    'UPC - Centre de la Imatge i la Tecnologia Multimèdia /Tech Talent Center - Campus Terrassa',
    "UPC - Escola d'Enginyeria Agroalimentària i de Biosistemes de Barcelona",
    'UPC - Campus Castelldefels',
    "UPC - Escola d'Enginyeria de Barcelona Est (EEBE) - Campus Diagonal-Besòs",
    "UPC - Escola d'Enginyeria de Telecomunicacions i Aeroespacial - Campus Diagonal Nord",
    "UPC - Escola Politècnica Superior d'Edificació de Barcelona - Campus Diagonal Sud",
    "UPC - Escola Tècnica Superior d'Enginyeria Industrial de Barcelona - Campus Diagonal Sud",
    "UPC - Escola Tècnica Superior d'Enginyers de Camins, Canals i Ports - Campus Diagonal Nord",
    'UPC - Facultat de Matemàtiques i Estadística - Campus Diagonal Sud',
    'UPC - Facultat de Nàutica de Barcelona (FNB) - Campus Barceloneta',
    "UPC - Facultat d'Informàtica de Barcelona (FIB) - Campus Diagonal Nord",
    "UPC - Escola Tècnica Superior d'Enginyeria Industrial de Barcelona (ETSEIB) Campus Diagonal-Besòs",
    "UPC - Escola Universitària d'Enginyeria Tècnica Industrial de Barcelona (EUETIB) Campus Diagonal-Besòs",
    'UPC - Institut de Bioenginyeria de Catalunya (IBC) - Campus Diagonal-Besòs',
    'UPC - Otras',
    'UPF - Barcelona School of Economics (BSE) - Campus Ciutadella',
    'UPF - Barcelona School of Management (BSM) - Edificio Balmes',
    'UPF - Escola Superior de Comerç Internacional (ESCI)',
    'UPF - Facultad de Ciencias Económicas y Empresariales - Campus Ciutadella',
    'UPF - Facultad de Traducción y Ciencias del Lenguaje - Campus Poblenou',
    'UPF - Escuela de Ingeniería - Campus Poblenou',
    'UPF - Escuela Superior de Enfermería del Mar (ESIMar) - Campus Mar',
    'UPF - Facultad de Ciencias de la Salud y de la Vida - Campus Mar',
    'UPF - Facultad de Ciencias Políticas y Sociales - Campus Ciutadella',
    'UPF - Facultad de Comunicación - Campus Poblenou',
    'UPF - Facultad de Derecho - Campus Ciutadella',
    'UPF - Facultad de Humanidades - Campus Ciutadella',
    'UPF - Otras',
    'URL - Escuela Superior de Diseño (ESDi)',
    'URL - Facultad de Filosofía',
    "URL - Facultat d'Educació Social i Treball Social Pere Tarrés",
    'URL - Institut Borja de Bioètica',
    'URL - Instituto Universitario de Salud Mental Vidal i Barraquer',
    'URL - Otras',
    'Otros',
];

export const universitiesCountryMap: Record<SfCountryCode, string[]> = {
    en: universitiesEn,
    es: universitiesEs,
};
