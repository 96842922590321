import { FC } from 'react';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ErrorState } from 'src/components/error-state';
import { AppPreloader } from 'src/components/app-preloader';
import { EmptyList } from 'src/components/empty-list/empty-list';

import { EventList } from '../../api';

import { EventCard } from './event-card';

export const EventsList: FC<{
    events: EventList | undefined;
    isError: boolean;
    isLoading: boolean;
}> = ({ events, isError, isLoading }) => {
    const { t } = useTranslation();

    if (isError) {
        return <ErrorState title={t('events_error')} />;
    }

    if (isLoading) {
        return <AppPreloader size="big" />;
    }

    if (!events || events.length < 1) {
        return <EmptyList mainText={<Trans i18nKey="my_events_empty_state_title" />} />;
    }

    return (
        <Box
            flex={1}
            display="grid"
            gridAutoRows={{
                xs: 'minmax(404px, min-content)',
                md: 'minmax(420px, min-content)',
                lg: 'minmax(324px, min-content)',
                xl: 'minmax(272px, min-content)',
            }}
            gridTemplateColumns={{ xs: '1fr', xl: '1fr 1fr' }}
            gap={{ xs: '24px', md: '40px' }}
        >
            {events.map((event) => (
                <EventCard event={event} key={event.id} />
            ))}
        </Box>
    );
};
