import { attachmentsSchema } from '../validation/attachment-schema';

export const getFileError = (file: File) => {
    try {
        attachmentsSchema.validateSync([file]);

        return undefined;
    } catch (error) {
        return error.message;
    }
};
