import { ChangeEventHandler, FC, useId, useRef } from 'react';

import { GA4 } from 'src/lib/ga4';

import { useGetCurrentUser } from '../../hooks/use-get-current-user';
import { usePostUserAvatar } from '../../hooks/use-put-user-avatar';

import { ProfileAvatarError } from './parts/profile-avatar-error';
import { ProfileAvatarExtended } from './parts/profile-avater-extended';
import { ProfileAvatarVertical } from './parts/profile-avatar-vertical';

type IAvatar = File | null | undefined;

export const ProfileAvatar: FC<{
    variant?: 'vertical' | 'expanded';
}> = ({ variant = 'horizontal' }) => {
    const { data } = useGetCurrentUser();
    const user = data!;

    const { mutate, isError, error, isLoading } = usePostUserAvatar(undefined, { id: user.id! });

    const errorCode = error?.response?.data?.code;

    const inputId = useId();
    const avatarRef = useRef<IAvatar>(null);

    const handleRetry = () => {
        const avatar = avatarRef.current;

        if (avatar) {
            mutate(avatar);
        }
    };

    const handleAvatarChange: ChangeEventHandler<HTMLInputElement> = ({ target: { files } }) => {
        const avatar = files?.item(0);
        avatarRef.current = avatar;

        if (avatar) {
            mutate(avatar);
        }

        GA4.submitEvent({
            name: 'Profile Picture Upload',
            label: 'Avatar Component',
            location: 'Avatar',
        });
    };

    const inputElement = (
        <input
            key="ProfileAvatar_input"
            type="file"
            accept="image/*"
            id={inputId}
            disabled={isLoading}
            hidden
            onChange={handleAvatarChange}
        />
    );

    const errorElement = isError ? (
        <ProfileAvatarError errorCode={errorCode!} onRetry={handleRetry} />
    ) : undefined;

    const props = { user, inputElement, errorElement, isLoading };

    return variant === 'expanded' ? (
        <ProfileAvatarExtended {...props} />
    ) : (
        <ProfileAvatarVertical {...props} />
    );
};
