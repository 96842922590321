import { conciergeClient } from 'src/api/concierge';

import { NotificationsEndpoints, NotificationStatus } from './constants';
import {
    PostNotificationTokenSuccessResponse,
    GetNotificationSuccessResponse,
    PatchNotificationStatusSuccessResponse,
} from './types';

const baseURL = process.env.REACT_APP_CONCIERGE_NOTIFICATIONS_BASE_URL;

export const getUserNotifications = async (userId: string) => {
    const { data: response } = await conciergeClient.get<GetNotificationSuccessResponse>(
        NotificationsEndpoints.GetNotifications.replace('{id}', userId),
        { baseURL }
    );

    return response.data;
};

export const postMessagingToken = async (userId: string, token: string) => {
    const { data: response } = await conciergeClient.post<PostNotificationTokenSuccessResponse>(
        NotificationsEndpoints.PostNotificationToken.replace('{id}', userId),
        { token },
        { baseURL }
    );

    return response.data;
};

export const patchNotificationStatus = async (
    notificationId: string,
    status: NotificationStatus
) => {
    const { data: response } = await conciergeClient.patch<PatchNotificationStatusSuccessResponse>(
        NotificationsEndpoints.PatchNotificationStatus.replace('{id}', notificationId),
        { status },
        { baseURL }
    );

    return response.data;
};
