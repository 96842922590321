import { ChangeEventHandler, LegacyRef, MouseEventHandler } from 'react';
import { Interpolation, Theme, useTheme } from '@emotion/react';
import { Badge, IconButton, Stack } from '@mui/material';

import { getFileLink } from 'src/utils/getFileLink';
import { ReactComponent as PlusIcon } from 'src/assets/svg/plus.svg';
import { ReactComponent as CrossIcon } from 'src/assets/svg/cross.svg';

import { getFileError } from './utils/get-file-error';
import { ALLOWED_ATTACHMENTS_FILE_TYPES } from './constants';
import { ReactComponent as WarningIcon } from './assets/warning-attachment.svg';

const cssPreviewBlock: Interpolation<Theme> = ({ palette }) => ({
    width: '80px',
    height: '80px',
    background: palette.background.paper,
    borderRadius: 0,
});

export const AttachmentsGallery = <Attachment extends File | string>({
    attachments = [],
    limit,
    inputRef,
    className,
    onChange,
    onOpen,
    onAdd,
    onDelete,
}: {
    attachments?: Attachment[];
    limit?: number;
    inputRef?: LegacyRef<HTMLInputElement>;
    className?: string;
    onChange?: (attachments: Attachment[]) => void;
    onDelete?: (attachment: Attachment) => void;
    onOpen?: MouseEventHandler;
    onAdd?: MouseEventHandler;
}) => {
    const { palette } = useTheme();

    const handleDelete = (attachment: Attachment) => () => onDelete?.(attachment);

    const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        if (!onChange) return;

        const inputFiles = Array.from(target.files || []);
        const nextAttachments = [...attachments, ...inputFiles];

        onChange(nextAttachments as Attachment[]);

        target.value = '';
    };

    const isMaximumAttachments = limit && limit <= attachments.length;

    const isEnabled = onAdd || onChange;

    return (
        <Stack
            direction="row"
            flexWrap="wrap"
            gap={{ xs: '20px', md: '32px' }}
            className={className}
        >
            {attachments.map((attachment, index) => {
                const isFileError = attachment instanceof File && getFileError(attachment);

                return (
                    <Badge
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        overlap="circular"
                        invisible={!onDelete}
                        badgeContent={
                            <IconButton
                                onClick={handleDelete(attachment)}
                                css={{
                                    borderRadius: 0,
                                    padding: '4px',
                                    background: palette.common.black,

                                    ':hover': {
                                        background: palette.primary.main,
                                    },
                                }}
                            >
                                <CrossIcon
                                    width="16px"
                                    height="16px"
                                    color={palette.common.white}
                                />
                            </IconButton>
                        }
                    >
                        {isFileError && (
                            <Stack
                                width="100%"
                                height="100%"
                                position="absolute"
                                top={0}
                                left={0}
                                color={palette.error.main}
                                justifyContent="center"
                                alignItems="center"
                                css={{ backgroundColor: `${palette.error.main}26` }}
                            >
                                <WarningIcon width="32px" height="32px" />
                            </Stack>
                        )}

                        <button
                            type="button"
                            aria-label="attachment"
                            data-index={index}
                            onClick={onOpen}
                            css={[
                                cssPreviewBlock,
                                {
                                    margin: 0,
                                    padding: 0,
                                    border: 'none',
                                    cursor: onOpen ? 'pointer' : 'initial',
                                    appearance: 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${
                                        attachment instanceof File
                                            ? getFileLink(attachment)
                                            : attachment
                                    })`,
                                },
                            ]}
                        />
                    </Badge>
                );
            })}

            {!isMaximumAttachments && isEnabled && (
                <IconButton
                    component="label"
                    aria-label="upload attachment"
                    onClick={onAdd}
                    css={[
                        cssPreviewBlock,
                        {
                            ':hover>svg': {
                                color: palette.primary.main,
                            },
                        },
                    ]}
                >
                    <input
                        ref={inputRef}
                        type="file"
                        accept={ALLOWED_ATTACHMENTS_FILE_TYPES.join(',')}
                        multiple
                        hidden
                        disabled={Boolean(onAdd)}
                        onChange={handleChange}
                    />

                    <PlusIcon width="24px" height="24px" color={palette.common.black} />
                </IconButton>
            )}
        </Stack>
    );
};
