import { type FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { PrivateHat } from 'src/layout/private-hat';
import { GuideVideo } from 'src/features/guides';
import { useGetActiveBuilding } from 'src/features/building';
import { ErrorState } from 'src/components/error-state';
import { AppPreloader } from 'src/components/app-preloader';

export const GuidesPage: FC = () => {
    const { t } = useTranslation();

    const { data: building, isLoading, isSuccess, isError } = useGetActiveBuilding();

    return (
        <Fragment>
            <PrivateHat title={t('guides_page_title')} backIconText={t('back_cta')} />

            {isLoading && <AppPreloader size="big" showLogo />}

            {isError && (
                <ErrorState
                    direction="row"
                    title={<Trans i18nKey="video-guides_error" />}
                    css={{ flex: 1 }}
                />
            )}

            {isSuccess && (
                <Box
                    display="grid"
                    gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
                    gap="20px"
                    padding={{ xs: '25px 0', md: '40px', lg: '83px 120px' }}
                >
                    <GuideVideo
                        thumbnail_url={building.building_guide_video.thumbnail_url}
                        video_url={building.building_guide_video.video_url}
                        title={t('video-guides_video-title_welcome')}
                    />
                    <GuideVideo
                        thumbnail_url={building.utilities_guide_video.thumbnail_url}
                        video_url={building.utilities_guide_video.video_url}
                        title={t('video-guides_video-title_utilities')}
                    />
                    <GuideVideo
                        thumbnail_url={building.your_studio_video.thumbnail_url}
                        video_url={building.your_studio_video.video_url}
                        title={t('video-guides_video-title_your-studio')}
                    />
                    <GuideVideo
                        thumbnail_url={building.safety_at_vita_student_video.thumbnail_url}
                        video_url={building.safety_at_vita_student_video.video_url}
                        title={t('video-guides_video-title_safety')}
                    />
                </Box>
            )}
        </Fragment>
    );
};
