import { FC, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Alert, AlertTitle, Typography, Badge, Stack } from '@mui/material';

import { logger } from 'src/services/logger';
import { isRelativeUrl } from 'src/utils/is-link-relative';

import { patchNotificationStatus } from '../../../api/requests';
import { Notification } from '../../../api/types';
import { NotificationStatus } from '../../../api/constants';

import { CATEGORY_ICONS } from './category-icons';

export const NotificationItem: FC<{
    notification: Notification;
    onClick?: VoidFunction;
}> = ({ notification, onClick }) => {
    const { palette } = useTheme();

    const showBackground = notification.status !== NotificationStatus.Actioned;
    const showBadge = notification.status !== NotificationStatus.Unseen;

    const url = notification.navigation_url;

    const linkProps =
        url &&
        (isRelativeUrl(url)
            ? { component: Link, to: url, state: { notification } }
            : { component: 'a', href: url, target: '_blank' });

    const handleClick = () => {
        onClick?.();

        if (notification.status !== NotificationStatus.Actioned) {
            patchNotificationStatus(notification.id, NotificationStatus.Actioned).catch((error) =>
                logger.error(error)
            );
        }
    };

    useEffect(() => {
        if (notification.status === NotificationStatus.Unseen) {
            patchNotificationStatus(notification.id, NotificationStatus.Read).catch((error) =>
                logger.error(error)
            );
        }
    }, []);

    return (
        <Stack
            {...linkProps}
            onClick={handleClick}
            css={{
                textDecoration: 'inherit',
                color: 'inherit',
                cursor: url ? 'pointer' : undefined,

                ':not(:last-child)::after': {
                    content: '""',
                    display: 'block',
                    height: '1px',
                    margin: '8px 0',
                    background: palette.divider,
                },
            }}
        >
            <Alert
                square
                icon={
                    <Badge
                        variant="dot"
                        color="primary"
                        invisible={showBadge}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        css={{
                            '.MuiBadge-badge': {
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                minWidth: 'unset',
                            },
                        }}
                    >
                        {CATEGORY_ICONS.get(notification.type)}
                    </Badge>
                }
                css={{
                    padding: '20px 24px',
                    gap: '20px',

                    background: showBackground
                        ? palette.background.paper
                        : palette.background.default,

                    '.MuiAlert-icon': {
                        width: 'auto',
                        height: 'auto',
                    },

                    '.MuiAlert-message': {
                        width: '100%',
                        overflow: 'visible',
                    },
                }}
            >
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <div>
                        <AlertTitle>
                            <Typography variant="h4" color="text.primary">
                                {notification.title}
                            </Typography>
                        </AlertTitle>

                        <Typography variant="body2" color="text.primary">
                            {notification.message}
                        </Typography>
                    </div>

                    <Typography variant="body2" color="text.secondary">
                        {format(parseISO(notification.created_at), 'HH:mm')}
                    </Typography>
                </Stack>
            </Alert>
        </Stack>
    );
};
