import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { queryClient } from 'src/lib/query-client';

import {
    PatchTicketAttachmentsBody,
    MaintenanceEndpoints,
    postTicketAttachment,
    patchTicketAttachments,
    MaintenanceAttachment,
} from '../api';
import { PatchAttachmentsFormFields } from '../constants/patch-attachments-form';

import type { AxiosError } from 'axios';

interface Arg {
    oldAttachments: MaintenanceAttachment[];
    newAttachments: File[];
}

type Config = UseMutationOptions<
    PatchTicketAttachmentsBody,
    AxiosError<ConciergeErrorResponse>,
    Arg
>;

export const usePatchAttachments = (id: string, config: Config = {}) => {
    const { onSuccess, ...restConfig } = config;

    return useMutation<PatchTicketAttachmentsBody, AxiosError<ConciergeErrorResponse>, Arg>({
        async mutationFn({ oldAttachments, newAttachments }) {
            const attachments = await Promise.all(
                newAttachments.map((attachment) => postTicketAttachment(attachment))
            );

            return patchTicketAttachments(id, {
                [PatchAttachmentsFormFields.Attachments]: [...oldAttachments, ...attachments],
            });
        },

        onSuccess(data, variables, context) {
            onSuccess?.(data, variables, context);
            queryClient.invalidateQueries([MaintenanceEndpoints.getTicket, id]);
        },

        ...restConfig,
    });
};
