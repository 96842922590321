import { format, Locale, parseISO } from 'date-fns';

export const formatBookingDate = (iso: string | null, useComma = false, locale?: Locale) => {
    // some of old bookings do not have date_from, date_to set
    if (!iso) {
        return '';
    }

    const date = parseISO(iso);
    const separator = useComma ? ', ' : ' ';

    return format(date, `d MMM${separator}yyyy`, { locale });
};
