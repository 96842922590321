import { MaintenanceTicketLocation } from '../api';

export enum CreateTicketFormFields {
    IssueType = 'issue_type',
    Issue = 'issue',
    Problem = 'problem',
    Location = 'location',
    DescriptionText = 'description_text',
    DescriptionAttachments = 'description_attachments',
}

export const LOCATION_OPTIONS = [
    {
        id: MaintenanceTicketLocation.MyLivingSpace,
        name: MaintenanceTicketLocation.MyLivingSpace,
        i18nKey: 'maintenance_location_living-space',
    },

    {
        id: MaintenanceTicketLocation.MyBedroom,
        name: MaintenanceTicketLocation.MyBedroom,
        i18nKey: 'maintenance_location_bedroom',
    },

    {
        id: MaintenanceTicketLocation.MyBathroom,
        name: MaintenanceTicketLocation.MyBathroom,
        i18nKey: 'maintenance_location_bathroom',
    },

    {
        id: MaintenanceTicketLocation.MyKitchen,
        name: MaintenanceTicketLocation.MyKitchen,
        i18nKey: 'maintenance_location_kitchen',
    },

    {
        id: MaintenanceTicketLocation.CommunalSpaces,
        name: MaintenanceTicketLocation.CommunalSpaces,
        i18nKey: 'maintenance_location_communal-areas',
    },

    {
        id: MaintenanceTicketLocation.OtherMyRoom,
        name: MaintenanceTicketLocation.OtherMyRoom,
        i18nKey: 'maintenance_location_my_room_other',
    },
];

export const DEFAULT_FULFILLED_PERCENT = 5;

export const FORM_STEPS = Object.values(CreateTicketFormFields);

export const FORM_REQUIRED_STEPS = [
    CreateTicketFormFields.IssueType,
    CreateTicketFormFields.Issue,
    CreateTicketFormFields.Problem,
    CreateTicketFormFields.Location,
];
