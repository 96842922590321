import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { BookingsEndpoints, getBookingsList, Booking, BookingList } from '../api';

type QueryConfig = UseQueryOptions<BookingList, AxiosError<ConciergeErrorResponse>, Booking>;

const selectActiveBooking = (bookings: BookingList) => {
    const activeBooking = bookings.find(({ active }) => active);

    if (!activeBooking) {
        const exception = new Error('Can not find active booking');

        captureException(exception, {
            extra: {
                bookings,
            },
        });

        throw exception;
    }

    return activeBooking;
};

export const useGetActiveBooking = (config: QueryConfig = {}) =>
    useQuery<BookingList, AxiosError<ConciergeErrorResponse>, Booking>({
        queryKey: [BookingsEndpoints.getMyBookings],
        queryFn: getBookingsList,
        select: selectActiveBooking,
        ...config,
    });
