import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { queryClient } from 'src/lib/query-client';

import { EventsEndpoints, Event, getEvent, EventList } from '../api';

type QueryConfig = UseQueryOptions<Event, AxiosError<ConciergeErrorResponse>>;

export const useGetEvent = (id: string, config: QueryConfig = {}) => {
    const allEvents = queryClient.getQueryData<EventList>([EventsEndpoints.GetEvents]);
    const myEvents = queryClient.getQueryData<EventList>([EventsEndpoints.GetMyEvents]);

    return useQuery<Event, AxiosError<ConciergeErrorResponse>>({
        queryKey: [EventsEndpoints.GetEvent, id],
        queryFn: () => getEvent(id),
        initialData:
            allEvents?.find((event) => event.id === id) ??
            myEvents?.find((event) => event.id === id),
        ...config,
    });
};
