import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

interface Dimensions {
    x: number;
    y: number;
}

interface Props<Container extends HTMLElement> {
    container?: Container | Window;
    delay?: number;
}

const getDimensions = (container: HTMLElement | Window): Dimensions => ({
    x: 'scrollLeft' in container ? container.scrollLeft : container.scrollX,
    y: 'scrollTop' in container ? container.scrollTop : container.scrollY,
});

export const useScrollDirection = <Container extends HTMLElement>({
    delay = 0,
    container = window,
}: Props<Container>) => {
    const initialData = getDimensions(container);

    const scrollDataRef = useRef(initialData);
    const prevScrollDataRef = useRef(initialData);

    // use force render to update state without using scroll data as state to avoid effect closure
    const [, forceRender] = useState(0);

    useEffect(() => {
        const scrollCallback = debounce(
            () => {
                prevScrollDataRef.current = scrollDataRef.current;
                scrollDataRef.current = getDimensions(container);

                const { x, y } = scrollDataRef.current;
                forceRender(x + y);
            },
            delay,
            { leading: true }
        );

        container.addEventListener('scroll', scrollCallback);
        return () => container.removeEventListener('scroll', scrollCallback);
    }, []);

    return { scrollData: scrollDataRef.current, prevScrollData: prevScrollDataRef.current };
};
