import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { useGetActiveBooking } from 'src/features/bookings';

import { BuildingEndpoints, BuildingOffers, getBuildingOffers } from '../api';

export const useGetBuildingOffers = <TData = BuildingOffers>(
    config: UseQueryOptions<BuildingOffers, AxiosError<ConciergeErrorResponse>, TData> = {}
) => {
    const { data: activeBooking } = useGetActiveBooking();

    return useQuery<BuildingOffers, AxiosError<ConciergeErrorResponse>, TData>({
        queryKey: [BuildingEndpoints.getOffers],
        queryFn: () => getBuildingOffers(activeBooking!.id),
        ...config,
    });
};
