import { FC, useRef } from 'react';
import { Box, Stack, Grow } from '@mui/material';
import { useTheme } from '@emotion/react';
import { format } from 'date-fns';

import { Booking } from 'src/features/bookings';

import { useClickOutsideElements } from '../../../hooks/use-click-outside-elements';

import { CalendarMonths } from './calendar-months';
import { CalendarYears } from './calendar-years';
import { CalendarDates } from './calendar-dates';
import { CalendarNavigation } from './calendar-navigation';

export const CalendarPicker: FC<{
    buttonElement: HTMLElement | null;
    isOpen: boolean;
    onCloseCalendar: VoidFunction;
    selectedDate: string | null;
    onDateSet: (date: string) => void;
    focusedMonth: number | null;
    onMonthSet: (month: number | null) => void;
    focusedYear: number | null;
    onYearSet: (year: number | null) => void;
    booking: Booking;
}> = ({
    buttonElement,
    isOpen,
    onCloseCalendar,
    selectedDate,
    onDateSet,
    focusedMonth,
    onMonthSet,
    focusedYear,
    onYearSet,
    booking,
}) => {
    const dateRef = useRef<HTMLDivElement>(null);

    const { palette, shadows, zIndex } = useTheme();

    const clickElements = [
        ...(buttonElement === null ? [] : [buttonElement]),
        ...(dateRef.current === null ? [] : [dateRef.current]),
    ];

    useClickOutsideElements(clickElements, () => onCloseCalendar());

    const updateSelectedDate = (date: number) => {
        onDateSet(
            format(
                new Date(
                    focusedYear ?? new Date().getUTCFullYear(),
                    focusedMonth ?? new Date().getUTCMonth(),
                    date
                ),
                'yyyy-MM-dd'
            )
        );

        onCloseCalendar();
    };

    const updateSelectedMonth = (month: number | null) => {
        onMonthSet(month);
    };

    const decrementMonth = () => {
        if (focusedMonth === null || focusedYear === null) return;

        if (focusedMonth === 0) {
            onMonthSet(11);
            onYearSet(focusedYear - 1);
            return;
        }

        onMonthSet(focusedMonth - 1);
    };

    const incrementMonth = () => {
        if (
            focusedMonth === null ||
            focusedYear === null ||
            (focusedMonth === 11 && focusedYear === 2032)
        )
            return;

        onMonthSet(focusedMonth === 11 ? 0 : focusedMonth + 1);

        if (focusedMonth === 11) onYearSet(focusedYear + 1);
    };

    const updateSelectedYear = (year: number | null) => {
        onYearSet(year);
    };

    return (
        <Stack ref={dateRef} position="relative" height={0} width="100%" zIndex={zIndex.modal}>
            <Grow
                in={isOpen}
                css={{
                    transformOrigin: '50% 0 0',
                }}
                timeout={isOpen ? 1000 : undefined}
            >
                <Box
                    padding="16px"
                    boxShadow={shadows[1]}
                    position="absolute"
                    marginTop="8px"
                    width="100%"
                    css={{
                        background: palette.common.white,
                    }}
                >
                    <CalendarNavigation
                        updateMonth={updateSelectedMonth}
                        incrementMonth={incrementMonth}
                        decrementMonth={decrementMonth}
                        updateYear={updateSelectedYear}
                        focusedMonth={focusedMonth}
                        focusedYear={focusedYear}
                    />

                    {focusedMonth !== null && focusedYear !== null && (
                        <CalendarDates
                            selectedDate={selectedDate}
                            updateSelectedDate={updateSelectedDate}
                            focusedMonth={focusedMonth}
                            focusedYear={focusedYear}
                            booking={booking}
                        />
                    )}
                    {focusedMonth === null && focusedYear !== null && (
                        <CalendarMonths
                            updateSelectedMonth={updateSelectedMonth}
                            focusedYear={focusedYear}
                            booking={booking}
                        />
                    )}
                    {focusedYear === null && (
                        <CalendarYears updateSelectedYear={updateSelectedYear} booking={booking} />
                    )}
                </Box>
            </Grow>
            <Box
                height={{ xs: '80px', md: '40px' }}
                flexShrink={0}
                display={isOpen ? undefined : 'none'}
                css={{ pointerEvents: 'none' }}
            />
        </Stack>
    );
};
