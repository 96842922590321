import { create } from 'zustand';

import { type RebookingFilterForm } from './types';

interface RebookingStore {
    page: number;
    filters: RebookingFilterForm | null;

    setFilters(filters: RebookingFilterForm): void;
    setPage(nextPage: number): void;
}

export const useRebookingStore = create<RebookingStore>((set) => ({
    page: 1,
    filters: null,

    setFilters(filters) {
        set({ filters, page: 1 });
    },

    setPage(page) {
        set({ page });
    },
}));
