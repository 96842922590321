import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PrivateHat } from 'src/layout/private-hat';
import { SocialSpaceBookingsList } from 'src/features/social-spaces';

export const SocialSpaceBookingsPage = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <PrivateHat title={t('social_space_my_bookings_title')} backIconText={t('back_cta')} />
            <SocialSpaceBookingsList />
        </Fragment>
    );
};
