import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewProfile, ProfileAvatar } from 'src/features/user';
import { PrivateHat } from 'src/layout/private-hat';

export const ProfilePage: FC = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <PrivateHat
                title={t('profile_page_title').toLocaleUpperCase()}
                backIconText={t('profile_navigate_back')}
            />

            <ProfileAvatar variant="expanded" />

            <ViewProfile />
        </Fragment>
    );
};
