import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BuildingNewsList } from 'src/features/building-updates';
import { PrivateHat } from 'src/layout/private-hat';

export const MyBuildingNewsPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <PrivateHat title={t('whats_happening_title')} backIconText={t('back_cta')} />
            <BuildingNewsList />
        </Fragment>
    );
};
