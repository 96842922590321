import { MaintenanceAttachment, PostTicketBody, PostTicketFields } from '../api';
import { CreateTicketFormFields } from '../constants/create-ticket-form';
import { CreateTicketFormData } from '../types/create-ticket-form';

export const mapCreateTicketForm = (
    form: CreateTicketFormData,
    bookingId: string,
    roomId: string,
    attachments: MaintenanceAttachment[]
): PostTicketBody => ({
    [PostTicketFields.IssueType]: form[CreateTicketFormFields.IssueType]!.name,
    [PostTicketFields.Issue]: form[CreateTicketFormFields.Issue]!.name,
    [PostTicketFields.Problem]: form[CreateTicketFormFields.Problem]!.name,
    [PostTicketFields.Location]: form[CreateTicketFormFields.Location].name,
    [PostTicketFields.RoomId]: roomId,
    [PostTicketFields.BookingId]: bookingId,
    [PostTicketFields.Description]: form[CreateTicketFormFields.DescriptionText],
    [PostTicketFields.Attachments]: attachments,
});
