import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from 'src/components/link';

// We don't need set props there, i18n clones elements with provided props from templates
export const searchElements = {
    // @ts-ignore
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    router: <Link component={RouterLink} />,
    // @ts-ignore
    // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
    a: <Link />,
    title: (
        <Typography
            variant="subtitle2"
            marginBottom="1.5em"
            css={{ textDecoration: 'underline' }}
        />
    ),
    ul: <ul />,
    li: <li />,
};
