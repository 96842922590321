import { FC } from 'react';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

import { ReactComponent as CrossIcon } from 'src/assets/svg/cross.svg';
import { Button } from 'src/components/button';

import { LocationOption } from '../../../api';

export const LocationChip: FC<{
    selected: boolean;
    location: LocationOption;
    onToggleLocation: (location: string) => void;
}> = ({ selected, location, onToggleLocation }) => {
    const { palette } = useTheme();

    const handleClick = () => onToggleLocation(location.slug);

    return (
        <Button
            css={{
                color: `${palette.common.black} !important`,
                background: selected
                    ? `${palette.secondary.main} !important`
                    : `${palette.background.paper} !important`,
                height: '36px',
                borderRadius: '22px',
                padding: '22px 16px',
            }}
            endIcon={
                selected && (
                    <CrossIcon
                        width="18px"
                        height="18px"
                        css={{
                            margin: '0 0 0 -8px',
                            color: palette.common.black,
                        }}
                    />
                )
            }
            onClick={handleClick}
        >
            <Typography variant="subtitle2">{location.name}</Typography>
        </Button>
    );
};
