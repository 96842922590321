import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { EventsEndpoints, EventList, getMyPastEventsList } from '../api';

type QueryConfig = UseQueryOptions<EventList, AxiosError<ConciergeErrorResponse>>;

export const useGetMyPastEvents = (config: QueryConfig = {}) => {
    const query = useQuery<EventList, AxiosError<ConciergeErrorResponse>>({
        queryKey: [EventsEndpoints.GetMyPastEvents],
        queryFn: getMyPastEventsList,
        ...config,
    });

    return query;
};
