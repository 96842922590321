import { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { STAFF_CATEGORY_TRANSLATION_KEY } from '../constants/translation-keys';
import { STAFF_CATEGORIES, StaffCategory } from '../constants/form-options';

interface Props {
    onStaffCategoryChange: (category: string) => void;
    selectedStaffCategory: string | null;
}

export const StaffCategoryList: FC<Props> = ({ onStaffCategoryChange, selectedStaffCategory }) => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    const handleChange = (category: StaffCategory) => () => {
        onStaffCategoryChange(category);

        GA4.ctaEvent({
            name: 'Feedback Staff Category Selected',
            location: 'Feedback Form',
            label: t(STAFF_CATEGORY_TRANSLATION_KEY[category], {
                lng: LanguagesCode.English,
            }),
        });
    };

    return (
        <Box justifyContent="stretch" gap="12px" display="flex" flexDirection="row" flexWrap="wrap">
            {STAFF_CATEGORIES.map((category) => {
                const selected = selectedStaffCategory === category;

                return (
                    <Typography
                        key={category}
                        variant="overlineRegular"
                        component={Button}
                        onClick={handleChange(category)}
                        padding="44px 22px"
                        textTransform="uppercase"
                        width="calc(50% - 6px)"
                        textAlign="center"
                        color={palette.text.primary}
                        css={{
                            background: selected
                                ? `${palette.common.black} !important`
                                : `${palette.background.paper} !important`,
                            color: selected ? palette.primary.contrastText : palette.common.black,
                            flexGrow: 1,
                            textDecoration: 'none',
                            borderRadius: 0,
                        }}
                    >
                        {t(STAFF_CATEGORY_TRANSLATION_KEY[category])}
                    </Typography>
                );
            })}
        </Box>
    );
};
