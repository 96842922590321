import { FC, Fragment, useState } from 'react';
import { Stack, Typography, Button, ButtonProps } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'src/constants/routes';
import { IconLabel } from 'src/components/icon-label';
import { useFormattedDate } from 'src/hooks/use-formatted-date';
import { GA4 } from 'src/lib/ga4';

import { SocialSpaceBooking } from '../../api';
import { ReactComponent as LocationIcon } from '../../assets/location.svg';
import { ReactComponent as CapacityIcon } from '../../assets/capacity.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { CancelBookingModal } from '../cancel-booking-modal/cancel-booking-modal';

export const SocialSpaceBookingCard: FC<{ socialSpaceBooking: SocialSpaceBooking }> = ({
    socialSpaceBooking,
}) => {
    const { palette, shadows } = useTheme();

    const getFormattedDate = useFormattedDate();

    const formattedDate = getFormattedDate(socialSpaceBooking.booking_start_date);

    const [isCancelBookingModalOpen, setIsCancelBookingModalOpen] = useState(false);

    const handleOpenCancelBookingModal: ButtonProps['onClick'] = (event) => {
        event.preventDefault();
        setIsCancelBookingModalOpen(true);

        GA4.modalEvent({
            name: 'Cancel Social Space Booking Modal',
            open: true,
            label: 'Delete Icon',
            location: 'Social Space My Bookings',
        });
    };

    const handleCloseCancelBookingModal = () => {
        setIsCancelBookingModalOpen(false);

        GA4.modalEvent({
            name: 'Cancel Social Space Booking Modal',
            open: false,
            label: 'Delete Icon',
            location: 'Social Space My Bookings',
        });
    };

    return (
        <Fragment>
            <Stack
                component={Link}
                to={routes.socialSpaces.id.replace(':id', socialSpaceBooking.resource_id)}
                padding={{ xs: '16px', lg: '25px 28px' }}
                justifyContent="space-between"
                direction="row"
                css={{
                    position: 'relative',
                    textDecoration: 'none',
                    color: palette.common.black,
                    boxShadow: shadows[1],
                }}
            >
                <Stack gap="18px">
                    <img
                        src={socialSpaceBooking.image_url}
                        alt="icon"
                        width="24px"
                        height="24px"
                        css={{ objectFit: 'contain' }}
                    />

                    <Typography variant="h3" css={{ textTransform: 'uppercase' }}>
                        {socialSpaceBooking.title}
                    </Typography>

                    <Typography
                        variant="body2"
                        marginTop={{ xs: '6px', md: '14px' }}
                        color={palette.text.secondary}
                    >
                        {formattedDate} <br />{' '}
                        {`${socialSpaceBooking.booking_start_time} - ${socialSpaceBooking.booking_end_time}`}
                    </Typography>

                    <Stack direction="row" gap="25px" marginTop={{ xs: '6px', md: '14px' }}>
                        <IconLabel icon={<LocationIcon />}>{socialSpaceBooking.location}</IconLabel>

                        <IconLabel icon={<CapacityIcon />}>
                            <Trans
                                i18nKey="social_space_occupancy"
                                values={{ count: socialSpaceBooking.occupancy }}
                            />
                        </IconLabel>
                    </Stack>
                </Stack>

                {!socialSpaceBooking.past_booking && (
                    <Button
                        onClick={handleOpenCancelBookingModal}
                        css={{
                            alignSelf: 'flex-end',
                            color: palette.common.black,
                            padding: '10px',
                            minWidth: 0,
                            margin: '-10px',
                            flexShrink: 0,
                        }}
                    >
                        <DeleteIcon width="24px" height="24px" />
                    </Button>
                )}
            </Stack>
            <CancelBookingModal
                isOpen={isCancelBookingModalOpen}
                onClose={handleCloseCancelBookingModal}
                bookingId={socialSpaceBooking.id}
            />
        </Fragment>
    );
};
