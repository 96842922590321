import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';
import { useGetCurrentUser } from 'src/features/user';

import { NotificationsEndpoints } from '../api/constants';
import { getUserNotifications } from '../api/requests';
import { Notifications } from '../api/types';

type ErrorResponse = AxiosError<ConciergeErrorResponse>;

type Config = UseQueryOptions<Notifications, ErrorResponse>;

export const useGetUserNotifications = (config: Config = {}) => {
    const { data: user } = useGetCurrentUser();
    const userId = user!.id;

    return useQuery<Notifications, ErrorResponse>({
        queryKey: [NotificationsEndpoints.GetNotifications, userId],
        queryFn: () => getUserNotifications(userId),
        ...config,
    });
};
