export enum BuildingUpdatesEndpoints {
    getIncidents = '/v1/bookings/{id}/incidents',
    getIncidentsCount = '/v1/bookings/{id}/incidents/count',
    getNews = '/v1/bookings/{id}/latest-news',
}

export enum BuildingUpdatesStatus {
    Active = 'Active',
    Resolved = 'Resolved',
}

export enum BuildingUpdatesType {
    Incident = 'Incident',
    News = 'News',
}
