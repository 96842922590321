import { css } from '@emotion/react';
import { Theme } from '@mui/material';

import { ASSETS_BASE_URL } from 'src/api/concierge';

const FONTS_BASE_URL = `${ASSETS_BASE_URL}/fonts`;

export const globalStyles = ({ palette }: Theme) => {
    const scrollbarBackground = palette.background.paper;
    const scrollbarThumb = palette.text.secondary;
    const firefoxScrollbar = `scrollbar-color: ${scrollbarThumb} ${scrollbarBackground}; scrollbar-width: thin;`;

    return css`
        :root {
            height: 100%;
            scroll-behavior: smooth;
            ${firefoxScrollbar}

            * {
                ${firefoxScrollbar}

                /* Scrollbar */
                ::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background: ${scrollbarBackground};
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: ${scrollbarThumb};
                    border-radius: 10px;
                }
            }
        }

        body {
            scroll-behavior: smooth;
            height: 100%;
        }

        body.chat-hidden #chat-widget-container {
            display: none;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }

        #root {
            min-height: 100%;
            display: flex;
            flex-direction: column;
        }

        @font-face {
            font-family: 'Radikal';
            src:
                url('${FONTS_BASE_URL}/nootype_-_radikal-light.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/nootype_-_radikal-light.woff') format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Radikal';
            src:
                url('${FONTS_BASE_URL}/nootype_-_radikal-regular-webfont.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/nootype_-_radikal-regular-webfont.woff') format('woff');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Radikal';
            src:
                url('${FONTS_BASE_URL}/nootype_-_radikal_light_italic.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/nootype_-_radikal-light-italic.woff') format('woff');
            font-weight: 400;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Radikal';
            src:
                url('${FONTS_BASE_URL}/nootype_-_radikal-medium-webfont.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/nootype_-_radikal-medium-webfont.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Radikal';
            src:
                url('${FONTS_BASE_URL}/nootype_-_radikal-bold-webfont.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/nootype_-_radikal-bold-webfont.woff') format('woff');
            font-weight: 700;
            font-style: bold;
            font-display: swap;
        }

        @font-face {
            font-family: 'Radikal';
            src:
                url('${FONTS_BASE_URL}/RadikalBoldItalic.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/RadikalBoldItalic.woff') format('woff');
            font-weight: 700;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: 'Trade Gothic LT Std';
            src:
                url('${FONTS_BASE_URL}/trade-gothic-linotype-standard-bold.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/trade-gothic-linotype-standard-bold.woff') format('woff');
            font-weight: 700;
            font-style: bold;
            font-display: swap;
        }

        @font-face {
            font-family: 'Trade Gothic LT Pro';
            src:
                url('${FONTS_BASE_URL}/trade-gothic-condensed-bold.woff2') format('woff2'),
                url('${FONTS_BASE_URL}/trade-gothic-condensed-bold.woff') format('woff');
            font-weight: 700;
            font-style: bold;
            font-display: swap;
        }
    `;
};
