import { conciergeClient } from 'src/api/concierge';

import { PaymentsEndpoints } from './constants';

import type {
    GetMakeMandateSuccessResponse,
    GetMakePaymentSuccessResponse,
    PaymentsReceiptPdf,
    GetPaymentsReceiptsResponse,
    GetPaymentsResponse,
} from './types';

export const getPayments = async (bookingId: string) => {
    const { data: response } = await conciergeClient.get<GetPaymentsResponse>(
        PaymentsEndpoints.getPayments,
        { params: { booking_id: bookingId } }
    );

    return response;
};

export const getPaymentReceipts = async (bookingId: string) => {
    const { data: response } = await conciergeClient.get<GetPaymentsReceiptsResponse>(
        PaymentsEndpoints.getPaymentReceipts,
        { params: { booking_id: bookingId } }
    );

    return response;
};

export const getPaymentReceiptPDF = async (id: string) => {
    const { data } = await conciergeClient.get<PaymentsReceiptPdf>(
        PaymentsEndpoints.getPaymentReceiptPDF.replace('{id}', id)
    );

    return data;
};

export const getMakePayment = async (bookingId: string) => {
    const { data: response } = await conciergeClient.get<GetMakePaymentSuccessResponse>(
        PaymentsEndpoints.getMakePayment.replace('{id}', bookingId)
    );

    return response.data;
};

export const getMakeMandate = async (bookingId: string) => {
    const { data: response } = await conciergeClient.get<GetMakeMandateSuccessResponse>(
        PaymentsEndpoints.getMakeMandate.replace('{id}', bookingId)
    );

    return response.data;
};
