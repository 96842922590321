import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';

import { routes } from 'src/constants/routes';
import { ASSETS_BASE_URL } from 'src/api/concierge';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/square-arrow.svg';
import { Image, SrcSet } from 'src/components/image';

import { useGetRebookingPhase } from '../../hooks/use-get-rebooking-room-phase';
import { RebookStatus } from '../../api/constants';

interface Content {
    title: string;
    description: string;
    primary_message: string;
    secondary_message: string;
    image_srcset: SrcSet;
}

const CONTENT_MAP = new Map<RebookStatus, Content>([
    [
        RebookStatus.ComingSoon,
        {
            title: 'rebookers-card_coming-soon_title',
            primary_message: 'rebookers-card_campaign-active_date',
            secondary_message: 'rebookers-card_coming-soon_years',
            description: 'rebookers-card_coming-soon_description',
            image_srcset: {
                xl: `${ASSETS_BASE_URL}/images/rebooking-banner_1920px.png`,
                lg: `${ASSETS_BASE_URL}/images/rebooking-banner_1366px.png`,
                md: `${ASSETS_BASE_URL}/images/rebooking-banner_768px.png`,
                xs: `${ASSETS_BASE_URL}/images/rebooking-banner_375px.png`,
            },
        },
    ],

    [
        RebookStatus.Active,
        {
            title: 'rebookers-card_campaign-active_title',
            primary_message: 'rebookers_link_label_discount',
            secondary_message: 'rebookers-card_campaign-active_years',
            description: 'rebookers-card_campaign-active_description',
            image_srcset: {
                xl: `${ASSETS_BASE_URL}/images/rebooking-banner_1920px.png`,
                lg: `${ASSETS_BASE_URL}/images/rebooking-banner_1366px.png`,
                md: `${ASSETS_BASE_URL}/images/rebooking-banner_768px.png`,
                xs: `${ASSETS_BASE_URL}/images/rebooking-banner_375px.png`,
            },
        },
    ],

    [
        RebookStatus.Ended,
        {
            title: 'rebookers-card_campaign-ended_title',
            primary_message: 'rebookers-card_campaign-ended_message',
            secondary_message: 'rebookers-card_campaign-ended_years',
            description: 'rebookers-card_campaign-ended_description',
            image_srcset: {
                xl: `${ASSETS_BASE_URL}/images/rebooking-banner_1920px.png`,
                lg: `${ASSETS_BASE_URL}/images/rebooking-banner_1366px.png`,
                md: `${ASSETS_BASE_URL}/images/rebooking-banner_768px.png`,
                xs: `${ASSETS_BASE_URL}/images/rebooking-banner_375px.png`,
            },
        },
    ],
]);

export const RebookingBanner: FC<{ className?: string }> = ({ className }) => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    const { data: phaseInfo, isLoading, isError } = useGetRebookingPhase();
    const rebookStatus = phaseInfo?.rebook_status;

    if (
        isError ||
        isLoading ||
        !phaseInfo.is_available ||
        !rebookStatus ||
        rebookStatus === RebookStatus.NotAllowed
    ) {
        return null;
    }

    const content = CONTENT_MAP.get(rebookStatus)!;

    const disableLink = rebookStatus === RebookStatus.ComingSoon;
    const linkProps = disableLink
        ? {}
        : {
              component: Link,
              to: routes.rebooking.index,
          };

    const maximumDiscount = phaseInfo.phases.reduce((max, { active, discount }) => {
        if (active && discount && discount > max) {
            return discount;
        }

        return max;
    }, 0);

    const showPrimaryMessage = rebookStatus !== RebookStatus.Active || maximumDiscount > 0;

    return (
        <Box
            {...linkProps}
            css={{
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
                paddingBottom: '32px',
                borderBottom: `1px solid ${palette.divider}`,
            }}
            className={className}
        >
            <Stack direction="row">
                <Stack
                    flex={0}
                    alignItems="center"
                    justifyContent="center"
                    padding={{ xs: '51px 24px', lg: '60px 24px' }}
                    css={{ background: palette.primary.main }}
                >
                    <Typography
                        variant="h2"
                        color={palette.primary.contrastText}
                        fontSize={{ lg: '44px' }}
                    >
                        <Trans i18nKey={content.title} />
                    </Typography>
                </Stack>

                <Box position="relative" flex={1}>
                    <Image srcSet={content.image_srcset} alt="Rebookers banner" />

                    {!disableLink && (
                        <ArrowIcon
                            width="40px"
                            height="40px"
                            color={palette.primary.main}
                            css={{ position: 'absolute', right: 0, top: 0 }}
                        />
                    )}

                    <Stack position="absolute" right="12px" bottom="12px">
                        <Typography
                            variant="body3"
                            padding="6px 7px"
                            textAlign="center"
                            css={{ background: palette.background.default }}
                        >
                            {t(content.secondary_message)}
                        </Typography>

                        {showPrimaryMessage && (
                            <Typography
                                variant="overlineMedium"
                                padding="6px 7px"
                                color={palette.primary.contrastText}
                                textAlign="center"
                                css={{ background: palette.primary.main }}
                            >
                                <Trans
                                    i18nKey={content.primary_message}
                                    values={{ discount: maximumDiscount }}
                                />
                            </Typography>
                        )}
                    </Stack>
                </Box>
            </Stack>

            <Box padding="24px" css={{ background: palette.common.black }}>
                <Typography variant="overlineRegular" color={palette.primary.contrastText}>
                    {t(content.description)}
                </Typography>
            </Box>
        </Box>
    );
};
