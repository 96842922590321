import { FC } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const AUTH0_ENABLE = process.env.REACT_APP_AUTH0_ENABLE === 'true';

export const Auth0HistoryProvider: FC<{
    children: JSX.Element;
}> = ({ children }) =>
    AUTH0_ENABLE ? (
        <Auth0Provider
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            redirectUri={window.location.origin}
            useRefreshTokens
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    ) : (
        children
    );
