import { BreakpointsOptions } from '@mui/material';

export const CUSTOM_VARIABLES = {
    headerHeight: 82,
    privateHeaderHeight: 96,
    footerHeight: 48,
    containerPaddings: {
        xs: '40px 16px 20px 16px',
        md: '40px 40px 20px 40px',
        lg: '40px 60px 20px 60px',
        xl: '40px 100px 20px 100px',
    },
};

export const breakpoints = {
    values: {
        xs: 0,
        sm: 393,
        md: 768,
        lg: 1366,
        xl: 1920,
    },
} as const satisfies BreakpointsOptions;

export const PRIMARY_FONT_FAMILY = 'Radikal, -apple-system, sans-serif';
export const SECONDARY_FONT_FAMILY = 'Trade Gothic LT Std, -apple-system, sans-serif';

export const PRIMARY_FONT_FAMILY_2 = 'Roboto, -apple-system, sans-serif';
export const SECONDARY_FONT_FAMILY_2 = 'Inter, -apple-system, sans-serif';

export const palette = {
    mode: 'light',

    primary: {
        light: '#F11061',
        main: '#E6204F',
        dark: '#E80053',
        contrastText: '#FFFFFF',
    },

    secondary: {
        light: '#CEFFFF',
        main: '#CEFFFF',
        dark: '#497D95',
    },

    text: {
        primary: '#101010',
        secondary: '#979797',
        disabled: '#D8D8D8',
        placeholder: '#CDCDCD',
    },

    divider: '#ECEDEE',

    success: {
        light: 'rgba(76, 182, 105, 0.1)',
        main: '#4CB669',
        dark: '#4CB669',
        contrastText: '#FFFFFF',
    },

    error: {
        light: 'rgba(226, 10, 87, 0.05)',
        main: '#E20A57',
        dark: '#E20A57',
    },

    warning: {
        light: 'rgba(255, 215, 30, 0.1)',
        main: '#FFD71E',
        dark: '#FFD71E',
    },

    background: {
        default: '#FFFFFF',
        paper: '#F5F5F5',
    },

    common: {
        black: '#212121',
    },

    grey: {
        100: '#F3F1F1',
        600: '#7E7E7E',
        900: '#282828',
    },
} as const;

export const palette2 = {
    mode: 'light',

    primary: {
        light: '#381320',
        main: '#87515e',
        dark: '#eb759f',
        contrastText: '#CCCCCC',
    },

    secondary: {
        light: '#2c4a4a',
        main: '#179696',
        dark: '#112f3d',
    },

    text: {
        primary: '#101010',
        secondary: '#615f5f',
        disabled: '#fcdede',
        placeholder: '#7a7878',
    },

    divider: '#a7bacc',

    success: {
        light: 'rgba(76, 182, 105, 0.1)',
        main: '#4CB669',
        dark: '#4CB669',
        contrastText: '#FFFFFF',
    },

    error: {
        light: 'rgba(226, 10, 87, 0.05)',
        main: '#781d3d',
        dark: '#781d3d',
    },

    warning: {
        light: 'rgba(255, 215, 30, 0.1)',
        main: '#fae069',
        dark: '#fae069',
    },

    background: {
        default: '#ffffff',
        paper: '#b5b3b3',
    },

    common: {
        black: '#03291e',
    },

    grey: {
        100: '#ebe6e6',
        600: '#5e5b5b',
        900: '#7a7474',
    },
} as const;
