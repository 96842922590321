import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Badge, BottomNavigation, BottomNavigationAction } from '@mui/material';

import { routes } from 'src/constants/routes';
import { useFeatureFlags } from 'src/features/user';
import { ReactComponent as CommunityPageIcon } from 'src/assets/svg/nav-community.svg';
import { ReactComponent as HomePageIcon } from 'src/assets/svg/tiles.svg';
import { ReactComponent as MyBuildingPageIcon } from 'src/assets/svg/nav-building.svg';
import { ReactComponent as NotificationPageIcon } from 'src/assets/svg/nav-notifications.svg';
import { NotificationStatus, useGetUserNotifications } from 'src/features/notifications';

export const Footer: FC = () => {
    const { pathname } = useLocation();

    const flags = useFeatureFlags();
    const { data: notifications } = useGetUserNotifications({ enabled: flags.notifications });

    const unseenMessagesAmount = notifications?.reduce((result, { status }) => {
        const increment = Number(status === NotificationStatus.Unseen);
        return result + increment;
    }, 0);

    const { palette, breakpoints, zIndex, custom } = useTheme();

    const basePath = `/${pathname.split('/')[1]}`;

    return (
        <BottomNavigation
            component="footer"
            value={basePath}
            css={{
                zIndex: zIndex.appBar,
                background: palette.text.primary,
                color: palette.text.disabled,
                position: 'sticky',
                padding: '0 12px',
                height: custom.footerHeight,
                width: '100%',
                bottom: 0,

                [breakpoints.up('md')]: {
                    display: 'none',
                },

                '.MuiBottomNavigationAction-root': {
                    position: 'relative',
                    maxWidth: 'unset',

                    '&.Mui-selected': {
                        color: palette.common.white,

                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            display: 'inline-block',
                            width: '100%',
                            height: '3px',
                            top: 0,
                            backgroundColor: palette.primary.main,
                        },
                    },
                },
            }}
        >
            {flags.posts && (
                <BottomNavigationAction
                    component={Link}
                    to={routes.community.index}
                    value={routes.community.index}
                    icon={<CommunityPageIcon />}
                />
            )}

            <BottomNavigationAction
                component={Link}
                to={routes.myBuilding.index}
                value={routes.myBuilding.index}
                icon={<MyBuildingPageIcon />}
            />

            {flags.notifications && (
                <BottomNavigationAction
                    component={Link}
                    to={routes.notifications.index}
                    value={routes.notifications.index}
                    icon={
                        <Badge badgeContent={unseenMessagesAmount} color="error">
                            <NotificationPageIcon />
                        </Badge>
                    }
                />
            )}

            <BottomNavigationAction
                component={Link}
                to={routes.home}
                value={routes.home}
                icon={<HomePageIcon />}
            />
        </BottomNavigation>
    );
};
