import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/button';
import { ReactComponent as TimeSmallIcon } from 'src/assets/svg/time-small.svg';
import { ReactComponent as PlusIcon } from 'src/assets/svg/plus.svg';

import { EventAttendanceStatus, EventStatus } from '../../api';
import { AttendButtonState } from '../../constants/attend-button-state';
import { getAttendButtonState } from '../../utils/get-attend-button-state';

export const EventAttendButton: FC<{
    attendanceStatus: EventAttendanceStatus;
    status: EventStatus;
    externalUrl: string | null;
    onOpenCancelAttendanceModal: VoidFunction;
    onOpenLeaveWaitlistModal: VoidFunction;
    onOpenAttendModal: VoidFunction;
    onOpenWaitlistModal: VoidFunction;
}> = ({
    attendanceStatus,
    status,
    externalUrl,
    onOpenCancelAttendanceModal,
    onOpenLeaveWaitlistModal,
    onOpenAttendModal,
    onOpenWaitlistModal,
}) => {
    const { t } = useTranslation();

    const buttonState = getAttendButtonState(status, attendanceStatus);

    switch (buttonState) {
        case AttendButtonState.Attending:
            return (
                <Button
                    design="light"
                    bordered
                    css={{
                        minWidth: '260px',
                        padding: '14.5px 40px',
                    }}
                    onClick={onOpenCancelAttendanceModal}
                >
                    {t('event_cancel_attendance_modal_confirm_cta')}
                </Button>
            );
        case AttendButtonState.OnWaitingList:
            return (
                <Button
                    onClick={onOpenLeaveWaitlistModal}
                    css={{
                        minWidth: '260px',
                        padding: '14.5px 40px',
                    }}
                    bordered
                >
                    {t('events_specific-event_footer_leave_cta')}
                </Button>
            );
        case AttendButtonState.NotOnWaitingList:
            return (
                <Button
                    onClick={onOpenWaitlistModal}
                    css={{
                        minWidth: '260px',
                        padding: '14.5px 40px',
                    }}
                    design="primary"
                    endIcon={<TimeSmallIcon width="16px" height="16px" />}
                >
                    {t('events_specific-event_footer_join_cta')}
                </Button>
            );
        case AttendButtonState.NotAttending:
        default:
            return (
                <Button
                    {...(externalUrl && {
                        href: externalUrl,
                        target: '_blank',
                    })}
                    design="primary"
                    endIcon={<PlusIcon width="16px" height="16px" />}
                    onClick={onOpenAttendModal}
                    css={{
                        minWidth: '260px',
                        padding: '14.5px 40px',
                    }}
                >
                    {t('event_attend_cta')}
                </Button>
            );
    }
};
