import { FC, Fragment } from 'react';
import { Divider, Typography, Stack } from '@mui/material';

export const TicketRow: FC<{ title: string; label?: string; content?: string }> = ({
    title,
    label,
    content,
}) => (
    <Fragment>
        <Stack
            direction={content ? 'column' : 'row'}
            alignItems={content ? 'flex-start' : 'center'}
            justifyContent="space-between"
            paddingY="20px"
            gap="8px"
        >
            <Typography variant="body2" color="text.secondary">
                {title}
            </Typography>
            {label && <Typography variant="subtitle2">{label}</Typography>}
            {content && <Typography variant="body2">{content}</Typography>}
        </Stack>
        <Divider />
    </Fragment>
);
