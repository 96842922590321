import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { Stack, Typography, Button as MuiButton, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { routes } from 'src/constants/routes';
import { LanguagesCode } from 'src/i18n/constants';
import { Dialog } from 'src/components/dialog';
import { logger } from 'src/services/logger';
import { GA4 } from 'src/lib/ga4';
import { getPartialTranslation } from 'src/utils/get-partial-translation';

import { Event } from '../../api';

export const ShareModal: FC<{
    event: Event;
    isOpen: boolean;
    onClose: MouseEventHandler;
}> = ({ event, isOpen, onClose }) => {
    const { t } = useTranslation();

    const [isCopied, setIsCopied] = useState(false);

    const url = event.external_event_url || routes.events.id.replace(':id', event.id);
    const title = getPartialTranslation(event.title);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setIsCopied(true);

            GA4.ctaEvent({
                name: 'Events Share Modal Copy Link Click',
                location: 'Share Event Modal',
                label: t('event_share_modal_copy_link', { lng: LanguagesCode.English }),
            });
        } catch (error) {
            captureException(error);
            logger.error(error);
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isCopied) {
            const timeout = setTimeout(() => setIsCopied(false), 5000);

            return () => clearTimeout(timeout);
        }
    }, [isCopied]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            actions={{
                decline: {
                    children: t('event_share_modal_cancel_cta'),
                    onClick: onClose,
                },
            }}
            css={{
                gap: '30px',
            }}
        >
            <Stack width="100%" justifyContent="center" alignItems="center" gap="16px">
                <Typography variant="h3" textAlign="center">
                    {t('event_share_modal_title')}
                </Typography>

                <Typography variant="body1" textAlign="center">
                    {title}
                </Typography>

                <Paper
                    square
                    elevation={0}
                    css={{ width: '100%', marginTop: '16px', padding: '12px' }}
                >
                    <Typography
                        variant="body2"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                    >
                        {url}
                    </Typography>
                </Paper>

                <MuiButton onClick={isCopied ? undefined : handleCopy} css={{ marginTop: '8px' }}>
                    {isCopied ? t('event_share_modal_copied') : t('event_share_modal_copy_link')}
                </MuiButton>
            </Stack>
        </Dialog>
    );
};
