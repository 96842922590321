import { memo, MouseEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Typography, Stack } from '@mui/material';

import { LanguagesCode } from 'src/i18n/constants';
import { Button } from 'src/components/button';
import { theme } from 'src/styles/theme';
import { AttachmentsGallery } from 'src/components/attachment-gallery';

import { TicketChip } from '../ticket-cheap';
import { MaintenanceTicket, MaintenanceTicketStatus } from '../../api';

import { AttachmentsModal } from './parts/attachments-modal';
import { TicketRow } from './parts/ticket-row';
import { AddAttachmentsModal } from './parts/add-attachments-modal';
import { ResolveTicketDialog } from './parts/resolve-ticket-dialog';

interface Props {
    ticket: MaintenanceTicket;
}

export const TicketInfo = memo<Props>(({ ticket }) => {
    const [showResolveIssueModal, setShowResolveIssueModal] = useState(false);
    const [showAddAttachmentsModal, setShowAddAttachments] = useState(false);
    const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);

    const activeAttachmentIndexRef = useRef(0);

    const { t, i18n } = useTranslation();
    const lngCode = i18n.language as LanguagesCode;

    const attachments = (ticket.attachments || []).map(({ url }) => url);
    const isResolved = ticket.status === MaintenanceTicketStatus.Done;

    const handleOpenAttachmentsModal: MouseEventHandler = (event) => {
        const element = event.target as HTMLElement;
        const { index } = element.dataset;

        activeAttachmentIndexRef.current = Number(index);
        setShowAttachmentsModal(true);
    };

    const handleCloseAttachmentsModal = () => setShowAttachmentsModal(false);

    const toggleAddAttachmentsModal = () => setShowAddAttachments(!showAddAttachmentsModal);

    const handleToggleResolveIssueModal = () => setShowResolveIssueModal(!showResolveIssueModal);

    return (
        <Stack
            flex="1 0 calc(50% - 61px)"
            overflow={{ xs: 'initial', lg: 'auto' }}
            padding={{ xs: '36px 16px 40px 16px', lg: '40px 60px 60px 60px' }}
        >
            <Stack alignItems="center">
                <TicketChip status={ticket.status} />
            </Stack>

            <TicketRow
                title={t('maintenance_specific-ticket_date-issue-reported')}
                label={format(parseISO(ticket.last_modified_date), 'dd/MM/yyyy')}
            />

            <TicketRow
                title={t('maintenance_specific-ticket_issue-type')}
                label={ticket.issue_type_display_name[lngCode]}
            />

            <TicketRow
                title={t('maintenance_specific-ticket_issue')}
                label={ticket.issue_display_name[lngCode]}
            />

            <TicketRow
                title={t('maintenance_specific-ticket_problem')}
                label={ticket.problem_display_name[lngCode]}
            />

            <TicketRow
                title={t('maintenance_specific-ticket_issue-location')}
                label={t(ticket.location_id)}
            />

            <TicketRow
                title={t('maintenance_specific-ticket_issue-details')}
                content={ticket.description}
            />

            <Typography
                component="p"
                variant="body2"
                color="text.secondary"
                gutterBottom
                margin="28px 0 8px 0"
            >
                {t('maintenance_specific-ticket_attached-files', {
                    file_count: attachments.length,
                })}
            </Typography>

            <AttachmentsGallery
                attachments={attachments}
                onOpen={handleOpenAttachmentsModal}
                onAdd={isResolved ? undefined : toggleAddAttachmentsModal}
            />

            {!isResolved && (
                <Stack
                    justifyContent="flex-end"
                    alignItems="center"
                    flex={{ lg: 1 }}
                    marginTop={{ xs: '40px', md: '76px' }}
                    marginBottom={{ xs: '100px', lg: 0 }}
                >
                    <Button
                        design="light"
                        bordered
                        fullWidth
                        onClick={handleToggleResolveIssueModal}
                        css={{
                            [theme.breakpoints.up('md')]: {
                                maxWidth: '260px',
                            },
                        }}
                    >
                        {t('maintenance_specific-ticket_button_resolve-issue')}
                    </Button>
                </Stack>
            )}

            <AttachmentsModal
                open={showAttachmentsModal}
                attachments={attachments}
                activeIndex={activeAttachmentIndexRef.current}
                onClose={handleCloseAttachmentsModal}
            />

            {!isResolved && (
                <AddAttachmentsModal
                    attachments={ticket.attachments || []}
                    ticketId={ticket.id}
                    open={showAddAttachmentsModal}
                    onClose={toggleAddAttachmentsModal}
                />
            )}

            <ResolveTicketDialog
                showDialog={showResolveIssueModal}
                ticketId={ticket.id}
                ticketIsResolved={isResolved}
                toggleDialog={handleToggleResolveIssueModal}
            />
        </Stack>
    );
});
