/* eslint-disable no-underscore-dangle */
import { PaymentInfo } from '../../api';

import type { TransfermateFormData, TransfermateInputs } from './types';

const transferMateAdapter = (conciergeData: PaymentInfo): TransfermateFormData => ({
    ro: 'room_number_text,__instalment_amount_price,__deposit_amount_price,__miscellaneous_amount_price,cu_id_number,vita_ref',
    location: conciergeData.account_id,
    student_number: conciergeData.user_id,
    room_number_text: conciergeData.room,
    cu_id_number: conciergeData.vitaref,
    vita_ref: conciergeData.vitaref,
    __instalment_amount_price: conciergeData.amount_installment_due,
    __deposit_amount_price: conciergeData.amount_deposit_due,
    __miscellaneous_amount_price: conciergeData.amount_miscellaneous_due,
    outstanding_amount: conciergeData.amount_outstanding_due,
    academic_year_text: conciergeData.year,
});

const createInputMap = (data: TransfermateFormData): TransfermateInputs => [
    {
        name: 'ro',
        value: 'room_number_text,__instalment_amount_price,__deposit_amount_price,__miscellaneous_amount_price,cu_id_number,vita_ref',
    },
    {
        name: 'location',
        value: data.location,
    },
    {
        name: 'student_number',
        value: data.student_number,
    },
    {
        name: 'room_number_text',
        value: data.room_number_text,
    },
    {
        name: 'cu_id_number',
        value: data.cu_id_number,
    },
    {
        name: 'vita_ref',
        value: data.vita_ref,
    },
    {
        name: '__instalment_amount_price',
        value: data.__instalment_amount_price,
    },
    {
        name: '__deposit_amount_price',
        value: data.__deposit_amount_price,
    },
    {
        name: '__miscellaneous_amount_price',
        value: data.__miscellaneous_amount_price,
    },
    {
        name: 'outstanding_amount',
        value: data.outstanding_amount,
    },
    {
        name: 'academic_year_text',
        value: data.academic_year_text,
    },
];

const createForm = (transferMateUrl: string, inputMap: TransfermateInputs) => {
    const form = document.createElement('form');

    form.action = transferMateUrl;
    form.hidden = true;
    form.method = 'post';

    inputMap.forEach(({ name, value }) => {
        const input = document.createElement('input');

        input.name = name;
        input.value = value as string;

        form.append(input);
    });

    return form;
};

export const transferMateRedirect = (conciergeData: PaymentInfo) => {
    const data = transferMateAdapter(conciergeData);

    const inputsMap = createInputMap(data);
    const form = createForm(conciergeData.url, inputsMap);

    document.body.append(form);
    return form.submit();
};
