import { FC } from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Interpolation, useTheme, Theme } from '@emotion/react';
import { Button, Stack, Typography, Divider, Box, useMediaQuery, Avatar } from '@mui/material';

import { Image } from 'src/components/image';
import { ReactComponent as ControlArrow } from 'src/assets/svg/control-arrow.svg';
import { routes } from 'src/constants/routes';

import { ShowMore } from './show-more';

import type { Post } from '../../../api';

const galleryOneImage: Interpolation<Theme> = {
    '[data-order="0"]': {
        gridRow: '1/3',
        gridColumn: '1/3',
    },
};

const galleryTwoImages: Interpolation<Theme> = {
    '[data-order]': {
        gridRow: '1/3',
    },
};

const galleryManyImages: Interpolation<Theme> = {
    '[data-order="0"]': {
        gridRow: '1/3',
        gridColumn: '1/2',
    },
};

export const PostItem: FC<{
    post: Post;
    mode?: 'preview' | 'page';
    className?: string;
}> = ({ post, mode = 'preview', className }) => {
    const { palette, breakpoints, shape } = useTheme();
    const widerThanTablet = useMediaQuery(breakpoints.up('md'));

    const attachments = post.images.urls;

    const title = (
        <Typography variant="subtitle1" color="inherit" gutterBottom>
            {post.title}
        </Typography>
    );

    return (
        <div
            className={className}
            css={{
                width: '100%',
                ':last-of-type .MuiDivider-root': {
                    display: 'none',
                },

                [breakpoints.up('md')]: {
                    padding: '40px',
                    borderRadius: `${shape.borderRadius}px`,
                    boxShadow: mode === 'preview' ? '2px 4px 30px rgba(0, 0, 0, 0.08)' : undefined,

                    '.MuiDivider-root': {
                        display: 'none',
                    },
                },
            }}
        >
            <Stack direction="row" spacing="16px" alignItems="center" marginBottom="16px">
                {mode === 'page' && (
                    <Link to={routes.community.index}>
                        <ControlArrow
                            css={{
                                transform: 'rotate(90deg)',

                                [breakpoints.up('md')]: {
                                    path: { fill: palette.common.white },
                                },
                            }}
                        />
                    </Link>
                )}

                <Stack
                    direction="row"
                    alignItems="center"
                    gap="16px"
                    css={{ textDecoration: 'inherit', color: 'inherit' }}
                >
                    <Avatar src={post.author_avatar_url} />

                    <div>
                        <Typography color="text.primary" variant="subtitle1">
                            {post.author_name}
                        </Typography>

                        <Typography color="text.secondary" variant="body2">
                            {format(parseISO(post.date), 'MMM d, yyyy')}
                        </Typography>
                    </div>
                </Stack>
            </Stack>

            <div>
                {mode === 'page' ? (
                    title
                ) : (
                    <Link
                        to={routes.community.id.replace(':id', post.id)}
                        css={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        {title}
                    </Link>
                )}

                <Typography variant="body2" component="div">
                    <ShowMore
                        limit={widerThanTablet ? 240 : 130}
                        showMore={
                            <Button
                                css={{
                                    padding: 0,
                                    marginLeft: 'auto',
                                    color: palette.text.secondary,
                                    fontWeight: 400,
                                    ':hover': { color: palette.primary.main },
                                }}
                            >
                                See more
                            </Button>
                        }
                    >
                        {post.content}
                    </ShowMore>
                </Typography>
            </div>

            {Boolean(attachments.length) && (
                <div
                    css={() => {
                        let imageGalleryVariant: Interpolation<Theme>;

                        if (mode === 'page') {
                            imageGalleryVariant = {};
                        } else if (attachments.length === 1) {
                            imageGalleryVariant = galleryOneImage;
                        } else if (attachments.length === 2) {
                            imageGalleryVariant = galleryTwoImages;
                        } else {
                            imageGalleryVariant = galleryManyImages;
                        }

                        return {
                            display: 'grid',
                            gridTemplateColumns: mode === 'page' ? '1fr' : '50% 50%',
                            gridTemplateRows: mode === 'page' ? undefined : '50% 50%',
                            gridAutoRows: mode === 'page' ? 'minmax(auto, 94vh)' : undefined,
                            height: mode === 'page' ? 'auto' : '280px',
                            gap: mode === 'page' ? '32px' : '1px',
                            margin: '24px -16px 0 -16px',
                            overflow: 'hidden',
                            ...imageGalleryVariant,

                            [breakpoints.up('md')]: {
                                borderRadius: mode === 'page' ? 0 : `${shape.borderRadius}px`,
                                margin: '24px 0 0 0',
                            },
                        };
                    }}
                >
                    {(() => {
                        const result: JSX.Element[] = [];

                        for (let i = 0; i < attachments.length; i++) {
                            const image = attachments[i];

                            if (mode === 'preview' && attachments.length > 3 && i === 2) {
                                const box = (
                                    <Box
                                        key={image + i}
                                        position="relative"
                                        height="100%"
                                        data-order={i}
                                    >
                                        <Link
                                            to={{
                                                pathname: routes.community.id.replace(
                                                    ':id',
                                                    post.id
                                                ),
                                                hash: `image${i}`,
                                            }}
                                        >
                                            <Image src={image} alt="gallery item" />
                                            <div
                                                css={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    background: 'rgba(16, 16, 16, 0.38)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    color="background.default"
                                                >
                                                    +{attachments.length - 3}
                                                </Typography>
                                            </div>
                                        </Link>
                                    </Box>
                                );

                                result.push(box);
                                break;
                            }

                            const preview = <Image src={image} alt="gallery item" />;

                            result.push(
                                <div key={image + i} data-order={i} id={`image${i}`}>
                                    {mode === 'preview' ? (
                                        <Link
                                            to={{
                                                pathname: routes.community.id.replace(
                                                    ':id',
                                                    post.id
                                                ),
                                                hash: `image${i}`,
                                            }}
                                            css={{
                                                textDecoration: 'inherit',
                                                color: 'inherit',
                                            }}
                                        >
                                            {preview}
                                        </Link>
                                    ) : (
                                        preview
                                    )}
                                </div>
                            );
                        }

                        return result;
                    })()}
                </div>
            )}

            <Divider css={{ marginTop: '32px' }} />
        </div>
    );
};
