import { conciergeClient } from 'src/api/concierge';

import { PromotionsEndpoints } from './constants';

import type { GetPromotionsSuccessResponse } from './types';

export const getPromotionsList = async () => {
    const { data: response } = await conciergeClient.get<GetPromotionsSuccessResponse>(
        PromotionsEndpoints.getPromotions
    );

    return response.data;
};
