export const TIMES = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
];

export type Time = (typeof TIMES)[number];

export const ONE_HOUR_SLOTS = new Map<Time, string>(
    TIMES.map((startTime, index) => {
        const endTime = TIMES[(index + 1) % TIMES.length];

        return [startTime, `${startTime} - ${endTime}`];
    })
);

export const TWO_HOUR_SLOTS = new Map<Time, string>(
    TIMES.map((startTime, index) => {
        const endTime = TIMES[(index + 2) % TIMES.length];

        return [startTime, `${startTime} - ${endTime}`];
    })
);

export const THREE_HOUR_SLOTS = new Map<Time, string>(
    TIMES.map((startTime, index) => {
        const endTime = TIMES[(index + 3) % TIMES.length];

        return [startTime, `${startTime} - ${endTime}`];
    })
);
