import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

import { EmptyList } from 'src/components/empty-list/empty-list';
import { AppPreloader } from 'src/components/app-preloader';
import { Button } from 'src/components/button';
import { ErrorState } from 'src/components/error-state';

import { useGetTickets } from '../../hooks/use-get-tickets';

import { TicketItem } from './parts/ticket-item';

export const TicketsList: FC<{ onClick: VoidFunction }> = ({ onClick }) => {
    const { data: tickets, isLoading, isError } = useGetTickets();

    const { breakpoints, custom } = useTheme();

    const { t } = useTranslation();

    if (isError) {
        return (
            <ErrorState
                title={<Trans i18nKey="general_error_something_went_wrong_title" />}
                subtitle={<Trans i18nKey="general_error_something_went_wrong_subtitle" />}
            />
        );
    }

    if (isLoading) {
        return <AppPreloader size="big" />;
    }

    return (
        <Box flex={1} padding={custom.containerPaddings}>
            {tickets.length ? (
                <Box
                    display="grid"
                    gap={{ xs: '12px', md: '36px 24px' }}
                    gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }}
                >
                    {tickets.map((ticket) => (
                        <TicketItem key={ticket.id} ticket={ticket} />
                    ))}
                </Box>
            ) : (
                <EmptyList
                    mainText={<Trans i18nKey="maintenance_reported-issues_empty-page_title" />}
                    actionRow={
                        <Button
                            onClick={onClick}
                            design="primary"
                            css={{
                                width: '100%',
                            }}
                        >
                            {t('maintenance_reported-issues_empty-page_button_report-issue')}
                        </Button>
                    }
                    css={{
                        [breakpoints.up('md')]: {
                            maxWidth: '280px',
                            margin: 'auto',
                        },
                    }}
                />
            )}
        </Box>
    );
};
