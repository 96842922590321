import { FC } from 'react';

import { IconLabel } from 'src/components/icon-label';
import { useFormattedDate } from 'src/hooks/use-formatted-date';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/calendar.svg';

import { Event } from '../api';

export const DateLabel: FC<{ event: Event }> = ({ event }) => {
    const getFormattedDate = useFormattedDate();

    const startDate = getFormattedDate(event.start_date);
    const startTime = getFormattedDate(event.start_date, 'p');
    const endTime = getFormattedDate(event.end_date, 'p');

    return (
        <IconLabel icon={<CalendarIcon width="24px" />}>
            {startDate}
            <br />
            {startTime} - {endTime}
        </IconLabel>
    );
};
