import { MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { ReactComponent as ArrowIcon } from '../assets/svg/control-arrow.svg';

import { FlagIcon } from './flag-icon';

interface Props<V extends string> {
    language: V;
    options: {
        value: V;
        label: string;
        code: string;
    }[];
    onChange: (language: V) => void;
    className?: string;
}

export const LangSwitcherView = <V extends string>({
    language,
    options,
    className,
    onChange,
}: Props<V>) => {
    const { palette, breakpoints } = useTheme();
    const isMobile = window.innerWidth < breakpoints.values.md;

    const arrowIcon = () => (
        <ArrowIcon
            width={18}
            className="MuiSelect-custom-arrow-component"
            css={{ flexShrink: 0, position: 'absolute', right: 5, pointerEvents: 'none' }}
        />
    );

    const handleChangeLanguage: SelectProps<string>['onChange'] = (event) => {
        const code = event.target.value;
        onChange(code as V);
    };

    return (
        <Select
            value={language}
            onChange={handleChangeLanguage}
            variant="standard"
            disableUnderline
            IconComponent={arrowIcon}
            MenuProps={{
                PaperProps: {
                    square: true,
                    elevation: 0,
                },

                anchorOrigin: {
                    vertical: isMobile ? 'top' : 'bottom',
                    horizontal: isMobile ? 'right' : 'center',
                },

                sx: {
                    '.MuiMenu-list': {
                        padding: 0,
                    },
                },
            }}
            css={{
                cursor: 'default',
                display: 'flex',
                border: 'none !important',

                '.MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '40px !important',
                    paddingBottom: '0 !important',
                    paddingTop: '0 !important',

                    '.LangSwitcher__country-name': {
                        display: 'none',
                    },

                    '&[aria-expanded="true"] ~ .MuiSelect-custom-arrow-component': {
                        transform: 'rotate(180deg)',
                    },
                },
            }}
            className={className}
        >
            {options.map(({ value, label, code }) => (
                <MenuItem
                    key={value}
                    value={value}
                    css={{
                        padding: '20px 32px',
                        color: `${palette.common.black} !important`,
                        background: palette.background.paper,

                        '&[aria-selected=true]': {
                            background: `${palette.background.paper} !important`,
                        },

                        ':not(:last-child)': {
                            borderBottom: `1px solid ${palette.divider}`,
                        },
                    }}
                >
                    <FlagIcon
                        code={code}
                        square
                        css={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            marginRight: 10,
                            flexShrink: 0,
                        }}
                    />

                    <Typography className="LangSwitcher__country-name" css={{ flexShrink: 0 }}>
                        {label}
                    </Typography>
                </MenuItem>
            ))}
        </Select>
    );
};
