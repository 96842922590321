import { FC } from 'react';
import { Stack } from '@mui/material';

import { GA4 } from 'src/lib/ga4';

import { BookingCheckInDetails } from '../../../types/booking-check-in-details';

import { CheckInInnerIncomplete } from './check-in-inner-incomplete';
import { CheckInInnerComplete } from './check-in-inner-complete';

export const CheckIn: FC<{
    checkInDetails: BookingCheckInDetails;
    checkInFormURL: string;
}> = ({ checkInDetails, checkInFormURL }) => {
    if (checkInDetails.date_online_check_in) {
        return <CheckInInnerComplete />;
    }

    const handleClick = () => GA4.outboundLink('Check-In Click', checkInFormURL);

    return (
        <Stack
            component="a"
            href={checkInFormURL}
            onClick={handleClick}
            target="_blank"
            css={{
                padding: 0,
                width: '100%',
                borderRadius: 0,
                textDecoration: 'inherit',
                color: 'inherit',
                ':hover': {
                    '[aria-label="link title"]': {
                        ':after': {
                            width: '100%',
                        },
                    },
                },
            }}
        >
            <CheckInInnerIncomplete />
        </Stack>
    );
};
