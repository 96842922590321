import { FC } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import xor from 'lodash/xor';

import { GA4 } from 'src/lib/ga4';

import { LocationOption } from '../../api';
import { useFilterQueryParameters } from '../../hooks/use-filter-query-parameters';

import { LocationChip } from './parts/locationChip';

export const LocationSelector: FC<{ locations: LocationOption[] }> = ({ locations }) => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    const queryParameters = useFilterQueryParameters();

    const handleToggleLocation = (toggleLocation: string) => {
        queryParameters.setLocations(xor(queryParameters.locations, [toggleLocation]));

        GA4.filterEvent({
            name: queryParameters.locations?.includes(toggleLocation)
                ? 'Social Spaces Location Filter Deselected'
                : 'Social Spaces Location Filter Selected',
            location: 'Social Space Filters',
            value: toggleLocation,
        });
    };

    return (
        <div>
            <Typography variant="body2" color={palette.text.secondary}>
                {t('social_space_filter_location')}
            </Typography>
            <Stack justifyContent="flex-start" direction="row" gap="12px" marginTop="12px">
                {locations.map((location) => {
                    const isSelected = Boolean(queryParameters.locations?.includes(location.slug));

                    return (
                        <LocationChip
                            key={location.slug}
                            location={location}
                            selected={isSelected}
                            onToggleLocation={handleToggleLocation}
                        />
                    );
                })}
            </Stack>
        </div>
    );
};
