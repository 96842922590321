import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { queryClient } from 'src/lib/query-client';

import {
    postSocialSpaceBooking,
    PostSocialSpaceBookingBody,
    PostSocialSpaceBookingErrorResponse,
    SocialSpaceBooking,
    SocialSpacesEndpoints,
} from '../api';

export const usePostSocialSpaceBooking = () => {
    const mutation = useMutation<
        SocialSpaceBooking,
        AxiosError<PostSocialSpaceBookingErrorResponse>,
        PostSocialSpaceBookingBody
    >({
        mutationFn: (socialSpaceBookingBody) => postSocialSpaceBooking(socialSpaceBookingBody),
        onSuccess: () => {
            queryClient.invalidateQueries([SocialSpacesEndpoints.GetMySocialSpaceBookings]);
        },
        cacheTime: 0,
    });

    return mutation;
};
