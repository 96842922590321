import { appConfig } from 'src/app/app-config';

import { liveChat } from './live-chat';
import { ChatEvents, PostMateType } from './live-chat.constants';

import type { ChatWindow, PostmateMessage } from './live-chat.types';

const global = window as ChatWindow;

export const initLiveChat = () => {
    if (!appConfig.liveChat.enabled) return;

    global.customerServiceOptions = {
        remoteConfig: appConfig.liveChat.remoteConfig,
    };

    /**
     * Waits for iframe's Postmate message of type 'handshake-reply', which means chat scripts are loaded
     */
    const onChatReady = ({ data }: MessageEvent<PostmateMessage>) => {
        if (data.postmate === PostMateType.HandShake) {
            /**
             * Runs after the 'ready' event, which means the chat is ready to work.
             * Hides the chat at the first load. Removes technical global class to avoid visible layout shift
             * WARN: Default behavior should be configured at the settings interface which is currently unavailable
             */
            global.LiveChatWidget?.once(ChatEvents.Ready, () => {
                global.LiveChatWidget?.once(ChatEvents.VisibilityChange, () => {
                    document.body.classList.remove('chat-hidden');
                });

                liveChat.hide();
            });

            window.removeEventListener('message', onChatReady);
        }
    };

    window.addEventListener('message', onChatReady);

    const script = document.createElement('script');
    script.src = 'https://dist.chatservice.co/latest/customerService.js';

    document.head.append(script);
};
