import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { PrivateHat } from 'src/layout/private-hat';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/calendar.svg';
import { ReactComponent as CalendarSmallIcon } from 'src/assets/svg/calendar-empty.svg';
import {
    SocialSpacesList,
    SocialSpacesFilters,
    useGetFiltersTree,
    useFilterQueryParameters,
    LimitAlert,
} from 'src/features/social-spaces';
import { Button } from 'src/components/button';
import { routes } from 'src/constants/routes';
import { useFormattedDate } from 'src/hooks/use-formatted-date';
import { ErrorState } from 'src/components/error-state';
import { AppPreloader } from 'src/components/app-preloader';
import { Filters } from 'src/features/social-spaces/api';

const MyBookingsButton = () => {
    const { palette, breakpoints } = useTheme();

    const { t } = useTranslation();

    return (
        <Fragment>
            <Button
                component={Link}
                to={routes.socialSpaces.myBookings}
                design="primary"
                endIcon={<CalendarSmallIcon />}
                css={{
                    [breakpoints.down('md')]: {
                        display: 'none',
                    },
                }}
            >
                {t('social_space_my_bookings_cta')}
            </Button>
            <Link to={routes.socialSpaces.myBookings}>
                <CalendarIcon
                    css={{
                        cursor: 'pointer',
                        color: palette.primary.main,
                        [breakpoints.up('md')]: {
                            display: 'none',
                        },
                    }}
                />
            </Link>
        </Fragment>
    );
};

export const SocialSpacesPage = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const getFormattedDate = useFormattedDate();

    const queryParameters = useFilterQueryParameters();

    const filterParams = {
        date: queryParameters.date,
        date_required: false,
        time: queryParameters.time,
    };

    const handleCheckFilterValues = async (newFilterData?: Filters) => {
        // don't do anything if filters no longer set
        if (!newFilterData) {
            return;
        }

        const isSelectedTimeInvalid =
            queryParameters.time && !newFilterData.times.includes(queryParameters.time);
        const isSelectedDurationInvalid =
            queryParameters.duration &&
            !newFilterData.durations.find(
                (duration) => duration.is_valid && duration.duration === queryParameters.duration
            );

        if (isSelectedTimeInvalid) {
            queryParameters.setTime(undefined, 'replaceIn');
        }

        if (isSelectedDurationInvalid) {
            queryParameters.setDuration(undefined, 'replaceIn');
        }
    };

    const {
        refetch,
        data: filtersData,
        isFetching: isFiltersFetching,
        isLoading: isFiltersLoading,
        isError: isFiltersError,
    } = useGetFiltersTree(filterParams, {
        onSuccess: handleCheckFilterValues,
    });

    const handleRetryFilters = () => refetch();

    const handleGoToMyBuilding = () => {
        navigate(routes.myBuilding.index);
    };

    if (isFiltersError) {
        return (
            <Stack justifyContent="center" alignItems="center" minHeight="100%">
                <ErrorState direction="row" title={<Trans i18nKey="social_space_error" />} />

                <Stack
                    width={{ md: '280px' }}
                    marginTop="32px"
                    gridRow={3}
                    bottom="16px"
                    right="16px"
                    left="16px"
                    gap="10px"
                    position={{ xs: 'absolute', md: 'static' }}
                >
                    <Button design="primary" fullWidth onClick={handleRetryFilters}>
                        {t('social_space_error_retry_cta')}
                    </Button>

                    <Button design="light" bordered fullWidth onClick={handleGoToMyBuilding}>
                        {t('social_space_error_back_cta')}
                    </Button>
                </Stack>
            </Stack>
        );
    }

    if ((isFiltersLoading || isFiltersFetching) && !filtersData) {
        return (
            <Stack justifyContent="center" alignItems="center" minHeight="100%">
                <AppPreloader size="small" />
            </Stack>
        );
    }

    const selectedDateFilter = filtersData.dates.find(
        (selectorDate) => selectorDate.date === queryParameters.date
    );

    const dailyLimitRemaining = selectedDateFilter?.daily_limit_remaining;

    const twoWeekLimitRemaining = filtersData.two_week_limit_remaining;

    const twoWeekLimitResetDate =
        filtersData.two_week_limit_reset_date &&
        getFormattedDate(filtersData.two_week_limit_reset_date);

    return (
        <Fragment>
            <PrivateHat
                title={t('social_space_page_title')}
                backIconText={t('back_cta')}
                onBack={handleGoToMyBuilding}
                actionLabel={<MyBookingsButton />}
            />

            <LimitAlert
                dailyLimitRemaining={dailyLimitRemaining}
                twoWeekLimitRemaining={twoWeekLimitRemaining}
                twoWeekLimitResetDate={twoWeekLimitResetDate}
            />

            <Stack
                flex={1}
                padding={{
                    xs: '16px 16px 40px 16px',
                    md: '40px 40px 60px 40px',
                    lg: '40px 60px 60px 60px',
                    xl: '40px 100px 60px 100px',
                }}
                gap="40px"
            >
                <SocialSpacesFilters filters={filtersData} isLoading={isFiltersFetching} />

                <SocialSpacesList />
            </Stack>
        </Fragment>
    );
};
