import { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LinkIcon } from 'src/assets/svg/link.svg';
import { ASSETS_BASE_URL } from 'src/api/concierge';

export const FaqRulesDocuments: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="16px" marginTop="16px">
            <Link
                target="_blank"
                download
                href={`${ASSETS_BASE_URL}/docs/wellbeing-charter.pdf`}
                display="flex"
                alignItems="center"
                gap="8px"
            >
                <Typography variant="subtitle2">
                    {t('faq_answer_home-rules_documents_wellbeing')}
                </Typography>

                <LinkIcon />
            </Link>
        </Stack>
    );
};
