import { useEffect } from 'react';

export const useClickOutsideElements = (elements: HTMLElement[], clickedOutside: () => void) => {
    const handleClickOutside = (event: MouseEvent) => {
        if (!elements.length) {
            return;
        }

        // if click outside all elements
        if (
            elements
                .map((element: HTMLElement) => element.contains(event.target as Element))
                .filter((inside: boolean) => inside).length === 0
        ) {
            clickedOutside();
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [elements]);
};
