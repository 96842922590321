import { FC, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';
import { enGB as en } from 'date-fns/locale';

import { useFeatureFlags } from 'src/features/user';
import {
    BookingInfo,
    BookingSelect,
    BookingsTabs,
    BookingDocs,
    useGetActiveBooking,
    useGetBookings,
    formatBookingDate,
} from 'src/features/bookings';
import {
    PaymentSchedule,
    MakePayment,
    PaymentReceipts,
    CardlessPayment,
} from 'src/features/payments';
import { PrivateHat } from 'src/layout/private-hat';
import { ContactsRow } from 'src/components/contacts-row/contacts-row';
import { routes } from 'src/constants/routes';
import { ReactComponent as QuestionIcon } from 'src/assets/svg/question-in-circle.svg';
import { BlockWithIcon } from 'src/components/block-with-icon';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { Page404 } from './page404';

export const MyBookingsPage: FC = () => {
    const { palette, breakpoints } = useTheme();

    const { data: activeBooking } = useGetActiveBooking();
    const { data: bookings } = useGetBookings();

    const navigate = useNavigate();
    const { id = activeBooking!.id } = useParams(); // this is booking with id provided by URL. "/my-bookings/<id>" or active booking

    const flags = useFeatureFlags();
    const { t } = useTranslation();

    const selectedBooking = bookings!.find((booking) => booking.id === id);

    if (!selectedBooking) {
        return <Page404 />;
    }

    const isGoCardless = selectedBooking?.payment_status.includes('GOCARDLESS_');

    const handleChangeBookingId = (selectedId: string) => {
        navigate(routes.myBooking.id.replace(':id', selectedId), { replace: true });

        const newSelectedBooking = bookings!.find((booking) => booking.id === id)!;

        const label = `${formatBookingDate(
            newSelectedBooking.date_from,
            false,
            en
        )} — ${formatBookingDate(newSelectedBooking.date_to, false, en)}`;

        GA4.tabEvent({
            name: 'Booking Tab Change',
            label,
            location: 'Booking Tab Group',
            value: newSelectedBooking.year,
        });
    };

    const handleClickEmail = () =>
        GA4.ctaEvent({
            name: 'Email Us Click',
            label: t('contact_email', { lng: LanguagesCode.English }),
            location: 'Alter Booking Contact',
        });

    const handleClickCallBack = () =>
        GA4.ctaEvent({
            name: 'Call Back Click',
            label: t('contact_callback', { lng: LanguagesCode.English }),
            location: 'Alter Booking Contact',
        });

    return (
        <Fragment>
            <PrivateHat title={t('page_link_title')} backIconText={t('back_cta')} />

            <Box padding={{ xs: '16px 16px 80px 16px', md: '0 60px 80px 60px' }} flex={1}>
                <BookingsTabs
                    bookingId={selectedBooking.id}
                    bookings={bookings!}
                    onBookingIdChange={handleChangeBookingId}
                />

                <BookingSelect
                    bookingId={selectedBooking.id}
                    bookings={bookings!}
                    onBookingIdChange={handleChangeBookingId}
                />

                <Stack
                    direction={{ xs: 'column', lg: 'row' }}
                    gap={{ xs: '52px', lg: '72px' }}
                    marginTop={{ xs: '42px', md: '72px' }}
                    alignItems={{ lg: 'flex-start' }}
                >
                    {flags.make_payment && (
                        <Box flex={{ lg: '0 0 420px' }}>
                            {isGoCardless ? (
                                <CardlessPayment bookingId={selectedBooking.id} />
                            ) : (
                                <MakePayment bookingId={selectedBooking.id} />
                            )}
                        </Box>
                    )}

                    <Box display={{ md: 'none' }}>
                        <BlockWithIcon
                            icon={<QuestionIcon />}
                            title={t('help_page_link_title').toLocaleUpperCase()}
                            body={t('help_page_link_subtitle')}
                            link={routes.supportHub.index}
                        />
                    </Box>

                    <Stack flex={1} gap={{ xs: '60px', lg: '72px' }} justifyContent="stretch">
                        <Stack flex={1} gap={{ xs: '60px', lg: '72px' }}>
                            <PaymentSchedule bookingId={selectedBooking.id} />
                            <PaymentReceipts bookingId={selectedBooking.id} />
                        </Stack>

                        <Stack gap={{ xs: '60px', lg: '72px' }}>
                            <BookingDocs
                                developmentSlug={selectedBooking.development_slug}
                                licenseAgreementLink={selectedBooking.license_agreement_url}
                                termsAndConditionsLink={selectedBooking.booking_terms_url}
                            />
                            <BookingInfo booking={selectedBooking} />
                        </Stack>
                    </Stack>
                </Stack>
            </Box>

            <div
                css={{
                    background: palette.grey[900],
                    color: palette.common.white,
                    padding: '36px 16px',

                    [breakpoints.up('md')]: {
                        padding: '40px',
                    },
                }}
            >
                <div css={{ maxWidth: '488px', textAlign: 'center', margin: 'auto' }}>
                    <Typography variant="h5" marginBottom="32px">
                        {t('my_bookings_change_requests_title')}
                    </Typography>

                    <Typography marginBottom="24px">
                        {t('my_bookings_change_requests_subtitle')}
                    </Typography>

                    <ContactsRow
                        variant="dark"
                        css={{
                            [breakpoints.up('md')]: {
                                flexDirection: 'row',
                            },
                        }}
                        onClickEmail={handleClickEmail}
                        onClickCallBack={handleClickCallBack}
                    />
                </div>
            </div>
        </Fragment>
    );
};
