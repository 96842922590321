import { ConciergeSuccessResponse, conciergeClient } from 'src/api/concierge';

import { BuildingUpdatesEndpoints } from './constants';

import type { ActiveBuildingIncidentCount, BuildingIncidents, BuildingNews } from './types';

export const getBuildingNews = async (id: string) => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<BuildingNews>>(
        BuildingUpdatesEndpoints.getNews.replace('{id}', id)
    );

    return response.data;
};

export const getBuildingIncidents = async (id: string) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<BuildingIncidents>
    >(BuildingUpdatesEndpoints.getIncidents.replace('{id}', id));

    return response.data;
};

export const getBuildingActiveIncidentsCount = async (id: string) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<ActiveBuildingIncidentCount>
    >(BuildingUpdatesEndpoints.getIncidentsCount.replace('{id}', id));

    return response.data;
};
