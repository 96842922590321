import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { PrivateHat } from 'src/layout/private-hat';
import { Event } from 'src/features/events/api';
import { EventAttendSuccess } from 'src/features/events/components/event-attend-success';
import { routes } from 'src/constants/routes';

interface LocationState {
    event: Event;
    isQuick: boolean;
}

export const EventAttendSuccessPage: FC = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const state = location.state as LocationState | null;

    if (!state) {
        return <Navigate to={routes.events.index} />;
    }

    return (
        <Fragment>
            <PrivateHat title={t('events_title')} backIconText={t('back_cta')} />

            <EventAttendSuccess event={state.event} isQuick={state.isQuick} />
        </Fragment>
    );
};
