/* eslint-disable no-console */
const ALLOW_LOGS = process.env.REACT_APP_ALLOW_LOGS === 'true';

/**
 * Temporary solution for dev logs
 */
export const logger = {
    log(...messages: unknown[]) {
        if (ALLOW_LOGS) {
            console.log(...messages);
        }
    },

    warn(...messages: unknown[]) {
        if (ALLOW_LOGS) {
            console.warn(...messages);
        }
    },

    error(...messages: unknown[]) {
        if (ALLOW_LOGS) {
            console.error(...messages);
        }
    },
};
