import { useQuery } from '@tanstack/react-query';

import type { ConciergeErrorResponse } from 'src/api/concierge';

import { GetPaymentsResponse, PaymentsEndpoints, getPayments } from '../api';

export const useGetPayments = (bookingId: string) =>
    useQuery<GetPaymentsResponse, ConciergeErrorResponse>({
        queryKey: [PaymentsEndpoints.getPayments, bookingId],
        queryFn: () => getPayments(bookingId),
    });
