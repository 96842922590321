import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { RebookingLinks } from 'src/features/rebooking';
import { PrivateHat } from 'src/layout/private-hat';

export const RebookingPage = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <PrivateHat title={t('rebookers_title')} backIconText={t('back_cta')} />

            <RebookingLinks />
        </Fragment>
    );
};
