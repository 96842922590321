import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { Dialog } from 'src/components/dialog';
import { ErrorState } from 'src/components/error-state';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { usePostCancelAttendanceEvent } from '../hooks/use-post-close-attendance';

export const EventCancelAttendanceModal: FC<{
    open: boolean;
    id: string;
    onClose: VoidFunction;
}> = ({ id, open, onClose }) => {
    const { t } = useTranslation();

    const { isLoading, isSuccess, isError, isIdle, mutate } = usePostCancelAttendanceEvent(id, {
        onSuccess: onClose,
    });

    const handleCancelAttendance = () => {
        mutate();

        GA4.submitEvent({
            name: 'Events Cancel Attendance Submit',
            label: t('event_cancel_attendance_modal_confirm_cta', { lng: LanguagesCode.English }),
            location: 'Events Cancel Attendance Modal',
        });
    };

    return (
        <Dialog
            open={open}
            loading={isLoading || isSuccess}
            onClose={onClose}
            actions={{
                decline: {
                    children: t('event_cancel_attendance_modal_back_cta'),
                    onClick: onClose,
                },
                accept: {
                    children: isError
                        ? t('event_error_cta_try_again')
                        : t('event_cancel_attendance_modal_confirm_cta'),
                    onClick: handleCancelAttendance,
                },
            }}
        >
            {isIdle && (
                <Typography variant="h3" textAlign="center">
                    <Trans i18nKey="event_cancel_attendance_modal_title" />
                </Typography>
            )}

            {isError && (
                <ErrorState title={<Trans i18nKey="general_error_something_went_wrong_title" />} />
            )}
        </Dialog>
    );
};
