import { Box } from '@mui/material';
import { FC, ReactNode, useState } from 'react';

export const ShowMore: FC<{
    children: string;
    limit: number;
    showMore?: ReactNode;
    showLess?: ReactNode;
    truncatedEndingComponent?: ReactNode;
    className?: string;
}> = ({
    children,
    limit,
    showMore,
    showLess,
    className,
    truncatedEndingComponent = <span>...</span>,
}) => {
    const isMoreThanLimit = children.length > limit;
    const [isTextExpanded, setTextExpanded] = useState(isMoreThanLimit);

    const toggleTextExpanded = () => setTextExpanded(!isTextExpanded);

    return (
        <div className={className}>
            {isTextExpanded ? children.substring(0, limit) : children}
            {isTextExpanded && truncatedEndingComponent}
            {showMore && isTextExpanded && (
                <Box
                    display="inline-flex"
                    role="button"
                    tabIndex={-1}
                    onClick={toggleTextExpanded}
                    onKeyDown={toggleTextExpanded}
                >
                    {showMore}
                </Box>
            )}
            {showLess && !isTextExpanded && (
                <Box
                    textAlign="right"
                    role="button"
                    tabIndex={-1}
                    onClick={toggleTextExpanded}
                    onKeyDown={toggleTextExpanded}
                >
                    {showLess}
                </Box>
            )}
        </div>
    );
};
