import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TableContainer,
    Table,
    Typography,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
} from '@mui/material';

import { AppPreloader } from 'src/components/app-preloader';
import { formatAmount } from 'src/utils/format-amount';

import { useGetPaymentsReceipts } from '../../hooks/use-get-payments-receipts';

import { PaymentReceipt } from './parts/payment-receipt';

export const PaymentReceipts: FC<{ bookingId: string }> = ({ bookingId }) => {
    const {
        data: response,
        isError,
        isLoading,
        isSuccess,
        error,
    } = useGetPaymentsReceipts(bookingId);

    const { t } = useTranslation();

    if (isSuccess && response.data.length < 1) {
        return null;
    }

    return (
        <div data-testid="PaymentReceipts.container">
            <Typography variant="h2" marginBottom="32px">
                {t('my_bookings_payment_receipts_title')}
            </Typography>

            {isError && (
                <Typography variant="h3" color="error">
                    {JSON.stringify(error)}
                </Typography>
            )}

            {isLoading && <AppPreloader css={{ minHeight: '400px' }} background="paper" />}

            {isSuccess && (
                <TableContainer css={{ width: 'calc(100% + 32px)', margin: '0 -16px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_receipts_date_paid_label')}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_receipts_category_label')}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_receipts_amount_label')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {response.data.map((receipt) => (
                                <PaymentReceipt key={receipt.id} receipt={receipt} />
                            ))}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="text.secondary">
                                        {t('my_bookings_payment_receipts_total_paid_label')}
                                    </Typography>
                                </TableCell>

                                <TableCell />

                                <TableCell>
                                    <Typography variant="subtitle2" color="text.primary">
                                        {response.meta.amount_total &&
                                            formatAmount(
                                                response.meta.amount_total,
                                                response.meta.currency
                                            )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};
