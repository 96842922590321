import { Box, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ErrorState } from 'src/components/error-state';
import { AppPreloader } from 'src/components/app-preloader';
import { Button } from 'src/components/button';
import { ReactComponent as RetryIcon } from 'src/assets/svg/retry.svg';
import { EmptyList } from 'src/components/empty-list/empty-list';

import { SocialSpaceCard } from '../social-space-card/social-space-card';
import { useGetSocialSpaces } from '../../hooks/use-get-social-spaces';
import { useFilterQueryParameters } from '../../hooks/use-filter-query-parameters';

export const SocialSpacesList = () => {
    const { t } = useTranslation();

    const queryParameters = useFilterQueryParameters();

    const params = {
        locations: queryParameters.locations,
        date: queryParameters.date,
        time: queryParameters.time,
        duration: queryParameters.duration,
    };

    const { data, isLoading, isError, refetch } = useGetSocialSpaces(params);

    const handleRetry = () => {
        queryParameters.setLocations(undefined);
        queryParameters.setDate(undefined);
        queryParameters.setTime(undefined);
        queryParameters.setDuration(undefined);
        refetch();
    };

    if (isError) {
        return (
            <Stack flex={1} justifyContent="center" alignItems="center">
                <ErrorState
                    direction="row"
                    title={<Trans i18nKey="social_space_list_error" />}
                    css={{ flex: 0 }}
                />

                <Button
                    css={{
                        width: '220px',
                        marginTop: '32px',
                    }}
                    design="primary"
                    onClick={handleRetry}
                    endIcon={<RetryIcon />}
                >
                    RETRY
                </Button>
            </Stack>
        );
    }

    if (isLoading) {
        return <AppPreloader css={{ padding: '100px 0' }} />;
    }

    const spaces = data!.sort((space1, space2) => {
        if (space1.out_of_order && !space2.out_of_order) {
            return 1;
        }
        if (!space1.out_of_order && space2.out_of_order) {
            return -1;
        }
        return 0;
    });

    if (!spaces!.length) {
        return (
            <EmptyList
                css={{
                    padding: '50px 0',
                }}
                mainText={t('social_space_list_empty_title')}
                secondaryText={t('social_space_list_empty_text')}
            />
        );
    }

    const roomsAvailableCount = spaces.filter((space) => !space.out_of_order).length;

    return (
        <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr ', lg: '1fr 1fr', xl: '1fr 1fr 1fr' }}
            gap={{ xs: '16px', lg: '24px' }}
            marginBottom="auto"
        >
            <Typography variant="h4" gridColumn={{ lg: '1/3', xl: '1/4' }}>
                <Trans
                    i18nKey="social_space_available_rooms"
                    values={{ count: roomsAvailableCount }}
                />
            </Typography>

            {spaces.map((space) => (
                <SocialSpaceCard space={space} key={space.id} />
            ))}
        </Box>
    );
};
