import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { EventsEndpoints, EventList, getMyEventsList } from '../api';

type QueryConfig = UseQueryOptions<EventList, AxiosError<ConciergeErrorResponse>>;

export const useGetMyEvents = (config: QueryConfig = {}) => {
    const query = useQuery<EventList, AxiosError<ConciergeErrorResponse>>({
        queryKey: [EventsEndpoints.GetMyEvents],
        queryFn: getMyEventsList,
        ...config,
    });

    return query;
};
