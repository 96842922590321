import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { SocialSpacesEndpoints, SocialSpaceBookingsList, getMySocialSpaceBookings } from '../api';

type QueryConfig = UseQueryOptions<SocialSpaceBookingsList, AxiosError<ConciergeErrorResponse>>;

export const useGetMySocialSpaceBookings = (config: QueryConfig = {}) => {
    const query = useQuery<SocialSpaceBookingsList, AxiosError<ConciergeErrorResponse>>({
        queryKey: [SocialSpacesEndpoints.GetMySocialSpaceBookings],
        queryFn: getMySocialSpaceBookings,
        ...config,
    });

    return query;
};
