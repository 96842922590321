import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import {
    SocialSpacesEndpoints,
    getSocialSpaceSlots,
    SocialSpaceSlots,
    GetSocialSpaceSlotsParams,
} from '../api';

type QueryConfig = UseQueryOptions<SocialSpaceSlots, AxiosError<ConciergeErrorResponse>>;

export const useGetSocialSpaceSlots = (
    id: string,
    params: GetSocialSpaceSlotsParams,
    config: QueryConfig = {}
) => {
    const query = useQuery<SocialSpaceSlots, AxiosError<ConciergeErrorResponse>>({
        queryKey: [SocialSpacesEndpoints.GetSocialSpaceSlots, id, params],
        queryFn: () => getSocialSpaceSlots(id, params),
        cacheTime: 0,
        ...config,
    });

    return query;
};
