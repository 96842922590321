import { FC } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { endOfDay, format, max, startOfDay, Interval, parseISO } from 'date-fns';
import { useTheme } from '@emotion/react';

import { Booking } from 'src/features/bookings';
import { MONTHS_INDEXED } from 'src/constants/time';

import { isYearOutsideRange } from '../../../utils/is-year-outside-range';
import { isMonthOutsideRange } from '../../../utils/is-month-outside-range';

export const CalendarMonths: FC<{
    updateSelectedMonth: (month: number) => void;
    focusedYear: number;
    booking: Booking;
}> = ({ updateSelectedMonth, focusedYear, booking }) => {
    const { palette } = useTheme();

    const currentDateObject = new Date();

    const dateFrom = max([parseISO(booking.date_from), currentDateObject]);
    const dateTo = new Date(dateFrom.getFullYear() + 1, 0, 31);
    const calendarRange: Interval = { start: startOfDay(dateFrom), end: endOfDay(dateTo) };

    const focusedYearObject = new Date(focusedYear, 1, 1);

    const isYearUnavailable = isYearOutsideRange(focusedYearObject, calendarRange);

    return (
        <Box display="grid" gridTemplateColumns="repeat(3,1fr)" padding="16px 0">
            {MONTHS_INDEXED.map((month: number) => {
                const monthObject = new Date(focusedYear, month, 1);

                const isUnavailable =
                    isYearUnavailable || isMonthOutsideRange(monthObject, calendarRange);

                return (
                    <Button
                        key={month}
                        css={{
                            textAlign: 'center',
                            padding: '19px 32px',
                            width: '100%',
                            borderRadius: 0,
                            background: 'none',
                            color: palette.text.primary,
                            '&:hover': {
                                background: palette.background.paper,
                            },
                            ...(isUnavailable && {
                                textDecoration: 'line-through',
                                color: palette.text.disabled,
                                pointerEvents: 'none',
                            }),
                        }}
                        onClick={() => updateSelectedMonth(month)}
                    >
                        <Typography variant="body1">
                            {focusedYear && format(monthObject, 'MMM')}
                        </Typography>
                    </Button>
                );
            })}
        </Box>
    );
};
