import { Subject } from 'src/features/feedback/constants/form-options';

export const routes = {
    index: '/',
    home: '/home',

    notifications: {
        index: '/notifications',
    },

    moveInDay: {
        index: '/move-in-day',
    },

    moveInWeek: {
        index: '/move-in-week',
    },

    supportHub: {
        index: '/support-hub',
        search: '/support-hub/search',
    },

    myBooking: {
        index: '/my-booking',
        id: '/my-booking/:id',
    },

    maintenance: {
        index: '/maintenance',
        id: '/maintenance/:id',
    },

    community: {
        index: '/community',
        id: '/community/:id',
    },

    myBuilding: {
        index: '/my-building',
        incidents: '/my-building/incidents',
        offers: '/my-building/offers',
        news: '/my-building/news',
    },

    profile: {
        index: '/profile',
        edit: '/profile/edit',
    },

    events: {
        index: '/events',
        id: '/events/:id',
        my: '/events/my',
        attendSuccess: '/events/attend-success',
    },

    socialSpaces: {
        index: '/book-a-space',
        myBookings: '/book-a-space/my-bookings',
        id: '/book-a-space/:id',
    },

    rebooking: {
        index: '/rebooking',
        swap: '/rebooking/swap',
    },

    feedback: {
        index: '/feedback',
        subject: '/feedback/:subject',
        staff: {
            index: `/feedback/${Subject.Staff}`,
            category: `/feedback/:subject/:staffCategory`,
        },
        events: {
            index: `/feedback/${Subject.Events}`,
            id: `/feedback/${Subject.Events}/:id`,
        },
    },

    guides: {
        index: '/guides',
    },

    logout: '/logout',

    auth0InternalErrorPage: '/auth-error',
};
