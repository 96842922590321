import { FC } from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { routes } from 'src/constants/routes';

import { BookingMoveInDetails } from '../../../types/booking-move-in-details';

import { MoveInWeekInner } from './move-in-week-inner';

export const MoveInWeek: FC<{ moveInDetails: BookingMoveInDetails; isMainOccupant: boolean }> = ({
    moveInDetails,
    isMainOccupant,
}) => {
    if (isMainOccupant) {
        return (
            <Stack
                component={Link}
                to={routes.moveInWeek.index}
                css={{
                    padding: 0,
                    width: '100%',
                    borderRadius: 0,
                    textDecoration: 'inherit',
                    color: 'inherit',
                    ':hover': {
                        '[aria-label="link title"]': {
                            ':after': {
                                width: '100%',
                            },
                        },
                    },
                }}
            >
                <MoveInWeekInner moveInDetails={moveInDetails} isMainOccupant />
            </Stack>
        );
    }

    return <MoveInWeekInner moveInDetails={moveInDetails} isMainOccupant={false} />;
};
