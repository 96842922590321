import { useQuery } from '@tanstack/react-query';

import type { ConciergeErrorResponse } from 'src/api/concierge';

import { getPosts, Posts, PostsEndpoints } from '../api';

export const useGetPosts = () => {
    const userQuery = useQuery<unknown, ConciergeErrorResponse, Posts>({
        queryKey: [PostsEndpoints.getPosts],
        queryFn: getPosts,
    });

    return userQuery;
};
