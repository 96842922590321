import { Theme } from '@mui/material/styles';

import { theme } from 'src/styles/theme';
import { theme2 } from 'src/styles/theme2';

const designsMap: Record<string, Record<string, string | Theme>> = {
    [process.env.REACT_APP_PRIMARY_DOMAIN as string]: {
        title: 'Vita Concierge',
        favicon: '/favicon.ico',
        theme,
    },
    [process.env.REACT_APP_SECONDARY_DOMAIN as string]: {
        title: 'Vita Student - Concierge',
        favicon: '/favicon2.ico',
        theme: theme2,
    },
};

const updateTitleAndFavicon = (design: Record<string, string | Theme>) => {
    document.title = design.title as string;

    const faviconElement = document.querySelector('link[rel="icon"]');

    if (faviconElement instanceof HTMLLinkElement) {
        faviconElement.href = design.favicon as string;
    }
};

export const getTheme = () => {
    const { hostname } = window.location;

    const mainTheme = Object.values(designsMap)[0];

    const design = designsMap[hostname] || mainTheme;

    updateTitleAndFavicon(design);

    return design.theme as Theme;
};
