import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { routes } from 'src/constants/routes';
import { StateView } from 'src/components/state-view';

export const Page404: FC = () => {
    const { t } = useTranslation();

    return (
        <StateView
            state="fail"
            title={<Trans i18nKey="404_page_title" />}
            subtitle={<Trans i18nKey="404_page_subtitle" />}
            actions={{
                main: {
                    text: t('404_page_cta'),
                    to: routes.home,
                },
            }}
        />
    );
};
