import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import {
    SocialSpacesEndpoints,
    SocialSpacesList,
    getSocialSpaces,
    GetSocialSpacesParams,
} from '../api';

type QueryConfig = UseQueryOptions<SocialSpacesList, AxiosError<ConciergeErrorResponse>>;

export const useGetSocialSpaces = (params: GetSocialSpacesParams, config: QueryConfig = {}) => {
    const query = useQuery<SocialSpacesList, AxiosError<ConciergeErrorResponse>>({
        queryKey: [SocialSpacesEndpoints.GetSocialSpaces, params],
        queryFn: () => getSocialSpaces(params),
        ...config,
    });

    return query;
};
