import { FC } from 'react';
import { ClassNames } from '@emotion/react';
import { SnackbarProvider as NotistackProvider } from 'notistack';

import { theme } from 'src/styles/theme';

interface Props {
    children: JSX.Element;
}

export const SnackbarProvider: FC<Props> = ({ children }) => (
    <ClassNames>
        {({ css }) => (
            <NotistackProvider
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                maxSnack={Infinity}
                disableWindowBlurListener
                classes={{
                    containerAnchorOriginTopRight: css({
                        width: '100%',
                        alignItems: 'unset',

                        [theme.breakpoints.up('md')]: {
                            alignItems: 'flex-end',
                            width: '360px',
                            top: '100px',
                            right: '40px',
                        },
                    }),
                }}
            >
                {children}
            </NotistackProvider>
        )}
    </ClassNames>
);
