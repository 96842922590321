import { FC } from 'react';
import { Autocomplete, AutocompleteProps, Stack, Typography, TextField } from '@mui/material';
import { max, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Booking } from 'src/features/bookings';
import { generateWeeks } from 'src/constants/weeks';

export const WeekSelect: FC<{
    selectedWeek: string | null;
    setSelectedWeek: (option: string | null) => void;
    booking: Booking;
}> = ({ selectedWeek, setSelectedWeek, booking }) => {
    const { t } = useTranslation();

    const currentDateObject = new Date();

    const dateFrom = max([parseISO(booking.date_from), currentDateObject]);
    const dateTo = new Date(dateFrom.getFullYear() + 1, 0, 31);
    const weeks = generateWeeks(dateFrom, dateTo);

    const setWeekValue: AutocompleteProps<string | null, false, false, false>['onChange'] = (
        _,
        value
    ) => {
        setSelectedWeek(value);
    };

    return (
        <Stack marginTop={{ xs: '20px', md: '36px' }}>
            <Typography variant="h3">{t('move_in_week_select_title')}</Typography>

            <Autocomplete
                value={selectedWeek}
                onChange={setWeekValue}
                options={weeks}
                css={{
                    marginTop: '20px',
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={
                            <span>
                                {t('move_in_week_select_label')}{' '}
                                <span style={{ color: 'red' }}>*</span>
                            </span>
                        }
                        placeholder={t('move_in_week_select_placeholder')}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </Stack>
    );
};
