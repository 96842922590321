import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

export const SubjectItem: FC<{
    icon: ReactNode;
    titleKey: string;
    link: string;
    className?: string;
}> = ({ icon, titleKey, link, className }) => {
    const { t } = useTranslation();

    const handleClick = () => {
        GA4.ctaEvent({
            name: 'Feedback Subject Click',
            location: 'Feedback Form',
            label: t(titleKey, { lng: LanguagesCode.English }),
        });
    };

    return (
        <Stack
            direction="row"
            padding="24px 0"
            gap="16px"
            alignItems="center"
            className={className}
            color="text.primary"
            component={Link}
            to={link}
            onClick={handleClick}
            css={{
                textDecoration: 'none',
            }}
        >
            {icon}

            <Typography
                variant="h4"
                aria-label="link title"
                width="fit-content"
                css={{
                    paddingBottom: '2px',
                    position: 'relative',
                }}
            >
                {t(titleKey)}
            </Typography>

            <ArrowIcon
                css={{
                    width: '24px',
                    height: '24px',
                    flexShrink: 0,
                    alignSelf: 'flex-end',
                    marginLeft: 'auto',
                    transform: 'rotate(-90deg)',
                }}
            />
        </Stack>
    );
};
