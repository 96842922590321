import { useTheme } from '@emotion/react';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'src/components/button';

import onboardingData from '../../mocks/onboarding-slides.json';

import { ProposalModal } from './parts/proposal-modal';

export const Onboarding: FC<{ passedOnboarding: boolean; onSkip: VoidFunction }> = ({
    passedOnboarding,
    onSkip,
}) => {
    const { t } = useTranslation();
    const { breakpoints } = useTheme();

    return (
        <ProposalModal
            open={!passedOnboarding}
            title={<Trans i18nKey={onboardingData.title} />}
            content={t(onboardingData.description)}
            image={onboardingData.image}
            footer={
                <Button
                    design="primary"
                    fullWidth
                    onClick={onSkip}
                    css={{
                        [breakpoints.up('md')]: {
                            maxWidth: '340px',
                            alignSelf: 'center',
                        },
                    }}
                >
                    {t(onboardingData.cta)}
                </Button>
            }
        />
    );
};
