import { useTheme } from '@emotion/react';
import { IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';

export const MoveOutInnerIncomplete = () => {
    const { palette, transitions } = useTheme();

    const { t } = useTranslation();

    return (
        <Stack
            padding="20px"
            gap="16px"
            width="100%"
            direction="row"
            css={{
                borderBottom: `1px solid ${palette.divider}`,
                color: palette.common.black,
                position: 'relative',
                pointerEvents: 'none',
            }}
        >
            <WarningIcon
                width="32px"
                height="32px"
                color={palette.error.main}
                css={{ flexShrink: 0 }}
            />

            <Stack flex={1} direction="column" gap="4px">
                <Typography
                    variant="h4"
                    aria-label="link title"
                    width="fit-content"
                    css={{
                        paddingBottom: '4px',
                        position: 'relative',

                        ':after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            height: '1px',
                            width: 0,
                            bottom: 0,
                            background: palette.text.primary,
                            transition: transitions.create('width'),
                        },
                    }}
                >
                    {t('check_out_form_title')}
                </Typography>
                <Typography variant="body2" color={palette.text.secondary}>
                    {t('check_out_form_subtitle_incomplete')}
                </Typography>
            </Stack>

            <IconButton>
                <ArrowIcon
                    css={{
                        color: palette.common.black,
                        width: '24px',
                        height: '24px',

                        transform: 'rotate(-90deg)',
                    }}
                />
            </IconButton>
        </Stack>
    );
};
