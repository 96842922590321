import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableFooter,
    TableContainer,
} from '@mui/material';

import { AppPreloader } from 'src/components/app-preloader';
import { formatAmount } from 'src/utils/format-amount';

import { useGetPayments } from '../../hooks/use-get-payments';

import { PaymentScheduleRow } from './payment-schedule-row';

export const PaymentSchedule: FC<{ bookingId: string }> = ({ bookingId }) => {
    const { t } = useTranslation();
    const { breakpoints } = useTheme();

    const { data: response, isLoading, isError, error, isSuccess } = useGetPayments(bookingId);

    return (
        <div data-testid="PaymentsSchedule.Container">
            <Typography variant="h2" marginBottom="32px">
                {t('my_bookings_payment_schedule_tite')}
            </Typography>

            {isError && (
                <Typography variant="h3" color="error">
                    {JSON.stringify(error)}
                </Typography>
            )}

            {isLoading && <AppPreloader css={{ minHeight: '400px' }} background="paper" />}

            {isSuccess && (
                <TableContainer
                    css={{
                        [breakpoints.down('lg')]: {
                            'td, th': {
                                padding: '10px',
                            },
                        },

                        [breakpoints.down('md')]: {
                            width: 'calc(100% + 32px)',
                            margin: '0 -16px',

                            'td, th': {
                                padding: '8px',
                            },
                        },
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell id="name">
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_schedule_transaction_label')}
                                    </Typography>
                                </TableCell>

                                <TableCell
                                    id="amount_total"
                                    css={{
                                        [breakpoints.down('md')]: {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_schedule_amount_label')}
                                    </Typography>
                                </TableCell>

                                <TableCell
                                    id="amount_paid"
                                    css={{
                                        [breakpoints.down('md')]: {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_schedule_paid_label')}
                                    </Typography>
                                </TableCell>

                                <TableCell id="remaining_amount">
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_schedule_remaining_label')}
                                    </Typography>
                                </TableCell>

                                <TableCell id="date_due">
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_schedule_due_date_label')}
                                    </Typography>
                                </TableCell>

                                {/* 
                                <TableCell>
                                    <Typography color="text.secondary" variant="body2">
                                        {t('my_bookings_payment_schedule_invoice_label')}
                                    </Typography>
                                </TableCell> */}
                            </TableRow>
                        </TableHead>

                        <TableBody data-testid="PaymentsSchedule.TBody">
                            {response.data.map((payment) => (
                                <PaymentScheduleRow key={payment.id} payment={payment} />
                            ))}
                        </TableBody>

                        {response.meta.amount_paid && (
                            <TableFooter data-testid="PaymentsSchedule.TFooter">
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('my_bookings_payment_schedule_total_label')}
                                        </Typography>
                                    </TableCell>

                                    <TableCell
                                        headers="amount_total"
                                        css={{
                                            [breakpoints.down('md')]: {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        <Typography variant="subtitle2" color="text.primary">
                                            {formatAmount(
                                                response.meta.amount_total,
                                                response.meta.currency
                                            )}
                                        </Typography>
                                    </TableCell>

                                    <TableCell
                                        headers="amount_paid"
                                        css={{
                                            [breakpoints.down('md')]: {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        <Typography variant="subtitle2" color="text.primary">
                                            {formatAmount(
                                                response.meta.amount_paid,
                                                response.meta.currency
                                            )}
                                        </Typography>
                                    </TableCell>

                                    <TableCell headers="remaining_amount">
                                        <Typography variant="subtitle2" color="text.primary">
                                            {formatAmount(
                                                response.meta.remaining_amount,
                                                response.meta.currency
                                            )}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};
