import { useTheme } from '@emotion/react';
import { Box, Stack } from '@mui/material';

import { useGetUpcomingBooking } from 'src/features/bookings';
import { useGetCurrentUser } from 'src/features/user';
import { appConfig } from 'src/app/app-config';

import { BookingMoveInDetails } from '../../types/booking-move-in-details';
import { BookingCheckInDetails } from '../../types/booking-check-in-details';
import mockMovingInData from '../../mocks/moving-in-data.json';

import { MoveInCardImage } from './parts/move-in-card-image';
import { CheckIn } from './parts/check-in';
import { MoveInDay } from './parts/move-in-day';
import { MoveInWeek } from './parts/move-in-week';

export const MoveInCard = () => {
    const { palette } = useTheme();

    const { data: user } = useGetCurrentUser();

    const { data: upcomingBooking } = useGetUpcomingBooking();

    if (!upcomingBooking || upcomingBooking.date_check_in) return null;

    const moveInDetails: BookingMoveInDetails = appConfig.mockData
        ? mockMovingInData
        : {
              date_movein: upcomingBooking.date_movein,
              week_movein: upcomingBooking.week_movein,
          };

    const checkInDetails: BookingCheckInDetails = appConfig.mockData
        ? mockMovingInData
        : {
              date_check_in: upcomingBooking.date_check_in,
              date_online_check_in: upcomingBooking.date_online_check_in,
          };

    const isCheckInBooked = Boolean(checkInDetails.date_online_check_in);
    const isMoveInBooked = Boolean(moveInDetails.date_movein && moveInDetails.week_movein);

    const isSecondaryOccupantAndMoveInNotBooked =
        !upcomingBooking.is_main_occupant && !isMoveInBooked;
    if (isSecondaryOccupantAndMoveInNotBooked) return null;

    const bookingId = upcomingBooking.id;
    const accountId = user!.id!;
    const inventoryId = upcomingBooking.room_id;
    const paymentScheduleId = upcomingBooking.payment_schedule_id;

    const checkInFormURL = appConfig.moveIn.checkInFormURL
        .replace('{bookingId}', bookingId)
        .replace('{accountId}', accountId)
        .replace('{inventoryId}', inventoryId)
        .replace('{paymentScheduleId}', paymentScheduleId);

    const currentDate = new Date();
    const dateFrom = new Date(upcomingBooking.date_from);
    const finalMoveInDate = new Date(dateFrom.getFullYear() + 1, 0, 31);

    return (
        <Box
            maxWidth={{ md: '640px' }}
            textAlign="center"
            css={{
                width: '100%',
                background: palette.common.white,
                boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.08)',
            }}
        >
            <Stack textAlign="left" alignItems="center">
                <MoveInCardImage
                    bookingYear={upcomingBooking.year}
                    isComplete={isCheckInBooked && isMoveInBooked}
                />

                {currentDate < finalMoveInDate && (
                    <MoveInWeek
                        moveInDetails={moveInDetails}
                        isMainOccupant={upcomingBooking.is_main_occupant}
                    />
                )}

                {currentDate < finalMoveInDate && (
                    <MoveInDay
                        moveInDetails={moveInDetails}
                        isMainOccupant={upcomingBooking.is_main_occupant}
                    />
                )}

                {upcomingBooking.is_main_occupant && (
                    <CheckIn checkInDetails={checkInDetails} checkInFormURL={checkInFormURL} />
                )}
            </Stack>
        </Box>
    );
};
