import { FC, ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import { Modal, Typography, Box, Stack } from '@mui/material';

interface Props {
    open: boolean;
    title: ReactNode;
    content: ReactNode;
    footer: ReactNode;
    image: { lg: string; md: string };
}

export const ProposalModal: FC<Props> = ({ open, title, content, footer, image }) => {
    const { palette, breakpoints } = useTheme();

    return (
        <Modal open={open}>
            <Stack
                direction={{ lg: 'row' }}
                css={{
                    background: palette.background.default,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                    position: 'absolute',
                    overflow: 'auto',
                    outline: 'none',
                }}
            >
                <Box flex="1 0 50%" maxHeight={{ xs: '40vh', md: 'unset' }} overflow="hidden">
                    <picture>
                        <source
                            media={`(min-width: ${breakpoints.values.lg}px)`}
                            srcSet={image.lg}
                        />

                        <source
                            media={`(min-width: ${breakpoints.values.md}px)`}
                            srcSet={image.md}
                        />

                        <img
                            src={image.md}
                            height="100%"
                            width="100%"
                            alt="slide preview"
                            css={{
                                objectPosition: 'center bottom',
                            }}
                        />
                    </picture>
                </Box>

                <Stack flex="1 0 50%" position="relative">
                    <Box
                        display="grid"
                        padding={{ xs: '56px 16px 0 16px', md: '100px' }}
                        flex={1}
                        overflow="auto"
                        alignItems={{ md: 'center' }}
                    >
                        <div>
                            <Typography variant="h2" marginBottom="48px" textAlign="center">
                                {title}
                            </Typography>

                            {typeof content === 'string' ? (
                                <Typography textAlign="center">{content}</Typography>
                            ) : (
                                content
                            )}
                        </div>
                    </Box>

                    <Stack
                        position="absolute"
                        paddingX="16px"
                        left={0}
                        right={0}
                        bottom={{ xs: '40px', md: '57px' }}
                    >
                        {footer}
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    );
};
