import { FC, useRef, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { max, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Booking } from 'src/features/bookings';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/calendar.svg';
import { useFormattedDate } from 'src/hooks/use-formatted-date';

import { CalendarPicker } from './parts/calendar-picker';

export const MoveInDay: FC<{
    date: string | null;
    setDate: (date: string) => void;
    booking: Booking;
}> = ({ date, setDate, booking }) => {
    const { palette } = useTheme();

    const { t } = useTranslation();

    const buttonRef = useRef<HTMLButtonElement>(null);

    const [isOpen, setIsOpen] = useState(false);

    const selectedDate = date ? parseISO(date) : undefined;
    const currentDate = new Date();
    const bookingFromDate = parseISO(booking.date_from);
    const noSelectedDateFocusedDate = max([bookingFromDate, currentDate]);
    const selectedWeekDates = booking.week_movein && booking.week_movein.split(' - ');
    const noSelectedDateFocusedMonth =
        (selectedWeekDates &&
            selectedWeekDates[0] &&
            new Date(selectedWeekDates[0]).getUTCMonth()) ||
        noSelectedDateFocusedDate.getUTCMonth();

    const [focusedMonth, setFocusedMonth] = useState<number | null>(
        selectedDate?.getUTCMonth() ?? noSelectedDateFocusedMonth
    );
    const [focusedYear, setFocusedYear] = useState<number | null>(
        selectedDate?.getUTCFullYear() ?? noSelectedDateFocusedDate.getUTCFullYear()
    );

    const getFormattedDate = useFormattedDate();

    const moveInDay = date && getFormattedDate(date);

    const toggleOpen = () => setIsOpen(!isOpen);

    const onCloseCalendar = () => setIsOpen(false);

    return (
        <Stack>
            <Typography variant="h3">
                {t('book_your_move_in_slot_step_1_arrival_date_title')}
            </Typography>

            <Typography
                variant="body1"
                color={palette.text.secondary}
                marginTop="20px"
                fontSize="14px"
            >
                {t('book_your_move_in_slot_step_1_check_in_date_label')}
            </Typography>

            <Button
                onClick={toggleOpen}
                component="button"
                ref={buttonRef}
                css={{
                    borderBottom: '1px solid',
                    borderBottomColor: palette.divider,
                    width: '100%',
                    justifyContent: 'space-between',
                    borderRadius: 0,
                    padding: '10px 0',
                    color: palette.common.black,
                    '&:hover': {
                        background: palette.background.paper,
                    },
                }}
            >
                <Typography color={date ? palette.text.primary : palette.text.placeholder}>
                    {date
                        ? moveInDay
                        : t('book_your_move_in_slot_step_1_select_a_date_placeholder')}
                </Typography>

                <CalendarIcon css={{ width: 24, height: 24 }} />
            </Button>

            <CalendarPicker
                buttonElement={buttonRef.current}
                isOpen={isOpen}
                onCloseCalendar={onCloseCalendar}
                selectedDate={date}
                onDateSet={setDate}
                focusedMonth={focusedMonth}
                onMonthSet={setFocusedMonth}
                focusedYear={focusedYear}
                onYearSet={setFocusedYear}
                booking={booking}
            />
        </Stack>
    );
};
