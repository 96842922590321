import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

import { routes } from 'src/constants/routes';
import { NotificationsList } from 'src/features/notifications';

export const NotificationsPage: FC = () => {
    const { breakpoints } = useTheme();

    const isDesktop = useMediaQuery(breakpoints.up('md'));

    return isDesktop ? <Navigate to={routes.home} /> : <NotificationsList isModal={false} />;
};
