import { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Pagination, Skeleton, Stack, Typography } from '@mui/material';

import { ErrorState } from 'src/components/error-state';
import { Button } from 'src/components/button';
import { EmptyList } from 'src/components/empty-list/empty-list';

import { useGetRebookingRooms } from '../../hooks/use-get-rebooking-rooms';
import { useRebookingStore } from '../../store';

import { RebookingCard } from './rebooking-card';
import { RebookingCardSkeleton } from './rebooking-card-skeleton';

export const RebookingList: FC = () => {
    const { data: response, isLoading, isError, refetch } = useGetRebookingRooms();

    const { setPage } = useRebookingStore.getState();

    const { t } = useTranslation();

    const handleRetry = () => refetch();

    useEffect(() => {
        window.scroll({ top: 0 });
    }, [response]);

    if (isLoading) {
        return (
            <Box flex={1}>
                <Skeleton
                    aria-label="list name"
                    variant="rectangular"
                    width="190px"
                    height="21px"
                />

                <Stack gap="16px" marginTop="64px">
                    <RebookingCardSkeleton />
                    <RebookingCardSkeleton />
                    <RebookingCardSkeleton />
                </Stack>
            </Box>
        );
    }

    if (isError) {
        return (
            <Stack flex={1} justifyContent="center" alignItems="center" gap="10px">
                <div>
                    <ErrorState
                        title={<Trans i18nKey="rebookers_swap_your_room_error_state_title" />}
                        css={{ paddingTop: '70px', paddingBottom: '20px' }}
                    />

                    <Button design="primary" onClick={handleRetry} css={{ width: '250px' }}>
                        {t('rebookers_swap_your_room_error_state_retry_cta')}
                    </Button>
                </div>
            </Stack>
        );
    }

    const { data: rebookings, meta: pagination } = response;

    if (!rebookings.length) {
        return (
            <EmptyList
                mainText={<Trans i18nKey="rebookers_swap_your_room_empty_state_title" />}
                secondaryText={<Trans i18nKey="rebookers_swap_your_room_empty_state_subtitle" />}
            />
        );
    }

    return (
        <Box flex={1}>
            <Typography variant="h3" marginBottom="57px" paddingLeft={{ md: '38px', lg: 0 }}>
                {t('rebookers_swap_your_room_available_rooms', { count: pagination.total })}
            </Typography>

            <Stack gap="16px">
                {rebookings.map((room) => (
                    <RebookingCard key={room.id} room={room} />
                ))}
            </Stack>

            <Pagination
                page={pagination.page}
                count={pagination.page_max}
                onChange={(event, page) => setPage(page)}
                css={{
                    padding: '32px 16px',

                    '.MuiPagination-ul': {
                        justifyContent: 'center',
                    },
                }}
            />
        </Box>
    );
};
