import { FC, Fragment } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { endOfDay, isSameDay, max, startOfDay, Interval, parseISO } from 'date-fns';
import { createDateMatrix } from 'date-matrix';

import { Booking } from 'src/features/bookings';
import { isDateOutsideRange } from 'src/utils/is-date-outside-range';

import { isYearOutsideRange } from '../../../utils/is-year-outside-range';
import { isMonthOutsideRange } from '../../../utils/is-month-outside-range';

export const CalendarDates: FC<{
    selectedDate: string | null;
    focusedMonth: number;
    focusedYear: number;
    updateSelectedDate: (date: number) => void;
    booking: Booking;
}> = ({ selectedDate, updateSelectedDate, focusedMonth, focusedYear, booking }) => {
    const { palette } = useTheme();

    const currentDateObject = new Date();

    const dateFrom = max([parseISO(booking.date_from), currentDateObject]);
    const dateTo = new Date(dateFrom.getFullYear() + 1, 0, 31);
    const calendarRange: Interval = { start: startOfDay(dateFrom), end: endOfDay(dateTo) };

    const focusedYearObject = new Date(focusedYear, 1, 1);

    const focusedMonthObject = new Date(focusedYear, focusedMonth, 1);

    const dateMatrix = createDateMatrix(focusedMonthObject);

    const isYearUnavailable = isYearOutsideRange(focusedYearObject, calendarRange);

    const isMonthUnavailable = isMonthOutsideRange(focusedMonthObject, calendarRange);

    return (
        <Box display="grid" gridTemplateColumns="repeat(7,1fr)" padding="16px 0">
            {dateMatrix.weekdays.map((weekday: string) => (
                <Typography
                    key={weekday}
                    variant="body1"
                    marginBottom="9px"
                    textAlign="center"
                    color={palette.text.secondary}
                    css={{
                        fontSize: '14px',
                    }}
                >
                    {weekday}
                </Typography>
            ))}
            {dateMatrix.weeks.map((week) => (
                <Fragment
                    key={`${week[0].day + week[0].type} to ${
                        week[week.length - 1].day + week[week.length - 1].type
                    } `}
                >
                    {week.map((day) => {
                        const date = new Date(focusedYear, focusedMonth, day.day);

                        const isCurrentDate =
                            selectedDate && isSameDay(parseISO(selectedDate), date);

                        const isDateUnavailable = isDateOutsideRange(date, calendarRange);

                        const isUnavailable =
                            !isCurrentDate &&
                            (isYearUnavailable || isMonthUnavailable || isDateUnavailable);

                        if (day.type === 'current')
                            return (
                                <Button
                                    key={day.type + day.day}
                                    css={{
                                        textAlign: 'center',
                                        width: '100%',
                                        padding: '9px',
                                        minWidth: 0,
                                        borderRadius: 0,
                                        ...(isCurrentDate
                                            ? {
                                                  background: palette.common.black,
                                                  color: palette.primary.contrastText,
                                              }
                                            : {
                                                  background: 'none',
                                                  color: palette.text.primary,
                                              }),
                                        ...(isUnavailable && {
                                            textDecoration: 'line-through',
                                            color: palette.text.disabled,
                                            pointerEvents: 'none',
                                        }),
                                        '&:hover': {
                                            background: isCurrentDate
                                                ? palette.common.black
                                                : palette.background.paper,
                                        },
                                    }}
                                    onClick={() => updateSelectedDate(day.day)}
                                >
                                    <Typography variant="body1">{day.day}</Typography>
                                </Button>
                            );

                        return <Box key={day.type + day.day} />;
                    })}
                </Fragment>
            ))}
        </Box>
    );
};
