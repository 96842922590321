import { FC } from 'react';
import { PropsOf, useTheme } from '@emotion/react';

import { ReactComponent as Logo } from 'src/assets/svg/vita-logo.svg';

import { Spinner } from './spinner';

interface Props {
    size?: PropsOf<typeof Spinner>['size'];
    showLogo?: boolean;
    background?: 'default' | 'paper';
    className?: string;
}

export const AppPreloader: FC<Props> = ({ size = 'small', showLogo, background, className }) => {
    const { palette } = useTheme();

    return (
        <div
            className={className}
            css={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',

                background:
                    background &&
                    { paper: palette.background.paper, default: palette.background.default }[
                        background
                    ],
            }}
        >
            {showLogo && (
                <Logo
                    color={palette.primary.main}
                    width={48}
                    height={48}
                    css={{ position: 'absolute' }}
                />
            )}
            <Spinner size={size} color={palette.primary.main} />
        </div>
    );
};
