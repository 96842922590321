import { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TimeIcon } from 'src/assets/svg/time.svg';

const SelectTimeIcon = () => <TimeIcon width={20} height={20} css={{ flexShrink: 0 }} />;

export const TimeDropdown: FC<{
    selectedTime?: string;
    onTimeChange: (newDate: string | null) => void;
    options: string[];
    disabled?: boolean;
}> = ({ selectedTime, onTimeChange, options, disabled }) => {
    const { t } = useTranslation();

    const handleChange: SelectProps<string | null>['onChange'] = (event) => {
        const newTime = event.target.value as string;

        onTimeChange(newTime || null);
    };

    return (
        <FormControl disabled={disabled}>
            <InputLabel id="time" variant="standard" shrink>
                {t('social_space_filter_time')}
            </InputLabel>

            <InputLabel id="time" variant="standard" aria-label="placeholder label">
                {t('social_space_filter_time_placeholder')}
            </InputLabel>

            <Select
                variant="standard"
                labelId="time"
                value={selectedTime ?? ''}
                onChange={handleChange}
                IconComponent={SelectTimeIcon}
            >
                <MenuItem value="">{t('social_space_filter_time_deselected')}</MenuItem>
                {options.map((time) => (
                    <MenuItem key={time} value={time}>
                        {time}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
