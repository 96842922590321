import * as yup from 'yup';

import { attachmentsSchema } from 'src/components/attachment-gallery/validation/attachment-schema';

import { CreateTicketFormFields } from '../constants/create-ticket-form';

const ISSUE_TYPE_TEXT_ONLY_MESSAGE = 'maintenance_report_issue_error_issue-type_text-only';
const ISSUE_TYPE_REQUIRED_MESSAGE = 'maintenance_report_issue_error_issue-type_required';
const ISSUE_TEXT_ONLY_MESSAGE = 'maintenance_report_issue_error_issue_text-only';
const ISSUE_REQUIRED_MESSAGE = 'maintenance_report_issue_error_issue_required';
const PROBLEM_TEXT_ONLY_MESSAGE = 'maintenance_report_issue_error_problem_text-only';
const PROBLEM_REQUIRED_MESSAGE = 'maintenance_report_issue_error_problem_required';
const LOCATION_TEXT_ONLY_MESSAGE = 'maintenance_report_issue_error_location_text-only';
const LOCATION_REQUIRED_MESSAGE = 'maintenance_report_issue_error_location_required';
const DESCRIPTION_TEXT_ONLY_MESSAGE = 'maintenance_report_issue_error_description_text-only';

const MaintenanceTicketSchema = yup.object({
    [CreateTicketFormFields.IssueType]: yup.object({
        name: yup
            .string()
            .typeError(ISSUE_TYPE_TEXT_ONLY_MESSAGE)
            .required(ISSUE_TYPE_REQUIRED_MESSAGE),
    }),

    [CreateTicketFormFields.Issue]: yup.object({
        name: yup.string().typeError(ISSUE_TEXT_ONLY_MESSAGE).required(ISSUE_REQUIRED_MESSAGE),
    }),

    [CreateTicketFormFields.Problem]: yup.object({
        name: yup.string().typeError(PROBLEM_TEXT_ONLY_MESSAGE).required(PROBLEM_REQUIRED_MESSAGE),
    }),

    [CreateTicketFormFields.Location]: yup.object({
        name: yup
            .string()
            .typeError(LOCATION_TEXT_ONLY_MESSAGE)
            .required(LOCATION_REQUIRED_MESSAGE),
    }),

    [CreateTicketFormFields.DescriptionText]: yup.string().typeError(DESCRIPTION_TEXT_ONLY_MESSAGE),

    [CreateTicketFormFields.DescriptionAttachments]: attachmentsSchema,
});

export default MaintenanceTicketSchema;
