export enum FaqCategories {
    Popular = 'popular',
    MovingIn = 'moving-in',
    MovingOut = 'moving-out',
    CancellingBooking = 'cancelling-booking',
    PayingForMyBooking = 'paying-for-my-booking',
    ManageExistingBooking = 'manage-existing-booking',
    IncludedAtVitaStudent = 'included-at-vita-student',
    HomeRules = 'home-rules',
    IncludedAtMyRoom = 'included-at-my-room',
}

export const FAQ_CATEGORIES_LIST = Object.values(FaqCategories);

export const FAQ_TRANSLATE_MAP: Record<FaqCategories, string> = {
    [FaqCategories.Popular]: 'faq_category_popular_questions',
    [FaqCategories.MovingIn]: 'faq_category_move_in',
    [FaqCategories.MovingOut]: 'faq_category_move_out',
    [FaqCategories.CancellingBooking]: 'faq_category_cancel',
    [FaqCategories.PayingForMyBooking]: 'faq_category_payments',
    [FaqCategories.ManageExistingBooking]: 'faq_category_manage',
    [FaqCategories.IncludedAtVitaStudent]: 'faq_category_whats_included_at_vita',
    [FaqCategories.HomeRules]: 'faq_category_home_rules',
    [FaqCategories.IncludedAtMyRoom]: 'faq_category_whats_included_in_room',
};

export const FAQ_DATA = [
    // CATEGORY: move in
    {
        // Can I move in early?
        question: 'faq_question_move_in_can_i_move_in_early',
        answer: 'faq_answer_move_in_can_i_move_in_early',
        categories: [FaqCategories.MovingIn],
    },

    {
        // When is my move in day?
        question: 'faq_question_move_in_when-is-my-move-in-day',
        answer: 'faq_answer_move_in_when-is-my-move-in-day',
        categories: [FaqCategories.MovingIn],
    },

    {
        // Are there specific times of the day that I can move in?
        question: 'faq_question_move_in_are-there-specific-times-of-day-i-can-move-in',
        answer: 'faq_answer_move_in_are-there-specific-times-of-day-i-can-move-in',
        categories: [FaqCategories.MovingIn],
    },

    {
        // What is the address of the building?
        question: 'faq_question_move_in_what_is_the_address_of_building',
        answer: 'faq_answer_move_in_what_is_the_address_of_building',
        categories: [FaqCategories.MovingIn],
    },

    {
        // Is there parking available outside on move in day?
        question: 'faq_question_move_in_is-there-parking-available-on-move-in-day',
        answer: 'faq_answer_move_in_is-there-parking-available-on-move-in-day',
        categories: [FaqCategories.MovingIn],
    },

    {
        // Can my parents stay over whilst moving in?
        question: 'faq_question_move_in_can-my-parents-stay-over-whilst-moving-in',
        answer: 'faq_answer_move_in_can-my-parents-stay-over-whilst-moving-in',
        categories: [FaqCategories.MovingIn],
    },

    {
        // What do I need to bring with me?
        question: 'faq_question_move_in_what-do-i-need-to-bring-with-me',
        answer: 'faq_answer_move_in_what-do-i-need-to-bring-with-me',
        categories: [FaqCategories.MovingIn],
    },

    // CATEGORY: Moving out of Vita Student
    {
        // How do I move out?
        question: 'faq_question_move_out_how-do-i-move-out',
        answer: 'faq_answer_move_out_how-do-i-move-out',
        categories: [FaqCategories.MovingOut],
    },

    // CATEGORY: Cancelling my booking
    {
        // What is your cancellation policy? (22/23)
        question: 'faq_question_cancel_cancellation-policy',
        answer: 'faq_answer_cancel_cancellation-policy',
        categories: [FaqCategories.CancellingBooking],
    },

    {
        // I didn’t get the grades I needed, can I cancel?
        question: 'faq_question_cancel_i-didnt-get-the-grades-i-needed',
        answer: 'faq_answer_cancel_i-didnt-get-the-grades-i-needed',
        categories: [FaqCategories.CancellingBooking],
    },

    {
        // I didn’t get my VISA, can I cancel?
        question: 'faq_question_cancel_i-didnt-get-my-visa',
        answer: 'faq_answer_cancel_i-didnt-get-my-visa',
        categories: [FaqCategories.CancellingBooking],
    },

    {
        // My place on the university course was not confirmed, can I cancel?
        question: 'faq_question_cancel_place-on-the-university-course-was-not-confirmed',
        answer: 'faq_answer_cancel_place-on-the-university-course-was-not-confirmed',
        categories: [FaqCategories.CancellingBooking],
    },

    {
        // Can I leave early and get the remainder of my license fee back?
        question: 'faq_question_cancel_can-i-leave-early-and-get-license-fee-back',
        answer: 'faq_answer_cancel_can-i-leave-early-and-get-license-fee-back',
        categories: [FaqCategories.CancellingBooking],
    },

    // CATEGORY: Paying for my booking
    {
        // How do I make a payment?
        question: 'faq_question_payments_how-do-i-make-a-payment',
        answer: 'faq_answer_payments_how-do-i-make-a-payment',
        categories: [FaqCategories.PayingForMyBooking, FaqCategories.Popular],
    },

    {
        // How can my parents make a payment?
        question: 'faq_question_payments_how-can-my-parents-make-a-payment',
        answer: 'faq_answer_payments_how-can-my-parents-make-a-payment',
        categories: [FaqCategories.PayingForMyBooking],
    },

    {
        // How can I download a Receipt for payment made?
        question: 'faq_question_payments_how-can-i-download-a-receipt-for-payment-made',
        answer: 'faq_answer_payments_how-can-i-download-a-receipt-for-payment-made',
        categories: [FaqCategories.PayingForMyBooking],
    },

    {
        // Can I pay via Bank Transfer?
        question: 'faq_question_payments_can-i-pay-via-bank-transfer',
        answer: 'faq_answer_payments_can-i-pay-via-bank-transfer',
        categories: [FaqCategories.PayingForMyBooking],
    },

    {
        // What currency can I pay in?
        question: 'faq_question_payments_what-currency-can-i-pay-in',
        answer: 'faq_answer_payments_what-currency-can-i-pay-in',
        categories: [FaqCategories.PayingForMyBooking],
    },

    {
        // I already paid but my payment is not showing under my booking - when will I be able to see my payment?
        question: 'faq_question_payments_when-will-i-be-able-to-see-my-payment',
        answer: 'faq_answer_payments_when-will-i-be-able-to-see-my-payment',
        categories: [FaqCategories.PayingForMyBooking],
    },

    {
        // What happens if I can’t make my payment on time?
        question: 'faq_question_payments_what-happens-if-i-cant-make-payment-on-time',
        answer: 'faq_answer_payments_what-happens-if-i-cant-make-payment-on-time',
        categories: [FaqCategories.PayingForMyBooking, FaqCategories.Popular],
    },

    {
        // Can I change my instalment plan?
        question: 'faq_question_payments_can-i-change-my-instalment-plan',
        answer: 'faq_answer_payments_can-i-change-my-instalment-plan',
        categories: [FaqCategories.PayingForMyBooking],
    },

    {
        // Can I delay my payment till I get my grades / visa?
        question: 'faq_question_payments_can-i-delay-my-payment-till-i-get-my-grades-visa',
        answer: 'faq_answer_payments_can-i-delay-my-payment-till-i-get-my-grades-visa',
        categories: [FaqCategories.PayingForMyBooking],
    },

    {
        // What happens if my loan doesn't get paid on time and I can’t pay my instalment before the due date?
        question: 'faq_question_payments_what-happens-if-my-loan-doesnt-get-paid-on-time',
        answer: 'faq_answer_payments_what-happens-if-my-loan-doesnt-get-paid-on-time',
        categories: [FaqCategories.PayingForMyBooking],
    },

    // CATEGORY: Manage or query an existing booking
    {
        // I haven’t received my grades yet
        question: 'faq_question_manage_i-havent-received-my-grades-yet',
        answer: 'faq_answer_manage_i-havent-received-my-grades-yet',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // I haven’t got my visa yet
        question: 'faq_question_manage_i-havent-got-my-visa-yet',
        answer: 'faq_answer_manage_i-havent-got-my-visa-yet',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // What is my booking reference (VITA REF)?
        question: 'faq_question_manage_what-is-my-vita-ref',
        answer: 'faq_answer_manage_what-is-my-vita-ref',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // Can I stay at Vita Student longer?
        question: 'faq_question_manage_can-i-stay-at-vita-student-longer',
        answer: 'faq_answer_manage_can-i-stay-at-vita-student-longer',
        categories: [FaqCategories.ManageExistingBooking, FaqCategories.Popular],
    },

    {
        // Can someone else access my room to drop things off or collect things?
        question: 'faq_uk_question_manage_can-someone-else-access-my-room-to-drop-things-off',
        answer: 'faq_answer_manage_can-someone-else-access-my-room-to-drop-things-off',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // Has my discount been applied?
        question: 'faq_question_manage_has-my-discount-been-applied',
        answer: 'faq_answer_manage_has-my-discount-been-applied',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // Can I swap to a different room or building?
        question: 'faq_question_manage_can-i-swap-into-a-different-room-or-building',
        answer: 'faq_answer_manage_can-i-swap-into-a-different-room-or-building',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // Do you allow couples to stay / visit?
        question: 'faq_question_manage_do-you-allow-couples-to-stay-visit',
        answer: 'faq_answer_manage_do-you-allow-couples-to-stay-visit',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // Can I have guests to stay / visit?
        question: 'faq_question_manage_can-i-have-guests-stay',
        answer: 'faq_answer_manage_can-i-have-guests-stay',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // What bills do I need to pay?
        question: 'faq_question_manage_what-bills-do-i-need-to-pay',
        answer: 'faq_answer_manage_what-bills-do-i-need-to-pay',
        categories: [FaqCategories.ManageExistingBooking],
    },

    {
        // Is contents insurance included in my rent?
        question: 'faq_question_manage_is-contents-insurance-included-in-rent',
        answer: 'faq_answer_manage_is-contents-insurance-included-in-rent',
        categories: [FaqCategories.ManageExistingBooking],
    },

    // CATEGORY: What’s included at Vita Student?
    {
        // What's in my building?
        question: 'faq_question_whats_included_at_vita_whats-in-my-building',
        answer: 'faq_answer_whats_included_at_vita_whats-in-my-building',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },
    {
        // What facilities can I use as part of the early access benefit if I book for next year?
        question:
            'faq_question_whats_included_at_vita_what-facilities-can-i-use-as-part-of-early-access-benefit',
        answer: 'faq_answer_whats_included_at_vita_what-facilities-can-i-use-as-part-of-early-access-benefit',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Is there WiFi in the building?
        question: 'faq_question_whats_included_at_vita_is-there-wifi-in-building',
        answer: 'faq_answer_whats_included_at_vita_is-there-wifi-in-building',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // How fast is the internet connection in the building?
        question: 'faq_question_whats_included_at_vita_how-fast-is-the-internet-connection',
        answer: 'faq_answer_whats_included_at_vita_how-fast-is-the-internet-connection',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Is there a common room?
        question: 'faq_question_whats_included_at_vita_is-there-a-common-room',
        answer: 'faq_answer_whats_included_at_vita_is-there-a-common-room',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Where can I store my bicycle?
        question: 'faq_question_whats_included_at_vita_where-can-i-store-my-bicycle',
        answer: 'faq_answer_whats_included_at_vita_where-can-i-store-my-bicycle',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Can I bring my car?
        question: 'faq_question_whats_included_at_vita_can-i-bring-my-car',
        answer: 'faq_answer_whats_included_at_vita_can-i-bring-my-car',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Can family and friends bring their cars when they come to visit?
        question: 'faq_question_whats_included_at_vita_can-family-and-friends-bring-their-cars',
        answer: 'faq_answer_whats_included_at_vita_can-family-and-friends-bring-their-cars',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Do you have a gym?
        question: 'faq_question_whats_included_at_vita_do-you-have-a-gym',
        answer: 'faq_answer_whats_included_at_vita_do-you-have-a-gym',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Who should I contact if I have a problem with the internet?
        question:
            'faq_question_whats_included_at_vita_who-should-i-contact-if-i-have-problems-with-internet',
        answer: 'faq_answer_whats_included_at_vita_who-should-i-contact-if-i-have-problems-with-internet',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Where do I wash my clothes?
        question: 'faq_question_whats_included_at_vita_where-do-i-wash-my-clothes',
        answer: 'faq_answer_whats_included_at_vita_where-do-i-wash-my-clothes',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // Is detergent provided?
        question: 'faq_question_whats_included_at_vita_is-detergent-provided',
        answer: 'faq_answer_whats_included_at_vita_is-detergent-provided',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // What’s Nearby?
        question: 'faq_question_whats_included_at_vita_whats-nearby',
        answer: 'faq_answer_whats_included_at_vita_whats-nearby',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // How do I access the printer located in reception?
        question: 'faq_question_whats_included_at_vita_how-do-i-access-the-printer',
        answer: 'faq_answer_whats_included_at_vita_how-do-i-access-the-printer',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // How much does it cost to print at the printer in reception?
        question: 'faq_question_whats_included_at_vita_how-much-does-it-cost-to-print',
        answer: 'faq_answer_whats_included_at_vita_how-much-does-it-cost-to-print',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    {
        // What if I have forgotten my username and password for the printer?
        question: 'faq_question_whats_included_at_vita_forgot-username-and-password-for-printer',
        answer: 'faq_answer_whats_included_at_vita_forgot-username-and-password-for-printer',
        categories: [FaqCategories.IncludedAtVitaStudent],
    },

    // CATEGORY: What’s included in my room?
    {
        // Is there a TV in my room?
        question: 'faq_question_whats_included_in_room_is-there-a-tv-in-my-room',
        answer: 'faq_answer_whats_included_in_room_is-there-a-tv-in-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // What happens if there is a breakage or problem with my room?
        question:
            'faq_question_whats_included_in_room_what-happens-if-there-is-a-breakage-or-problem',
        answer: 'faq_answer_whats_included_in_room_what-happens-if-there-is-a-breakage-or-problem',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // Do I have to supply my own food?
        question: 'faq_question_whats_included_in_room_do-i-have-to-supply-my-own-food',
        answer: 'faq_answer_whats_included_in_room_do-i-have-to-supply-my-own-food',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // Can my friend store their belongings in my room?
        question:
            'faq_question_whats_included_in_room_can-my-friend-store-their-belongings-in-my-room',
        answer: 'faq_answer_whats_included_in_room_can-my-friend-store-their-belongings-in-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // How safe is my room?
        question: 'faq_question_whats_included_in_room_how-safe-is-my-room',
        answer: 'faq_answer_whats_included_in_room_how-safe-is-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // Can I smoke in my room?
        question: 'faq_question_whats_included_in_room_can-i-smoke-in-room',
        answer: 'faq_answer_whats_included_in_room_can-i-smoke-in-room',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // What are the unacceptable electronic items and why do Vita Student have them?
        question: 'faq_question_whats_included_in_room_what-are-the-unacceptable-electronic-items',
        answer: 'faq_answer_whats_included_in_room_what-are-the-unacceptable-electronic-items',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // What are the unacceptable electronic items and why do Vita Student have them?
        question: 'faq_question_whats_included_in_room_do-i-need-to-bring-my-own-saucepans',
        answer: 'faq_answer_whats_included_in_room_do-i-need-to-bring-my-own-saucepans',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    {
        // Is bedding included in my room?
        question: 'faq_question_whats_included_in_room_is-bedding-included-in-my-room',
        answer: 'faq_answer_whats_included_in_room_is-bedding-included-in-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
    },

    // CATEGORY: House rules, disclaimers & wellbeing
    {
        // What are the Vita Student house rules?
        question: 'faq_question_home-rules_disclaimers',
        answer: 'faq_answer_home-rules_disclaimers',
        categories: [FaqCategories.HomeRules],
    },

    {
        // Do you have any wellbeing documents?
        question: 'faq_question_home-rules_documents',
        answer: 'faq_answer_home-rules_documents',
        categories: [FaqCategories.HomeRules],
    },

    {
        // Can I view Vita Student Resident Engagement Strategy?
        question: 'faq_question_resident_engagement_strategy',
        answer: 'faq_answer_resident_engagement_strategy',
        categories: [FaqCategories.HomeRules],
    },
];
